export const _saveExamProgress = async function() {
  const { levelId, currentScore, previousScore, answersIndex } = this.state;
  // currentScore = 90;
  const { updateExamProgress } = this.props;
  console.log(currentScore, previousScore);
  console.log("The old Score is " + previousScore);
  await updateExamProgress(currentScore, levelId, answersIndex);
  // if (previousScore < currentScore || currentScore >= 100) {
  //   console.log("The new Score is " + currentScore);
  //   await updateExamProgress(currentScore, levelId, answersIndex);
  // } else {
  //   console.log("No");
  //   this.props.history.push(`/business-portal/exams/`);
  // }
};

export const getTransText = (languageCode, content, translationObj) => {
  if (content.includes("TXT", 0)) {
    return checkIfIsHtml(translationObj[languageCode][content].text);
  } else {
    return checkIfIsHtml(content);
  }
};

export const checkIfIsHtml = content => {
  if (content.includes("<")) {
    return { text: content, isHtml: true };
  } else {
    return { text: content, isHtml: false };
  }
};

export const lessonPageInit = (lesson, courseId, auth) => {
  //retrive any potential progress information

  let isAllLessonsCompleted = false;

  let remainingLessonList = [];
  // console.log(auth.progressInfo);
  let lessonIdList =
    auth.progressInfo.lessons &&
    auth.progressInfo.lessons[courseId] &&
    auth.progressInfo.lessons[courseId][lesson.docId]
      ? auth.progressInfo.lessons[courseId][lesson.docId]
      : [];
  remainingLessonList = lesson.scenes.filter(
    item => !lessonIdList.includes(item.docId)
  );

  //check if all lesson completed, if yes then start all over again...
  if (remainingLessonList.length === 0) {
    isAllLessonsCompleted = true;
  }

  return { isAllLessonsCompleted };
};

export const _saveLessonProgress = async function(
  courseId,
  lessonId,
  seenScene,
  location
) {
  const { updateLessonProgress } = this.props;

  let lessonIdList = seenScene;

  //retrieve previously saved lessonId list & ignore save if it's completely identical or nothing to be saved(exp: access 1st lesson and click up buttom)

  await updateLessonProgress(lessonIdList, courseId, lessonId);
  this.props.history.push(location);
};

export const _saveProbingQuestingProgress = async function(
  questionId,
  seenContents
) {
  // console.log(questionId, seenContents);
  const { updateProbingQuestionProgress } = this.props;

  let lessonIdList = seenContents;

  //retrieve previously saved lessonId list & ignore save if it's completely identical or nothing to be saved(exp: access 1st lesson and click up buttom)

  await updateProbingQuestionProgress(lessonIdList, questionId, seenContents);
  window.history.back();
};
