import React, { Component, Fragment } from "react";
import { Row, Col, Button, message, Spin, Tooltip } from "antd";
import EbookCoverPreview from "../EbookCoverPreview";
import "./Template0001.css";
import _ from "lodash";
import FunnelPageTopBar from "../FunnelPageTopBar/FunnelPageTopBar";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  saveSalesFunnel,
  loadFunnelPageData,
  loadFunnelPageDataOnlyBackground,
  resetSalesFunnelData
} from "../salesFunnel/salesFunnelActions";
import { operationTypeEnum } from "../OperationTypeConstant";
import {
  salesFunnelPageModeEnum,
  minFontSize,
  maxFontSize
} from "../SharedConstant";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { withFirebase } from "react-redux-firebase";
import HeaderTextDrawer from "../HeaderTextDrawer";
import Outline1TextDrawer from "../Outline1TextDrawer";
import Outline2TextDrawer from "../Outline2TextDrawer";
import Outline3TextDrawer from "../Outline3TextDrawer";
import Outline4TextDrawer from "../Outline4TextDrawer";
import Outline5TextDrawer from "../Outline5TextDrawer";
import BottomDesc1TextDrawer from "../BottomDesc1TextDrawer";
import BottomDesc2TextDrawer from "../BottomDesc2TextDrawer";
import BottomDesc3TextDrawer from "../BottomDesc3TextDrawer";
import ButtonDrawer from "../ButtonDrawer";
import EBookDrawer from "../EBookDrawer";
import {
  DOWNLOADNOW,
  ENTERYOUROUTLINE,
  EDITBOTTOMDESCRIPTION,
  EDITTITLEANDOUTLINE,
  ENTERYOURDESCRIPTION,
  SAVINGSALESFUNNEL
} from "../BusinessPortal/TranslationConstant";

class Template0001 extends Component {
  eBookCoverContainerRef = React.createRef();
  headerTextareaRef = React.createRef();
  outline1TextareaRef = React.createRef();
  outline2TextareaRef = React.createRef();
  outline3TextareaRef = React.createRef();
  outline4TextareaRef = React.createRef();
  outline5TextareaRef = React.createRef();
  bottomDesc1TextareaRef = React.createRef();
  bottomDesc2TextareaRef = React.createRef();
  bottomDesc3TextareaRef = React.createRef();

  state = {
    isLoading: true,
    dataReadiness: {
      funnelPageReady: false
    },
    isPublic: false,
    isEBookDrawerVisible: false,
    isHeaderTextDrawerVisible: false,
    isOutline1TextDrawerVisible: false,
    isOutline2TextDrawerVisible: false,
    isOutline3TextDrawerVisible: false,
    isOutline4TextDrawerVisible: false,
    isBottomDesc1TextDrawerVisible: false,
    isBottomDesc2TextDrawerVisible: false,
    isBottomDesc3TextDrawerVisible: false,
    isButtonDrawerVisible: false,
    eBookCoverObj: {
      bookNameObj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "40",
        color: ""
      },
      authorObj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "18",
        color: ""
      },
      tagline1Obj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "20",
        color: ""
      },
      tagline2Obj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "20",
        color: ""
      },
      error: false,
      selected: false,
      isTooltipVisible: true
    },
    buttonObj: {
      buttonUrl: "",
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "20",
      color: "",
      isTooltipVisible: true
    },
    headerObj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "24",
      color: ""
    },
    outline1Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "20",
      color: ""
    },
    outline2Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "20",
      color: ""
    },
    outline3Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "20",
      color: ""
    },
    outline4Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "20",
      color: ""
    },
    outline5Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "20",
      color: "",
      isVisible: true
    },
    bottomDesc1Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "25",
      color: "",
      isTooltipVisible: true
    },
    bottomDesc2Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "25",
      color: "",
      isTooltipVisible: true
    },
    bottomDesc3Obj: {
      text: "",
      error: false,
      selected: false,
      fontFamily: "Lato-Regular",
      fontSize: "25",
      color: "",
      isTooltipVisible: true
    },
    formImageObj: {
      profileImageObj: {
        imageDisplayList: [],
        imageLocalList: [],
        imageDBList: [],
        imageDBToRemoveList: []
      }
    },
    backgroundImageUrls: null,
    selectedBackgroundIdx: 0,
    windowWidth: window.innerWidth,
    eBookContainerWidth: 0,
    eBookContainerHeight: 0,
    isTitleAndOutlineTooltipVisible: true
  };

  async componentDidMount() {
    const {
      match,
      salesFunnelPageMode,
      domain,
      loadFunnelPageData,
      loadFunnelPageDataOnlyBackground
    } = this.props;
    let funnelPageId = match.params.funnelPageId;

    if (salesFunnelPageMode === salesFunnelPageModeEnum.PUBLIC) {
      await loadFunnelPageData(domain.urlInfoObj.docId);
    }

    if (salesFunnelPageMode === salesFunnelPageModeEnum.UPDATE) {
      await loadFunnelPageData(funnelPageId);
    }

    if (salesFunnelPageMode === salesFunnelPageModeEnum.NEW) {
      await loadFunnelPageDataOnlyBackground();
    }
    window.addEventListener("resize", this.onHandleResize);
  }

  componentWillUnmount() {
    this.props.resetSalesFunnelData();
    window.removeEventListener("resize", this.onHandleResize);
  }

  async componentDidUpdate(prevProps) {
    const { isLoading, dataReadiness } = this.state;
    const {
      firebase,
      operation,
      salesFunnelPageMode,
      salesFunnel,
      book,
      image,
      dbAuth
    } = this.props;

    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.LOAD_FUNNEL_PAGE &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        let backgroundImagePromises = [];
        image.funnelPageImageObj.backgroundImagePathList.forEach(item => {
          backgroundImagePromises.push(storageRef.child(item).getDownloadURL());
        });
        let backgroundImageUrls = await Promise.all(backgroundImagePromises);

        if (
          salesFunnelPageMode === salesFunnelPageModeEnum.PUBLIC ||
          salesFunnelPageMode === salesFunnelPageModeEnum.UPDATE
        ) {
          let funnelPageObj = salesFunnel.funnelPageObj;

          let profileImageUrl = "";
          // if (funnelPageObj.profileImagePath.length > 0) {
          //   profileImageUrl = await storageRef
          //     .child(funnelPageObj.profileImagePath)
          //     .getDownloadURL();
          // }

          let backgroundImagePathInArr = funnelPageObj.backgroundImagePath.split(
            "/"
          );
          // console.log(
          //   "TT",
          //   backgroundImagePathInArr[backgroundImagePathInArr.length - 1]
          // );
          let selectedBackgroundIdx = 0;
          for (
            let idx = 0;
            idx < image.funnelPageImageObj.backgroundImagePathList.length;
            idx++
          ) {
            let templateBackgroundImagePathInArr = image.funnelPageImageObj.backgroundImagePathList[
              idx
            ].split("/");
            if (
              templateBackgroundImagePathInArr[
                templateBackgroundImagePathInArr.length - 1
              ] ===
              backgroundImagePathInArr[backgroundImagePathInArr.length - 1]
            ) {
              selectedBackgroundIdx = idx;
              break;
            }
          }
          this.setState(prevState => ({
            eBookCoverObj: {
              ...prevState.eBookCoverObj,
              bookNameObj: funnelPageObj.eBookCoverInfo.bookNameInfo,
              authorObj: funnelPageObj.eBookCoverInfo.authorInfo,
              tagline1Obj: funnelPageObj.eBookCoverInfo.tagline1Info,
              tagline2Obj: funnelPageObj.eBookCoverInfo.tagline2Info,
              isTooltipVisible: false
            },
            headerObj: {
              ...prevState.headerObj,
              ...funnelPageObj.headerInfo
            },
            outline1Obj: {
              ...prevState.outline1Obj,
              ...funnelPageObj.outline1Info
            },
            outline2Obj: {
              ...prevState.outline2Obj,
              ...funnelPageObj.outline2Info
            },
            outline3Obj: {
              ...prevState.outline3Obj,
              ...funnelPageObj.outline3Info
            },
            outline4Obj: {
              ...prevState.outline4Obj,
              ...funnelPageObj.outline4Info
            },
            outline5Obj: {
              ...prevState.outline5Obj,
              ...funnelPageObj.outline5Info
            },
            buttonObj: {
              ...prevState.buttonObj,
              ...funnelPageObj.buttonInfo,
              isTooltipVisible: false
            },
            bottomDesc1Obj: {
              ...prevState.bottomDesc1Obj,
              ...funnelPageObj.bottomDesc1Info,
              isTooltipVisible: false
            },
            bottomDesc2Obj: {
              ...prevState.bottomDesc2Obj,
              ...funnelPageObj.bottomDesc2Info,
              isTooltipVisible: false
            },
            bottomDesc3Obj: {
              ...prevState.bottomDesc3Obj,
              ...funnelPageObj.bottomDesc3Info,
              isTooltipVisible: false
            },
            isTitleAndOutlineTooltipVisible: false,
            isPublic: salesFunnelPageMode === salesFunnelPageModeEnum.PUBLIC,
            //isPublic : true, //testing ONLY
            formImageObj: {
              profileImageObj: {
                imageDisplayList:
                  profileImageUrl.length > 0 ? [profileImageUrl] : [],
                imageDBList:
                  profileImageUrl.length > 0 ? [profileImageUrl] : [],
                imageLocalList: [],
                imageDBToRemoveList: []
              }
            },
            backgroundImageUrls,
            dataReadiness: {
              funnelPageReady: true
            },
            selectedBackgroundIdx
          }));
        }

        if (salesFunnelPageMode === salesFunnelPageModeEnum.NEW) {
          this.setState(prevState => ({
            eBookCoverObj: {
              ...prevState.eBookCoverObj,
              bookNameObj: {
                ...prevState.eBookCoverObj.bookNameObj,
                text: book.selectedEBook.bookName
              }
            },
            buttonObj: {
              ...prevState.buttonObj,
              text:
                DOWNLOADNOW[
                  dbAuth.userInfo.nativeLanguage
                    ? dbAuth.userInfo.nativeLanguage
                    : "en"
                ]
            },
            bottomDesc1Obj: {
              ...prevState.bottomDesc1Obj
            },
            bottomDesc2Obj: {
              ...prevState.bottomDesc2Obj
            },
            bottomDesc3Obj: {
              ...prevState.bottomDesc3Obj
            },
            backgroundImageUrls,
            dataReadiness: {
              funnelPageReady: true
            }
          }));
        }
      }
    }

    if (isLoading && dataReadiness.funnelPageReady) {
      this.setState({
        isLoading: false
      });

      if (
        this.headerTextareaRef.current &&
        this.headerTextareaRef.current.style
      ) {
        this.headerTextareaRef.current.style.height =
          this.headerTextareaRef.current.scrollHeight + "px";
      }

      if (
        this.outline1TextareaRef.current &&
        this.outline1TextareaRef.current.style
      ) {
        this.outline1TextareaRef.current.style.height =
          this.outline1TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.outline2TextareaRef.current &&
        this.outline2TextareaRef.current.style
      ) {
        this.outline2TextareaRef.current.style.height =
          this.outline2TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.outline3TextareaRef.current &&
        this.outline3TextareaRef.current.style
      ) {
        this.outline3TextareaRef.current.style.height =
          this.outline3TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.outline4TextareaRef.current &&
        this.outline4TextareaRef.current.style
      ) {
        this.outline4TextareaRef.current.style.height =
          this.outline4TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.outline5TextareaRef.current &&
        this.outline5TextareaRef.current.style
      ) {
        this.outline5TextareaRef.current.style.height =
          this.outline5TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.bottomDesc1TextareaRef.current &&
        this.bottomDesc1TextareaRef.current.style
      ) {
        this.bottomDesc1TextareaRef.current.style.height =
          this.bottomDesc1TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.bottomDesc2TextareaRef.current &&
        this.bottomDesc2TextareaRef.current.style
      ) {
        this.bottomDesc2TextareaRef.current.style.height =
          this.bottomDesc2TextareaRef.current.scrollHeight + "px";
      }

      if (
        this.bottomDesc3TextareaRef.current &&
        this.bottomDesc3TextareaRef.current.style
      ) {
        this.bottomDesc3TextareaRef.current.style.height =
          this.bottomDesc3TextareaRef.current.scrollHeight + "px";
      }
    }

    //notification related
    if (
      prevProps.operation.operationType ===
        operationTypeEnum.SAVE_FUNNEL_PAGE &&
      operation.operationType === operationTypeEnum.NO_OPS
    ) {
      message.success("Funnel page saved successfully.");
    }

    if (
      prevProps.operation.operationType ===
        operationTypeEnum.SAVE_FUNNEL_PAGE &&
      operation.operationType === operationTypeEnum.UNRECOVERABLE_ERROR
    ) {
      message.error(
        "Issues occured when saving funnel page. Please contact admin."
      );
      this.setState({
        dataReadiness: { funnelPageReady: true }
      });
    }

    //set initial container width using latest state
    if (this.state.eBookContainerWidth === 0) {
      if (this.eBookCoverContainerRef.current) {
        this.setState({
          eBookContainerWidth: this.eBookCoverContainerRef.current.offsetWidth
        });
      }
    }

    //set initial container height using latest state
    if (this.state.eBookContainerHeight === 0) {
      if (this.eBookCoverContainerRef.current) {
        this.setState({
          eBookContainerHeight: this.eBookCoverContainerRef.current.offsetHeight
        });
      }
    }
  }

  onHandleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      eBookContainerWidth: this.eBookCoverContainerRef.current.offsetWidth,
      eBookContainerHeight: this.eBookCoverContainerRef.current.offsetHeight
    });

    this.headerTextareaRef.current.style.height = "auto";
    this.headerTextareaRef.current.style.height = `${this.headerTextareaRef.current.scrollHeight}px`;

    this.outline1TextareaRef.current.style.height = "auto";
    this.outline1TextareaRef.current.style.height = `${this.outline1TextareaRef.current.scrollHeight}px`;

    this.outline2TextareaRef.current.style.height = "auto";
    this.outline2TextareaRef.current.style.height = `${this.outline2TextareaRef.current.scrollHeight}px`;

    this.outline3TextareaRef.current.style.height = "auto";
    this.outline3TextareaRef.current.style.height = `${this.outline3TextareaRef.current.scrollHeight}px`;

    this.outline4TextareaRef.current.style.height = "auto";
    this.outline4TextareaRef.current.style.height = `${this.outline4TextareaRef.current.scrollHeight}px`;

    this.outline5TextareaRef.current.style.height = "auto";
    this.outline5TextareaRef.current.style.height = `${this.outline5TextareaRef.current.scrollHeight}px`;

    this.bottomDesc1TextareaRef.current.style.height = "auto";
    this.bottomDesc1TextareaRef.current.style.height = `${this.bottomDesc1TextareaRef.current.scrollHeight}px`;

    this.bottomDesc2TextareaRef.current.style.height = "auto";
    this.bottomDesc2TextareaRef.current.style.height = `${this.bottomDesc2TextareaRef.current.scrollHeight}px`;

    this.bottomDesc3TextareaRef.current.style.height = "auto";
    this.bottomDesc3TextareaRef.current.style.height = `${this.bottomDesc3TextareaRef.current.scrollHeight}px`;
  };

  onHandleOpenEBookDrawerClick = () => {
    const { isPublic } = this.state;
    if (!isPublic) {
      this.setState(prevState => ({
        isEBookDrawerVisible: true,
        eBookCoverObj: {
          ...prevState.eBookCoverObj,
          selected: true,
          isTooltipVisible: false
        }
      }));
    }
  };

  onHandleCloseEBookDrawerClick = () => {
    this.setState(prevState => ({
      isEBookDrawerVisible: false,
      eBookCoverObj: {
        ...prevState.eBookCoverObj,
        selected: false
      }
    }));
  };

  onHandleBookBackgroundImageClick = selectedBackgroundIdx => {
    this.setState({
      selectedBackgroundIdx
    });
  };

  onHandleEbookEditFormValueChange = changedValues => {
    this.setState({
      ...changedValues
    });
  };

  onHandleAcceptedFilesFromDropzone = (acceptedFiles, targetedImageObjName) => {
    //filter just one image/file
    let file = acceptedFiles[0];

    let randomNumber =
      new Date().toISOString() + "_" + Math.round(Math.random() * 1e10);
    let fileObj = new File(
      [file],
      `${randomNumber}.${file.type.split("/")[1]}`,
      { type: file.type }
    );

    let uploadedFile = Object.assign(fileObj, {
      preview: URL.createObjectURL(fileObj)
    });

    this.setState(prevState => {
      //   let newImageLocalListTmp = [
      //     ...prevState.formImageObj[targetedImageObjName].imageLocalList,
      //     uploadedFile
      //   ];

      //   let newImageDBListTmp = [
      //     ...prevState.formImageObj[targetedImageObjName].imageDBList
      //   ];

      //   newImageDBListTmp = newImageDBListTmp.filter(
      //     (url, idx) =>
      //       !prevState.formImageObj[
      //         targetedImageObjName
      //       ].imageDBToRemoveList.includes(idx)
      //   );

      return {
        formImageObj: {
          ...prevState.formImageObj,
          [targetedImageObjName]: {
            ...prevState.formImageObj[targetedImageObjName],
            imageDisplayList: [uploadedFile],
            imageLocalList: [uploadedFile]
            // imageDBToRemoveList:
            //   prevState.formImageObj[targetedImageObjName].imageDBToRemoveList
            //     .length > 0
            //     ? [
            //         prevState.formImageObj[targetedImageObjName]
            //           .imageDBToRemoveList[0]
            //       ]
            //     : []
          }
        }
      };
    });
  };

  //== TODO: NEW CODE STARTING HERE...

  onHandleOpenHeaderTextDrawerClick = () => {
    this.setState(prevState => ({
      isHeaderTextDrawerVisible: true,
      headerObj: {
        ...prevState.headerObj,
        selected: true
      },
      isTitleAndOutlineTooltipVisible: false
    }));
  };
  onHandleCloseHeaderTextDrawerClick = () => {
    this.setState(prevState => ({
      isHeaderTextDrawerVisible: false,
      headerObj: {
        ...prevState.headerObj,
        selected: false
      }
    }));
  };

  onHandleOpenOutline1TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline1TextDrawerVisible: true,
      outline1Obj: {
        ...prevState.outline1Obj,
        selected: true
      },
      isTitleAndOutlineTooltipVisible: false
    }));
  };
  onHandleCloseOutline1TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline1TextDrawerVisible: false,
      outline1Obj: {
        ...prevState.outline1Obj,
        selected: false
      }
    }));
  };

  onHandleOpenOutline2TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline2TextDrawerVisible: true,
      outline2Obj: {
        ...prevState.outline2Obj,
        selected: true
      },
      isTitleAndOutlineTooltipVisible: false
    }));
  };
  onHandleCloseOutline2TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline2TextDrawerVisible: false,
      outline2Obj: {
        ...prevState.outline2Obj,
        selected: false
      }
    }));
  };

  onHandleOpenOutline3TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline3TextDrawerVisible: true,
      outline3Obj: {
        ...prevState.outline3Obj,
        selected: true
      },
      isTitleAndOutlineTooltipVisible: false
    }));
  };
  onHandleCloseOutline3TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline3TextDrawerVisible: false,
      outline3Obj: {
        ...prevState.outline3Obj,
        selected: false
      }
    }));
  };

  onHandleOpenOutline4TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline4TextDrawerVisible: true,
      outline4Obj: {
        ...prevState.outline4Obj,
        selected: true
      },
      isTitleAndOutlineTooltipVisible: false
    }));
  };
  onHandleCloseOutline4TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline4TextDrawerVisible: false,
      outline4Obj: {
        ...prevState.outline4Obj,
        selected: false
      }
    }));
  };

  onHandleOpenOutline5TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline5TextDrawerVisible: true,
      outline5Obj: {
        ...prevState.outline5Obj,
        selected: true
      },
      isTitleAndOutlineTooltipVisible: false
    }));
  };
  onHandleCloseOutline5TextDrawerClick = () => {
    this.setState(prevState => ({
      isOutline5TextDrawerVisible: false,
      outline5Obj: {
        ...prevState.outline5Obj,
        selected: false
      }
    }));
  };

  onHandleOpenBottomDesc1TextDrawerClick = () => {
    this.setState(prevState => ({
      isBottomDesc1TextDrawerVisible: true,
      bottomDesc1Obj: {
        ...prevState.bottomDesc1Obj,
        selected: true,
        isTooltipVisible: false
      }
    }));
  };
  onHandleCloseBottomDesc1TextDrawerClick = () => {
    this.setState(prevState => ({
      isBottomDesc1TextDrawerVisible: false,
      bottomDesc1Obj: {
        ...prevState.bottomDesc1Obj,
        selected: false
      }
    }));
  };

  onHandleOpenBottomDesc2TextDrawerClick = () => {
    this.setState(prevState => ({
      isBottomDesc2TextDrawerVisible: true,
      bottomDesc2Obj: {
        ...prevState.bottomDesc2Obj,
        selected: true,
        isTooltipVisible: false
      }
    }));
  };
  onHandleCloseBottomDesc2TextDrawerClick = () => {
    this.setState(prevState => ({
      isBottomDesc2TextDrawerVisible: false,
      bottomDesc2Obj: {
        ...prevState.bottomDesc2Obj,
        selected: false
      }
    }));
  };

  onHandleOpenBottomDesc3TextDrawerClick = () => {
    this.setState(prevState => ({
      isBottomDesc3TextDrawerVisible: true,
      bottomDesc3Obj: {
        ...prevState.bottomDesc3Obj,
        selected: true,
        isTooltipVisible: false
      }
    }));
  };

  onHandleCloseBottomDesc3TextDrawerClick = () => {
    this.setState(prevState => ({
      isBottomDesc3TextDrawerVisible: false,
      bottomDesc3Obj: {
        ...prevState.bottomDesc3Obj,
        selected: false
      }
    }));
  };

  // onDrawerInputMultiDependencyTextChange = () => {};

  //==

  onEBookDrawerInputTextChange = (e, inputType) => {
    let value = e.target.value;

    this.setState(prevState => {
      let isValid = false;
      if (inputType === "bookNameObj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "authorObj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "tagline1Obj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "tagline2Obj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize;
      }

      return {
        eBookCoverObj: {
          ...prevState.eBookCoverObj,
          [inputType]: {
            ...prevState.eBookCoverObj[inputType],
            text: value
          },
          error: prevState.eBookCoverObj.error
            ? !isValid
            : prevState.eBookCoverObj.error
        }
      };
    });
  };

  onEBookDrawerAutoCompleteTextChange = (value, inputType) => {
    this.setState(prevState => {
      let isValid = false;
      if (inputType === "bookNameObj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "authorObj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "tagline1Obj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "tagline2Obj") {
        isValid =
          value.length > 0 &&
          prevState.eBookCoverObj[inputType].fontSize > minFontSize &&
          prevState.eBookCoverObj[inputType].fontSize < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize;
      }

      return {
        eBookCoverObj: {
          ...prevState.eBookCoverObj,
          [inputType]: {
            ...prevState.eBookCoverObj[inputType],
            text: value
          },
          error: prevState.eBookCoverObj.error
            ? !isValid
            : prevState.eBookCoverObj.error
        }
      };
    });
  };

  onEBookDrawerInputFontFamilyChange = (value, inputType, pos) => {
    this.setState(prevState => {
      let fontFamilyArr = prevState.eBookCoverObj[inputType].fontFamily.split(
        "-"
      );
      fontFamilyArr[pos] = value;
      return {
        eBookCoverObj: {
          ...prevState.eBookCoverObj,
          [inputType]: {
            ...prevState.eBookCoverObj[inputType],
            fontFamily: fontFamilyArr.join("-")
          }
        }
      };
    });
  };

  onEBookDrawerInputFontSizeChange = (e, inputType) => {
    let value = e.target.value;

    this.setState(prevState => {
      let isValid = false;
      if (inputType === "bookNameObj") {
        isValid =
          value > minFontSize &&
          prevState.eBookCoverObj[inputType].text.length > 0 &&
          value < maxFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "authorObj") {
        isValid =
          value > minFontSize &&
          prevState.eBookCoverObj[inputType].text.length > 0 &&
          value < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "tagline1Obj") {
        isValid =
          value > minFontSize &&
          prevState.eBookCoverObj[inputType].text.length > 0 &&
          value < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline2Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline2Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline2Obj.fontSize < maxFontSize;
      } else if (inputType === "tagline2Obj") {
        isValid =
          value > minFontSize &&
          prevState.eBookCoverObj[inputType].text.length > 0 &&
          value < maxFontSize &&
          prevState.eBookCoverObj.bookNameObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.bookNameObj.text.length > 0 &&
          prevState.eBookCoverObj.bookNameObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.authorObj.fontSize > minFontSize &&
          prevState.eBookCoverObj.authorObj.text.length > 0 &&
          prevState.eBookCoverObj.authorObj.fontSize < maxFontSize &&
          prevState.eBookCoverObj.tagline1Obj.fontSize > minFontSize &&
          prevState.eBookCoverObj.tagline1Obj.text.length > 0 &&
          prevState.eBookCoverObj.tagline1Obj.fontSize < maxFontSize;
      }

      return {
        eBookCoverObj: {
          ...prevState.eBookCoverObj,
          [inputType]: {
            ...prevState.eBookCoverObj[inputType],
            fontSize: value
          },
          error: prevState.eBookCoverObj.error
            ? !isValid
            : prevState.eBookCoverObj.error
        }
      };
    });
  };

  onEBookDrawerInputColorChange = (value, inputType) => {
    this.setState(prevState => ({
      eBookCoverObj: {
        ...prevState.eBookCoverObj,
        [inputType]: {
          ...prevState.eBookCoverObj[inputType],
          color: value.rgba
        }
      }
    }));
  };
  //==

  onButtonUrlChange = e => {
    let value = e.target.value;
    this.setState(prevState => ({
      buttonObj: {
        ...prevState.buttonObj,
        buttonUrl: value,
        error: prevState.buttonObj.error
          ? !(
              prevState.buttonObj.text.length > 0 &&
              prevState.buttonObj.buttonUrl.length > 0
            )
          : prevState.buttonObj.error
      }
    }));
  };

  onDrawerInputTextChange = (value, inputType) => {
    this.setState(
      prevState => {
        let isValid = false;
        if (inputType === "buttonObj") {
          isValid =
            value.length > 0 &&
            prevState[inputType].fontSize > minFontSize &&
            prevState[inputType].fontSize < maxFontSize &&
            prevState[inputType].buttonUrl.length > 0;
        } else {
          //typical fontsize & input
          isValid =
            value.length > 0 &&
            prevState[inputType].fontSize > minFontSize &&
            prevState[inputType].fontSize < maxFontSize;
        }
        return {
          [inputType]: {
            ...prevState[inputType],
            text: value,
            error: prevState[inputType].error
              ? !isValid
              : prevState[inputType].error
            // reset error IFF constraint passed
          }
        };
      },
      () => {
        if (value.length === 0) {
          this.headerTextareaRef.current.style.height = "auto";

          this.outline1TextareaRef.current.style.height = "auto";

          this.outline2TextareaRef.current.style.height = "auto";

          this.outline3TextareaRef.current.style.height = "auto";

          this.outline4TextareaRef.current.style.height = "auto";

          this.outline5TextareaRef.current.style.height = "auto";

          this.bottomDesc1TextareaRef.current.style.height = "auto";

          this.bottomDesc2TextareaRef.current.style.height = "auto";

          this.bottomDesc3TextareaRef.current.style.height = "auto";
        } else {
          this.headerTextareaRef.current.style.height = "inherit";
          this.headerTextareaRef.current.style.height = `${this.headerTextareaRef.current.scrollHeight}px`;

          this.outline1TextareaRef.current.style.height = "inherit";
          this.outline1TextareaRef.current.style.height = `${this.outline1TextareaRef.current.scrollHeight}px`;

          this.outline2TextareaRef.current.style.height = "inherit";
          this.outline2TextareaRef.current.style.height = `${this.outline2TextareaRef.current.scrollHeight}px`;

          this.outline3TextareaRef.current.style.height = "inherit";
          this.outline3TextareaRef.current.style.height = `${this.outline3TextareaRef.current.scrollHeight}px`;

          this.outline4TextareaRef.current.style.height = "inherit";
          this.outline4TextareaRef.current.style.height = `${this.outline4TextareaRef.current.scrollHeight}px`;

          this.outline5TextareaRef.current.style.height = "inherit";
          this.outline5TextareaRef.current.style.height = `${this.outline5TextareaRef.current.scrollHeight}px`;

          this.bottomDesc1TextareaRef.current.style.height = "inherit";
          this.bottomDesc1TextareaRef.current.style.height = `${this.bottomDesc1TextareaRef.current.scrollHeight}px`;

          this.bottomDesc2TextareaRef.current.style.height = "inherit";
          this.bottomDesc2TextareaRef.current.style.height = `${this.bottomDesc2TextareaRef.current.scrollHeight}px`;

          this.bottomDesc3TextareaRef.current.style.height = "inherit";
          this.bottomDesc3TextareaRef.current.style.height = `${this.bottomDesc3TextareaRef.current.scrollHeight}px`;
        }
      }
    );
  };

  onDrawerInputColorChange = (value, inputType) => {
    this.setState(prevState => ({
      [inputType]: {
        ...prevState[inputType],
        color: value.rgba
      }
    }));
  };

  onDrawerInputFontSizeChange = (e, inputType) => {
    let value = e.target.value;

    this.setState(
      prevState => ({
        [inputType]: {
          ...prevState[inputType],
          fontSize: value,
          error: prevState[inputType].error
            ? !(
                value > minFontSize &&
                prevState[inputType].text.length > 0 &&
                value < maxFontSize
              )
            : prevState[inputType].error
          // reset error IFF both input length & fontSize constraint passed
        }
      }),
      () => {
        this.headerTextareaRef.current.style.height = "auto";
        this.headerTextareaRef.current.style.height = `${this.headerTextareaRef.current.scrollHeight}px`;

        this.outline1TextareaRef.current.style.height = "auto";
        this.outline1TextareaRef.current.style.height = `${this.outline1TextareaRef.current.scrollHeight}px`;

        this.outline2TextareaRef.current.style.height = "auto";
        this.outline2TextareaRef.current.style.height = `${this.outline2TextareaRef.current.scrollHeight}px`;

        this.outline3TextareaRef.current.style.height = "auto";
        this.outline3TextareaRef.current.style.height = `${this.outline3TextareaRef.current.scrollHeight}px`;

        this.outline4TextareaRef.current.style.height = "auto";
        this.outline4TextareaRef.current.style.height = `${this.outline4TextareaRef.current.scrollHeight}px`;

        this.outline5TextareaRef.current.style.height = "auto";
        this.outline5TextareaRef.current.style.height = `${this.outline5TextareaRef.current.scrollHeight}px`;

        this.bottomDesc1TextareaRef.current.style.height = "auto";
        this.bottomDesc1TextareaRef.current.style.height = `${this.bottomDesc1TextareaRef.current.scrollHeight}px`;

        this.bottomDesc2TextareaRef.current.style.height = "auto";
        this.bottomDesc2TextareaRef.current.style.height = `${this.bottomDesc2TextareaRef.current.scrollHeight}px`;

        this.bottomDesc3TextareaRef.current.style.height = "auto";
        this.bottomDesc3TextareaRef.current.style.height = `${this.bottomDesc3TextareaRef.current.scrollHeight}px`;
      }
    );
  };

  onDrawerInputFontFamilyChange = (value, inputType, pos) => {
    this.setState(
      prevState => {
        let fontFamilyArr = prevState[inputType].fontFamily.split("-");
        fontFamilyArr[pos] = value;
        return {
          [inputType]: {
            ...prevState[inputType],
            fontFamily: fontFamilyArr.join("-")
          }
        };
      },
      () => {
        this.headerTextareaRef.current.style.height = "auto";
        this.headerTextareaRef.current.style.height = `${this.headerTextareaRef.current.scrollHeight}px`;

        this.outline1TextareaRef.current.style.height = "auto";
        this.outline1TextareaRef.current.style.height = `${this.outline1TextareaRef.current.scrollHeight}px`;

        this.outline2TextareaRef.current.style.height = "auto";
        this.outline2TextareaRef.current.style.height = `${this.outline2TextareaRef.current.scrollHeight}px`;

        this.outline3TextareaRef.current.style.height = "auto";
        this.outline3TextareaRef.current.style.height = `${this.outline3TextareaRef.current.scrollHeight}px`;

        this.outline4TextareaRef.current.style.height = "auto";
        this.outline4TextareaRef.current.style.height = `${this.outline4TextareaRef.current.scrollHeight}px`;

        this.outline5TextareaRef.current.style.height = "auto";
        this.outline5TextareaRef.current.style.height = `${this.outline5TextareaRef.current.scrollHeight}px`;

        this.bottomDesc1TextareaRef.current.style.height = "auto";
        this.bottomDesc1TextareaRef.current.style.height = `${this.bottomDesc1TextareaRef.current.scrollHeight}px`;

        this.bottomDesc2TextareaRef.current.style.height = "auto";
        this.bottomDesc2TextareaRef.current.style.height = `${this.bottomDesc2TextareaRef.current.scrollHeight}px`;

        this.bottomDesc3TextareaRef.current.style.height = "auto";
        this.bottomDesc3TextareaRef.current.style.height = `${this.bottomDesc3TextareaRef.current.scrollHeight}px`;
      }
    );
  };

  onHandleOpenButtonDrawerClick = () => {
    const { isPublic, buttonObj } = this.state;
    if (isPublic) {
      if (!buttonObj.buttonUrl.match(/^https?:\/\//i)) {
        let newUrl = "http://" + buttonObj.buttonUrl;
        window.open(newUrl, "_blank");
      } else {
        window.open(buttonObj.buttonUrl, "_blank");
      }
    } else {
      this.setState(prevState => ({
        isButtonDrawerVisible: true,
        buttonObj: {
          ...prevState.buttonObj,
          selected: true,
          isTooltipVisible: false
        }
      }));
    }
  };
  onHandleCloseButtonDrawerClick = () => {
    this.setState(prevState => ({
      isButtonDrawerVisible: false,
      buttonObj: {
        ...prevState.buttonObj,
        selected: false
      }
    }));
  };

  onHandleInputValueChange = (e, inputField, inputFieldErrorFlag) => {
    this.setState({
      [inputField]: e.target.value,
      [inputFieldErrorFlag]: false //always clear error if something entered...
    });
  };

  onHandleMultiDependencyInputValueChange = (
    e,
    inputField,
    dependentInputFieldList,
    inputFieldErrorFlag
  ) => {
    let inputValue = e.target.value;

    let isIncomplete = false;
    dependentInputFieldList.forEach(dependentInputField => {
      if (this.state[dependentInputField].length === 0) {
        isIncomplete = true;
      }
    });

    //hack with profile image check here....
    // if (imageObj) {
    //   if (imageObj.imageDisplayList.length === 0) {
    //     isIncomplete = true;
    //   }
    // }

    if (isIncomplete) {
      //skip clearing flag if incomplete
      this.setState({
        [inputField]: inputValue
      });
    } else {
      this.setState({
        [inputField]: inputValue,
        [inputFieldErrorFlag]: false
      });
    }
  };

  onHandleBackButtonClick = () => {
    //this.props.history.goBack();
    // this.props.history.replace("/portal/main");
    this.props.history.replace("/business-portal/sales-funnel");
  };

  onHandleSaveButtonClick = () => {
    const {
      eBookCoverObj,
      headerObj,
      outline1Obj,
      outline2Obj,
      outline3Obj,
      outline4Obj,
      outline5Obj,
      buttonObj,
      bottomDesc1Obj,
      bottomDesc2Obj,
      bottomDesc3Obj,
      formImageObj
    } = this.state;
    const {
      match,
      auth,
      salesFunnel,
      saveSalesFunnel,
      salesFunnelPageMode
    } = this.props;

    let bookId;
    if (salesFunnelPageMode === salesFunnelPageModeEnum.NEW) {
      bookId = match.params.bookId;
    } else if (salesFunnelPageMode === salesFunnelPageModeEnum.UPDATE) {
      bookId = salesFunnel.funnelPageObj.bookId;
    }

    let errorObjLabelList = [];

    if (
      eBookCoverObj.bookNameObj.text.length === 0 ||
      eBookCoverObj.bookNameObj.fontSize <= minFontSize ||
      eBookCoverObj.bookNameObj.fontSize >= maxFontSize ||
      eBookCoverObj.authorObj.text.length === 0 ||
      eBookCoverObj.authorObj.fontSize <= minFontSize ||
      eBookCoverObj.authorObj.fontSize >= maxFontSize ||
      eBookCoverObj.tagline1Obj.text.length === 0 ||
      eBookCoverObj.tagline1Obj.fontSize <= minFontSize ||
      eBookCoverObj.tagline1Obj.fontSize >= maxFontSize ||
      eBookCoverObj.tagline2Obj.text.length === 0 ||
      eBookCoverObj.tagline2Obj.fontSize <= minFontSize ||
      eBookCoverObj.tagline2Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("eBookCoverObj");
    }

    if (
      headerObj.text.length === 0 ||
      headerObj.fontSize <= minFontSize ||
      headerObj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("headerObj");
    }

    if (
      outline1Obj.text.length === 0 ||
      outline1Obj.fontSize <= minFontSize ||
      outline1Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("outline1Obj");
    }

    if (
      outline2Obj.text.length === 0 ||
      outline2Obj.fontSize <= minFontSize ||
      outline2Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("outline2Obj");
    }

    if (
      outline3Obj.text.length === 0 ||
      outline3Obj.fontSize <= minFontSize ||
      outline3Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("outline3Obj");
    }

    if (
      outline4Obj.text.length === 0 ||
      outline4Obj.fontSize <= minFontSize ||
      outline4Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("outline4Obj");
    }

    if (
      (outline5Obj.text.length === 0 && outline5Obj.isVisible) ||
      outline5Obj.fontSize <= minFontSize ||
      outline5Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("outline5Obj");
    }

    if (
      buttonObj.text.length === 0 ||
      buttonObj.fontSize <= minFontSize ||
      buttonObj.fontSize >= maxFontSize ||
      buttonObj.buttonUrl.length === 0
    ) {
      errorObjLabelList.push("buttonObj");
    }

    if (
      bottomDesc1Obj.text.length === 0 ||
      bottomDesc1Obj.fontSize <= minFontSize ||
      bottomDesc1Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("bottomDesc1Obj");
    }
    if (
      bottomDesc2Obj.text.length === 0 ||
      bottomDesc2Obj.fontSize <= minFontSize ||
      bottomDesc2Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("bottomDesc2Obj");
    }
    if (
      bottomDesc3Obj.text.length === 0 ||
      bottomDesc3Obj.fontSize <= minFontSize ||
      bottomDesc3Obj.fontSize >= maxFontSize
    ) {
      errorObjLabelList.push("bottomDesc3Obj");
    }

    if (errorObjLabelList.length > 0) {
      errorObjLabelList.forEach(errorObjLabel => {
        this.setState(prevState => ({
          [errorObjLabel]: {
            ...prevState[errorObjLabel],
            error: true
          }
        }));
      });

      message.error(
        "Your funnel page details are incomplete. Please double check."
      );
    } else {
      this.setState(
        {
          dataReadiness: {
            funnelPageReady: false
          }
        },
        async () => {
          await saveSalesFunnel(
            this.state,
            auth.uid,
            formImageObj.profileImageObj,
            salesFunnelPageMode === salesFunnelPageModeEnum.UPDATE,
            bookId
          );
        }
      );
    }
  };

  onHideOutlineCheckboxValueChange = (e, inputType) => {
    let value = e.target.checked;
    this.setState(prevState => ({
      [inputType]: {
        ...prevState[inputType],
        isVisible: !value,
        error: false
      }
    }));
  };

  render() {
    const { dbAuth } = this.props;
    const {
      isPublic,
      isHeaderTextDrawerVisible,
      isOutline1TextDrawerVisible,
      isOutline2TextDrawerVisible,
      isOutline3TextDrawerVisible,
      isOutline4TextDrawerVisible,
      isOutline5TextDrawerVisible,
      isBottomDesc1TextDrawerVisible,
      isBottomDesc2TextDrawerVisible,
      isBottomDesc3TextDrawerVisible,
      isButtonDrawerVisible,
      isEBookDrawerVisible,
      buttonObj,
      eBookCoverObj,
      headerObj,
      outline1Obj,
      outline2Obj,
      outline3Obj,
      outline4Obj,
      outline5Obj,
      bottomDesc1Obj,
      bottomDesc2Obj,
      bottomDesc3Obj,
      formImageObj,
      isLoading,
      backgroundImageUrls,
      selectedBackgroundIdx,
      dataReadiness,
      windowWidth,
      eBookContainerWidth,
      eBookContainerHeight,
      isTitleAndOutlineTooltipVisible
    } = this.state;

    if (isLoading) return <LoadingComponent auth={dbAuth} />;

    return (
      <Fragment>
        <Spin
          tip={
            SAVINGSALESFUNNEL[
              dbAuth.userInfo
                ? dbAuth.userInfo.nativeLanguage
                  ? dbAuth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
          spinning={!dataReadiness.funnelPageReady}
        >
          {!isPublic && (
            <FunnelPageTopBar
              onSave={this.onHandleSaveButtonClick}
              onBack={this.onHandleBackButtonClick}
              windowWidth={windowWidth}
              auth={dbAuth}
            />
          )}

          <Row>
            <Col span={24}>
              <div
                className="template0001-funnel-page-title-wrapper"
                style={{
                  background: "#3A3939",
                  color: "white",
                  fontFamily: "PTSans-Regular",
                  padding: "16px"
                }}
              >
                Marketing Guru
              </div>
            </Col>
            <Col
              span={24}
              style={{
                background:
                  "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(/assets/funnel-page-background.jpg)",
                backgroundSize: "cover"
              }}
            >
              <Row>
                <Col
                  xs={{ offset: 1, span: 22 }}
                  sm={{ offset: 2, span: 20 }}
                  md={{ offset: 2, span: 20 }}
                  lg={{ offset: 2, span: 20 }}
                  xl={{ offset: 2, span: 20 }}
                  style={{
                    background: "rgba(89, 89, 89, 0.46)",
                    borderRadius: "16px",
                    marginTop: "24px",
                    marginBottom: "24px"
                  }}
                >
                  <Row style={{ margin: "24px 0px" }}>
                    <Col
                      xs={{ offset: 0, span: 24 }}
                      sm={{ offset: 0, span: 24 }}
                      md={{ offset: 0, span: 24 }}
                      lg={{ offset: 0, span: 24 }}
                      xl={{ offset: 2, span: 9 }}
                    >
                      <Row>
                        <Col
                          span={24}
                          onClick={this.onHandleOpenEBookDrawerClick}
                          ref={this.eBookCoverContainerRef}
                        >
                          <Tooltip
                            title={
                              ENTERYOURDESCRIPTION[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                            color="#FBAF41"
                            visible={eBookCoverObj.isTooltipVisible}
                            //add class
                          >
                            <EbookCoverPreview
                              eBookContainerHeight={eBookContainerHeight}
                              eBookContainerWidth={eBookContainerWidth}
                              eBookCoverObj={eBookCoverObj}
                              backgroundImage={
                                backgroundImageUrls.length > 0
                                  ? backgroundImageUrls[selectedBackgroundIdx]
                                  : ""
                              }
                              profileImageObj={formImageObj.profileImageObj}
                              isPublic={isPublic}
                              auth={dbAuth}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={0}
                      style={{ marginTop: "24px" }}
                    />

                    <Col
                      xs={{ offset: 0, span: 24 }}
                      sm={{ offset: 0, span: 24 }}
                      md={{ offset: 0, span: 24 }}
                      lg={{ offset: 0, span: 24 }}
                      xl={{ offset: 1, span: 10 }}
                      style={{
                        background: "#3A3939",
                        borderRadius: "8px",
                        minHeight: eBookContainerHeight
                      }}
                    >
                      <Tooltip
                        title={
                          EDITTITLEANDOUTLINE[
                            dbAuth.userInfo
                              ? dbAuth.userInfo.nativeLanguage
                                ? dbAuth.userInfo.nativeLanguage
                                : "en"
                              : "en"
                          ]
                        }
                        color="#FBAF41"
                        placement="topRight"
                        visible={isTitleAndOutlineTooltipVisible}
                      >
                        <Row
                          style={{
                            borderRadius: "8px",
                            margin: "8px"
                          }}
                          className={
                            "title-and-outline-container" +
                            (isTitleAndOutlineTooltipVisible
                              ? " not-selected"
                              : " selected")
                          }
                        >
                          <Col
                            offset={1}
                            span={22}
                            style={{ marginTop: "8px" }}
                          >
                            <div
                              className={
                                "template0001-header-textarea" +
                                (isPublic ? " public" : " non-public") +
                                (headerObj.selected
                                  ? " selected"
                                  : " not-selected")
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "16px",
                                padding: "0px 8px"
                              }}
                            >
                              <textarea
                                ref={this.headerTextareaRef}
                                value={headerObj.text}
                                disabled={isPublic}
                                placeholder="Enter your title here"
                                readOnly
                                style={{
                                  resize: "none",
                                  background: "transparent",
                                  // fontSize: headerObj.fontSize + "px",
                                  fontSize:
                                    headerObj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  fontFamily: headerObj.fontFamily,
                                  border: "none",
                                  outline: "none",
                                  color: headerObj.color,
                                  textAlign: "center",
                                  flex: 1 //replacement for width in flex box
                                }}
                                rows={1}
                                onClick={this.onHandleOpenHeaderTextDrawerClick}
                              />

                              <div style={{ width: "20px" }}>
                                {headerObj.error ? (
                                  <img src="/assets/error-icon.svg" alt="" />
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col span={1} />

                          <br />
                          <br />

                          <Col offset={1} span={22}>
                            <div
                              className={
                                "template0001-outline1-textarea" +
                                (isPublic ? " public" : " non-public") +
                                (outline1Obj.selected
                                  ? " selected"
                                  : " not-selected")
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "16px",
                                padding: "0px 8px",
                                margin: "8px 0px"
                              }}
                            >
                              <span
                                style={{
                                  // fontSize: outline1Obj.fontSize + "px",
                                  fontSize:
                                    outline1Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  color:
                                    outline1Obj.color.length > 0
                                      ? outline1Obj.color
                                      : "#FFFFFF"
                                }}
                              >
                                •&nbsp;
                              </span>
                              <textarea
                                ref={this.outline1TextareaRef}
                                value={outline1Obj.text}
                                disabled={isPublic}
                                placeholder={
                                  ENTERYOUROUTLINE[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                                readOnly
                                style={{
                                  resize: "none",
                                  background: "transparent",
                                  // fontSize: outline1Obj.fontSize + "px",
                                  fontSize:
                                    outline1Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  fontFamily: outline1Obj.fontFamily,
                                  border: "none",
                                  outline: "none",
                                  color: outline1Obj.color,
                                  flex: 1
                                }}
                                rows={1}
                                onClick={
                                  this.onHandleOpenOutline1TextDrawerClick
                                }
                              />

                              <div style={{ width: "20px" }}>
                                {outline1Obj.error ? (
                                  <img src="/assets/error-icon.svg" alt="" />
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col span={1} />
                          <Col offset={1} span={22}>
                            <div
                              className={
                                "template0001-outline2-textarea" +
                                (isPublic ? " public" : " non-public") +
                                (outline2Obj.selected
                                  ? " selected"
                                  : " not-selected")
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "16px",
                                padding: "0px 8px",
                                margin: "8px 0px"
                              }}
                            >
                              <span
                                style={{
                                  // fontSize: outline2Obj.fontSize + "px",
                                  fontSize:
                                    outline2Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  color:
                                    outline2Obj.color.length > 0
                                      ? outline2Obj.color
                                      : "#FFFFFF"
                                }}
                              >
                                •&nbsp;
                              </span>
                              <textarea
                                ref={this.outline2TextareaRef}
                                value={outline2Obj.text}
                                disabled={isPublic}
                                placeholder={
                                  ENTERYOUROUTLINE[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                                readOnly
                                style={{
                                  resize: "none",
                                  background: "transparent",
                                  // fontSize: outline2Obj.fontSize + "px",
                                  fontSize:
                                    outline2Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  fontFamily: outline2Obj.fontFamily,
                                  border: "none",
                                  outline: "none",
                                  color: outline2Obj.color,
                                  flex: 1
                                }}
                                rows={1}
                                onClick={
                                  this.onHandleOpenOutline2TextDrawerClick
                                }
                              />

                              <div style={{ width: "20px" }}>
                                {outline2Obj.error ? (
                                  <img src="/assets/error-icon.svg" alt="" />
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col span={1} />
                          <Col offset={1} span={22}>
                            <div
                              className={
                                "template0001-outline3-textarea" +
                                (isPublic ? " public" : " non-public") +
                                (outline3Obj.selected
                                  ? " selected"
                                  : " not-selected")
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "16px",
                                padding: "0px 8px",
                                margin: "8px 0px"
                              }}
                            >
                              <span
                                style={{
                                  // fontSize: outline3Obj.fontSize + "px",
                                  fontSize:
                                    outline3Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  color:
                                    outline3Obj.color.length > 0
                                      ? outline3Obj.color
                                      : "#FFFFFF"
                                }}
                              >
                                •&nbsp;
                              </span>
                              <textarea
                                ref={this.outline3TextareaRef}
                                value={outline3Obj.text}
                                disabled={isPublic}
                                placeholder={
                                  ENTERYOUROUTLINE[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                                readOnly
                                style={{
                                  resize: "none",
                                  background: "transparent",
                                  // fontSize: outline3Obj.fontSize + "px",
                                  fontSize:
                                    outline3Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  fontFamily: outline3Obj.fontFamily,
                                  border: "none",
                                  outline: "none",
                                  color: outline3Obj.color,
                                  flex: 1
                                }}
                                rows={1}
                                onClick={
                                  this.onHandleOpenOutline3TextDrawerClick
                                }
                              />

                              <div style={{ width: "20px" }}>
                                {outline3Obj.error ? (
                                  <img src="/assets/error-icon.svg" alt="" />
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col span={1} />
                          <Col offset={1} span={22}>
                            <div
                              className={
                                "template0001-outline4-textarea" +
                                (isPublic ? " public" : " non-public") +
                                (outline4Obj.selected
                                  ? " selected"
                                  : " not-selected")
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "16px",
                                padding: "0px 8px",
                                margin: "8px 0px"
                              }}
                            >
                              <span
                                style={{
                                  // fontSize: outline4Obj.fontSize + "px",
                                  fontSize:
                                    outline4Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  color:
                                    outline4Obj.color.length > 0
                                      ? outline4Obj.color
                                      : "#FFFFFF"
                                }}
                              >
                                •&nbsp;
                              </span>
                              <textarea
                                ref={this.outline4TextareaRef}
                                value={outline4Obj.text}
                                disabled={isPublic}
                                placeholder={
                                  ENTERYOUROUTLINE[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                                readOnly
                                style={{
                                  resize: "none",
                                  background: "transparent",
                                  // fontSize: outline4Obj.fontSize + "px",
                                  fontSize:
                                    outline4Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  fontFamily: outline4Obj.fontFamily,
                                  border: "none",
                                  outline: "none",
                                  color: outline4Obj.color,
                                  flex: 1
                                }}
                                rows={1}
                                onClick={
                                  this.onHandleOpenOutline4TextDrawerClick
                                }
                              />

                              <div style={{ width: "20px" }}>
                                {outline4Obj.error ? (
                                  <img src="/assets/error-icon.svg" alt="" />
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col span={1} />
                          <Col offset={1} span={22}>
                            <div
                              className={
                                "template0001-outline5-textarea" +
                                (isPublic ? " public" : " non-public") +
                                (outline5Obj.selected
                                  ? " selected"
                                  : " not-selected")
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "16px",
                                padding: "0px 8px",
                                margin: "8px 0px"
                              }}
                            >
                              <span
                                style={{
                                  // fontSize: outline5Obj.fontSize + "px",
                                  fontSize:
                                    outline5Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  color:
                                    outline5Obj.color.length > 0
                                      ? outline5Obj.color
                                      : "#FFFFFF",
                                  visibility: outline5Obj.isVisible
                                    ? "visible"
                                    : "hidden"
                                }}
                              >
                                •&nbsp;
                              </span>
                              <textarea
                                ref={this.outline5TextareaRef}
                                value={
                                  outline5Obj.isVisible ? outline5Obj.text : ""
                                }
                                disabled={isPublic}
                                placeholder={
                                  outline5Obj.isVisible
                                    ? ENTERYOUROUTLINE[
                                        dbAuth.userInfo
                                          ? dbAuth.userInfo.nativeLanguage
                                            ? dbAuth.userInfo.nativeLanguage
                                            : "en"
                                          : "en"
                                      ]
                                    : ""
                                }
                                readOnly
                                style={{
                                  resize: "none",
                                  background: "transparent",
                                  // fontSize: outline5Obj.fontSize + "px",
                                  fontSize:
                                    outline5Obj.fontSize *
                                      (eBookContainerWidth /
                                        widthScaleDownRatio) +
                                    "%",
                                  fontFamily: outline5Obj.fontFamily,
                                  border: "none",
                                  outline: "none",
                                  color: outline5Obj.color,
                                  flex: 1
                                }}
                                rows={1}
                                onClick={
                                  this.onHandleOpenOutline5TextDrawerClick
                                }
                              />

                              <div style={{ width: "20px" }}>
                                {outline5Obj.error ? (
                                  <img src="/assets/error-icon.svg" alt="" />
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col span={1} />
                        </Row>
                      </Tooltip>

                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "100%"
                        }}
                      >
                        <Row>
                          <Col offset={3} span={18}>
                            <Tooltip
                              title="Edit button text here"
                              color="#FBAF41"
                              placement="topRight"
                              visible={buttonObj.isTooltipVisible}
                            >
                              <Button
                                // className="template0001-ebook-button-wrapper"
                                style={{
                                  background: "#FBAF41",
                                  borderRadius: "4px",
                                  height: "auto",
                                  padding: "8px"
                                }}
                                block
                                onClick={this.onHandleOpenButtonDrawerClick}
                              >
                                <div
                                  style={{
                                    whiteSpace: "normal",
                                    overflowWrap: "break-word", //new version of wordWrap?
                                    // fontSize: buttonObj.fontSize + "px",
                                    fontSize:
                                      buttonObj.fontSize *
                                        (eBookContainerWidth /
                                          widthScaleDownRatio) +
                                      "%",
                                    fontFamily: buttonObj.fontFamily,
                                    color:
                                      buttonObj.color.length > 0
                                        ? buttonObj.color
                                        : "#FFFFFF"
                                  }}
                                >
                                  {buttonObj.text.length > 0
                                    ? buttonObj.text
                                    : "Button Text Placeholder"}
                                  {buttonObj.error && (
                                    <Fragment>
                                      &nbsp;&nbsp;
                                      <img
                                        src="/assets/error-icon.svg"
                                        alt=""
                                      />
                                    </Fragment>
                                  )}
                                </div>
                              </Button>
                            </Tooltip>
                          </Col>

                          <Col span={3} />
                        </Row>
                      </div>
                      <br />
                    </Col>

                    <Col span={2} />
                  </Row>
                </Col>

                <Col
                  xs={{ span: 1 }}
                  sm={{ span: 2 }}
                  md={{ span: 2 }}
                  lg={{ span: 2 }}
                  xl={{ span: 2 }}
                />

                <Col span={24}>
                  <Row
                    justify="space-around"
                    align="middle"
                    style={{
                      background: "#ADADAD"
                    }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={7}>
                      <Tooltip
                        title={
                          EDITBOTTOMDESCRIPTION[
                            dbAuth.userInfo
                              ? dbAuth.userInfo.nativeLanguage
                                ? dbAuth.userInfo.nativeLanguage
                                : "en"
                              : "en"
                          ]
                        }
                        color="#FBAF41"
                        visible={bottomDesc1Obj.isTooltipVisible}
                        className="edit-bottom-tooltip"
                      >
                        <div
                          className={
                            "template0001-bottomDesc1-textarea" +
                            (isPublic ? " public" : " non-public") +
                            (bottomDesc1Obj.selected
                              ? " selected"
                              : " not-selected")
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "16px",
                            padding: "0px 8px",
                            margin: "16px 0px"
                          }}
                        >
                          <textarea
                            ref={this.bottomDesc1TextareaRef}
                            value={bottomDesc1Obj.text}
                            disabled={isPublic}
                            placeholder={
                              ENTERYOURDESCRIPTION[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                            readOnly
                            style={{
                              resize: "none",
                              background: "transparent",
                              fontSize: bottomDesc1Obj.fontSize + "px",
                              fontFamily: bottomDesc1Obj.fontFamily,
                              border: "none",
                              outline: "none",
                              color: bottomDesc1Obj.color,
                              textAlign: "center",
                              flex: 1 //replacement for width in flex box
                            }}
                            rows={1}
                            onClick={
                              this.onHandleOpenBottomDesc1TextDrawerClick
                            }
                          />

                          <div style={{ width: "20px" }}>
                            {bottomDesc1Obj.error ? (
                              <img src="/assets/error-icon.svg" alt="" />
                            ) : (
                              <span />
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={7}>
                      <Tooltip
                        title={
                          EDITBOTTOMDESCRIPTION[
                            dbAuth.userInfo
                              ? dbAuth.userInfo.nativeLanguage
                                ? dbAuth.userInfo.nativeLanguage
                                : "en"
                              : "en"
                          ]
                        }
                        color="#FBAF41"
                        visible={bottomDesc2Obj.isTooltipVisible}
                      >
                        <div
                          className={
                            "template0001-bottomDesc2-textarea" +
                            (isPublic ? " public" : " non-public") +
                            (bottomDesc2Obj.selected
                              ? " selected"
                              : " not-selected")
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "16px",
                            padding: "0px 8px",
                            margin: "16px 0px"
                          }}
                        >
                          <textarea
                            ref={this.bottomDesc2TextareaRef}
                            value={bottomDesc2Obj.text}
                            disabled={isPublic}
                            placeholder={
                              ENTERYOURDESCRIPTION[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                            readOnly
                            style={{
                              resize: "none",
                              background: "transparent",
                              fontSize: bottomDesc2Obj.fontSize + "px",
                              fontFamily: bottomDesc2Obj.fontFamily,
                              border: "none",
                              outline: "none",
                              color: bottomDesc2Obj.color,
                              textAlign: "center",
                              flex: 1 //replacement for width in flex box
                            }}
                            rows={1}
                            onClick={
                              this.onHandleOpenBottomDesc2TextDrawerClick
                            }
                          />

                          <div style={{ width: "20px" }}>
                            {bottomDesc2Obj.error ? (
                              <img src="/assets/error-icon.svg" alt="" />
                            ) : (
                              <span />
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={7}>
                      <Tooltip
                        title={
                          EDITBOTTOMDESCRIPTION[
                            dbAuth.userInfo
                              ? dbAuth.userInfo.nativeLanguage
                                ? dbAuth.userInfo.nativeLanguage
                                : "en"
                              : "en"
                          ]
                        }
                        color="#FBAF41"
                        visible={bottomDesc3Obj.isTooltipVisible}
                      >
                        <div
                          className={
                            "template0001-bottomDesc3-textarea" +
                            (isPublic ? " public" : " non-public") +
                            (bottomDesc3Obj.selected
                              ? " selected"
                              : " not-selected")
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "16px",
                            padding: "0px 8px",
                            margin: "16px 0px"
                          }}
                        >
                          <textarea
                            ref={this.bottomDesc3TextareaRef}
                            value={bottomDesc3Obj.text}
                            disabled={isPublic}
                            placeholder={
                              ENTERYOURDESCRIPTION[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                            readOnly
                            style={{
                              resize: "none",
                              background: "transparent",
                              fontSize: bottomDesc3Obj.fontSize + "px",
                              fontFamily: bottomDesc3Obj.fontFamily,
                              border: "none",
                              outline: "none",
                              color: bottomDesc3Obj.color,
                              textAlign: "center",
                              flex: 1 //replacement for width in flex box
                            }}
                            rows={1}
                            onClick={
                              this.onHandleOpenBottomDesc3TextDrawerClick
                            }
                          />

                          <div style={{ width: "20px" }}>
                            {bottomDesc3Obj.error ? (
                              <img src="/assets/error-icon.svg" alt="" />
                            ) : (
                              <span />
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

                {/* <Col span={24}>
                  <div
                    className="copyright-text-wrapper"
                    style={{
                      textAlign: "center",
                      background: "#3A3939",
                      fontFamily: "PTSans-Regular",
                      color: "white",
                      padding: "16px"
                    }}
                  >
                    &#169; 2020 All Rights Reserved
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Spin>

        <EBookDrawer
          onHandleAcceptedFilesFromDropzone={
            this.onHandleAcceptedFilesFromDropzone
          }
          onAutoCompleteChange={this.onEBookDrawerAutoCompleteTextChange}
          onTextChange={this.onEBookDrawerInputTextChange}
          onColorChange={this.onEBookDrawerInputColorChange}
          onFontFamilyChange={this.onEBookDrawerInputFontFamilyChange}
          onFontSizeChange={this.onEBookDrawerInputFontSizeChange}
          onClose={this.onHandleCloseEBookDrawerClick}
          isVisible={isEBookDrawerVisible}
          inputFieldObj={eBookCoverObj}
          windowWidth={windowWidth}
          backgroundImageUrls={backgroundImageUrls}
          selectedBackgroundIdx={selectedBackgroundIdx}
          onHandleBookBackgroundImageClick={
            this.onHandleBookBackgroundImageClick
          }
          auth={dbAuth}
        />

        <HeaderTextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseHeaderTextDrawerClick}
          isVisible={isHeaderTextDrawerVisible}
          inputFieldObj={headerObj}
          windowWidth={windowWidth}
        />
        <Outline1TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseOutline1TextDrawerClick}
          isVisible={isOutline1TextDrawerVisible}
          inputFieldObj={outline1Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />
        <Outline2TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseOutline2TextDrawerClick}
          isVisible={isOutline2TextDrawerVisible}
          inputFieldObj={outline2Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />
        <Outline3TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseOutline3TextDrawerClick}
          isVisible={isOutline3TextDrawerVisible}
          inputFieldObj={outline3Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />
        <Outline4TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseOutline4TextDrawerClick}
          isVisible={isOutline4TextDrawerVisible}
          inputFieldObj={outline4Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />
        <Outline5TextDrawer
          onCheckboxChange={this.onHideOutlineCheckboxValueChange}
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseOutline5TextDrawerClick}
          isVisible={isOutline5TextDrawerVisible}
          inputFieldObj={outline5Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />

        <ButtonDrawer
          onButtonUrlChange={this.onButtonUrlChange}
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseButtonDrawerClick}
          isVisible={isButtonDrawerVisible}
          inputFieldObj={buttonObj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />

        <BottomDesc1TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseBottomDesc1TextDrawerClick}
          isVisible={isBottomDesc1TextDrawerVisible}
          inputFieldObj={bottomDesc1Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />

        <BottomDesc2TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseBottomDesc2TextDrawerClick}
          isVisible={isBottomDesc2TextDrawerVisible}
          inputFieldObj={bottomDesc2Obj}
          windowWidth={windowWidth}
          auth={dbAuth}
        />

        <BottomDesc3TextDrawer
          onTextChange={this.onDrawerInputTextChange}
          onColorChange={this.onDrawerInputColorChange}
          onFontFamilyChange={this.onDrawerInputFontFamilyChange}
          onFontSizeChange={this.onDrawerInputFontSizeChange}
          onClose={this.onHandleCloseBottomDesc3TextDrawerClick}
          isVisible={isBottomDesc3TextDrawerVisible}
          inputFieldObj={bottomDesc3Obj}
          windowWidth={windowWidth}
        />
      </Fragment>
    );
  }
}
const widthScaleDownRatio = 60; //the larger the value the smaller the font

const mapDispatchToProps = {
  loadFunnelPageData,
  loadFunnelPageDataOnlyBackground,
  saveSalesFunnel,
  resetSalesFunnelData
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  operation: state.operation,
  book: state.book,
  salesFunnel: state.salesFunnel,
  image: state.image,
  domain: state.domain,
  dbAuth: state.auth
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Template0001);
