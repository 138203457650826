import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { validateDomain } from "./domain/domainActions";

class DomainVal extends Component {
  state = { isLoading: true };

  async componentDidMount() {
    await this.props.validateDomain();
  }

  componentDidUpdate(prevProps) {
    const { domain } = this.props;
    if (
      !_.isEqual(prevProps.domain.isPublicDomain, domain.isPublicDomain) &&
      domain.isPublicDomain !== null
    ) {
      this.setState({
        isLoading: false
      });
    }
  }

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return null;
    return children;
  }
}

const mapStateToProps = state => ({
  domain: state.domain
});

const mapDispatchToProps = {
  validateDomain
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DomainVal);
