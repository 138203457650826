import React, { Fragment } from "react";
import "./App.less";
import { Switch, Route } from "react-router";
import FunnelPageCustomise from "./FunnelPageCustomise";
import EbookCover from "./EbookCover";
import { connect } from "react-redux";
import LandingPage from "./BusinessPortal/LandingPage";
import BusinessPortal from "./BusinessPortal/BusinessPortal";
import AdminLoginPage from "./Admin/AdminLoginPage";
import ConsoleDashboard from "./Admin/ConsoleDashboard";
import Home from "./New/Home";
import SingUp from "./New/SignUpNew";
import Login from "./New/Login";
import ForgotPassword from "./New/ForgetPassword";

function App({ domain }) {
  return (
    <Fragment>
      {domain.isPublicDomain ? (
        <Switch>
          <Route path="/" component={FunnelPageCustomise} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/business-portal" component={BusinessPortal} />
          <Route path="/console" component={ConsoleDashboard} />
          <Route path="/ebook-cover/:uid" component={EbookCover} />
          <Route path="/admin" component={AdminLoginPage} />
          <Route path="/login/forgotPassword" component={ForgotPassword} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={SingUp} />
          <Route path="/" component={Home} />
        </Switch>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  domain: state.domain,
});

export default connect(mapStateToProps)(App);
