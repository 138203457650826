import {
  LOAD_EBOOK,
  RESET_EBOOK,
  LOAD_SELECTED_EBOOK,
  LOAD_ALL_EBOOKS,
} from "./ebookConstant";
import { createReducer } from "../app/common/utils/reducerUtils";

const initialState = {
  eBookList: null,
  selectedEBook: null,
};

const loadEBook = (state, payload) => {
  return {
    ...state,
    eBookList: payload.eBookList,
  };
};

const loadSelectedEBook = (state, payload) => {
  return {
    ...state,
    selectedEBook: payload.selectedEBook,
  };
};

const resetEBook = (state) => {
  return {
    ...state,
    eBookList: null,
    selectedEBook: null,
  };
};

export default createReducer(initialState, {
  [LOAD_EBOOK]: loadEBook,
  [RESET_EBOOK]: resetEBook,
  [LOAD_SELECTED_EBOOK]: loadSelectedEBook,
  [LOAD_ALL_EBOOKS]: loadEBook,
});
