
import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_BOOK_BACKGROUND, RESET_BOOK_BACKGROUND } from "./imageConstant";


const initialState = {
    funnelPageImageObj : null
}

const loadBookBackground = (state, payload) => {
    return {
        ...state,
        funnelPageImageObj: payload.funnelPageImageObj
    }
}

const resetBookBackground = (state) => {
    return {
        ...state,
        funnelPageImageObj: null
    }
}

export default createReducer(initialState, {
    [LOAD_BOOK_BACKGROUND]: loadBookBackground,
    [RESET_BOOK_BACKGROUND]: resetBookBackground
});
