import React, { Component, Fragment } from "react";
import { Row, Col, Button, notification, Collapse } from "antd";

import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from "@ant-design/icons";
// import Marquee from "react-marquee-slider";
import "@brainhubeu/react-carousel/lib/style.css";
import "./LandingPage.less";
import { updateLanguage } from "../auth/authActions";
import { sendMessage } from "./ContactUs/ContactUsAction";
import ContactUsModal from "./ContactUs/ContactUs";
import LandingPageModal from "./LandingPageModal";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import AcademyModal from "../modals/AcademyModal";
import EBOOKModal from "../modals/EBOOKModal";
import VisibilitySensor from "react-visibility-sensor";
// import Bowser from "bowser"
const { Panel } = Collapse;
// const broswer = Bowser.getParser(window.navigator.userAgent)

class LandingPage extends Component {
  state = {
    loginModalVisible: false,
    messageModalVisible: false,
    languagePopoverVisible: false,
    visibility: false,
    visibility2: false
  };

  showModal = () => {
    this.setState({
      loginModalVisible: true
    });
  };

  showMessageModal = () => {
    this.setState({
      messageModalVisible: true
    });
  };

  showAcademy = () => {
    this.setState({
      academyModalVisible: true
    });
  };

  showEBookModal = () => {
    this.setState({
      eBookModalVisible: true
    });
  };

  onLoginModalCancel = () => {
    console.log(this.state);
    this.setState({
      loginModalVisible: false
    });
  };

  onModalFinish = () => {
    this.setState({
      loginModalVisible: false
    });
  };

  hideModal = values => {
    this.setState({
      reset: true,
      messageModalVisible: false,
      academyModalVisible: false,
      eBookModalVisible: false
    });
  };

  sendMessage = values => {
    // form.current.validateFields().then(values => {
    notification.open({
      key,
      message: "Sending Message",
      duration: 0,
      icon: <LoadingOutlined />
    });
    this.props.sendMessage(values);
    this.setState({
      reset: true,
      messageModalVisible: false
    });
    // });
  };

  goToBusinessPortal = () => {
    this.props.history.push("/business-portal");
  };

  changeVisible = isVisible => {
    if (isVisible) {
      this.setState({ visibility: isVisible });
      if (isVisible)
        setTimeout(() => {
          this.setState({ visibility: !isVisible });
        }, 3000);
    }
  };

  changeVisible2 = isVisible => {
    if (isVisible) {
      this.setState({ visibility2: isVisible });
      if (isVisible)
        setTimeout(() => {
          this.setState({ visibility2: !isVisible });
        }, 3750);
    }
  };

  componentDidUpdate() {
    const { contactUs } = this.props;
    if (!contactUs.loading && contactUs.contactUsSuccess && this.state.reset) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully sent a message",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        )
      });
      this.setState({
        reset: false
      });
    } else if (
      !contactUs.loading &&
      contactUs.contactUsFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: contactUs.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        )
      });
      // this.form.current.resetFields();
      this.setState({
        reset: false
      });
    }
  }

  onLanguageMenuClick = async e => {
    this.props.updateLanguage(e.key);
    this.setState({
      languagePopoverVisible: !this.state.languagePopoverVisible
    });
  };

  languagePopoverClick = () => {
    this.setState({
      languagePopoverVisible: !this.state.languagePopoverVisible
    });
  };

  render() {
    const { dbAuth } = this.props;
    const {
      messageModalVisible,
      loginModalVisible,
      academyModalVisible,
      eBookModalVisible
    } = this.state;
    return (
      <Fragment>
        {/* {console.log(broswer)} */}
        <LandingPageModal
          visible={loginModalVisible}
          onLoginModalCancel={this.onLoginModalCancel}
          goToBusinessPortal={this.goToBusinessPortal}
        />
        <AcademyModal
          visible={academyModalVisible}
          hideModal={this.hideModal}
        />
        <EBOOKModal visible={eBookModalVisible} hideModal={this.hideModal} />
        <Row>
          <Col xs={1} md={2} />
          <Col xs={22} md={20}>
            <Row className="topbar">
              <Col flex="auto">
                <div className="top-title">BizMastery</div>
              </Col>

              <Col flex="fill">
                <div className="top-login" onClick={this.showModal}>
                  Log In
                </div>
              </Col>
            </Row>
            <hr className="divider" />
            <Row style={{ marginTop: "2rem" }}>
              <Col xs={24} lg={0}>
                <img
                  src="/assets/businessPortal/landing-page-img1.gif"
                  alt="Landing Page Row 1"
                  className="row1-img"
                />
              </Col>
              <Col xs={24} lg={12}>
                {/* <div className="row1-title">
                  {BUSINESSPORTAL[dbAuth.selectedLanguage]}
                </div> */}
                <div className="row1-title-2">
                  JOIN Mini MBA BUSINESS
                  {/* &nbsp;- {SINCE[dbAuth.selectedLanguage]} 2010 */}
                </div>
                <div className="row1-big-title">MASTERY CRASH COURSE</div>
                <div className="row-big-title">
                  The Best of Dato Seri Edward
                </div>
                <div className="row1-description">
                  Get better leadership, sales, networking, negotiation,
                  speaking, Social Media and other essential business.
                  {/* {LANDINGDESC[dbAuth.selectedLanguage]} */}
                </div>
                <Button
                  className="button-text-white"
                  onClick={this.showMessageModal}
                >
                  CONTACT US
                </Button>
                {console.log("here 1")}
                <ContactUsModal
                  visible={messageModalVisible}
                  hideModal={this.hideModal}
                  sendMessage={this.sendMessage}
                  selectedLanguage={dbAuth.selectedLanguage}
                />
              </Col>
              <Col xs={0} lg={12}>
                <img
                  src="/assets/businessPortal/landing-page-img1.gif"
                  alt="Landing Page Row 1"
                  className="row1-img"
                />
              </Col>
              <Col xs={24} lg={12}>
                <div className="row1-scroll-down">Scroll Down</div>
                <img
                  src="/assets/businessPortal/mouse-icon.png"
                  alt="mouse icon"
                  className="mouse-icon"
                />
              </Col>
            </Row>
            <hr className="divider" />
            <Row style={{ paddingTop: "2rem" }}>
              <Col xs={24} md={7}>
                <div className="row-title-2">WE TRAIN&nbsp;</div>
                <div className="row-title">AND&nbsp;</div>
                <div className="row-title-2">COACH YOU</div>
                <br />
                <div className="row-title">GET READY FOR THIS 10-CHAPTER</div>
              </Col>
              <Col
                xs={24}
                md={{ offset: 1, span: 16 }}
                xl={{ offset: 0, span: 17 }}
              >
                <div className="row-title-D2">
                  Get ready for this 10-part highlight reel of everything you
                  need to know about MLM Mastery. This includes how to master
                  the art of leadership and business development. Get better
                  leadership, sales, networking, negotiation, speaking, and
                  other essential business skills. Learn How to Become a
                  Legendary Leader
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "2rem" }} align="middle">
              <Col xs={24} md={7} style={{ textAlign: "center" }}>
                <img
                  src="/assets/businessPortal/achievements.gif"
                  alt="Achievements"
                  className="row2-img"
                />
              </Col>
              <Col
                xs={24}
                md={{ offset: 1, span: 16 }}
                xl={{ offset: 0, span: 17 }}
                className="row2-points-segment"
              >
                <Row gutter={[0, 40]} justify="center">
                  <Col xs={24} md={12}>
                    <Row>
                      <Col xs={4} style={{ alignSelf: "center" }}>
                        <img
                          src="/assets/businessPortal/generate-income.png"
                          alt="Business to Client"
                          className="row2-points-img"
                        />
                      </Col>
                      <Col xs={20}>
                        <div className="row2-sub-title">Success</div>
                        <div className="row2-sub-description">
                          Tap into the legendary leadership, sales, networking,
                          and success principles.
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row>
                      <Col xs={4} style={{ alignSelf: "center" }}>
                        <img
                          src="/assets/businessPortal/corporate-culture.png"
                          alt="Corporate Culture"
                          className="row2-points-img"
                        />
                      </Col>
                      <Col xs={20}>
                        <div className="row2-sub-title">Become Rich</div>
                        <div className="row2-sub-description">
                          Become a member of one of the richest and most
                          successful leadership and business networks in the
                          world
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={0} md={12}>
                    <Row>
                      <Col xs={4} style={{ alignSelf: "center" }}>
                        <img
                          src="/assets/businessPortal/team-collab.png"
                          alt="Team Collaboration"
                          className="row2-points-img"
                        />
                      </Col>
                      <Col xs={20}>
                        <div className="row2-sub-title">Training</div>
                        <div className="row2-sub-description">
                          Get instant access online training programs, and much
                          more
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={0}>
                    <Row>
                      <Col xs={4}>
                        <img
                          src="/assets/businessPortal/team-collab.png"
                          alt="Team Collaboration"
                          className="row2-points-img"
                        />
                      </Col>
                      <Col xs={20}>
                        <div className="row2-sub-title">Training</div>
                        <div className="row2-sub-description">
                          Get instant access online training programs, and much
                          more
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr className="divider" />
            <Row style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
              <Col xs={24} md={10}>
                <div className="row3-big-title">
                  DO YOU WANT TO SEE A REAL CHANGE IN YOUR LIFE ?
                </div>
              </Col>
              <Col xs={24} md={{ span: 13, offset: 1 }}>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Do you want to build a strong network to advise you in life
                  and business?
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Do you want to build strong, enduring business relationships?
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Do you want to learn how to negotiate or sell anything?
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Are you ready to become a great leader?
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "1.5rem" }}
                >
                  Are you ready to make life-changing improvements starting
                  today?
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", fontFamily: "Poppins-Bold" }}
                >
                  Click below to secure your spot in my Business Mastery Crash
                  Course
                </div>
                <br />
                <Button
                  className="button-text-white"
                  onClick={this.showMessageModal}
                >
                  BUY FOR $1999.00
                </Button>
                {console.log("here 2")}
                <ContactUsModal
                  visible={messageModalVisible}
                  hideModal={this.hideModal}
                  sendMessage={this.sendMessage}
                  selectedLanguage={dbAuth.selectedLanguage}
                />
              </Col>
            </Row>
            <hr className="divider" />
            <Row style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
              <Col xs={24} lg={{ span: 18, offset: 1 }}>
                <div
                  style={{ paddingBottom: "1rem" }}
                  className="row3-big-title"
                >
                  OUR SERVICES
                </div>
              </Col>
              <Col
                xs={0}
                md={12}
                lg={{ span: 10, offset: 1 }}
                xl={{ span: 8, offset: 1 }}
              >
                <div
                  className="our-service"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0.5rem",
                    border: "1px solid black",
                    padding: "1rem",
                    marginRight: "0.5rem"
                  }}
                >
                  Business Skill Academy
                  <br />
                  <Button
                    className="button-text-white"
                    onClick={this.showAcademy}
                  >
                    LEARN MORE
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={0}>
                <div
                  className="our-service"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0.5rem",
                    border: "1px solid black",
                    padding: "1rem"
                  }}
                >
                  Business Skill Academy
                  <br />
                  <Button
                    className="button-text-white"
                    onClick={this.showAcademy}
                  >
                    LEARN MORE
                  </Button>
                </div>
              </Col>
              <Col
                xs={0}
                md={12}
                lg={{ span: 10, offset: 2 }}
                xl={{ span: 8, offset: 2 }}
              >
                <div
                  className="our-service"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0.5rem",
                    border: "1px solid black",
                    padding: "1rem",
                    marginLeft: "0.5rem"
                  }}
                >
                  Download E-Book & Power Point
                  <br />
                  <Button
                    className="button-text-white"
                    onClick={this.showEBookModal}
                  >
                    LEARN MORE
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={0}>
                <div
                  className="our-service"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0.5rem",
                    border: "1px solid black",
                    padding: "1rem"
                  }}
                >
                  Download E-Book & Power Point
                  <br />
                  <Button
                    className="button-text-white"
                    onClick={this.showEBookModal}
                  >
                    LEARN MORE
                  </Button>
                </div>
              </Col>
            </Row>
            <hr className="divider" />
            <Row style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
              <Col xs={24} md={0} style={{ alignSelf: "center" }}>
                <VisibilitySensor
                  partialVisibility
                  onChange={isVisible => this.changeVisible(isVisible)}
                >
                  {this.state.visibility ? (
                    <div className="row3-card-img-container">
                      <img
                        style={{ width: "40%" }}
                        src="/assets/businessPortal/inspiration.gif"
                        alt="Creativity"
                        className="row3-card-img"
                      />
                    </div>
                  ) : (
                    <div className="row3-card-img-container">
                      <img
                        style={{ width: "40%" }}
                        src="/assets/businessPortal/inspiration-3.png"
                        alt="Creativity"
                        className="row3-card-img"
                      />
                    </div>
                  )}
                </VisibilitySensor>
              </Col>
              <Col xs={24} md={{ span: 13, offset: 1 }}>
                <div
                  className="row2-big-title"
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  MISTAKES
                </div>
                <div
                  className="row2-big-title"
                  style={{ marginBottom: "0.5rem" }}
                >
                  Marketing Performance
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "1.5rem" }}
                >
                  The figures and campaign results listed are not typical and
                  are the result of years of training, experimenting and
                  learning from mistakes.
                </div>
                <div
                  className="row2-big-title"
                  style={{ marginBottom: "0.5rem" }}
                >
                  Don’t...
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Invest in another $3997 course.
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Settle for a meager income.
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Keep running in circles, trying tactics to grow start or your
                  business that don't work.
                </div>
              </Col>
              <Col xs={0} md={10} style={{ alignSelf: "center" }}>
              <VisibilitySensor
                  partialVisibility
                  onChange={isVisible => this.changeVisible(isVisible)}
                >
                  {this.state.visibility ? (
                    <div className="row3-card-img-container">
                      <img
                        style={{ width: "40%" }}
                        src="/assets/businessPortal/inspiration.gif"
                        alt="Creativity"
                        className="row3-card-img"
                      />
                    </div>
                  ) : (
                    <div className="row3-card-img-container">
                      <img
                        style={{ width: "40%" }}
                        src="/assets/businessPortal/inspiration-3.png"
                        alt="Creativity"
                        className="row3-card-img"
                      />
                    </div>
                  )}
                </VisibilitySensor>
              </Col>

              <Col
                xs={24}
                md={10}
                style={{ alignSelf: "center", marginTop: "2rem" }}
              >
                <VisibilitySensor
                  partialVisibility
                  onChange={isVisible => this.changeVisible2(isVisible)}
                >
                  {this.state.visibility2 ? (
                     <div className="row3-card-img-container">
                     <img
                       style={{ width: "30%" }}
                       src="/assets/businessPortal/idea.gif"
                       alt="Creativity"
                       className="row3-card-img"
                     />
                   </div>
                  ) : (
                    <div className="row3-card-img-container">
                      <img
                        style={{ width: "30%" }}
                        src="/assets/businessPortal/idea.png"
                        alt="Creativity"
                        className="row3-card-img"
                      />
                    </div>
                  )}
                </VisibilitySensor>
               
              </Col>
              <Col
                xs={24}
                md={{ span: 13, offset: 1 }}
                style={{ marginTop: "4rem" }}
              >
                <div
                  className="row2-big-title"
                  style={{ marginTop: "0px", marginBottom: "1rem" }}
                >
                  Now is the perfect time to turn your business and life around
                </div>
                <div
                  className="row2-big-title"
                  style={{ marginBottom: "0.5rem" }}
                >
                  You’ll learn how to
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "1.5rem" }}
                >
                  Negotiate or sell anything.
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Build enduring business relationships.
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  Become a great leader.
                </div>
                <div
                  className="row3-description"
                  style={{ marginTop: "0px", marginBottom: "0.5rem" }}
                >
                  And more strategies to improve your life.
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={1} md={2} />
        </Row>

        <hr className="divider" />
        <Row style={{ padding: "3rem 1rem" }}>
          <Col xs={24} lg={{ span: 18, offset: 3 }}>
            <div style={{ paddingBottom: "1rem" }} className="row3-big-title">
              SPECIAL BONUS DOWNLOAD E-BOOK
            </div>
          </Col>
          <Col
            xs={0}
            md={12}
            lg={{ span: 8, offset: 3 }}
            xl={{ span: 6, offset: 3 }}
          >
            <div
              className="our-service"
              style={{
                marginTop: "0px",
                marginBottom: "0.5rem",
                border: "1px solid black",
                padding: "1rem",
                marginRight: "0.5rem"
              }}
            >
              Recipe For Success In Internet Marketing
              <br />
              <Button
                className="button-text-white"
                onClick={this.showMessageModal}
              >
                DOWNLOAD
              </Button>
            </div>
          </Col>
          <Col xs={24} md={0}>
            <div
              className="our-service"
              style={{
                marginTop: "0px",
                marginBottom: "0.5rem",
                border: "1px solid black",
                padding: "1rem"
              }}
            >
              Recipe For Success In Internet Marketing
              <br />
              <Button
                className="button-text-white"
                onClick={this.showMessageModal}
              >
                DOWNLOAD
              </Button>
            </div>
          </Col>
          <Col
            xs={0}
            md={12}
            lg={{ span: 8, offset: 2 }}
            xl={{ span: 6, offset: 2 }}
          >
            <div
              className="our-service"
              style={{
                marginTop: "0px",
                marginBottom: "0.5rem",
                border: "1px solid black",
                padding: "1rem",
                marginLeft: "0.5rem"
              }}
            >
              Recipe For Success In Network Marketing
              <br />
              <Button
                className="button-text-white"
                onClick={this.showMessageModal}
              >
                DOWNLOAD
              </Button>
            </div>
          </Col>
          <Col xs={24} md={0}>
            <div
              className="our-service"
              style={{
                marginTop: "0px",
                marginBottom: "0.5rem",
                border: "1px solid black",
                padding: "1rem"
              }}
            >
              Recipe For Success In Network Marketing
              <br />
              <Button
                className="button-text-white"
                onClick={this.showMessageModal}
              >
                DOWNLOAD
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={2} />
          <Col xs={20}>
            <hr className="divider" />
            <Row
              justify="center"
              align="middle"
              className="rows"
              style={{
                backgroundImage:
                  "url('/assets/businessPortal/contact-us-bg.png')",
                backgroundSize: "cover",
                height: "550px"
              }}
            >
              <Col xs={24} md={20} style={{ textAlign: "center" }}>
                <div className="row2-big-title">
                  Got a question about BizMastery??
                </div>
                <div className="row2-big-title">Reach out to us!</div>
                <br />
                <div className="row3-big-title" style={{ padding: "1rem" }}>
                  FREQUENTLY ASK QUESTIONS
                </div>
                <Collapse style={{ backgroundColor: "white" }}>
                  <Panel
                    header="1. I don't own my own business, will this work for me?"
                    key="1"
                  >
                    <p>
                      Yes! These lessons are applicable no matter where you are
                      in life. The course will help you achieve your goals,
                      whatever they may be!
                    </p>
                  </Panel>
                  <Panel
                    header="2. How long will I have access to the course?"
                    key="2"
                  >
                    <p>
                      Once you get access to our platform and the courses,
                      you're a member for 1 year.
                    </p>
                  </Panel>
                  <Panel header="3. How do I access my courses?" key="3">
                    <p>
                      You'll sign into our member portal once you have a user
                      name and password. From there, login through the portal to
                      access all of your courses!
                    </p>
                  </Panel>
                  <Panel header="4. What's your refund policy?" key="4">
                    <p>
                      If you've taken the course, viewed all of our content and
                      still aren't satisfied, I'll refund you up to 30 days
                      after purchase. Just contact our customer support.
                    </p>
                  </Panel>
                </Collapse>
                <div className="row3-description">
                  Our team of customer care ninjas is ready to hear from you
                </div>
                <br />
                <Button
                  className="button-text-white"
                  onClick={this.showMessageModal}
                >
                  CONTACT US
                </Button>
                {console.log("here 3")}
                <ContactUsModal
                  visible={messageModalVisible}
                  hideModal={this.hideModal}
                  sendMessage={this.sendMessage}
                  selectedLanguage={dbAuth.selectedLanguage}
                />
              </Col>
            </Row>
            {/* <hr className="divider" /> */}
          </Col>
          <Col xs={2} />
        </Row>
        <Row style={{ marginTop: "3rem" }}>
          <Col xs={2} />
          <Col xs={20} md={6} className="footer-title-col">
            <div className="footer-title">BizMastery</div>
            <div className="footer-title-2">Network Marketer</div>
            <div className="footer-title-3">- Since 2010</div>
          </Col>
          <Col xs={24} md={16} className="footer-black-bg">
            <Row justify="center" style={{ height: "100%" }}>
              <Col xs={20} xl={6} className="footer-columns">
                <Row>
                  <Col span={3} style={{ textAlign: "center" }}>
                    <img
                      src="/assets/businessPortal/email.svg"
                      alt="Email"
                      className="footer-icon"
                      // style={{textAlign:"center"}}
                    />
                  </Col>
                  <Col span={21}>
                    <div className="footer-text">
                      support@infinijunction.com
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={20} xl={5} className="footer-columns">
                <Row>
                  <Col span={3} style={{ textAlign: "center" }}>
                    <img
                      src="/assets/businessPortal/phone.svg"
                      alt="Phone"
                      className="footer-icon"
                      // style={{textAlign:"center"}}
                    />
                  </Col>
                  <Col span={21}>
                    <div className="footer-text">0166651066</div>
                  </Col>
                </Row>
              </Col>
              <Col xs={20} xl={7} className="footer-columns">
                <Row>
                  <Col span={3} style={{ textAlign: "center" }}>
                    <img
                      src="/assets/businessPortal/globe.svg"
                      alt="Map Marker"
                      className="footer-icon"
                      width="25px"
                      // style={{textAlign:"center"}}
                    />
                  </Col>
                  <Col span={21}>
                    <div className="footer-text">
                      datoseriedward.com/mlmconsultant.asia
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} style={{ textAlign: "center" }}>
                <div className="footer-text-2">
                  copyright @infinijunction sdn. bhd.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const key = "messageNotification";
const mapStateToProps = state => ({
  auth: state.firebase.auth,
  contactUs: state.contactUs,
  dbAuth: state.auth
});
const mapDispatchToProps = {
  sendMessage,
  updateLanguage
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(LandingPage);
