import React, { Fragment } from "react";
import { Drawer, Input, Select, Row, Col, AutoComplete } from "antd";
import {
  bookTaglineTemplateList,
  minFontSize,
  supportedFontVariantsMap,
  maxFontSize
} from "./SharedConstant";
import InputColor from "react-input-color";
// import DropzoneComponent from "./app/common/upload/DropzoneComponent";
import { filterOptionFunc } from "./antUtils";
import {
  BOOKBACKGROUND,
  NAMEOFBOOK,
  FONTVARIANT,
  FONTTYPE,
  COLOR,
  FONTSIZE,
  YOUNAMEORCOMPANY,
  BOOKTAGLINE1,
  BOOKTAGLINE2
} from "./BusinessPortal/TranslationConstant";
const rgbHex = require("rgb-hex");

const EBookDrawer = ({
  onClose,
  isVisible,
  windowWidth,
  onAutoCompleteChange,
  onTextChange,
  onColorChange,
  onFontSizeChange,
  onFontFamilyChange,
  backgroundImageUrls,
  selectedBackgroundIdx,
  onHandleAcceptedFilesFromDropzone,
  onHandleBookBackgroundImageClick,
  inputFieldObj,
  auth
}) => {
  return (
    <Fragment>
      <Drawer
        onClose={onClose}
        visible={isVisible}
        width={windowWidth > 600 ? 600 : windowWidth}
      >
        <Row>
          <Col span={24}>
            <div
              style={{
                fontSize: "18px",
                fontFamily: "Poppins-SemiBold",
                color: "#34374D"
              }}
            >
              {
                BOOKBACKGROUND[
                  auth.userInfo
                  ? auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                  : "en"
                ]
              }
            </div>
            <Row>
              {backgroundImageUrls.map((item, idx) => (
                <Col
                  key={idx}
                  className={
                    "template0001-img-col-wrapper" +
                    (selectedBackgroundIdx === idx
                      ? " selected"
                      : " not-selected")
                  }
                  xs={11}
                  md={5}
                  style={{
                    borderRadius: "16px",
                    margin: "4px",
                    padding: "4px"
                  }}
                  onClick={() => onHandleBookBackgroundImageClick(idx)}
                >
                  <img
                    src={item}
                    alt=""
                    style={{
                      width: "100%",
                      height: "130px",
                      borderRadius: "16px"
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            NAMEOFBOOK[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error && inputFieldObj.bookNameObj.text.length === 0
              ? "(Book name cannot be empty)"
              : null}
          </span>
        </div>
        <Input
          style={{ width: "100%" }}
          onChange={value => onTextChange(value, "bookNameObj")}
          value={inputFieldObj.bookNameObj.text}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTTYPE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.bookNameObj.fontFamily.split("-")[0]}
          onChange={value => onFontFamilyChange(value, "bookNameObj", 0)}
        >
          {[...supportedFontVariantsMap.keys()].map((item, idx) => (
            <Select.Option key={`${item}_${idx}`} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTVARIANT[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.bookNameObj.fontFamily.split("-")[1]}
          onChange={value => onFontFamilyChange(value, "bookNameObj", 1)}
        >
          {supportedFontVariantsMap
            .get(inputFieldObj.bookNameObj.fontFamily.split("-")[0])
            .map((item, idx) => (
              <Select.Option key={`${item}_${idx}`} value={item}>
                {item}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTSIZE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error
              ? inputFieldObj.bookNameObj.fontSize <= minFontSize
                ? "(Font size is too small)"
                : inputFieldObj.bookNameObj.fontSize >= maxFontSize
                ? "(Font size is too big)"
                : null
              : null}
          </span>
        </div>
        <Input
          type="number"
          style={{ fontSize: "18px", fontFamily: "Poppins-Regular" }}
          onChange={e => onFontSizeChange(e, "bookNameObj")}
          value={inputFieldObj.bookNameObj.fontSize}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            COLOR[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>
        <InputColor
          initialValue={
            inputFieldObj.bookNameObj.color.length > 0
              ? "#" + rgbHex(inputFieldObj.bookNameObj.color)
              : "#FFFFFF"
          }
          onChange={value => onColorChange(value, "bookNameObj")}
          style={{ width: "100%", height: "35px" }}
        />

        <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            YOUNAMEORCOMPANY[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error && inputFieldObj.authorObj.text.length === 0
              ? "(Author infomation cannot be empty)"
              : null}
          </span>
        </div>
        <Input
          onChange={value => onTextChange(value, "authorObj")}
          style={{ width: "100%" }}
          value={inputFieldObj.authorObj.text}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTTYPE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.authorObj.fontFamily.split("-")[0]}
          onChange={value => onFontFamilyChange(value, "authorObj", 0)}
        >
          {[...supportedFontVariantsMap.keys()].map((item, idx) => (
            <Select.Option key={`${item}_${idx}`} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTVARIANT[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.authorObj.fontFamily.split("-")[1]}
          onChange={value => onFontFamilyChange(value, "authorObj", 1)}
        >
          {supportedFontVariantsMap
            .get(inputFieldObj.authorObj.fontFamily.split("-")[0])
            .map((item, idx) => (
              <Select.Option key={`${item}_${idx}`} value={item}>
                {item}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTSIZE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error
              ? inputFieldObj.authorObj.fontSize <= minFontSize
                ? "(Font size is too small)"
                : inputFieldObj.authorObj.fontSize >= maxFontSize
                ? "(Font size is too big)"
                : null
              : null}
          </span>
        </div>
        <Input
          type="number"
          style={{ fontSize: "18px", fontFamily: "Poppins-Regular" }}
          onChange={e => onFontSizeChange(e, "authorObj")}
          value={inputFieldObj.authorObj.fontSize}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            COLOR[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>
        <InputColor
          initialValue={
            inputFieldObj.authorObj.color.length > 0
              ? "#" + rgbHex(inputFieldObj.authorObj.color)
              : "#FFFFFF"
          }
          onChange={value => onColorChange(value, "authorObj")}
          style={{ width: "100%", height: "35px" }}
        />

        <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            BOOKTAGLINE1[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error && inputFieldObj.tagline1Obj.text.length === 0
              ? "(Tagline cannot be empty)"
              : null}
          </span>
        </div>
        {/* <Input
          onChange={value => onTextChange(value, "tagline1Obj")}
          style={{ width: "100%" }}
          value={inputFieldObj.tagline1Obj.text}
        /> */}
        <AutoComplete
          style={{ width: "100%" }}
          options={bookTaglineTemplateList}
          filterOption={filterOptionFunc}
          onChange={value => onAutoCompleteChange(value, "tagline1Obj")}
          value={inputFieldObj.tagline1Obj.text}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTTYPE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.tagline1Obj.fontFamily.split("-")[0]}
          onChange={value => onFontFamilyChange(value, "tagline1Obj", 0)}
        >
          {[...supportedFontVariantsMap.keys()].map((item, idx) => (
            <Select.Option key={`${item}_${idx}`} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTVARIANT[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.tagline1Obj.fontFamily.split("-")[1]}
          onChange={value => onFontFamilyChange(value, "tagline1Obj", 1)}
        >
          {supportedFontVariantsMap
            .get(inputFieldObj.tagline1Obj.fontFamily.split("-")[0])
            .map((item, idx) => (
              <Select.Option key={`${item}_${idx}`} value={item}>
                {item}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTSIZE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error
              ? inputFieldObj.tagline1Obj.fontSize <= minFontSize
                ? "(Font size is too small)"
                : inputFieldObj.tagline1Obj.fontSize >= maxFontSize
                ? "(Font size is too big)"
                : null
              : null}
          </span>
        </div>
        <Input
          type="number"
          style={{ fontSize: "18px", fontFamily: "Poppins-Regular" }}
          onChange={e => onFontSizeChange(e, "tagline1Obj")}
          value={inputFieldObj.tagline1Obj.fontSize}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            COLOR[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>
        <InputColor
          initialValue={
            inputFieldObj.tagline1Obj.color.length > 0
              ? "#" + rgbHex(inputFieldObj.tagline1Obj.color)
              : "#FFFFFF"
          }
          onChange={value => onColorChange(value, "tagline1Obj")}
          style={{ width: "100%", height: "35px" }}
        />

        <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            BOOKTAGLINE2[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error && inputFieldObj.tagline2Obj.text.length === 0
              ? "(Tagline cannot be empty)"
              : null}
          </span>
        </div>
        {/* <Input
          onChange={value => onTextChange(value, "tagline2Obj")}
          style={{ width: "100%" }}
          value={inputFieldObj.tagline2Obj.text}
        /> */}
        <AutoComplete
          style={{ width: "100%" }}
          options={bookTaglineTemplateList}
          filterOption={filterOptionFunc}
          onChange={value => onAutoCompleteChange(value, "tagline2Obj")}
          value={inputFieldObj.tagline2Obj.text}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTTYPE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.tagline2Obj.fontFamily.split("-")[0]}
          onChange={value => onFontFamilyChange(value, "tagline2Obj", 0)}
        >
          {[...supportedFontVariantsMap.keys()].map((item, idx) => (
            <Select.Option key={`${item}_${idx}`} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTVARIANT[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.tagline2Obj.fontFamily.split("-")[1]}
          onChange={value => onFontFamilyChange(value, "tagline2Obj", 1)}
        >
          {supportedFontVariantsMap
            .get(inputFieldObj.tagline2Obj.fontFamily.split("-")[0])
            .map((item, idx) => (
              <Select.Option key={`${item}_${idx}`} value={item}>
                {item}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTSIZE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error
              ? inputFieldObj.tagline2Obj.fontSize <= minFontSize
                ? "(Font size is too small)"
                : inputFieldObj.tagline2Obj.fontSize >= maxFontSize
                ? "(Font size is too big)"
                : null
              : null}
          </span>
        </div>
        <Input
          type="number"
          style={{ fontSize: "18px", fontFamily: "Poppins-Regular" }}
          onChange={e => onFontSizeChange(e, "tagline2Obj")}
          value={inputFieldObj.tagline2Obj.fontSize}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            COLOR[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>
        <InputColor
          initialValue={
            inputFieldObj.tagline2Obj.color.length > 0
              ? "#" + rgbHex(inputFieldObj.tagline2Obj.color)
              : "#FFFFFF"
          }
          onChange={value => onColorChange(value, "tagline2Obj")}
          style={{ width: "100%", height: "35px" }}
        />

        {/* <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374D",
            margin: "16px 0px 8px"
          }}
        >
          Photo (Optional)
        </div>
        <Row>
          <Col span={24} style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
            <DropzoneComponent
              onHandleAcceptedFilesFromDropzone={acceptedFiles =>
                onHandleAcceptedFilesFromDropzone(
                  acceptedFiles,
                  "profileImageObj"
                )
              }
              uploadImageIcon={
                <Row justify="space-between" align="middle">
                  <Col
                    style={{
                      padding: "4px 8px",
                      fontSize: "18px",
                      fontFamily: "Poppins-Regular"
                    }}
                  >
                    Photo
                  </Col>
                  <Col>
                    <img
                      src="/assets/camera-icon.svg"
                      alt=""
                      style={{
                        width: "32px",
                        height: "32px",
                        padding: "4px"
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row> */}
      </Drawer>
    </Fragment>
  );
};

export default EBookDrawer;
