import React, { Fragment } from "react";
import "./ChooseEBookModal.css";
import { Modal, Row, Col, Steps, Button, Spin } from "antd";
import {
  SELECTLANGUAGE,
  CUSTOMIZEFUNNEL,
  SELECTEBOOK,
  GOBACK
} from "../BusinessPortal/TranslationConstant";
const { Step } = Steps;

const ChooseEbookModal = ({
  isVisible,
  onCancel,
  onPrevious,
  eBookList,
  imageReady,
  onIncrementImageLoadedCount,
  selectedEBookDocId,
  onEBookClick,
  onNext,
  windowWidth,
  auth
}) => {
  return (
    <Fragment>
      <Modal
        title="&nbsp;"
        visible={isVisible}
        onCancel={onCancel}
        width={windowWidth > 900 ? 900 : windowWidth - 30}
        footer={null}
        closeIcon={
          <img
            src="/assets/close-white-icon.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        }
      >
        <Spin spinning={!imageReady}>
          <Row align={windowWidth > 576 ? "top" : "middle"}>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Steps
                current={1}
                className="choose-ebook-model-steps-wrapper"
                direction={windowWidth > 576 ? "horizontal" : "vertical"}
                style={{
                  fontFamily: "Poppins-SemiBold"
                }}
              >
                <Step
                  title={
                    SELECTLANGUAGE[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                />
                <Step
                  title={
                    SELECTEBOOK[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                />
                <Step
                  title={
                    CUSTOMIZEFUNNEL[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                />
              </Steps>
            </Col>

            <Col
              xs={{ span: 12 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              style={{
                marginTop: "16px",
                display: imageReady ? "block" : "none"
              }}
            >
              <Row
                gutter={windowWidth > 576 ? [32, 32] : [16, 16]}
                style={{
                  maxHeight: "280px",
                  overflowY: "scroll"
                }}
              >
                {eBookList &&
                  eBookList.map(eBook => (
                    <Col key={eBook.docId} xs={24} sm={12} md={6} lg={6} xl={6}>
                      <img
                        className={
                          "choose-ebook-modal-img-wrapper" +
                          (selectedEBookDocId === eBook.docId
                            ? " selected"
                            : " not-selected")
                        }
                        src={eBook.previewImageUrl}
                        alt=""
                        onLoad={onIncrementImageLoadedCount}
                        onClick={() => onEBookClick(eBook.docId)}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col style={{ padding: "5px" }}>
              <Button
                className="choose-ebook-model-button-wrapper"
                style={{
                  fontFamily: "Poppins-Medium",
                  borderRadius: "24px",
                  height: "auto"
                }}
                onClick={onPrevious}
                type="primary"
              >
                {
                  GOBACK[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            </Col>
            <Col>
              <Button
                className="choose-ebook-model-button-wrapper"
                style={{
                  fontFamily: "Poppins-Medium",
                  borderRadius: "24px",
                  height: "auto"
                }}
                disabled={!selectedEBookDocId}
                onClick={onNext}
                type="primary"
              >
                {
                  CUSTOMIZEFUNNEL[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default ChooseEbookModal;
