export const operationTypeEnum = {
  NO_OPS: "NO_OPS",
  UNRECOVERABLE_ERROR: "UNRECOVERABLE_ERROR",
  SAVE_FUNNEL_PAGE: "SAVE_FUNNEL_PAGE",
  LOAD_FUNNEL_PAGE: "LOAD_FUNNEL_PAGE",
  PUBLISH_NEW_SUBDOMAIN: "PUBLISH_NEW_SUBDOMAIN",
  UPDATE_USER_DATA: " UPDATE_USER_DATA",
  UPDATE_QUIZ_PROGRESS: " UPDATE_QUIZ_PROGRESS",
  UPDATE_EXAM_PROGRESS: " UPDATE_EXAM_PROGRESS",
  UPDATE_USER_LANGUAGE: " UPDATE_USER_LANGUAGE",
  UPDATE_PROFILE_INFO: " UPDATE_PROFILE_INFO"
};
