import React, { Component, Fragment } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Popover,
} from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { withRouter } from "react-router";
import { initDBLoad, signUp } from "../auth/authActions";
import {
  GETSTARTEDNOW,
  ITSFREETOJOINANDGAIN,
  PLEASEENTERFULLNAME,
  NAME,
  PLEASEENTEREMAIL,
  EMAILADD,
  MALE,
  FEMALE,
  PREFERNOTSAY,
  INVALIDEMAIL,
  ECODE,
  PLEASESELECTGENDER,
  PLEASEENTERPASSWORD,
  PLEASEENTERECODE,
  PLEASESELECTBIRTHDAY,
  PRIVACYPOLICY,
  SIGNUP,
  SIGNIN,
  HERE,
  ALREADYHAVEACCOUNT,
  GENDER,
  DATE,
  PLEASEACCEPTPRIVACYPOLICY,
  PASSWORD,
  PASSWORDREQUIRED,
} from "./../BusinessPortal/TranslationConstant";

class SignUpNew extends Component {
  state = {
    reset: false,
    signingUp: false,
    isLoading: true,
    confirmPassErr: null,
  };

  onPasswordChange = (e, inputField) => {
    const { confirmPassword } = this.state;
    let regex = "^(?=.*[a-z])(?=.*[0-9])(?=.{8,})";
    let inputVal = e.target.value;
    if (inputVal.match(regex)) {
      this.setState({
        [inputField]: inputVal,
      });
    } else {
      this.setState({
        [inputField]: inputVal,
      });
    }

    if (confirmPassword != undefined) {
      if (inputVal == confirmPassword) {
        this.setState({
          [inputField]: inputVal,
          confirmPassErr: "",
        });
      } else {
        this.setState({
          [inputField]: inputVal,
          confirmPassErr: "Password not same",
        });
      }
    }
  };

  onConfirmPasswordChange = (e, inputField) => {
    const { password } = this.state;
    let inputVal = e.target.value;
    if (inputVal == password) {
      this.setState({
        [inputField]: inputVal,
        confirmPassErr: "",
      });
    } else {
      this.setState({
        [inputField]: inputVal,
        confirmPassErr: "Password not same",
      });
    }
  };

  showLogin = () => {
    this.props.history.push("/login");
  };

  onHandleSignUpFormFinish = async (e) => {
    const { signUp, history, initDBLoad, auth, dbAuth } = this.props;
    this.setState(
      {
        signingUp: true,
      },
      async () => {
        let result = await signUp(e, dbAuth.selectedLanguage);

        //error clause
        if (result) {
          message.error(result.code + " : " + result.message);
          this.setState({
            signingUp: false,
          });
        } else {
          await initDBLoad(auth.uid);
          history.push("/business-portal");
          //redirectToFreeTrial(); //TODO: Replace with proper system/check
        }
      }
    );
  };

  render() {
    const { dbAuth } = this.props;

    return (
      <Fragment>
        <Row>
          <Col xs={0} xl={15} style={{ padding: "90px" }}>
            <div
              style={{
                font: "28px Poppins-Bold",
                margin: "0px 0px",
                color: "#5E51A1 ",
              }}
            >
              BizMastery
            </div>
            <div
              style={{
                font: "20px Poppins-Bold",
                color: "black",
                margin: "60px 0px 40px 0px",
              }}
            >
              Register now and start your journey with Bizmastery now!
            </div>
            <Form ref={this.form} onFinish={this.onHandleSignUpFormFinish}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: PLEASEENTERFULLNAME[dbAuth.selectedLanguage],
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={NAME[dbAuth.selectedLanguage]}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    borderColor: "#c2beda",
                    padding: "0px 30px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: INVALIDEMAIL[dbAuth.selectedLanguage],
                  },
                  {
                    required: true,
                    message: PLEASEENTEREMAIL[dbAuth.selectedLanguage],
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={EMAILADD[dbAuth.selectedLanguage]}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    borderColor: "#c2beda",
                    padding: "0px 30px",
                  }}
                />
              </Form.Item>
              <Row>
                <Col span={12} style={{ padding: "0px 5px 0px 0px" }}>
                  <Form.Item
                    name="password"
                    onChange={(e) => this.onPasswordChange(e, "password")}
                    rules={[
                      {
                        pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                        message: PASSWORDREQUIRED[dbAuth.selectedLanguage],
                        // type: "regexp"
                      },
                      {
                        required: true,
                        message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={PASSWORD[dbAuth.selectedLanguage]}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#c2beda",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: "0px 0px 0px 5px" }}>
                  <Form.Item
                    name="confrimPassword"
                    onChange={(e) =>
                      this.onConfirmPasswordChange(e, "confirmPassword")
                    }
                    rules={
                      [
                        // {
                        //   pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                        //   message: PASSWORDREQUIRED[dbAuth.selectedLanguage],
                        //   // type: "regexp"
                        // },
                        // {
                        //   required: true,
                        //   message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage],
                        // },
                      ]
                    }
                  >
                    <Input.Password
                      size="large"
                      placeholder={PASSWORD[dbAuth.selectedLanguage]}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#c2beda",
                        padding: "0px 30px",
                      }}
                    />
                    {this.state.confirmPassErr != null ? (
                      <div style={{ color: "red" }}>
                        {this.state.confirmPassErr}
                      </div>
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="ecode"
                rules={[
                  {
                    required: true,
                    message: PLEASEENTERECODE[dbAuth.selectedLanguage],
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={ECODE[dbAuth.selectedLanguage]}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    borderColor: "#c2beda",
                    padding: "0px 30px",
                  }}
                />
              </Form.Item>
              <Row>
                <Col span={12} style={{ padding: "0px 5px 0px 0px" }}>
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: PLEASESELECTGENDER[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <Select
                      placeholder={GENDER[dbAuth.selectedLanguage]}
                      className="sign-up-gender-select-new"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Select.Option
                        className="sign-up-gender-select-new"
                        value="Male"
                      >
                        {MALE[dbAuth.selectedLanguage]}
                      </Select.Option>
                      <Select.Option
                        className="sign-up-gender-select-new"
                        value="Female"
                      >
                        {FEMALE[dbAuth.selectedLanguage]}
                      </Select.Option>
                      <Select.Option
                        className="sign-up-gender-select-new"
                        value="Prefer not to say"
                      >
                        {PREFERNOTSAY[dbAuth.selectedLanguage]}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: "0px 0px 0px 5px" }}>
                  <Form.Item
                    name="birthday"
                    rules={[
                      {
                        required: true,
                        message: PLEASESELECTBIRTHDAY[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <DatePicker
                      // disabledDate={this.disabledDate}

                      placeholder={DATE[dbAuth.selectedLanguage]}
                      format="DD/MM/YYYY"
                      inputReadOnly
                      suffixIcon={null}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="sign-up-datepicker-new"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={4} sm={4} md={8} lg={3} xl={5} />
                <Col xs={16} sm={16} md={8} lg={18} xl={14}>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="sign-up-button-blue"
                    loading={this.state.signingUp}
                  >
                    Register Now
                  </Button>
                </Col>
                <Col xs={4} sm={4} md={8} lg={3} xl={5} />
              </Row>
            </Form>
            <br />
            <div
              className="sign-up-description"
              style={{ textAlign: "center" }}
            >
              {ALREADYHAVEACCOUNT[dbAuth.selectedLanguage]}{" "}
              <u
                onClick={this.showLogin}
                style={{ cursor: "pointer", color: "#5E51A1 " }}
              >
                Login
              </u>
            </div>
          </Col>
          <Col xs={24} xl={0} style={{ padding: "60px" }}>
            <div
              style={{
                font: "28px Poppins-Bold",
                margin: "60px 0px",
                color: "#5E51A1 ",
              }}
            >
              BizMastery
            </div>
            <div
              style={{
                font: "20px Poppins-Bold",
                color: "black",
                margin: "60px 0px 40px 0px",
              }}
            >
              Register now and start your journey with Bizmastery now!
            </div>
            <Form
              ref={this.form}
              onFinish={this.onHandleSignUpFormFinish}
              initialValues={{
                name: this.state.name,
                email: this.state.email,
                mobileNumber: this.state.mobileNumber,
                address: this.state.address,
                birthday: this.state.birthday,
              }}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: PLEASEENTERFULLNAME[dbAuth.selectedLanguage],
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={NAME[dbAuth.selectedLanguage]}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    borderColor: "#c2beda",
                    padding: "0px 30px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: INVALIDEMAIL[dbAuth.selectedLanguage],
                  },
                  {
                    required: true,
                    message: PLEASEENTEREMAIL[dbAuth.selectedLanguage],
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={EMAILADD[dbAuth.selectedLanguage]}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    borderColor: "#c2beda",
                    padding: "0px 30px",
                  }}
                />
              </Form.Item>
              <Row>
                <Col span={12} style={{ padding: "0px 5px 0px 0px" }}>
                  <Form.Item
                    name="password"
                    onChange={(e) => this.onPasswordChange(e, "password")}
                    rules={[
                      {
                        pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                        message: PASSWORDREQUIRED[dbAuth.selectedLanguage],
                        // type: "regexp"
                      },
                      {
                        required: true,
                        message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={PASSWORD[dbAuth.selectedLanguage]}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#c2beda",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: "0px 0px 0px 5px" }}>
                  <Form.Item
                    name="confrimPassword"
                    onChange={(e) =>
                      this.onConfirmPasswordChange(e, "confirmPassword")
                    }
                    rules={[
                      {
                        pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                        message: PASSWORDREQUIRED[dbAuth.selectedLanguage],
                        // type: "regexp"
                      },
                      {
                        required: true,
                        message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={PASSWORD[dbAuth.selectedLanguage]}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#c2beda",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="ecode"
                rules={[
                  {
                    required: true,
                    message: PLEASEENTERECODE[dbAuth.selectedLanguage],
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={ECODE[dbAuth.selectedLanguage]}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    borderColor: "#c2beda",
                    padding: "0px 30px",
                  }}
                />
              </Form.Item>
              <Row>
                <Col span={12} style={{ padding: "0px 5px 0px 0px" }}>
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: PLEASESELECTGENDER[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <Select
                      placeholder={GENDER[dbAuth.selectedLanguage]}
                      className="sign-up-gender-select-new"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Select.Option
                        className="sign-up-gender-select-new"
                        value="Male"
                      >
                        {MALE[dbAuth.selectedLanguage]}
                      </Select.Option>
                      <Select.Option
                        className="sign-up-gender-select-new"
                        value="Female"
                      >
                        {FEMALE[dbAuth.selectedLanguage]}
                      </Select.Option>
                      <Select.Option
                        className="sign-up-gender-select-new"
                        value="Prefer not to say"
                      >
                        {PREFERNOTSAY[dbAuth.selectedLanguage]}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: "0px 0px 0px 5px" }}>
                  <Form.Item
                    name="birthday"
                    rules={[
                      {
                        required: true,
                        message: PLEASESELECTBIRTHDAY[dbAuth.selectedLanguage],
                      },
                    ]}
                  >
                    <DatePicker
                      // disabledDate={this.disabledDate}

                      placeholder={DATE[dbAuth.selectedLanguage]}
                      format="DD/MM/YYYY"
                      inputReadOnly
                      suffixIcon={null}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="sign-up-datepicker-new"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={4} sm={4} md={8} lg={3} xl={5} />
                <Col xs={16} sm={16} md={8} lg={18} xl={14}>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="sign-up-button-blue"
                    loading={this.state.signingUp}
                  >
                    Register Now
                  </Button>
                </Col>
                <Col xs={4} sm={4} md={8} lg={3} xl={5} />
              </Row>
            </Form>
            <br />
            <div
              className="sign-up-description"
              style={{ textAlign: "center" }}
            >
              {ALREADYHAVEACCOUNT[dbAuth.selectedLanguage]}{" "}
              <u
                onClick={this.showLogin}
                style={{ cursor: "pointer", color: "#5E51A1" }}
              >
                Login
              </u>
            </div>
          </Col>
          <Col
            xs={24}
            xl={0}
            flex="auto"
            style={{
              backgroundColor: "#f5f5f5",
              padding: "40px",
            }}
          >
            <div
              style={{
                font: "18px Poppins-Bold",
                color: "black",
                marginTop: "30px",
              }}
            >
              Why learn with BizMastery
            </div>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 0px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-1.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "15px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      ALL IN 1 ACCESS
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "14px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Full access to all learning contents and features with no
                      hidden cost
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 0px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-2.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "15px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      FAST AND IMPACTFUL LEARNING
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "14px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Fast and impactful learning with diverse, high quality
                      learning content
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 0px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-3.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "15px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      365 DAYS TO LEARN ANYWHERE, ANYTIME
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "14px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Master new skillsets at your own leisure
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 0px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-4.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "15px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      RECOGNIZED MASTER COACHE
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "14px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Learn from an esteemed industry expert
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 0px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-5.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "15px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      EXCLUSIVE CONTENT
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "14px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Exclusive access to special learning contents unavilable
                      elsewhere
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xs={0}
            xl={9}
            flex="auto"
            style={{
              backgroundColor: "#f5f5f5",
              width: "100vw",
              height: "100vh",
            }}
          >
            <div
              style={{
                font: "20px Poppins-Bold",
                color: "black",
                margin: "120px 80px 60px 80px",
              }}
            >
              Why learn with BizMastery
            </div>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 60px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-1.svg"
                  alt="Landing Page Row 1"
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      ALL IN 1 ACCESS
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Full access to all learning contents and features with no
                      hidden cost
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 60px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-2.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      FAST AND IMPACTFUL LEARNING
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Fast and impactful learning with diverse, high quality
                      learning content
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 60px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-3.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      365 DAYS TO LEARN ANYWHERE, ANYTIME
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Master new skillsets at your own leisure
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 60px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-4.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      RECOGNIZED MASTER COACHE
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Learn from an esteemed industry expert
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                margin: "20px 60px",
                borderRadius: "10px",
                alignItems: "center",
                boxShadow:
                  "0px 1px 2px 0px #c2beda, 1px 2px 4px 0px #c2beda, 2px 4px 8px 0px #c2beda, 2px 4px 16px 0px #c2beda",
              }}
            >
              <Col span={4} style={{ margin: "20px", flex: "none" }}>
                <img
                  src="/assets/New/Register/Why-Learn-Icons/Icon-5.svg"
                  alt="Landing Page Row 1"
                  style={{}}
                />
              </Col>
              <Col span={17}>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Bold",
                        color: "black",
                      }}
                    >
                      EXCLUSIVE CONTENT
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        font: "16px Poppins-Normal",
                        color: "grey",
                      }}
                    >
                      Exclusive access to special learning contents unavilable
                      elsewhere
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  signUp,
  initDBLoad,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUpNew);
