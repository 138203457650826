import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
// import UserMainPageTopBar from "./UserMainPageTopBar/UserMainPageTopBar";
import SelectEBookLanguageModal from "./modals/SelectEBookLanguageModal";
import ChooseEBookModal from "./modals/ChooseEBookModal";
import { Row, Col, Button, Input, message } from "antd";
import "./UserMainPage.css";
import FreeYoutube from "./FreeYoutube/FreeYoutube";
// import UserMainPageFooter from "./UserMainPageFooter/UserMainPageFooter";
import { loadEBook, resetEBook } from "./book/bookActions";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";

import LoadingComponent from "./app/common/loading/LoadingComponent";
import { operationTypeEnum } from "./OperationTypeConstant";
import {
  loadUserMainPageInfo,
  publishNewSubdomain,
  resetUserMainPageInfo
} from "./domain/domainActions";
import {
  CREATEANDEDIT,
  ADDSALESFUNNEL,
  EDITFUNNEL,
  DOWNLOADEBOOK,
  DOMAINCONFIG,
  CONFIGSUBDOMAIN,
  BUILDYOURFUNNEL,
  CHECKAVAILABITIYSUBDOMAIN,
  SUBDOMAINISAVAILABLE,
  SUBDOMAINISUNAVAILABLE,
  CURRENTSUBDOMAIN,
  BUYADOMAININSTEED,
  HERESSTEPS,
  SELECTLANGUAGE,
  SELECTEBOOK,
  CUSTOMIZEFUNNEL,
  PUBLISHFUNNEL,
  YOUHAVEPUBLISHEDFUNNEL,
  REPUBLISHFUNNEL
} from "./BusinessPortal/TranslationConstant";

class UserMainPage extends Component {
  inputSearchTimer = null;

  state = {
    currentSubdomain: "",
    enteredSubdomain: "",
    isValidSubdomain: false,
    isValidatingDomain: false,
    isLoading: true,
    isSelectEBookLanguageModalVisible: false,
    isChooseEBookModalVisible: false,
    selectedLanguageCode: null,
    selectedEBookDocId: null,
    eBookList: null,
    loadedImageCount: 0,
    imageReady: false,
    windowWidth: window.innerWidth
  };

  async componentDidMount() {
    const { auth, loadUserMainPageInfo } = this.props;
    await loadUserMainPageInfo(auth.uid);
    window.addEventListener("resize", this.onHandleResize);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { loadedImageCount, imageReady, eBookList } = this.state;
    const { firebase, book, operation, domain } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (operation.operationType === operationTypeEnum.NO_OPS) {
        this.setState({
          isLoading: false,
          currentSubdomain: domain.userDomainInfoObj.subdomain,
          enteredSubdomain: domain.userDomainInfoObj.subdomain,
          isValidSubdomain: false
        });
      }
    }

    //==
    if (
      !_.isEqual(prevState.loadedImageCount, loadedImageCount) &&
      !imageReady &&
      eBookList
    ) {
      if (loadedImageCount === eBookList.length) {
        this.setState({
          imageReady: true
        });
      }
    }
    //==

    if (
      !_.isEqual(prevProps.book.eBookList, book.eBookList) &&
      book.eBookList
    ) {
      let previewImagePromises = [];
      let eBookList = [];

      book.eBookList.forEach(eBook => {
        previewImagePromises.push(
          storageRef.child(eBook.previewImagePath).getDownloadURL()
        );
      });

      let previewImageUrls = await Promise.all(previewImagePromises);

      //assume all images's url downloaded completely
      for (let [idx, value] of previewImageUrls.entries()) {
        eBookList.push({
          previewImageUrl: value + "&v=" + Math.random(), //randomize url to prevent caching
          docId: book.eBookList[idx].docId
        });
      }

      this.setState({
        eBookList
      });
    }

    //notification related
    if (
      prevProps.operation.operationType ===
        operationTypeEnum.PUBLISH_NEW_SUBDOMAIN &&
      operation.operationType === operationTypeEnum.NO_OPS
    ) {
      message.success("Subdomain updated successfully.");
    }
  }

  componentWillUnmount() {
    //TODO:might need reset more if user already have funnel created previously
    this.props.resetUserMainPageInfo();
    window.removeEventListener("resize", this.onHandleResize);
  }

  onHandleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  onHandleAddFunnelClick = () => {
    this.setState({
      isSelectEBookLanguageModalVisible: true
    });
  };

  onHandleAddFunnelCancelButtonClick = () => {
    this.setState({
      isSelectEBookLanguageModalVisible: false,
      selectedLanguageCode: null
    });
  };

  onHandleAddFunnelNextButtonClick = async () => {
    const { selectedLanguageCode } = this.state;
    const { loadEBook } = this.props;

    await loadEBook(selectedLanguageCode);

    this.setState({
      isSelectEBookLanguageModalVisible: false,
      isChooseEBookModalVisible: true
    });
  };

  onLanguageImageClick = selectedLanguageCode => {
    this.setState({
      selectedLanguageCode
    });
  };

  //
  onHandleChooseEBookCancelButtonClick = () => {
    const { resetEBook } = this.props;
    this.setState(
      {
        isChooseEBookModalVisible: false,
        selectedLanguageCode: null,
        eBookList: null,
        loadedImageCount: 0,
        imageReady: false,
        selectedEBookDocId: null
      },
      () => {
        resetEBook();
      }
    );
  };

  onHandleChooseEBookPreviousButtonClick = () => {
    const { resetEBook } = this.props;
    this.setState(
      {
        isSelectEBookLanguageModalVisible: true,
        isChooseEBookModalVisible: false,
        eBookList: null,
        loadedImageCount: 0,
        imageReady: false,
        selectedEBookDocId: null
      },
      () => {
        resetEBook();
      }
    );
  };

  onIncrementImageLoadedCount = () => {
    // console.log("IMAGE LOAD COUNT", this.state.loadedImageCount);
    this.setState(prevState => ({
      loadedImageCount: prevState.loadedImageCount + 1
    }));
  };

  onEBookClick = selectedEBookDocId => {
    this.setState({
      selectedEBookDocId
    });
  };

  onHandleChooseEBookNextButtonClick = () => {
    const { selectedLanguageCode, selectedEBookDocId } = this.state;

    this.props.history.push(
      `/business-portal/sales-funnel/new-funnel-page/${selectedLanguageCode}/${selectedEBookDocId}/T0001`
    );
  };

  onHandleEditFunnelButtonClick = () => {
    const { auth } = this.props;
    // Old code
    // this.props.history.push(`/portal/funnel-page/${auth.uid}`);
    // New code
    this.props.history.push(
      `/business-portal/sales-funnel/funnel-page/${auth.uid}`
    );
  };

  onHandleDownloadEBookButtonClick = async () => {
    const { salesFunnel } = this.props;

    let pdfDownloadPromise = new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function(event) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(xhr.status);
        }
      };
      xhr.open("GET", salesFunnel.pdfFileUrl);
      xhr.send();
    });

    let downloadedBlob = await pdfDownloadPromise;
    var url = URL.createObjectURL(downloadedBlob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "sales-funnel-eBook.pdf";

    a.click();
  };

  onHandleSubdomainInputValueChange = async e => {
    const { firebase } = this.props;
    const db = firebase.firestore();
    let enteredSubdomain = e.target.value;

    clearTimeout(this.inputSearchTimer);

    this.setState({
      isValidatingDomain: true,
      enteredSubdomain,
      isValidSubdomain: false
    });

    this.inputSearchTimer = setTimeout(async () => {
      if (enteredSubdomain.toLowerCase() === "www") {
        this.setState({
          isValidSubdomain: false,
          isValidatingDomain: false
        });
      } else {
        let querySnap = await db
          .collection("urls")
          .where("subdomain", "==", enteredSubdomain)
          .limit(1)
          .get();

        this.setState({
          isValidSubdomain: querySnap.empty,
          isValidatingDomain: false
        });
      }
    }, 1000);
  };

  onHandlePublicFunnelButtonClick = async () => {
    const { enteredSubdomain } = this.state;
    const { publishNewSubdomain, auth } = this.props;
    await publishNewSubdomain(auth.uid, enteredSubdomain);
  };

  render() {
    const {
      isLoading,
      isSelectEBookLanguageModalVisible,
      isChooseEBookModalVisible,
      selectedLanguageCode,
      eBookList,
      imageReady,
      selectedEBookDocId,
      currentSubdomain,
      enteredSubdomain,
      isValidSubdomain,
      isValidatingDomain,
      windowWidth
    } = this.state;

    const { salesFunnel, domain, dbAuth } = this.props;
    if (isLoading) return <LoadingComponent auth={dbAuth} />;

    return (
      <Fragment>
        {/* <UserMainPageTopBar /> */}
        <SelectEBookLanguageModal
          isVisible={isSelectEBookLanguageModalVisible}
          onCancel={this.onHandleAddFunnelCancelButtonClick}
          onNext={this.onHandleAddFunnelNextButtonClick}
          onLanguageImageClick={this.onLanguageImageClick}
          selectedLanguageCode={selectedLanguageCode}
          windowWidth={windowWidth}
          auth={dbAuth}
        />
        <ChooseEBookModal
          isVisible={isChooseEBookModalVisible}
          onCancel={this.onHandleChooseEBookCancelButtonClick}
          onPrevious={this.onHandleChooseEBookPreviousButtonClick}
          eBookList={eBookList}
          onIncrementImageLoadedCount={this.onIncrementImageLoadedCount}
          imageReady={imageReady}
          selectedEBookDocId={selectedEBookDocId}
          onEBookClick={this.onEBookClick}
          onNext={this.onHandleChooseEBookNextButtonClick}
          windowWidth={windowWidth}
          auth={dbAuth}
        />

        <Row style={{ background: "#F5F5F5" }}>
          <Col
            offset={2}
            span={20}
            // style={{ marginTop: "36px" }}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={5}
                style={{ marginTop: "36px" }}
              >
                <FreeYoutube auth={dbAuth} />
              </Col>
              <Col
                xs={{ offset: 0, span: 24 }}
                sm={{ offset: 0, span: 24 }}
                md={{ offset: 0, span: 24 }}
                lg={{ offset: 0, span: 24 }}
                xl={{ offset: 2, span: 17 }}
                style={{ marginTop: "36px" }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      borderRadius: "16px",
                      padding: "20px 40px 40px 20px",
                      background: "white",
                      boxShadow: "2px 1px rgba(0,0,0,0.14)"
                    }}
                  >
                    <Row style={{ textAlign: "center" }}>
                      <Col
                        className="user-main-page-funnel-page-manage-funnel-title-wrapper"
                        span={24}
                      >
                        {
                          CREATEANDEDIT[
                            dbAuth.userInfo
                              ? dbAuth.userInfo.nativeLanguage
                                ? dbAuth.userInfo.nativeLanguage
                                : "en"
                              : "en"
                          ]
                        }
                      </Col>
                      <Col
                        xs={{ offset: 0, span: 24 }}
                        sm={{ offset: 0, span: 24 }}
                        md={{ offset: 0, span: 8 }}
                        lg={{ offset: 0, span: 8 }}
                        xl={{ offset: 0, span: 8 }}
                      >
                        <Button
                          style={{
                            fontFamily: "Poppins-Medium",
                            borderRadius: "4px",
                            color: "white",
                            height: "48px"
                            // width: "20%"
                          }}
                          onClick={this.onHandleAddFunnelClick}
                          type="primary"
                        >
                          {
                            ADDSALESFUNNEL[
                              dbAuth.userInfo
                                ? dbAuth.userInfo.nativeLanguage
                                  ? dbAuth.userInfo.nativeLanguage
                                  : "en"
                                : "en"
                            ]
                          }
                        </Button>
                      </Col>

                      {salesFunnel.isOwningSalesFunnel && (
                        <Fragment>
                          <Col
                            xs={24}
                            sm={24}
                            md={0}
                            lg={0}
                            xl={0}
                            style={{ marginTop: "24px" }}
                          />
                          <Col
                            xs={{ offset: 0, span: 24 }}
                            sm={{ offset: 0, span: 24 }}
                            md={{ offset: 0, span: 8 }}
                            lg={{ offset: 0, span: 8 }}
                            xl={{ offset: 0, span: 8 }}
                          >
                            <Button
                              style={{
                                fontFamily: "Poppins-Medium",
                                borderRadius: "4px",
                                color: "white",
                                height: "48px"
                              }}
                              onClick={this.onHandleEditFunnelButtonClick}
                              type="primary"
                            >
                              {
                                EDITFUNNEL[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </Button>
                          </Col>

                          <Col
                            xs={24}
                            sm={24}
                            md={0}
                            lg={0}
                            xl={0}
                            style={{ marginTop: "24px" }}
                          />
                          <Col
                            xs={{ offset: 0, span: 24 }}
                            sm={{ offset: 0, span: 24 }}
                            md={{ offset: 0, span: 8 }}
                            lg={{ offset: 0, span: 8 }}
                            xl={{ offset: 0, span: 8 }}
                          >
                            <Button
                              style={{
                                fontFamily: "Poppins-Medium",
                                borderRadius: "4px",
                                color: "white",
                                height: "48px"
                              }}
                              onClick={this.onHandleDownloadEBookButtonClick}
                              disabled={salesFunnel.pdfFileUrl.length === 0}
                              type="primary"
                            >
                              {
                                DOWNLOADEBOOK[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </Button>
                          </Col>
                        </Fragment>
                      )}
                    </Row>
                  </Col>

                  {salesFunnel.isOwningSalesFunnel && (
                    <Col
                      span={24}
                      style={{
                        borderRadius: "16px",
                        padding:
                          windowWidth > 768 ? "20px 40px 40px 20px" : "16px",
                        background: "white",
                        boxShadow: "2px 1px rgba(0,0,0,0.14)",
                        marginTop: "36px"
                      }}
                    >
                      <Row>
                        <Col
                          className="user-main-page-funnel-page-manage-funnel-configure-title-wrapper"
                          span={24}
                        >
                          {
                            DOMAINCONFIG[
                              dbAuth.userInfo
                                ? dbAuth.userInfo.nativeLanguage
                                  ? dbAuth.userInfo.nativeLanguage
                                  : "en"
                                : "en"
                            ]
                          }
                        </Col>
                        <Col
                          xs={{ offset: 0, span: 24 }}
                          sm={{ offset: 4, span: 16 }}
                          md={{ offset: 4, span: 16 }}
                          lg={{ offset: 4, span: 16 }}
                          xl={{ offset: 4, span: 16 }}
                        >
                          {currentSubdomain.length > 0 ? (
                            <div className="user-main-page-funnel-page-created-publish-title-wrapper">
                              {
                                CONFIGSUBDOMAIN[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </div>
                          ) : (
                            <div className="user-main-page-funnel-page-created-publish-title-wrapper">
                              {
                                BUILDYOURFUNNEL[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </div>
                          )}

                          <br />
                          <div className="user-main-page-funnel-page-created-publish-check-availability-desc-wrapper">
                            {
                              CHECKAVAILABITIYSUBDOMAIN[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </div>
                          <Input
                            style={{
                              width:
                                windowWidth > 768
                                  ? "200px"
                                  : windowWidth > 400
                                  ? "150px"
                                  : "100px"
                            }}
                            value={enteredSubdomain}
                            onChange={e =>
                              this.onHandleSubdomainInputValueChange(e)
                            }
                          />
                          <span className="user-main-page-funnel-page-created-publish-hostname-wrapper">
                            &nbsp;.{domain.sharedDomain}
                          </span>
                          {!isValidatingDomain &&
                            enteredSubdomain.length > 0 &&
                            isValidSubdomain &&
                            enteredSubdomain !== currentSubdomain && (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Poppins-SemiBold",
                                  color: "#40CBDC"
                                }}
                              >
                                {
                                  SUBDOMAINISAVAILABLE[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                              </div>
                            )}

                          {!isValidatingDomain &&
                            enteredSubdomain.length > 0 &&
                            !isValidSubdomain &&
                            enteredSubdomain !== currentSubdomain && (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Poppins-SemiBold",
                                  color: "#DC4040"
                                }}
                              >
                                {
                                  SUBDOMAINISUNAVAILABLE[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                              </div>
                            )}

                          {!isValidatingDomain &&
                            enteredSubdomain === currentSubdomain &&
                            currentSubdomain.length > 0 && (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Poppins-SemiBold",
                                  color: "#40CBDC"
                                }}
                              >
                                {
                                  CURRENTSUBDOMAIN[
                                    dbAuth.userInfo
                                      ? dbAuth.userInfo.nativeLanguage
                                        ? dbAuth.userInfo.nativeLanguage
                                        : "en"
                                      : "en"
                                  ]
                                }
                              </div>
                            )}
                        </Col>
                        <Col xs={0} sm={4} md={4} lg={4} xl={4} />
                      </Row>

                      <br />

                      <Row style={{ textAlign: "center" }}>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Button
                            style={{
                              fontFamily: "Poppins-Medium",
                              borderRadius: "4px",
                              color: "white",
                              height: "48px",
                              width: "200px"
                            }}
                            type="primary"
                          >
                            {
                              BUYADOMAININSTEED[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </Button>
                        </Col>

                        <Col
                          xs={24}
                          sm={24}
                          md={0}
                          lg={0}
                          xl={0}
                          style={{ marginTop: "24px" }}
                        />
                        <Col
                          xs={{ offset: 0, span: 24 }}
                          sm={{ offset: 0, span: 24 }}
                          md={{ offset: 12, span: 6 }}
                          lg={{ offset: 12, span: 6 }}
                          xl={{ offset: 12, span: 6 }}
                        >
                          <Button
                            className="user-main-page-public-funnel-button-wrapper"
                            style={{
                              fontFamily: "Poppins-Medium",
                              borderRadius: "4px",

                              height: "48px",
                              width: "auto"
                            }}
                            disabled={
                              enteredSubdomain.length === 0 ||
                              (enteredSubdomain.length > 0 && !isValidSubdomain)
                            }
                            onClick={this.onHandlePublicFunnelButtonClick}
                            loading={isValidatingDomain}
                            type="primary"
                          >
                            {currentSubdomain.length > 0
                              ? REPUBLISHFUNNEL[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              : PUBLISHFUNNEL[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {domain.userDomainInfoObj.host.length === 0 &&
                    domain.userDomainInfoObj.subdomain.length === 0 && (
                      <Col
                        span={24}
                        style={{
                          borderRadius: "16px",
                          padding: "40px",
                          background: "white",
                          boxShadow: "2px 1px rgba(0,0,0,0.14)",
                          marginTop: "36px"
                        }}
                      >
                        <Row align="bottom">
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={10}
                            xl={10}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              className="user-main-page-step-image-wrapper"
                              src="/assets/hit-goal-image.jpg"
                              alt=""
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                            <div
                              style={{
                                fontSize: "18px",
                                fontFamily: "Poppins-SemiBold",
                                color: "#34374D"
                              }}
                            >
                              {
                                HERESSTEPS[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </div>
                            <br />
                            <div className="user-main-page-steps-circle-wrapper" />
                            <span className="user-main-page-steps-text-wrapper">
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {
                                SELECTLANGUAGE[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </span>
                            <br />
                            <br />
                            <div className="user-main-page-steps-circle-wrapper" />
                            <span className="user-main-page-steps-text-wrapper">
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {
                                SELECTEBOOK[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </span>
                            <br />
                            <br />
                            <div className="user-main-page-steps-circle-wrapper" />
                            <span className="user-main-page-steps-text-wrapper">
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {
                                CUSTOMIZEFUNNEL[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </span>
                            <br />
                            <br />
                            <div className="user-main-page-steps-circle-wrapper" />
                            <span className="user-main-page-steps-text-wrapper">
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {
                                PUBLISHFUNNEL[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  {(domain.userDomainInfoObj.host.length > 0 ||
                    domain.userDomainInfoObj.subdomain.length > 0) && (
                    <Col
                      span={24}
                      style={{
                        borderRadius: "16px",
                        padding: "40px",
                        background: "white",
                        boxShadow: "2px 1px rgba(0,0,0,0.14)",
                        marginTop: "36px"
                      }}
                    >
                      <Row align="bottom">
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={10}
                          xl={10}
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="user-main-page-funnel-page-created-steps-image-wrapper"
                            src="/assets/hit-goal-image.jpg"
                            alt=""
                          />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                          <div
                            className="user-main-page-funnel-page-created-steps-title-wrapper"
                            style={{
                              textAlign: windowWidth > 576 ? "left" : "center"
                            }}
                          >
                            {
                              YOUHAVEPUBLISHEDFUNNEL[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </div>
                          <br />
                          <img
                            className="user-main-page-funnel-page-created-steps-circle-wrapper"
                            src="/assets/checked-icon.svg"
                            alt=""
                          />
                          <span className="user-main-page-funnel-page-created-steps-text-wrapper">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {
                              SELECTLANGUAGE[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </span>
                          <br />
                          <br />
                          <img
                            className="user-main-page-funnel-page-created-steps-circle-wrapper"
                            src="/assets/checked-icon.svg"
                            alt=""
                          />
                          <span className="user-main-page-funnel-page-created-steps-text-wrapper">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {
                              SELECTEBOOK[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </span>
                          <br />
                          <br />
                          <img
                            className="user-main-page-funnel-page-created-steps-circle-wrapper"
                            src="/assets/checked-icon.svg"
                            alt=""
                          />
                          <span className="user-main-page-funnel-page-created-steps-text-wrapper">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {
                              CUSTOMIZEFUNNEL[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </span>
                          <br />
                          <br />
                          <img
                            className="user-main-page-funnel-page-created-steps-circle-wrapper"
                            src="/assets/checked-icon.svg"
                            alt=""
                          />
                          <span className="user-main-page-funnel-page-created-steps-text-wrapper">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {
                              PUBLISHFUNNEL[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={2} />
          {!salesFunnel.isOwningSalesFunnel && (
            <Col
              xs={0}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "350px" }}
            />
          )}

          <Col span={24} style={{ marginTop: "16px", marginBottom: "16px" }}>
            {/* <UserMainPageFooter /> */}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  loadEBook,
  loadUserMainPageInfo,
  publishNewSubdomain,
  resetUserMainPageInfo,
  resetEBook
};

const mapStateToProps = state => ({
  book: state.book,
  auth: state.firebase.auth,
  salesFunnel: state.salesFunnel,
  operation: state.operation,
  domain: state.domain,
  dbAuth: state.auth
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(UserMainPage);
