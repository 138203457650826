import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import React, { Component, Fragment } from "react";
import "./ReviewQuestions.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { loadAllExam } from "./examActions";
import { getTransText } from "../../SharedUtils";
import _ from "lodash";
import LoadingComponent from "../../app/common/loading/LoadingComponent";

class ReviewQuestions extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    const { match } = this.props;
    let levelId = match.params.levelId;

    this.props.loadAllExam();

    this.setState({
      levelId
    });
  }

  async componentDidUpdate(prevProps) {
    const { exams, auth, translations } = this.props;
    const { levelId } = this.state;

    if (
      !_.isEqual(prevProps.exams.examInfoObj, exams.examInfoObj) ||
      (exams.examInfoObj !== null && this.state.isLoading)
    ) {
      const questionList = [];

      for (const level of exams.examInfoObj) {
        if (level.docId === levelId) {
          for (const question of level.questions) {
            questionList.push(question);
          }
          const data = questionList.map((question, idx) => {
            // console.log(question.answer === auth.progressInfo.exams[levelId].answersIndex[idx])
            return {
              key: idx,
              question: auth.userInfo.nativeLanguage
                ? getTransText(
                    auth.userInfo.nativeLanguage,
                    question.question,
                    translations.translationObj
                  ).text
                : getTransText(
                    "en",
                    question.question,
                    translations.translationObj
                  ).text,
              answer: this.toAnswer(question.answer),
              correct:
                question.answer ===
                auth.progressInfo.exams[levelId].answersIndex[idx]
            };
          });
          // console.log(questionList, data)
          this.setState({
            isLoading: false,
            questionList,
            data
          });
        }
      }
    }
  }

  done = () => {
    // const { levelId } = this.state;
    // this.props.history.push(`/business-portal/exams/${levelId}/certificate`);
    window.history.back();
  };

  back = () => {
    this.props.history.goBack();
  };

  toAnswer = idx => {
    return idx === 0
      ? "A"
      : idx === 1
      ? "B"
      : idx === 2
      ? "C"
      : idx === 3
      ? "D"
      : "No answer";
  };

  render() {
    const { questionList, isLoading, data } = this.state;
    const { auth, translations } = this.props;
    const columns = [
      {
        dataIndex: "question",
        title: "Questions",
        render: value => (
          <div className="review-questions-table-row-text">{value}</div>
        )
      },
      {
        dataIndex: "answer",
        title: "Your Answer",
        align: "center",
        render: value => (
          <div className="review-questions-table-row-text">{value}</div>
        )
      },
      {
        dataIndex: "correct",
        title: "Correct Answer",
        align: "center",
        render: (value, record, index) => (
          <>
            {value ? (
              <img
                src="/assets/businessPortal/correct-answer.png"
                alt="Correct Answer"
                className="review-questions-img"
              />
            ) : (
              <>
                <img
                  src="/assets/businessPortal/wrong-answer.png"
                  alt="Wrong Answer"
                  className="review-questions-img"
                />
                <div className="review-questions-table-row-text">
                  Correct:{" "}
                  {auth.userInfo.nativeLanguage
                    ? getTransText(
                        auth.userInfo.nativeLanguage,
                        questionList[index].options[questionList[index].answer],
                        translations.translationObj
                      ).text
                    : getTransText(
                        "en",
                        questionList[index].options[questionList[index].answer],
                        translations.translationObj
                      ).text}
                </div>
              </>
            )}
          </>
        )
      }
    ];

    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row className="review-questions-row">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={4}
            className="review-questions-left-sidebar"
          >
            <Button
              icon={<LeftOutlined />}
              className="review-questions-back-button"
              onClick={this.back}
            >
              Back
            </Button>
            <div className="review-questions-left-sidebar-title">
              {questionList.name}
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={16}
            className="review-questions-container"
          >
            <div className="review-questions-title">Review exam questions</div>
            <br />
            <Table
              className="review-questions-table"
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered={true}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={4}
            className="review-questions-right-sidebar"
          >
            <Button
              className="review-questions-done-button"
              onClick={this.done}
            >
              DONE
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  exams: state.exams,
  auth: state.auth,
  translations: state.translations
});

const mapDispatchToProps = {
  loadAllExam
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ReviewQuestions);
