import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";
import "./ExamCert.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import { withFirebase } from "react-redux-firebase";
import { getTransText } from "../../SharedUtils";
import { loadAllExam } from "./examActions";
import _ from "lodash";
import {
  REVIEWQUEST,
  DONE,
  BACK,
  LEVEL,
  FAILEDEXAM
} from "../TranslationConstant";

class ExamFail extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    const { match } = this.props;
    let levelId = match.params.levelId;
    this.props.loadAllExam();
    window.scrollTo(0, 0);
    this.setState({
      levelId
    });
  }

  async componentDidUpdate(prevProps) {
    const { exams } = this.props;

    if (
      !_.isEqual(prevProps.exams.examInfoObj, exams.examInfoObj) ||
      (exams.examInfoObj !== null && this.state.isLoading)
    ) {
      this.setState({
        isLoading: false
      });
    }
  }
  reviewQuestions = () => {
    const { levelId } = this.state;
    this.props.history.push(`/business-portal/${levelId}/review-questions`);
  };

  back = () => {
    this.props.history.push(`/business-portal/exams`);
  };

  render() {
    const { isLoading, levelId } = this.state;
    const { exams, auth, translations } = this.props;
    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row className="exam-row">
          <Col xs={0} sm={0} md={0} lg={0} xl={4} className="exam-left-sidebar">
            <Button
              icon={<LeftOutlined />}
              className="exam-back-button"
              onClick={this.back}
            >
              {
                BACK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />
            {exams.examInfoObj.map((lesson, idx) => {
              if (lesson.docId === levelId) {
                return (
                  <div key={idx} className="lesson-left-sidebar-title">
                    {
                      LEVEL[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }{" "}
                    {auth.userInfo.nativeLanguage
                      ? getTransText(
                          auth.userInfo.nativeLanguage,
                          lesson.name,
                          translations.translationObj
                        ).text
                      : getTransText(
                          "en",
                          lesson.name,
                          translations.translationObj
                        ).text}
                  </div>
                );
              } else return null;
            })}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={0}
            style={{ paddingTop: "40px", paddingLeft: "40px" }}
          >
            <Button
              icon={<LeftOutlined />}
              className="exam-back-button-small"
              onClick={this.back}
            >
              {
                BACK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
          </Col>

          <Col lg={24} xl={16} className="exam-container">
            <div className="exam-title">
              {
                FAILEDEXAM[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
              <br />
            </div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={4}
            className="exam-right-sidebar"
            style={{ textAlign: "center", paddingTop: "40px" }}
          >
            <Button
              className="exam-continue-button"
              onClick={this.reviewQuestions}
            >
              {
                REVIEWQUEST[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />

            <Button className="exam-continue-button" onClick={this.back}>
              {
                DONE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={0}
            style={{
              left: "0",
              bottom: "0",
              width: "100%",
              textAlign: "center"
            }}
          >
            <Button
              className="exam-continue-button"
              onClick={this.reviewQuestions}
            >
              REVIEW QUESTIONS
            </Button>
            <br />
            <br />

            <Button className="exam-continue-button" onClick={this.back}>
              DONE
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  exams: state.exams,
  auth: state.auth,
  translations: state.translations
});

const mapDispatchToProps = {
  loadAllExam
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ExamFail);
