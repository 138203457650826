import React, { Component, Fragment } from "react";
import { Row, Col, Typography, Modal, Divider } from "antd";

const { Text } = Typography;

class AcademyModal extends Component {
  // form = React.createRef();
  render() {
    const { visible, hideModal } = this.props;
    return (
      <Fragment>
        <Modal visible={visible} footer={false} onCancel={hideModal}>
          <Row key="">
            <Col span={2} />
            <Col span={20}>
              <Row>
                <Col offset={1} span={22} style={{ textAlign: "center" }}>
                  <Text className="home-title">Business Skill Academy</Text>
                </Col>
                <Divider />
                <Col span={24}>
                  <div>Course 1: Power To Believe (English)</div>
                  <div>Course 2: Marketing</div>
                  <div>Course 3: Business Speaking</div>
                  <div>Course 4: Professional Selling</div>
                  <div>Course 5: Network Marketing</div>
                  <div>Course 6: Turning Yearly Income</div>
                </Col>
              </Row>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

export default AcademyModal;
