import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  notification,
  Collapse,
  Menu,
  Popover,
  FloatButton,
} from "antd";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SnippetsOutlined,
  MenuOutlined,
} from "@ant-design/icons";
// import Marquee from "react-marquee-slider";
import "@brainhubeu/react-carousel/lib/style.css";
import "../BusinessPortal/LandingPage.less";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import VisibilitySensor from "react-visibility-sensor";
import { initDBLoad, updateLanguage } from "../auth/authActions";

// import Bowser from "bowser"
const { Panel } = Collapse;
// const broswer = Bowser.getParser(window.navigator.userAgent)

class LandingPage extends Component {
  state = {
    loginModalVisible: false,
    messageModalVisible: false,
    languagePopoverVisible: false,
    languageBigPopoverVisible: false,
    visibility: false,
    visibility2: false,
  };

  onRegisterClick = () => {
    this.props.history.push("/register");
  };

  onLoginClick = () => {
    this.props.history.push("/login");
  };

  languagePopoverChange = (visible) => {
    this.setState({
      languagePopoverVisible: visible,
    });
  };

  languageBigPopoverChange = (visible) => {
    this.setState({
      languageBigPopoverVisible: visible,
    });
  };

  onLanguageMenuClick = async (e) => {
    console.log(this.props.dbAuth, e.key, "hello");
    if (this.props.dbAuth) {
      this.props.updateLanguage(e.key, this.props.dbAuth.selectedLanguage);

      console.log(e.key);
      this.setState({
        languagePopoverVisible: false,
        diroctoryCollapsed: false,
        // languagePopoverVisible: !this.state.languagePopoverVisible
      });
    }
  };

  onLanguageBigMenuClick = async (e) => {
    console.log(this.props.dbAuth, e.key, "Big");
    if (this.props.dbAuth) {
      this.props.updateLanguage(e.key, this.props.dbAuth.selectedLanguage);

      console.log(e.key);
      this.setState({
        languageBigPopoverVisible: false,
        diroctoryCollapsed: false,
        // languagePopoverVisible: !this.state.languagePopoverVisible
      });
    }
  };

  componentDidUpdate() {
    const {} = this.props;
  }

  render() {
    const { dbAuth } = this.props;
    const {} = this.state;
    return (
      <Fragment>
        <Row flex="auto">
          <Col
            xs={0}
            lg={24}
            style={{
              backgroundImage: "url(/assets/New/Home/Hero-Background.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100vw",
              height: "100vh",
              padding: "0px 60px 0px 60px",
            }}
          >
            <Row className="topbar">
              <Col flex="auto" style={{ margin: "10px" }}>
                <img
                  src="/assets/New/Home/Bizmastery_Logo.svg"
                  alt="Landing Page"
                />
              </Col>

              <Col flex="fill">
                <Popover
                  visible={this.state.languageBigPopoverVisible}
                  onVisibleChange={this.languageBigPopoverChange}
                  overlayClassName="home-page-popover-container-wrapper"
                  content={
                    <Menu
                      defaultSelectedKeys={[
                        dbAuth ? dbAuth.selectedLanguage : "en",
                      ]}
                      style={{
                        fontFamily: "Calibri-Regular",
                        fontSize: "16px",
                        borderRight: "none",
                      }}
                      onClick={(e) => this.onLanguageBigMenuClick(e)}
                    >
                      <Menu.Item key={"en"}>
                        <img
                          src="/assets/businessPortal/uk-icon.svg"
                          alt=""
                          style={{ width: "50px", height: "25px" }}
                        />
                        English
                      </Menu.Item>
                      <Menu.Item key={"ms"}>
                        <img
                          src="/assets/businessPortal/malaysia-icon.svg"
                          alt=""
                          style={{ width: "50px", height: "25px" }}
                        />
                        Bahasa Malaysia
                      </Menu.Item>
                      <Menu.Item key={"cs"}>
                        <img
                          src="/assets/businessPortal/china-icon.svg"
                          alt=""
                          style={{ width: "50px", height: "25px" }}
                        />
                        简体中文
                      </Menu.Item>
                      <Menu.Item key={"ct"}>
                        <img
                          src="/assets/businessPortal/china-icon.svg"
                          alt=""
                          style={{ width: "50px", height: "25px" }}
                        />
                        繁體中文
                      </Menu.Item>
                      <Menu.Item key={"in"}>
                        <img
                          src="/assets/businessPortal/indonesia-icon.svg"
                          alt=""
                          style={{ width: "50px", height: "25px" }}
                        />
                        Bahasa Indonesia
                      </Menu.Item>
                    </Menu>
                  }
                  trigger="click"
                >
                  <span
                    className="top-login"
                    style={{
                      cursor: "pointer",
                      font: "18px Poppins-Bold",
                    }}
                  >
                    {dbAuth
                      ? dbAuth.selectedLanguage === "en"
                        ? "English"
                        : dbAuth.selectedLanguage === "ms"
                        ? "Malay"
                        : dbAuth.selectedLanguage === "cs"
                        ? "简体中文"
                        : dbAuth.selectedLanguage === "ct"
                        ? "繁體中文"
                        : dbAuth.selectedLanguage === "in"
                        ? "Indonesia"
                        : "ไทย"
                      : "English"}
                    &nbsp;&nbsp;
                    <img
                      src="/assets/New/Home/Dropdown-Icon.svg"
                      alt=""
                      width="20px"
                    />
                  </span>
                </Popover>
              </Col>
              <Col flex="fill" style={{ alignSelf: "center" }}>
                <Button
                  className="button-text-new-small"
                  style={{
                    margin: "0px 10px",
                  }}
                  onClick={this.onLoginClick}
                >
                  Login
                </Button>
              </Col>
              <Col flex="fill" style={{ alignSelf: "center" }}>
                <Button
                  className="button-text-new-white-small"
                  onClick={this.onRegisterClick}
                >
                  Register
                </Button>
              </Col>
            </Row>
            <hr className="divider" />
            <Row style={{ marginTop: "2rem" }}>
              <Col xs={0} lg={11}>
                <div
                  style={{
                    font: "2.8vw Poppins-Bold",
                    color: "white",
                    marginTop: "60px",
                  }}
                >
                  A Subscription Based Global Online Learning Platform.
                </div>
                <div
                  style={{
                    width: "95%",
                    font: "1.5vw Poppins-Medium",
                    color: "white",
                    marginTop: "1.2em",
                  }}
                >
                  Unlock your full potential and future-proof your career with
                  mini MBA Bizmastery. Get Tomorrow's skills, today.
                  {/* {LANDINGDESC[dbAuth.selectedLanguage]} */}
                </div>
                {/* <div
                  style={{
                    font: "1.9vw Poppins-Bold",
                    color: "white",
                    marginTop: "30px",
                  }}
                >
                  Every 1$ Can Learn
                </div> */}

                <Button
                  className="button-text-new-white"
                  onClick={this.onRegisterClick}
                >
                  Get Started
                </Button>
              </Col>
              <Col xs={0} lg={1} />
              <Col
                xs={0}
                lg={12}
                style={{ textAlign: "center", alignSelf: "center" }}
              >
                <img
                  src="/assets/New/Home/Hero-Image.svg"
                  alt="Landing Page"
                  style={{ width: "70%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                span={12}
                style={{
                  position: "absolute",
                  bottom: "0px",
                  paddingBottom: "15px",
                }}
              >
                <div
                  style={{
                    font: "24px Poppins-Medium",
                    color: "white",
                  }}
                >
                  &#169; 2023 reserved by BizMastery
                </div>
              </Col>

              <Col
                span={12}
                style={{
                  textAlign: "end",
                  position: "absolute",
                  bottom: "0px",
                  paddingBottom: "15px",
                  right: "50px",
                }}
              >
                <div
                  style={{
                    font: "24px Poppins-Medium",
                    color: "white",
                  }}
                >
                  Terms &amp; Condition &ensp; Privacy Policy
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={24}
            lg={0}
            style={{
              backgroundImage: "url(/assets/New/Home/Hero-Background.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              padding: "0px 60px 0px 60px",
            }}
          >
            <Col md={24} lg={0}>
              <Row className="topbar">
                <Col flex="auto" style={{ margin: "10px" }}>
                  <img
                    src="/assets/New/Home/Bizmastery_Logo.svg"
                    alt="Landing Page Row 1"
                  />
                </Col>
                <Col flex="fill" style={{ alignSelf: "center" }}>
                  <Popover
                    overlayClassName="home-page-popover-container-wrapper"
                    content={
                      <Menu
                        style={{
                          fontFamily: "Calibri-Regular",
                          fontSize: "16px",
                          borderRight: "none",
                        }}
                      >
                        <Menu.Item key="Login" onClick={this.onLoginClick}>
                          Login
                        </Menu.Item>
                        <Menu.Item
                          key="Register"
                          onClick={this.onRegisterClick}
                        >
                          Register
                        </Menu.Item>
                        <Menu.Item key="Language">
                          <Popover
                            visible={this.state.languagePopoverVisible}
                            onVisibleChange={this.languagePopoverChange}
                            overlayClassName="home-page-popover-container-wrapper"
                            content={
                              <Menu
                                //defaultSelectedKeys={dbAuth ? dbAuth.selectedLanguage : "en"}
                                style={{
                                  fontFamily: "Calibri-Regular",
                                  fontSize: "16px",
                                  borderRight: "none",
                                }}
                                onClick={(e) => this.onLanguageMenuClick(e)}
                              >
                                <Menu.Item key={"en"}>English</Menu.Item>
                                <Menu.Item key={"ms"}>
                                  Bahasa Malaysia
                                </Menu.Item>
                                <Menu.Item key={"cs"}>简体中文</Menu.Item>
                                <Menu.Item key={"ct"}>繁體中文</Menu.Item>
                                <Menu.Item key={"in"}>
                                  Bahasa Indonesia
                                </Menu.Item>
                              </Menu>
                            }
                            trigger="click"
                          >
                            <span>
                              {dbAuth
                                ? dbAuth.selectedLanguage === "en"
                                  ? "English"
                                  : dbAuth.selectedLanguage === "ms"
                                  ? "Malay"
                                  : dbAuth.selectedLanguage === "cs"
                                  ? "简体中文"
                                  : dbAuth.selectedLanguage === "ct"
                                  ? "繁體中文"
                                  : dbAuth.selectedLanguage === "in"
                                  ? "Indonesia"
                                  : "ไทย"
                                : "English"}{" "}
                              &nbsp;&nbsp;
                              <img
                                src="/assets/New/Home/Dropdown-Icon-black.svg"
                                alt=""
                                width="20px"
                              />
                            </span>
                          </Popover>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger="click"
                  >
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Calibri-Bold",
                          fontSize: "23px",
                          color: "#707070",
                        }}
                      >
                        <MenuOutlined style={{ color: "white" }} />
                      </span>
                    </div>
                  </Popover>
                </Col>
              </Row>
            </Col>

            <hr className="divider" />
            <Row style={{ marginTop: "2rem" }}>
              <Col md={24} lg={0}>
                <img
                  src="/assets/New/Home/Hero-Image.svg"
                  alt="Landing Page Row 1"
                  className="row1-img"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col md={24} lg={0}>
                <div
                  style={{
                    font: "24px Poppins-Bold",
                    color: "white",
                    marginTop: "60px",
                    textAlign: "justify",
                  }}
                >
                  A Subscription Based Global Online Learning Platform.
                </div>
                <div
                  style={{
                    width: "95%",
                    font: "18px Poppins-Medium",
                    color: "white",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                    textAlign: "justify",
                  }}
                >
                  Unlock your full potential and future-proof your career with
                  mini MBA Bizmastery. Get Tomorrow's skills, today.
                  {/* {LANDINGDESC[dbAuth.selectedLanguage]} */}
                </div>
                {/* <div
                  style={{
                    font: "20px Poppins-Bold",
                    color: "white",
                    marginTop: "30px",
                  }}
                >
                  Every 1$ Can Learn
                </div> */}

                <Button
                  className="button-text-new-white"
                  onClick={this.onRegisterClick}
                  style={{}}
                >
                  Get Started
                </Button>
              </Col>
            </Row>
            <Row className="topbar">
              <Col flex="auto">
                <div
                  style={{
                    width: "95%",
                    font: "20px Poppins-Medium",
                    color: "white",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  &#169; 2023 reserved by BizMastery
                </div>
              </Col>

              <Col flex="fill">
                <div
                  style={{
                    font: "20px Poppins-Medium",
                    color: "white",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                    marginRight: "1.2em",
                  }}
                >
                  Terms &amp; Condition
                </div>
              </Col>
              <Col flex="fill">
                <div
                  style={{
                    font: "20px Poppins-Medium",
                    color: "white",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  Privacy Policy
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              position: "fixed",
              bottom: "0px",
              right: "50px",
              padding: "50px 0px",
            }}
          >
            <a href="/assets/mlmbizmastery.pdf" download="proposed_file_name">
              <Button
                size="large"
                shape="circle"
                icon={<SnippetsOutlined style={{ color: "#5E51A1" }} />}
                style={{}}
              />
            </a>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  initDBLoad,
  updateLanguage,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(LandingPage);
