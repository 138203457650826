import { Row, Col, Button, Spin } from "antd";
import React, { Component, Fragment } from "react";
import "./Resources.less";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { loadResource, resetResource } from "../resources/resourcesActions";
import _ from "lodash";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import {
  DOWNLOADNOW,
  RESOURCESTABTITLE,
  RESOURCESTABDESC
} from "./TranslationConstant";

class Resources extends Component {
  state = {
    isLoading: true,
    loadedResources: this.props.resource
  };

  componentDidMount() {
    this.props.loadResource();
  }

  async componentDidUpdate(prevProps) {
    const { resource, firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      !_.isEqual(prevProps.resource.resourceList, resource.resourceList) &&
      resource.resourceList
    ) {
      let resourceImagePromises = [];

      resource.resourceList.forEach(item => {
        resourceImagePromises.push(
          storageRef.child(item.imageFilePath).getDownloadURL()
        );
      });
      let loadedResourceImages = await Promise.all(resourceImagePromises);
      this.setState({
        isLoading: false,
        loadedResourceImages
      });
    }
  }

  componentWillUnmount() {
    this.props.resetResource();
  }

  onHandleDownloadResourcesButtonClick = (resource, index) => {
    let newResource = this.props.resource.resourceList;
    newResource[index].isDownloading = true;
    this.setState({ resource: newResource }, async () => {
      const storageRef = firebase.storage().ref();
      var resourceRef = storageRef.child(resource.pdfFilePath);

      let pdfDownloadPromise = new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function(event) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.status);
          }
        };
        resourceRef.getDownloadURL().then(url => {
          xhr.open("GET", url);
          xhr.send();
        });
      });

      let downloadedBlob = await pdfDownloadPromise;
      var url = URL.createObjectURL(downloadedBlob);
      let a = document.createElement("a");
      a.href = url;
      a.download = resource.pdfFilePath;

      a.click();
      let newResource = this.props.resource.resourceList;
      newResource[index].isDownloading = false;
      this.setState({ resource: newResource });
    });
  };

  render() {
    const { resource, auth } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row className="resources-row">
          <Col xs={2} md={5} />
          <Col xs={20} md={14} style={{ textAlign: "center" }}>
            <div className="resources-title">
              {
                RESOURCESTABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <div className="resources-description">
              {
                RESOURCESTABDESC[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
          </Col>
          <Col xs={2} md={5} />
        </Row>
        <Row className="resources-row2">
          <Col xs={2} xl={4} />
          <Col xs={20} xl={16}>
            <Row>
              {resource.resourceList &&
                resource.resourceList.map((resource, index) => (
                  <Col xs={24} sm={12} md={12} lg={8} key={index}>
                    {/* {console.log(resource)} */}
                    <div className="resources-card">
                      <img
                        src={
                          this.state.loadedResourceImages &&
                          this.state.loadedResourceImages[index]
                        }
                        alt={`Resource-${index + 1}`}
                        className="resources-card-img"
                      />
                      <div className="card-overlay">
                        {resource.isDownloading ? (
                          <Button className="card-overlay-button" disabled>
                            <Spin />
                          </Button>
                        ) : (
                          <Button
                            className="card-overlay-button"
                            onClick={e =>
                              this.onHandleDownloadResourcesButtonClick(
                                resource,
                                index
                              )
                            }
                          >
                            {
                              DOWNLOADNOW[
                                auth.userInfo.nativeLanguage
                                  ? auth.userInfo.nativeLanguage
                                  : "en"
                              ]
                            }
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </Col>
          <Col xs={2} xl={4} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    resource: state.resource,
    // general: state.general,
    auth: state.auth,
    translations: state.translations
  };
};

const mapDispatchToProps = {
  loadResource,
  resetResource
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(Resources));
