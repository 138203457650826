import {
  ECODE_GENERATION_START,
  ECODE_GENERATION_SUCCESS,
  ECODE_GENERATION_FAILURE,
  ECODE_USER_DELETE_START,
  ECODE_USER_DELETE_SUCCESS,
  ECODE_USER_DELETE_FAILURE,
  LOAD_ALL_ECODES
} from "./ecodeConstant";
import Moment from "moment";

export const generateEcodes = newEcodesInfoObj => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: ECODE_GENERATION_START });
    const db = getFirebase().firestore();
    try {
      let createdBy = getFirebase().auth().currentUser.email;
      let createdAt = new Date();
      let docRef = db.collection("e-code_creation_records").doc();
      docRef
        .set({
          createdAt: createdAt,
          createdBy: createdBy,
          duration: parseInt(newEcodesInfoObj.duration),
          totalECodes: parseInt(newEcodesInfoObj.totalECodes),
          status: "InProgress"
        })
        .then(() => {
          const observer = docRef.onSnapshot(
            docSnapshot => {
              // console.log(docSnapshot.data());
              if (docSnapshot.data().status === "Completed") {
                dispatch({
                  type: ECODE_GENERATION_SUCCESS
                });
                observer();
              } else if (docSnapshot.data().status === "Failed") {
                dispatch({
                  type: ECODE_GENERATION_FAILURE,
                  payload: {
                    errMsg:
                      "Error occured while generating codes. Please try again later."
                  }
                });
                observer();
              }
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: ECODE_GENERATION_FAILURE,
            payload: {
              errMsg:
                "Error occured while generating codes. Please try again later."
            }
          });
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadAllECodes = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let ecodeGenerationInfoObj = [];

      let ecodeCreationRecordSnap = await db
        .collection("e-code_creation_records")
        .where("status", "==", "Completed")
        .orderBy("createdAt", "desc")
        .get();

      let ecodesQuerySnap = await db.collection("ecodes").get();

      ecodeCreationRecordSnap.forEach(async record => {
        let ecodeList = [];
        ecodesQuerySnap.forEach(docSnap => {
          // console.log(docSnap.data())
          if (
            Moment(new Date(docSnap.data().createdAt.seconds * 1000)).format(
              "MMMM Do YYYY, h:mm a"
            ) ===
            Moment(new Date(record.data().createdAt.seconds * 1000)).format(
              "MMMM Do YYYY, h:mm a"
            )
          )
            ecodeList.push({
              ...docSnap.data(),
              docId: docSnap.id
            });
        });
        ecodeGenerationInfoObj.push({
          ...record.data(),
          docId: record.id,
          ecodeList: ecodeList
        });
        // console.log(profileImageUrl);
      });

      // console.log(ecodeGenerationInfoObj);
      dispatch({
        type: LOAD_ALL_ECODES,
        payload: { ecodeGenerationInfoObj }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteECodeUser = clickedRecord => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    dispatch({ type: ECODE_USER_DELETE_START });
    try {
      console.log(clickedRecord);
      db.collection("ecodes")
        .doc(clickedRecord.docId)
        .set({
          code: clickedRecord.eCode,
          createdAt: clickedRecord.createdAt,
          durationInDays: parseInt(clickedRecord.durationLeft),
          status: "Available",
          user: { email: "", id: "" }
        })
        .then(() => {
          // console.log("Success")
          dispatch({
            type: ECODE_USER_DELETE_SUCCESS
          });
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: ECODE_USER_DELETE_FAILURE,
            payload: {
              errMsg:
                "Error occured while deleting user. Please try again later."
            }
          });
        });
    } catch (err) {
      console.log(err);
    }
  };
};
