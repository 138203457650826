import { createReducer } from "../../app/common/utils/reducerUtils";
import {
  LOAD_ALL_EXAM
} from "./examConstant";

const initialState = {
  examInfoObj: null
};

const loadAllExam = (state, payload) => {
  return {
    ...state,
    examInfoObj: payload.examInfoObj
  };
};

export default createReducer(initialState, {
  [LOAD_ALL_EXAM]: loadAllExam,
});
