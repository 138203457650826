import { Row, Col, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { Component, Fragment } from "react";
import "./ProbingQuestions.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getTransText, _saveProbingQuestingProgress } from "../SharedUtils";
import { loadProbingQuestions } from "./lessons/courseActions";
import { updateProbingQuestionProgress } from "../auth/authActions";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { BACK, CONTINUE, PREVIOUS } from "./TranslationConstant";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";

class ProbingQuestionsList extends Component {
  constructor(props) {
    super(props);
    this._saveProbingQuestingProgress = _saveProbingQuestingProgress.bind(this);
  }

  state = {
    showContent: 0,
    isLoading: true,
    seenContents: []
  };

  componentDidMount() {
    const { match } = this.props;
    let questionId = match.params.questionId;

    this.props.loadProbingQuestions();

    this.setState({
      questionId
    });
  }

  async componentDidUpdate(prevProps) {
    const { course, auth } = this.props;
    const { questionId } = this.state;
    if (
      !_.isEqual(
        prevProps.course.probingQuestionsInfoObj,
        course.probingQuestionsInfoObj
      ) ||
      (course.probingQuestionsInfoObj !== null && this.state.isLoading)
    ) {
      const questionList = [];

      for (const lesson of course.probingQuestionsInfoObj) {
        if (lesson.docId === questionId) {
          for (const doc of lesson.questions) {
            for (const question of doc.questions) {
              questionList.push(question);
            }
          }
        }
      }
      // console.log(questionList);
      this.setState({
        isLoading: false,
        questionList,
        seenContents:
          auth.progressInfo.lessons &&
          auth.progressInfo.lessons.probing_questions &&
          auth.progressInfo.lessons.probing_questions[questionId]
            ? auth.progressInfo.lessons.probing_questions[questionId]
            : [0]
      });
    }
  }

  loadScene = showContent => {
    if (this.state.seenContents.indexOf(showContent) !== -1) {
      this.setState({ showContent });
    } else {
      let seenContents = this.state.seenContents;
      seenContents.push(showContent);
      this.setState({
        showContent,
        seenContents
      });
    }
  };

  onBackClick = () => {
    const { questionId, seenContents } = this.state;
    this._saveProbingQuestingProgress(questionId, seenContents);
    // window.history.back();
  };

  continue = () => {
    const { showContent, questionList, questionId } = this.state;
    if (showContent === questionList.length - 1) {
      this._saveProbingQuestingProgress(
        questionId,
        this.state.seenContents,
        `/business-portal/probing-questions/${questionId}/lesson-completed`
      );
    } else {
      if (this.state.seenContents.indexOf(showContent + 1) !== -1) {
        this.setState({ showContent: showContent + 1 });
      } else {
        let seenContents = this.state.seenContents;
        seenContents.push(showContent + 1);
        this.setState({ showContent: showContent + 1, seenContents });
      }

      // console.log(showContent + 1);
    }
  };

  previous = () => {
    const { showContent } = this.state;

    if (this.state.seenContents.indexOf(showContent - 1) !== -1) {
      this.setState({ showContent: showContent - 1 });
    } else {
      let seenContents = this.state.seenContents;
      seenContents.push(showContent - 1);
      this.setState({ showContent: showContent - 1, seenContents });
    }

    // console.log(showContent + 1);
  };

  render() {
    const {
      isLoading,
      showContent,
      questionId,
      questionList,
      seenContents
    } = this.state;
    const { course, auth, translations } = this.props;
    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row className="lesson-paragraph-row">
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={4}
            className="lesson-left-sidebar"
            style={{ padding: "40px 0px 0px 40px" }}
          >
            <Button
              icon={<LeftOutlined />}
              className="lesson-back-button"
              onClick={this.onBackClick}
              style={{ border: "unset" }}
            >
              {
                BACK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />
            {course.probingQuestionsInfoObj.map((lesson, idx) => {
              if (lesson.docId === questionId) {
                return (
                  <div key={idx} className="lesson-left-sidebar-title">
                    {auth.userInfo.nativeLanguage
                      ? getTransText(
                          auth.userInfo.nativeLanguage,
                          lesson.name,
                          translations.translationObj
                        ).text
                      : getTransText(
                          "en",
                          lesson.name,
                          translations.translationObj
                        ).text}
                  </div>
                );
              } else return null;
            })}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={0}
            style={{ paddingTop: "40px", paddingLeft: "40px" }}
          >
            <Button
              icon={<LeftOutlined />}
              className="lesson-back-button-small"
              onClick={this.onBackClick}
              style={{ border: "unset" }}
            >
              {
                BACK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
          </Col>
          <Col xs={0} sm={24} xl={12} className="lesson-paragraph-container">
            {course.probingQuestionsInfoObj.map((lesson, idx) => {
              if (lesson.docId === questionId) {
                return (
                  <div key={idx} className="lesson-paragraph-title">
                    {auth.userInfo.nativeLanguage
                      ? getTransText(
                          auth.userInfo.nativeLanguage,
                          lesson.name,
                          translations.translationObj
                        ).text
                      : getTransText(
                          "en",
                          lesson.name,
                          translations.translationObj
                        ).text}
                  </div>
                );
              } else return null;
            })}
            <br />
            <div
              className="lesson-paragraph-description"
              dangerouslySetInnerHTML={{
                __html: auth.userInfo.nativeLanguage
                  ? getTransText(
                      auth.userInfo.nativeLanguage,
                      questionList[showContent],
                      translations.translationObj
                    ).text
                  : getTransText(
                      "en",
                      questionList[showContent],
                      translations.translationObj
                    ).text
              }}
            ></div>
          </Col>
          <Col
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            className="lesson-paragraph-container"
          >
            {course.probingQuestionsInfoObj.map((lesson, idx) => {
              if (lesson.docId === questionId) {
                return (
                  <div key={idx} className="lesson-paragraph-title">
                    {auth.userInfo.nativeLanguage
                      ? getTransText(
                          auth.userInfo.nativeLanguage,
                          lesson.name,
                          translations.translationObj
                        ).text
                      : getTransText(
                          "en",
                          lesson.name,
                          translations.translationObj
                        ).text}
                  </div>
                );
              } else return null;
            })}
            <br />
            <div
              className="lesson-paragraph-description"
              dangerouslySetInnerHTML={{
                __html: auth.userInfo.nativeLanguage
                  ? getTransText(
                      auth.userInfo.nativeLanguage,
                      questionList[showContent],
                      translations.translationObj
                    ).text
                  : getTransText(
                      "en",
                      questionList[showContent],
                      translations.translationObj
                    ).text
              }}
            ></div>
          </Col>
          <Col xs={24} xl={8} className="lesson-right-sidebar">
            <Row style={{ padding: "1rem" }} justify="center" align="middle">
              <Col
                xs={2}
                sm={2}
                md={3}
                lg={4}
                xl={2}
                style={{ textAlign: "end" }}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: "0px"
                  }}
                  disabled={showContent === 0}
                  icon={<LeftOutlined style={{ height: "1rem" }} />}
                  onClick={this.previous}
                ></Button>
              </Col>
              <Col xs={1} />
              <Col xs={18} sm={18} md={16} lg={14} xl={18}>
                {/* {console.log(seenScene)} */}
                {questionList.map((lessonScene, idx) => (
                  <Button
                    key={idx}
                    style={{
                      height: "1rem",
                      width: "1px",
                      backgroundColor:
                        showContent === idx
                          ? "yellow"
                          : seenContents.includes(idx)
                          ? "black"
                          : "lightgray"
                    }}
                    // disabled={clickDisabled}
                    onClick={() => this.loadScene(idx)}
                  >
                    &nbsp;
                  </Button>
                ))}
              </Col>
              <Col xs={1} />
              <Col
                xs={2}
                sm={2}
                md={3}
                lg={4}
                xl={2}
                style={{ textAlign: "start" }}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: "0px"
                  }}
                  // disabled={clickDisabled}
                  icon={<RightOutlined style={{ height: "1rem" }} />}
                  onClick={this.continue}
                ></Button>
              </Col>
            </Row>

            <br />
            {seenContents.length !== questionList.length &&
            showContent === questionList.length - 1 ? (
              <Button
                // disabled={clickDisabled}
                className="lesson-continue-button"
                onClick={this.previous}
              >
                {
                  PREVIOUS[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            ) : (
              <Button
                // disabled={clickDisabled}
                className="lesson-continue-button"
                onClick={this.continue}
              >
                {
                  CONTINUE[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations
});

const mapDispatchToProps = {
  loadProbingQuestions,
  updateProbingQuestionProgress
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProbingQuestionsList);
