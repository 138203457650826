import { Row, Col, Input, Table, Select, Spin } from "antd";
import React, { Component, Fragment } from "react";
import "./BusinessSpeaking.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { loadBusinessSpeaking } from "../BusinessPortal/lessons/courseActions";
import { getTransText } from "../SharedUtils";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { withFirebase } from "react-redux-firebase";
import _ from "lodash";
import {
  SEARCH,
  BUSINESSPHRASES,
  ROWPERPAGE,
  OF,
  BUSINESSSPEAKINGTABTITLE,
  BUSINESSSPEAKINGTABDESC
} from "./TranslationConstant";
let audioElement = new Audio();

class BusinessSpeaking extends Component {
  state = {
    isLoading: true,
    dataStart: 1,
    loadData: false,
    dataPerPage: 10,
    filter: "",
    filteredData: [],
    clickDisabled: false,
    record: {}
  };

  componentWillUnmount() {
    audioElement.pause();
    audioElement.currentTime = 0;
  }

  componentDidMount() {
    this.props.loadBusinessSpeaking();
  }

  async componentDidUpdate(prevProps) {
    const { course, auth, translations } = this.props;
    const { dataPerPage } = this.state;
    if (
      !_.isEqual(
        prevProps.course.businessSpeakingInfo,
        course.businessSpeakingInfo
      ) ||
      (course.businessSpeakingInfo !== null && this.state.isLoading) ||
      auth.userInfo.nativeLanguage !== prevProps.auth.userInfo.nativeLanguage
    ) {
      //change data length
      const data = [];
      const businessSpeakingInfoList = [];

      for (const doc of course.businessSpeakingInfo) {
        for (const phrase of doc.phrases) {
          if (data.length < dataPerPage) {
            // console.log(phrase.phrase)
            data.push({
              key: phrase.order,
              phrase: this.showhtml(
                getTransText(
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en",
                  phrase.phrase,
                  translations.translationObj
                ).text,
                phrase.order
              )
            });
          }
          // console.log(phrase);
          businessSpeakingInfoList.push({
            phrase: {
              ...phrase,
              phrase: getTransText(
                auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en",
                phrase.phrase,
                translations.translationObj
              ).text
            },
            isPlayingAudio: false,
            isAudioDownloadedLocally: false,
            clickDisabled: false,
            isLoading: false
          });
        }
      }
      this.setState({
        isLoading: false,
        data,
        dataShown: data.length,
        businessSpeakingInfoList,
        filteredData: businessSpeakingInfoList
      });
    }
  }

  handleSearchChange = event => {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.setState(prevState => ({
      businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
        phrase => {
          return { ...phrase, clickDisabled: false, isPlayingAudio: false };
        }
      )
    }));

    const { auth, translations } = this.props;
    const { businessSpeakingInfoList, dataPerPage } = this.state;
    const lowercasedFilter = event.target.value.toLowerCase();
    const filteredData = businessSpeakingInfoList.filter(item => {
      // console.log(Object.keys(item.phrase))
      return Object.keys(item.phrase).some(
        key =>
          // console.log(item.phrase[key])
          typeof item.phrase[key] === "string" &&
          item.phrase[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    const data = [];
    for (const phrase of filteredData) {
      if (data.length < dataPerPage) {
        data.push({
          key: phrase.phrase.order,
          phrase: this.showhtml(
            auth.userInfo.nativeLanguage
              ? getTransText(
                  auth.userInfo.nativeLanguage,
                  phrase.phrase.phrase,
                  translations.translationObj
                ).text
              : getTransText(
                  "en",
                  phrase.phrase.phrase,
                  translations.translationObj
                ).text
          )
        });
      }
    }
    let dataStart = 0;
    if (data.length > 0) {
      dataStart = 1;
    }

    this.setState({
      isLoading: false,
      data,
      dataShown: data.length,
      dataStart: dataStart,
      filter: event.target.value,
      filteredData
    });
  };

  handleLeftClick = () => {
    const {
      data,
      dataStart,
      dataPerPage,
      dataShown,
      filteredData,
      clickDisabled
    } = this.state;

    const { auth, translations } = this.props;

    if (dataStart > 1 && !clickDisabled) {
      audioElement.pause();
      audioElement.currentTime = 0;
      const newDataShown = dataShown - data.length;
      const newdataStart = dataStart - dataPerPage;
      const newData = [];
      for (var i = newdataStart - 1; i < filteredData.length; i++) {
        if (newData.length < dataPerPage) {
          newData.push({
            key: filteredData[i].phrase.order,
            phrase: this.showhtml(
              auth.userInfo.nativeLanguage
                ? getTransText(
                    auth.userInfo.nativeLanguage,
                    filteredData[i].phrase.phrase,
                    translations.translationObj
                  ).text
                : getTransText(
                    "en",
                    filteredData[i].phrase.phrase,
                    translations.translationObj
                  ).text
            )
          });
        }
      }
      this.setState({
        data: newData,
        dataShown: newDataShown,
        dataStart: newdataStart
      });
    }
  };

  handleRightClick = () => {
    const { auth, translations } = this.props;
    const {
      dataStart,
      dataShown,
      dataPerPage,
      filteredData,
      clickDisabled
    } = this.state;
    // var tempPhrases = course.businessSpeakingInfo.phrases
    if (
      dataShown !== filteredData.length &&
      dataShown < filteredData.length &&
      !clickDisabled
    ) {
      const data = [];
      for (var i = dataShown; i < filteredData.length; i++) {
        if (data.length < dataPerPage) {
          data.push({
            key: filteredData[i].phrase.order,
            phrase: this.showhtml(
              auth.userInfo.nativeLanguage
                ? getTransText(
                    auth.userInfo.nativeLanguage,
                    filteredData[i].phrase.phrase,
                    translations.translationObj
                  ).text
                : getTransText(
                    "en",
                    filteredData[i].phrase.phrase,
                    translations.translationObj
                  ).text
            )
          });
        }
      }
      // console.log(dataShown, data.length, filteredData.length);
      audioElement.pause();
      audioElement.currentTime = 0;

      this.setState({
        data,
        dataShown: dataShown + data.length,
        dataStart: dataStart + dataPerPage
      });
    }
  };

  handleChange = event => {
    const { auth, translations } = this.props;
    const { filteredData } = this.state;
    const data = [];
    for (const phrase of filteredData) {
      if (data.length < event) {
        data.push({
          key: phrase.phrase.order,
          phrase: this.showhtml(
            auth.userInfo.nativeLanguage
              ? getTransText(
                  auth.userInfo.nativeLanguage,
                  phrase.phrase.phrase,
                  translations.translationObj
                ).text
              : getTransText(
                  "en",
                  phrase.phrase.phrase,
                  translations.translationObj
                ).text
          )
        });
      }
    }
    this.setState({
      isLoading: false,
      data,
      dataShown: data.length,
      dataStart: 1,
      dataPerPage: parseInt(event)
    });
  };

  onHandlePlayIconClick = record => {
    const { businessSpeakingInfoList } = this.state;
    const { firebase, auth } = this.props;
    const storageRef = firebase.storage().ref();
    // console.log(businessSpeakingInfoList[record.key - 1].phrase.voiceoverPath);
    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      if (
        businessSpeakingInfoList[record.key - 1].phrase.isAudioDownloadedLocally
      ) {
        this.setState(
          prevState => ({
            record: record,
            clickDisabled: true,
            businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
              phrase => {
                if (phrase.phrase.order === record.key) {
                  return { ...phrase, clickDisabled: true, isLoading: true };
                }
                return { ...phrase, clickDisabled: true };
              }
            )
          }),
          () => {
            this.setState(
              prevState => ({
                businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
                  phrase => {
                    // console.log(record.key - 1);
                    if (phrase.phrase.order === record.key) {
                      return {
                        ...phrase,
                        isPlayingAudio: true,
                        clickDisabled: false,
                        isLoading: false
                      };
                    }
                    return { ...phrase, clickDisabled: true };
                  }
                )
              }),
              () => {
                audioElement.play();
              }
            );
          }
        );
      } else {
        this.setState(
          prevState => ({
            record: record,
            clickDisabled: true,
            businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
              phrase => {
                if (phrase.phrase.order === record.key) {
                  return { ...phrase, clickDisabled: true, isLoading: true };
                }
                return { ...phrase, clickDisabled: true };
              }
            )
          }),
          async () => {
            //get voice over url
            if (
              businessSpeakingInfoList[record.key - 1].phrase.voiceoverPath[
                auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
              ].length > 0
            ) {
              storageRef
                .child(
                  businessSpeakingInfoList[record.key - 1].phrase.voiceoverPath[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                )
                .getDownloadURL()
                .then(async vUrl => {
                  let downloadPromise = new Promise((resolve, reject) => {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = function(event) {
                      if (xhr.status === 200) {
                        resolve(xhr.response);
                      } else {
                        reject(xhr.status);
                      }
                    };
                    xhr.open("GET", vUrl);
                    xhr.send();
                  });
                  let downloadedBlob = await downloadPromise;

                  audioElement.addEventListener("ended", e =>
                    this.onAudioEnded(record)
                  );
                  this.setState(
                    prevState => ({
                      businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
                        phrase => {
                          // console.log(record.key - 1);
                          if (phrase.phrase.order === record.key) {
                            return {
                              ...phrase,
                              isAudioDownloadedLocally: true,
                              isPlayingAudio: true,
                              clickDisabled: false,
                              isLoading: false
                            };
                          }
                          return { ...phrase, clickDisabled: true };
                        }
                      )
                    }),
                    () => {
                      audioElement.src = URL.createObjectURL(downloadedBlob);
                      audioElement.play();
                    }
                  );
                })
                .catch(error => {
                  alert(error.message_);
                  console.log(error);
                  this.setState(prevState => ({
                    businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
                      phrase => {
                        // console.log(record.key - 1);
                        if (phrase.phrase.order === record.key) {
                          return {
                            ...phrase,
                            isLoading: false
                          };
                        }
                        return { ...phrase, clickDisabled: false };
                      }
                    )
                  }));
                });
            }
          }
        );
      }
    } else {
      //resume playing

      audioElement.play();
      this.setState(prevState => ({
        businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
          phrase => {
            // console.log(record.key - 1);
            if (phrase.phrase.order === record.key) {
              return {
                ...phrase,
                isPlayingAudio: true
              };
            }
            return { ...phrase };
          }
        )
      }));
    }
  };

  onAudioEnded = record => {
    this.setState(prevState => ({
      record: {},
      clickDisabled: false,
      businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
        phrase => {
          // console.log(record.key - 1);
          if (phrase.phrase.order === record.key) {
            return {
              ...phrase,
              isPlayingAudio: false
            };
          }
          return { ...phrase, clickDisabled: false };
        }
      )
    }));
  };

  onHandlePauseIconClick = record => {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.setState(prevState => ({
      record: {},
      clickDisabled: false,
      businessSpeakingInfoList: prevState.businessSpeakingInfoList.map(
        phrase => {
          // console.log(record.key - 1);
          if (phrase.phrase.order === record.key) {
            return {
              ...phrase,
              isPlayingAudio: false
            };
          }
          return { ...phrase, clickDisabled: false };
        }
      )
    }));
  };

  showhtml(data, order) {
    let html = { __html: data };
    return <div key={order} dangerouslySetInnerHTML={html}></div>;
  }

  render() {
    const {
      businessSpeakingInfoList,
      filteredData,
      isLoading,
      data,
      dataStart,
      dataShown
    } = this.state;

    const { auth } = this.props;

    const columns = [
      {
        dataIndex: "phrase",
        className: "table-row-text",
        render: value => <div className="table-row-text">{value}</div>
      },
      {
        dataIndex: "action",
        align: "end",
        render: (value, record, index) => (
          <Fragment>
            {businessSpeakingInfoList[record.key - 1].isLoading ? (
              <Spin style={{ paddingRight: "25px" }} />
            ) : businessSpeakingInfoList[record.key - 1].phrase.voiceoverPath[
                auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
              ] !== "" ? (
              <img
                onClick={
                  businessSpeakingInfoList[record.key - 1].clickDisabled
                    ? null
                    : businessSpeakingInfoList[record.key - 1].isPlayingAudio
                    ? e => this.onHandlePauseIconClick(record)
                    : e => this.onHandlePlayIconClick(record)
                }
                src={
                  businessSpeakingInfoList[record.key - 1].isPlayingAudio
                    ? "/assets/businessPortal/pause-button.png"
                    : "/assets/businessPortal/play-button.png"
                }
                alt="Play Button"
                style={{
                  paddingRight: "20px",
                  cursor: businessSpeakingInfoList[record.key - 1].clickDisabled
                    ? "not-allowed"
                    : "pointer"
                }}
              />
            ) : null}
          </Fragment>
        )
      }
    ];

    const columnsSmall = [
      {
        dataIndex: "phrase",
        className: "table-row-text-small",
        render: value => <div className="table-row-text-small">{value}</div>
      },
      {
        dataIndex: "action",
        align: "center",
        render: (value, record, index) => (
          <Fragment>
            {businessSpeakingInfoList[record.key - 1].isLoading ? (
              <Spin />
            ) : (
              <img
                onClick={
                  businessSpeakingInfoList[record.key - 1].clickDisabled
                    ? null
                    : businessSpeakingInfoList[record.key - 1].isPlayingAudio
                    ? e => this.onHandlePauseIconClick(record)
                    : e => this.onHandlePlayIconClick(record)
                }
                src={
                  businessSpeakingInfoList[record.key - 1].isPlayingAudio
                    ? "/assets/businessPortal/pause-button.png"
                    : "/assets/businessPortal/play-button.png"
                }
                alt="Play Button"
                style={{
                  cursor: businessSpeakingInfoList[record.key - 1].clickDisabled
                    ? "not-allowed"
                    : "pointer"
                }}
              />
            )}
          </Fragment>
        )
      }
    ];

    const { clickDisabled } = this.state;
    if (isLoading) return <LoadingComponent auth={auth} />;

    return (
      <Fragment>
        <Row>
          <Col span={24}>
            <img
              src="/assets/businessPortal/business-speaking-banner.png"
              alt="Business Speaking Banner"
              className="business-speaking-banner"
            />
          </Col>
        </Row>
        <Row className="business-speaking-row">
          <Col xs={1} sm={1} md={2} lg={2} xl={2} xxl={3} />
          <Col
            xs={0}
            sm={22}
            md={20}
            lg={20}
            xxl={18}
            style={{ textAlign: "center" }}
          >
            <div className="business-speaking-title">
              {
                BUSINESSSPEAKINGTABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <br />
            <div className="business-speaking-description">
              {
                BUSINESSSPEAKINGTABDESC[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <br />
            <Input
              onChange={this.handleSearchChange}
              className="business-speaking-search-input"
              placeholder={
                SEARCH[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
              prefix={
                <img
                  src="/assets/businessPortal/search-icon.png"
                  alt="Search Icon"
                />
              }
              bordered={false}
            />
            <br />
            <br />
            <br />

            <Table
              className="business-speaking-table"
              columns={columns}
              dataSource={data}
              showHeader={false}
              pagination={false}
              // expandedRowRender={this.showhtml.bind(this)}
              title={() => (
                <div className="table-header">
                  {
                    BUSINESSPHRASES[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                </div>
              )}
              footer={() => (
                <div className="table-footer">
                  <div className="table-footer-text">
                    {
                      ROWPERPAGE[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                    :
                  </div>
                  <Select
                    onChange={this.handleChange}
                    defaultValue="10"
                    className="table-footer-text"
                    bordered={false}
                    disabled={clickDisabled}
                  >
                    <Select.Option value="10">10</Select.Option>
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="50">50</Select.Option>
                  </Select>
                  <div className="table-footer-text2">
                    {dataStart} - {dataShown}{" "}
                    {
                      OF[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }{" "}
                    {filteredData.length}
                  </div>
                  {dataStart > 1 && !clickDisabled ? (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-left.png"
                      alt="Left Chevron"
                      onClick={this.handleLeftClick}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-left.png"
                      alt="Left Chevron"
                      onClick={this.handleLeftClick}
                      style={{ cursor: "not-allowed" }}
                    />
                  )}

                  {dataShown !== filteredData.length &&
                  (dataShown < filteredData.length) & !clickDisabled ? (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-right.png"
                      alt="Right Chevron"
                      onClick={this.handleRightClick}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-right.png"
                      alt="Right Chevron"
                      onClick={this.handleRightClick}
                      style={{ cursor: "not-allowed" }}
                    />
                  )}
                </div>
              )}
            />
          </Col>

          <Col
            xs={22}
            sm={0}
            md={0}
            lg={0}
            xxl={0}
            style={{ textAlign: "center" }}
          >
            <div className="business-speaking-title-small">
              {
                BUSINESSSPEAKINGTABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <br />
            <div className="business-speaking-description-small">
              {
                BUSINESSSPEAKINGTABDESC[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <br />
            <Input
              className="business-speaking-search-input"
              placeholder="Search"
              prefix={
                <img
                  src="/assets/businessPortal/search-icon.png"
                  alt="Search Icon"
                />
              }
              bordered={false}
            />
            <br />
            <br />
            <br />
            <Table
              className="business-speaking-table"
              columns={columnsSmall}
              dataSource={data}
              showHeader={false}
              pagination={false}
              title={() => (
                <div className="table-header">
                  {
                    BUSINESSPHRASES[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                </div>
              )}
              footer={() => (
                <div className="table-footer">
                  <div className="table-footer-text">
                    {
                      ROWPERPAGE[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                    :
                  </div>
                  <Select
                    onChange={this.handleChange}
                    defaultValue="10"
                    className="table-footer-text"
                    bordered={false}
                    disabled={clickDisabled}
                  >
                    <Select.Option value="10">10</Select.Option>
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="50">50</Select.Option>
                  </Select>
                  <div className="table-footer-text2">
                    {dataStart} - {dataShown}{" "}
                    {
                      OF[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }{" "}
                    {filteredData.length}
                  </div>
                  {dataStart > 1 && !clickDisabled ? (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-left.png"
                      alt="Left Chevron"
                      onClick={this.handleLeftClick}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-left.png"
                      alt="Left Chevron"
                      onClick={this.handleLeftClick}
                      style={{ cursor: "not-allowed" }}
                    />
                  )}

                  {dataShown !== filteredData.length &&
                  dataShown < filteredData.length &&
                  !clickDisabled ? (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-right.png"
                      alt="Right Chevron"
                      onClick={this.handleRightClick}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      className="table-footer-img"
                      src="/assets/businessPortal/chevron-right.png"
                      alt="Right Chevron"
                      onClick={this.handleRightClick}
                      style={{ cursor: "not-allowed" }}
                    />
                  )}
                </div>
              )}
            />
          </Col>

          <Col xs={3} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations
  // general: state.general
});

const mapDispatchToProps = {
  loadBusinessSpeaking
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BusinessSpeaking);
