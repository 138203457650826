import { LOAD_BOOK_BACKGROUND } from "./imageConstant";

export const loadFunnelPageBookBackground = () => {
    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();

        try {
            let funnelPageImageObj = {}
            let docSnap = await db.doc("images/funnel-page").get();
            if (docSnap.exists) {
                funnelPageImageObj = { ...docSnap.data(), docId: docSnap.id }
            }

            dispatch({ type: LOAD_BOOK_BACKGROUND, payload: { funnelPageImageObj } })

        } catch (err) {
            console.log(err)
            throw (err)
        }
    }
}

// export const resetFunnelPageBookBackground = () => {
//     return async (dispatch) => {
//         try {

//             dispatch({ type: RESET_BOOK_BACKGROUND })

//         } catch (err) {
//             console.log(err)
//         }
//     }
// }