import React, { Component, Fragment } from "react";
import "./FunnelPageCustomise.css";
import {
  eBookLanguageCodeEnum,
  salesFunnelPageModeEnum
} from "./SharedConstant";
import _ from "lodash";
import { loadSelectedEBook, resetEBook } from "./book/bookActions";
import { compose } from "redux";
import { connect } from "react-redux";
import LoadingComponent from "./app/common/loading/LoadingComponent";
import { withFirebase } from "react-redux-firebase";
import Template0001 from "./templates/Template0001";

class FunnelPageCustomise extends Component {
  state = {
    isLoading: true,
    salesFunnelPageMode: salesFunnelPageModeEnum.NEW,
    errorMessage: ""
  };

  async componentDidMount() {
    const { match, domain, loadSelectedEBook, firebase } = this.props;
    const db = firebase.firestore();

    // console.log("CHECK URL : " + window.location.href);
    // console.log("CHECK HOST : " + window.location.host);
    // console.log("CHECK PATHNAME : " + window.location.pathname);
    // console.log("CHECK HOSTNAME : " + window.location.hostname);

    let salesFunnelPageMode;
    let urlPathName = window.location.pathname;
    if (urlPathName === "/") {
      //check subdomain or host
      salesFunnelPageMode = salesFunnelPageModeEnum.PUBLIC;

      let hostName = window.location.hostname;

      let subDomainRegexp = new RegExp(`(.*).${domain.sharedDomain}`, "g");
      let regexpResult = subDomainRegexp.exec(hostName);

      //if there's no subdomain provided
      if (regexpResult === null) {
        //potential user custom domain, BUT has to further check since top-level(DomainVal) is perfoming only shallow check to define proper route
        if (domain.urlInfoObj.host === hostName) {
          this.setState({
            isLoading: false,
            salesFunnelPageMode
          });
        } else {
          if (domain.sharedDomain === hostName) {
            this.setState({
              isLoading: false,
              errorMessage: "Sorry. Your url is missing subdomain."
            });
          } else {
            this.setState({
              isLoading: false,
              errorMessage: "Invalid custom domain."
            });
          }
        }
      } else {
        if (regexpResult[1].match(/\./g) === null) {
          if (domain.urlInfoObj.docId) {
            this.setState({
              isLoading: false,
              salesFunnelPageMode
            });
          } else {
            this.setState({
              isLoading: false,
              errorMessage: "Invalid subdomain."
            });
          }
        } else {
          //disallow if there's dots in between(potentially unsupported multi-level domain)
          this.setState({
            isLoading: false,
            errorMessage: "Sorry. Multi level sub-domain is not supported."
          });
        }
      }
    } else {
      if (!domain.isPublicDomain) {
        //check "/" occurences; if > 1, then this would be either edit or new funnel page creation
        if (urlPathName.match(/\//g).length > 1) {
          let funnelPageId = match.params.funnelPageId;

          if (funnelPageId) {
            salesFunnelPageMode = salesFunnelPageModeEnum.UPDATE;

            //load here to avoid re-rendering
            let docSnap = await db.doc(`sales_funnels/${funnelPageId}`).get();

            if (docSnap.exists) {
              this.setState({
                isLoading: false,
                salesFunnelPageMode
              });
            } else {
              this.setState({
                isLoading: false,
                errorMessage: "Invalid Funnel Page ID."
              });
            }
          } else {
            salesFunnelPageMode = salesFunnelPageModeEnum.NEW;

            let languageCode = match.params.languageCode;
            let bookId = match.params.bookId;

            if (languageCode && bookId) {
              if (Object.values(eBookLanguageCodeEnum).includes(languageCode)) {
                this.setState(
                  {
                    salesFunnelPageMode
                  },
                  async () => {
                    await loadSelectedEBook(languageCode, bookId);
                  }
                );
              } else {
                this.setState({
                  isLoading: false,
                  errorMessage: "Invalid language."
                });
              }
            } else {
              this.setState({
                isLoading: false,
                errorMessage: "Missing url parameters."
              });
            }
          }
        }
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "Invalid request."
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.state;
    const { book } = this.props;

    if (
      !_.isEqual(prevProps.book.selectedEBook, book.selectedEBook) &&
      isLoading
    ) {
      if (!_.isEmpty(book.selectedEBook)) {
        this.setState({
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "Invalid book ID."
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetEBook();
  }

  render() {
    const { isLoading, errorMessage, salesFunnelPageMode } = this.state;

    if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        {errorMessage.length === 0 ? (
          <Template0001 salesFunnelPageMode={salesFunnelPageMode} />
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Poppins-Regular",
              marginTop: "120px"
            }}
          >
            {errorMessage}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  loadSelectedEBook,
  resetEBook
};

const mapStateToProps = state => ({
  book: state.book,
  domain: state.domain
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(FunnelPageCustomise);
