import { VALIDATE_DOMAIN, PUBLISH_NEW_SUBDOMAIN, LOAD_USER_DOMAIN_INFO } from "./domainConstant";
import { operationFailed, operationCompleted, operationStarted } from "../operation/operationActions";
import { operationTypeEnum } from "../OperationTypeConstant";
import { checkIfUserOwnFunnelPage } from "../salesFunnel/salesFunnelActions";
import { RESET_EBOOK } from "../book/bookConstant";
import { RESET_SALES_FUNNEL_DATA } from "../salesFunnel/salesFunnelConstant";

export const validateDomain = () => {
    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        try {

            let hostName = window.location.hostname;
            let sharedDomain = getState().domain.sharedDomain;
            let regExp = new RegExp(sharedDomain, "g")

            const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');

            let urlInfoObj = {
                host: "",
                subdomain: ""
            }
            let isPublicDomain = false
           
            if (subdomain !== "" && hostName.match(regExp) !== null) {
                isPublicDomain = true

                //further check if subdomain is provided
                let subDomainRegexp = new RegExp(`(.*).${sharedDomain}`, "g");
                let regexpResult = subDomainRegexp.exec(hostName);

                if (regexpResult !== null) {
                    let urlQuerySnap = await db.collection("urls").where("subdomain", "==", regexpResult[1]).limit(1).get();
                    if (!urlQuerySnap.empty) {
                        urlInfoObj = { ...urlQuerySnap.docs[0].data(), docId: urlQuerySnap.docs[0].id }
                    }
                }

            } else {
                //check if this's user custom domain
                let urlQuerySnap = await db.collection("urls").where("host", "==", hostName).limit(1).get();
                if (!urlQuerySnap.empty) {
                    isPublicDomain = true
                    urlInfoObj = { ...urlQuerySnap.docs[0].data(), docId: urlQuerySnap.docs[0].id }
                }
            }
            dispatch({ type: VALIDATE_DOMAIN, payload: { isPublicDomain, urlInfoObj } })

        } catch (err) {
            console.log(err)
        }
    }
}

export const publishNewSubdomain = (uid, enteredSubdomain) => {
    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        try {
            dispatch(operationStarted(operationTypeEnum.PUBLISH_NEW_SUBDOMAIN))

            //TODO:very likely have to capture sharedDomain information
            await db.doc(`urls/${uid}`).set({ subdomain: enteredSubdomain }, { merge: true });

            dispatch({ type: PUBLISH_NEW_SUBDOMAIN, payload: { enteredSubdomain } })

            dispatch(operationCompleted())

        } catch (err) {
            console.log(err)
            dispatch(operationFailed())
        }
    }
}

export const loadUserMainPageInfo = (uid) => {
    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        try {
            dispatch(operationStarted(operationTypeEnum.LOAD_USER_MAIN_PAGE_INFO))

            //check if user own funnel page
            await dispatch(checkIfUserOwnFunnelPage(uid))

            //load user domain info, if any
            let userDomainInfoObj = {
                host: "",
                subdomain: "",
            }
            let docSnap = await db.doc(`urls/${uid}`).get();

            if (docSnap.exists) {
                userDomainInfoObj = {
                    host: docSnap.data().host ? docSnap.data().host : "",
                    subdomain: docSnap.data().subdomain ? docSnap.data().subdomain : "",
                }
            }

            dispatch({ type: LOAD_USER_DOMAIN_INFO, payload: { userDomainInfoObj } })



            dispatch(operationCompleted())

        } catch (err) {
            console.log(err)
            dispatch(operationFailed())
        }
    }
}


export const resetUserMainPageInfo = () => {
    return async (dispatch) => {
        try {

            dispatch({ type: RESET_EBOOK })
            dispatch({ type: RESET_SALES_FUNNEL_DATA })

        } catch (err) {
            console.log(err)
        }
    }
}
