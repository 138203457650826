import React, { Component, Fragment } from "react";
import { Col, Row } from "antd";
import { withFirebase } from "react-redux-firebase";
import { compose } from "redux";
import EbookCoverPreview from "./EbookCoverPreview";
// import LoadingComponent from "./app/common/loading/LoadingComponent";

class EbookCover extends Component {
  eBookCoverContainerRef = React.createRef();
  state = {
    isLoading: true,
    eBookCoverObj: {
      bookNameObj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "35",
        color: ""
      },
      authorObj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "18",
        color: ""
      },
      tagline1Obj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "20",
        color: ""
      },
      tagline2Obj: {
        text: "",
        fontFamily: "Lato-Regular",
        fontSize: "20",
        color: ""
      },
      error: false,
      selected: false,
      isTooltipVisible: false
    },
    formImageObj: {
      profileImageObj: {
        imageDisplayList: [],
        imageLocalList: [],
        imageDBList: [],
        imageDBToRemoveList: []
      }
    },
    eBookContainerWidth: 0,
    eBookContainerHeight: 0
  };

  async componentDidMount() {
    const { match, firebase } = this.props;
    const db = firebase.firestore();
    const storageRef = firebase.storage().ref();

    let uid = match.params.uid;

    let funnelPageObj = {};
    let docSnap = await db.doc(`sales_funnels/${uid}`).get();

    funnelPageObj = { ...docSnap.data(), docId: docSnap.id };

    let backgroundImagePathUrl = "";
    if (funnelPageObj.backgroundImagePath.length > 0) {
      backgroundImagePathUrl = await storageRef
        .child(funnelPageObj.backgroundImagePath)
        .getDownloadURL();
    }

    let profileImageUrl = "";
    // if (funnelPageObj.profileImagePath.length > 0) {
    //   profileImageUrl = await storageRef
    //     .child(funnelPageObj.profileImagePath)
    //     .getDownloadURL();
    // }

    this.setState(prevState => ({
      backgroundImagePathUrl,
      isLoading: false,
      eBookCoverObj: {
        ...prevState.eBookCoverObj,
        bookNameObj: funnelPageObj.eBookCoverInfo.bookNameInfo,
        authorObj: funnelPageObj.eBookCoverInfo.authorInfo,
        tagline1Obj: funnelPageObj.eBookCoverInfo.tagline1Info,
        tagline2Obj: funnelPageObj.eBookCoverInfo.tagline2Info
      },
      formImageObj: {
        profileImageObj: {
          imageDisplayList: profileImageUrl.length > 0 ? [profileImageUrl] : [],
          imageDBList: profileImageUrl.length > 0 ? [profileImageUrl] : [],
          imageLocalList: [],
          imageDBToRemoveList: []
        }
      }
    }));
  }

  componentDidUpdate() {
    //set initial container width using latest state
    if (this.state.eBookContainerWidth === 0) {
      if (this.eBookCoverContainerRef.current) {
        this.setState({
          eBookContainerWidth: this.eBookCoverContainerRef.current.offsetWidth
        });
      }
    }

    //set initial container height using latest state
    if (this.state.eBookContainerHeight === 0) {
      if (this.eBookCoverContainerRef.current) {
        this.setState({
          eBookContainerHeight: this.eBookCoverContainerRef.current.offsetHeight
        });
      }
    }
  }

  render() {
    const {
      isLoading,
      eBookContainerWidth,
      eBookContainerHeight,
      eBookCoverObj,
      backgroundImagePathUrl,
      formImageObj
    } = this.state;

    // if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        {!isLoading && (
          <Row
            className="ebook-cover-snapshot-container"
            ref={this.eBookCoverContainerRef}
          >
            <Col span={24}>
              <EbookCoverPreview
                eBookContainerHeight={eBookContainerHeight}
                eBookContainerWidth={eBookContainerWidth}
                eBookCoverObj={eBookCoverObj}
                backgroundImage={backgroundImagePathUrl}
                profileImageObj={formImageObj.profileImageObj}
                isPublic={true}
              />
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default compose(withFirebase)(EbookCover);
