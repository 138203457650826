import React from "react";
import Dropzone from "react-dropzone";

const DropzoneComponent = ({ onHandleAcceptedFilesFromDropzone, uploadImageIcon }) => {
  return (
    <Dropzone
      accept="image/*"
      onDrop={acceptedFiles => onHandleAcceptedFilesFromDropzone(acceptedFiles)}
      noDrag={true}
      multiple={false}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {uploadImageIcon}
        </div>
      )}
    </Dropzone>
  );
};

export default DropzoneComponent;
