import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_RESOURCE, RESET_RESOURCE } from "./resourcesConstant";

const initialState = {
  resourceList: [],
};

const loadResource = (state, payload) => {
  return {
    ...state,
    resourceList: payload.resourceList,
  };
};

const resetResource = (state) => {
  return {
    ...state,
    resourceList: null,
  };
};

export default createReducer(initialState, {
  [LOAD_RESOURCE]: loadResource,
  [RESET_RESOURCE]: resetResource,
});
