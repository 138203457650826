import { createReducer } from "../../app/common/utils/reducerUtils";
import {
  ECODE_GENERATION_START,
  ECODE_GENERATION_SUCCESS,
  ECODE_GENERATION_FAILURE,
  ECODE_USER_DELETE_START,
  ECODE_USER_DELETE_SUCCESS,
  ECODE_USER_DELETE_FAILURE,
  LOAD_ALL_ECODES
} from "./ecodeConstant";

const initialState = {
  loading: false,
  generateEcodesSuccess: false,
  generateEcodesFailure: false,
  ecodeUserDeleteSuccess: false,
  ecodeUserDeleteFailure: false,
  errMsg: "",
  ecodeGenerationInfoObj: null
};

const generateEcodesStart = state => {
  return {
    ...state,
    generateEcodesSuccess: false,
    generateEcodesFailure: false,
    errMsg: "",
    loading: true
  };
};

const generateEcodesSuccess = state => {
  return {
    ...state,
    generateEcodesSuccess: true,
    loading: false
  };
};

const generateEcodesFailure = (state, payload) => {
  return {
    ...state,
    generateEcodesFailure: true,
    errMsg: payload.errMsg,
    loading: false
  };
};
const loadDatabaseSyncData = (state, payload) => {
  return {
    ...initialState,
    ecodeGenerationInfoObj: payload.ecodeGenerationInfoObj
  };
};

const ecodeUserDeleteStart = state => {
  return {
    ...state,
    ecodeUserDeleteSuccess: false,
    ecodeUserDeleteFailure: false,
    errMsg: "",
    loading: true
  };
};

const ecodeUserDeleteSuccess = state => {
  return {
    ...state,
    ecodeUserDeleteSuccess: true,
    loading: false
  };
};

const ecodeUserDeleteFailure = (state, payload) => {
  return {
    ...state,
    ecodeUserDeleteFailure: true,
    errMsg: payload.errMsg,
    loading: false
  };
};

export default createReducer(initialState, {
  [ECODE_GENERATION_START]: generateEcodesStart,
  [ECODE_GENERATION_SUCCESS]: generateEcodesSuccess,
  [ECODE_GENERATION_FAILURE]: generateEcodesFailure,
  [ECODE_USER_DELETE_START]: ecodeUserDeleteStart,
  [ECODE_USER_DELETE_SUCCESS]: ecodeUserDeleteSuccess,
  [ECODE_USER_DELETE_FAILURE]: ecodeUserDeleteFailure,
  [LOAD_ALL_ECODES]: loadDatabaseSyncData
});
