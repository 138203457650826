import React, { Fragment, Component } from "react";
import { Row, Col, Typography, Button } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";
const { Text } = Typography;

class TopBar extends Component {
  onHandleLogoutClick = async () => {
    const { firebase } = this.props;
    await firebase.auth().signOut();
    this.props.history.push("/");
  };

  render() {
    const { auth } = this.props;
    return (
      <Fragment>
        <Row align="middle">
          <Col span={20}>
            <br />
            <Text
              style={{
                fontFamily: "montserrat-semibold",
                fontSize: "38px",
                marginLeft: "16px"
              }}
            >
              BizMastery - Admin Console
            </Text>

            <br />
            <br />
          </Col>
          <Col
            span={3}
            style={{
              textAlign: "right"
            }}
          >
            <br />
            {auth.email && (
              <Fragment>
                <Text
                  style={{
                    fontFamily: "montserrat-semibold",
                    fontSize: "16px"
                  }}
                >
                  {auth.email}
                </Text>
                <br />
                <Button
                  type="link"
                  style={{
                    fontFamily: "montserrat-medium",
                    fontSize: "16px",
                    color: "#00690C",
                    padding: 0
                  }}
                  onClick={this.onHandleLogoutClick}
                >
                  Log Out
                </Button>
              </Fragment>
            )}
          </Col>
          <Col span={1} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(TopBar);
