import { VALIDATE_DOMAIN, PUBLISH_NEW_SUBDOMAIN, LOAD_USER_DOMAIN_INFO, RESET_USER_DOMAIN_INFO } from "./domainConstant";
import { createReducer } from "../app/common/utils/reducerUtils";


const initialState = {
    sharedDomain: "mlmbizmastery.com",
    // sharedDomain: "businessportal.com",
    isPublicDomain: null, //public domain consists of user custom domain or globally shared domain(without deep check on subdomain)
    urlInfoObj: null,

    userDomainInfoObj: null
}

const validateDomain = (state, payload) => {
    return {
        ...state,
        isPublicDomain: payload.isPublicDomain,
        urlInfoObj: payload.urlInfoObj
    }
}

const publicNewSubdomain = (state, payload) => {
    return {
        ...state,
        userDomainInfoObj: { ...state.userDomainInfoObj, subdomain: payload.enteredSubdomain }

    }
}

const loadUserDomainInfo = (state, payload) => {
    return {
        ...state,
        userDomainInfoObj: payload.userDomainInfoObj
    }
}

const resetUserDomainInfo = (state) => {
    return {
        ...state,
        userDomainInfoObj: null
    }
}

export default createReducer(initialState, {
    [VALIDATE_DOMAIN]: validateDomain,
    [PUBLISH_NEW_SUBDOMAIN]: publicNewSubdomain,
    [LOAD_USER_DOMAIN_INFO]: loadUserDomainInfo,
    [RESET_USER_DOMAIN_INFO]: resetUserDomainInfo
});
