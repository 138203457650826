import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { initDBLoad, logout } from "./auth/authActions";
import { withRouter } from "react-router";
import { message } from "antd";
class DbAuthLoad extends Component {
  state = { isLoading: true };

  async componentDidMount() {
    const { initDBLoad, auth, history } = this.props;
    const isAuthenticated = auth.uid && !auth.isAnonymous;

    if (isAuthenticated) {
      if (
        window.location.pathname !== "/" &&
        !window.location.pathname.includes("/admin")
      ) {
        let result = await initDBLoad(auth.uid); //load this IFF user already logged in
        console.log("here");
        if (result) {
          message.error(result.code + " : " + result.message);
          history.push("/");
        }
      }
      this.setState({
        isLoading: false,
      });
    } else {
      if (
        window.location.pathname !== "/" &&
        !window.location.pathname.includes("/ebook-cover") &&
        !window.location.pathname.includes("/admin") &&
        !window.location.pathname.includes("/login") &&
        !window.location.pathname.includes("/register")
      ) {
        console.log("here");
        history.push("/");
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dbAuth } = this.props;
    //trigger IFF user info get updated(IFF completed login)
    if (!_.isEqual(prevProps.dbAuth.userInfo, dbAuth.userInfo)) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;

    if (isLoading) return null;
    return children;
  }
}
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  initDBLoad,
  logout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DbAuthLoad);
