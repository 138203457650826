import React from "react";
import { Spin, Row, Col } from "antd";
import { LOADING } from "../../../BusinessPortal/TranslationConstant";

const LoadingComponent = auth => {
  return (
    <Row justify="center" align="middle">
      <Col style={{ textAlign: "center", marginTop: "256px" }}>
        {/* {console.log(auth)} */}
        <Spin
          tip={
            LOADING[
              auth
                ? auth.auth
                  ? auth.auth.userInfo
                    ? auth.auth.userInfo.nativeLanguage
                      ? auth.auth.userInfo.nativeLanguage
                      : "en"
                    : "en"
                  : "en"
                : "en"
            ]
          }
          size="large"
        />
      </Col>
    </Row>
  );
};

export default LoadingComponent;
