import { Row, Col, Table } from "antd";
import React, { Component, Fragment } from "react";
import "./ChooseLesson.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import {
  loadTargetedCourseLessons,
  resetTargetedCourseLesson
} from "./courseActions";
import { getTransText, lessonPageInit } from "../../SharedUtils";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import { CaretRightOutlined } from "@ant-design/icons";
import { LESSONS } from "../TranslationConstant";

class ChooseLesson extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;

    this.props.loadTargetedCourseLessons(courseId);

    this.setState({
      courseId
    });
  }

  componentWillUnmount() {
    this.props.resetTargetedCourseLesson();
  }

  loadCourse = () => {
    const { course, match, translations, auth } = this.props;
    let courseId = match.params.courseId;
    const data = [];
    for (const lesson of course.lessonInfoList) {
      const courseName = "";
      // const courseName = getTransText(
      //   auth.userInfo
                // ? auth.userInfo.nativeLanguage
                //   ? auth.userInfo.nativeLanguage
                //   : "en"
                // : "en",
      //   courseList.name,
      //   translations.translationObj
      // ).text;

      data.push({
        key: lesson.docId,
        phrase: `
        ${
          getTransText(
            auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
            lesson.name,
            translations.translationObj
          ).text
        }`,
        lesson: lesson
      });

      this.setState({
        isLoading: false,
        courseId,
        courseName,
        data
      });
    }
  };

  async componentDidUpdate(prevProps) {
    const { course, auth } = this.props;
    if (
      !_.isEqual(prevProps.course.lessonInfoList, course.lessonInfoList) ||
      (course.lessonInfoList !== null && this.state.isLoading) ||
      auth.userInfo.nativeLanguage !== prevProps.auth.userInfo.nativeLanguage
    ) {
      this.loadCourse();
    }
  }

  onRowClick = lessonId => {
    // console.log(lessonId);
    const { courseId } = this.state;
    this.props.history.push(`/business-portal/${courseId}/${lessonId}`);
  };
  render() {
    const { data, courseName, courseId } = this.state;
    const { auth } = this.props;
    const columns = [
      {
        dataIndex: "phrase",
        className: "table-row-text",
        render: (value, record, index) => (
          <div className="table-row-text">
            {value}
            {"  "}
            {lessonPageInit(record.lesson, courseId, auth)
              .isAllLessonsCompleted && (
              <img
                src="/assets/businessPortal/complete.png"
                alt="Completed"
                width="25px"
              />
            )}
          </div>
        )
      },
      {
        dataIndex: "action",
        align: "end",
        render: (value, record, index) => (
          <CaretRightOutlined style={{ padding: "20px" }} />
          // <img src="/assets/businessPortal/play-button.png" alt="Play Button" />
        )
      }
    ];
    const { isLoading } = this.state;

    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row className="choose-lesson-row">
          <Col xs={3} />
          <Col xs={18} style={{ textAlign: "center" }}>
            <div className="choose-lesson-title">{courseName}</div>
            <Table
              className="choose-lesson-table"
              columns={columns}
              dataSource={data}
              showHeader={false}
              pagination={false}
              title={() => (
                <div className="table-header">
                  {
                    LESSONS[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                </div>
              )}
              onRow={(record, rowIndex) => {
                return {
                  onClick: e => {
                    this.onRowClick(record.key);
                  }
                };
              }}
            />
          </Col>
          <Col xs={3} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations
});

const mapDispatchToProps = {
  loadTargetedCourseLessons,
  resetTargetedCourseLesson
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChooseLesson);
