import React, { Fragment } from "react";
import { Drawer, Input, Select, AutoComplete } from "antd";
import { filterOptionFunc } from "./antUtils";
import {
  titleTemplateList,
  minFontSize,
  supportedFontVariantsMap,
  maxFontSize
} from "./SharedConstant";
import InputColor from "react-input-color";
const rgbHex = require("rgb-hex");

const HeaderTextDrawer = ({
  onClose,
  isVisible,
  windowWidth,
  onTextChange,
  onColorChange,
  onFontSizeChange,
  onFontFamilyChange,
  inputFieldObj
}) => {
  return (
    <Fragment>
      <Drawer
        onClose={onClose}
        visible={isVisible}
        width={windowWidth > 600 ? 600 : windowWidth}
      >
        <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          Title{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error && inputFieldObj.text.length === 0
              ? "(Title cannot be empty)"
              : null}
          </span>
        </div>

        <AutoComplete
          style={{ width: "100%" }}
          options={titleTemplateList}
          filterOption={filterOptionFunc}
          onChange={value => onTextChange(value, "headerObj")}
          value={inputFieldObj.text}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          Font Type
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.fontFamily.split("-")[0]}
          onChange={value => onFontFamilyChange(value, "headerObj", 0)}
        >
          {[...supportedFontVariantsMap.keys()].map((item, idx) => (
            <Select.Option key={`${item}_${idx}`} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          Font Variant
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.fontFamily.split("-")[1]}
          onChange={value => onFontFamilyChange(value, "headerObj", 1)}
        >
          {supportedFontVariantsMap
            .get(inputFieldObj.fontFamily.split("-")[0])
            .map((item, idx) => (
              <Select.Option key={`${item}_${idx}`} value={item}>
                {item}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          Font Size{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error
              ? inputFieldObj.fontSize <= minFontSize
                ? "(Font size is too small)"
                : inputFieldObj.fontSize >= maxFontSize
                ? "(Font size is too big)"
                : null
              : null}
          </span>
        </div>
        <Input
          type="number"
          style={{ fontSize: "18px", fontFamily: "Poppins-Regular" }}
          onChange={e => onFontSizeChange(e, "headerObj")}
          value={inputFieldObj.fontSize}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          Color
        </div>
        <InputColor
          initialValue={
            inputFieldObj.color.length > 0
              ? "#" + rgbHex(inputFieldObj.color)
              : "#FFFFFF"
          }
          onChange={value => onColorChange(value, "headerObj")}
          style={{ width: "100%", height: "35px" }}
        />
      </Drawer>
    </Fragment>
  );
};

export default HeaderTextDrawer;
