//course related
export const LOAD_ALL_COURSE = "LOAD_ALL_COURSE";
export const RESET_ALL_COURSE = "RESET_ALL_COURSE";
export const LOAD_COURSE_LESSONS = "LOAD_COURSE_LESSONS";
export const RESET_COURSE_LESSONS = "RESET_COURSE_LESSONS";
export const LOAD_COURSE_LESSON_SCENE = "LOAD_COURSE_LESSON_SCENE";
export const RESET_COURSE_LESSON_SCENE = "RESET_COURSE_LESSON_SCENE";

//email templetes related
export const LOAD_ALL_EMAIL_TEMPLATES = "LOAD_ALL_EMAIL_TEMPLATES";

//business speaking related
export const LOAD_BUSINESS_SPEAKING = "LOAD_BUSINESS_SPEAKING";

export const LOAD_PROBING_QUESTIONS = "LOAD_PROBING_QUESTIONS";
