import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Typography,
  Modal,
  Input,
  Button,
  Form,
  Divider,
} from "antd";
import "./ContactUs.less";
import {
  CONTACTUS,
  PLEASEENTERFULLNAME,
  NAME,
  EMAILADD,
  PLEASEENTEREMAIL,
  MESSAGE,
  MESSAGEISEMPTY,
  SEND,
} from "../TranslationConstant";

const { Text } = Typography;
const { TextArea } = Input;

class ContactUs extends Component {
  // form = React.createRef();
  render() {
    const { visible, hideModal, sendMessage, auth, selectedLanguage } =
      this.props;
    return (
      <Fragment>
        <Modal visible={visible} footer={false} onCancel={hideModal}>
          <Row key="">
            <Col span={2} />
            <Col span={20}>
              <Row>
                <Col offset={1} span={22} style={{ textAlign: "center" }}>
                  <Text className="home-title">
                    {
                      CONTACTUS[
                        auth
                          ? auth.userInfo && auth.userInfo.nativeLanguage
                            ? auth.userInfo.nativeLanguage
                            : "en"
                          : selectedLanguage
                          ? selectedLanguage
                          : "en"
                      ]
                    }
                  </Text>
                </Col>
                <Divider />
                <Col span={24}>
                  <Form
                    onFinish={sendMessage}
                    layout="vertical"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Form.Item
                      label={
                        NAME[
                          auth
                            ? auth.userInfo && auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                            : selectedLanguage
                            ? selectedLanguage
                            : "en"
                        ]
                      }
                      name="name"
                      rules={[
                        {
                          required: true,
                          message:
                            PLEASEENTERFULLNAME[
                              auth
                                ? auth.userInfo && auth.userInfo.nativeLanguage
                                  ? auth.userInfo.nativeLanguage
                                  : "en"
                                : selectedLanguage
                                ? selectedLanguage
                                : "en"
                            ],
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={
                        EMAILADD[
                          auth
                            ? auth.userInfo && auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                            : selectedLanguage
                            ? selectedLanguage
                            : "en"
                        ]
                      }
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message:
                            PLEASEENTEREMAIL[
                              auth
                                ? auth.userInfo && auth.userInfo.nativeLanguage
                                  ? auth.userInfo.nativeLanguage
                                  : "en"
                                : selectedLanguage
                                ? selectedLanguage
                                : "en"
                            ],
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={
                        MESSAGE[
                          auth
                            ? auth.userInfo && auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                            : selectedLanguage
                            ? selectedLanguage
                            : "en"
                        ]
                      }
                      name="message"
                      rules={[
                        {
                          required: true,
                          message:
                            MESSAGEISEMPTY[
                              auth
                                ? auth.userInfo && auth.userInfo.nativeLanguage
                                  ? auth.userInfo.nativeLanguage
                                  : "en"
                                : selectedLanguage
                                ? selectedLanguage
                                : "en"
                            ],
                        },
                      ]}
                    >
                      <TextArea rows={6} />
                    </Form.Item>
                    <Button
                      htmlType="submit"
                      shape="round"
                      style={{
                        background: "black",
                      }}
                      // onClick={hideModal}
                    >
                      <Text
                        className="shop-now-button-text"
                        style={{
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        {
                          SEND[
                            auth
                              ? auth.userInfo && auth.userInfo.nativeLanguage
                                ? auth.userInfo.nativeLanguage
                                : "en"
                              : selectedLanguage
                              ? selectedLanguage
                              : "en"
                          ]
                        }
                      </Text>
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

export default ContactUs;
