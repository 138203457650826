import { createReducer } from "../../app/common/utils/reducerUtils";
import {
  LOAD_ALL_COURSE,
  RESET_ALL_COURSE,
  LOAD_COURSE_LESSONS,
  RESET_COURSE_LESSONS,
  LOAD_COURSE_LESSON_SCENE,
  RESET_COURSE_LESSON_SCENE,
  LOAD_ALL_EMAIL_TEMPLATES,
  LOAD_BUSINESS_SPEAKING,
  LOAD_PROBING_QUESTIONS
} from "./courseConstant";

const initialState = {
  courseInfoObj: null,
  powerToBelieve: null,
  lessonInfoList: null,
  lessonScenesInfoObj: null,

  emailTemplatesInfoObj: null,

  businessSpeakingInfo: null,

  probingQuestionsInfoObj: null
};

const loadAllCourse = (state, payload) => {
  return {
    ...state,
    courseInfoObj: payload.courseInfoList,
    powerToBelieve: payload.powerToBelieve
  };
};

const resetAllCourse = state => {
  return {
    ...state,
    courseInfoObj: null,
    powerToBelieve: null
  };
};

const loadTargetedCourseLessons = (state, payload) => {
  return {
    ...state,
    lessonInfoList: payload.lessonInfoList
  };
};
const resetTargetedCourseLesson = state => {
  return {
    ...state,
    lessonInfoList: null
  };
};

const loadCourseSubTopics = (state, payload) => {
  return {
    ...state,
    lessonScenesInfoObj: payload.lessonScenesInfoObj
  };
};

const resetCourseSubTopics = state => {
  return {
    ...state,
    lessonScenesInfoObj: null
  };
};

const loadAllEmailTemplates = (state, payload) => {
  return {
    ...state,
    emailTemplatesInfoObj: payload.emailTemplatesInfoObj
  };
};

const loadBusinessSpeaking = (state, payload) => {
  return {
    ...state,
    businessSpeakingInfo: payload.businessSpeakingInfo
  };
};

const loadProbingQuestions = (state, payload) => {
  return {
    ...state,
    probingQuestionsInfoObj: payload.probingQuestionsInfoObj
  };
};

export default createReducer(initialState, {
  [LOAD_ALL_COURSE]: loadAllCourse,
  [RESET_ALL_COURSE]: resetAllCourse,
  [LOAD_COURSE_LESSON_SCENE]: loadCourseSubTopics,
  [RESET_COURSE_LESSON_SCENE]: resetCourseSubTopics,
  [LOAD_COURSE_LESSONS]: loadTargetedCourseLessons,
  [RESET_COURSE_LESSONS]: resetTargetedCourseLesson,
  [LOAD_ALL_EMAIL_TEMPLATES]: loadAllEmailTemplates,

  [LOAD_BUSINESS_SPEAKING]: loadBusinessSpeaking,

  [LOAD_PROBING_QUESTIONS]: loadProbingQuestions
});
