import { createStore, applyMiddleware } from "redux";
import {  getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers/rootReducer";
import thunk from "redux-thunk";



export const configureStore = () => {
  const middlewares = [thunk.withExtraArgument({ getFirebase,getFirestore })];

  const composedEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );
  const store = createStore(rootReducer,composedEnhancer);


  return store;
};
