import {
  LOAD_USER_INFO,
  RESET_USER_INFO,
  UPDATE_USER_DATA,
  UPDATE_LESSON_PROGRESS,
  UPDATE_PROBING_QUESTIONS_PROGRESS,
  UPDATE_EXAM_PROGRESS,
  UPDATE_USER_LANGUAGE,
  UPDATE_LANGUAGE,
  RESET,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from "./authConstant";

import { operationTypeEnum } from "../OperationTypeConstant";
import {
  operationStarted,
  operationCompleted,
  operationFailed
} from "../operation/operationActions";

import { loadTranslationData } from "../BusinessPortal/translation/translationActions";
// import { loadAllCourse } from "../BusinessPortal/lessons/courseActions";
// import { loadGeneral } from "../general/generalActions";

export const login = creds => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);

      let currentUser = firebase.auth().currentUser;
      let userDoc = await db.doc(`users/${currentUser.uid}`).get();
      let ecodeEndDate = userDoc.data().ecodeEndDate;
      if (ecodeEndDate.toDate() < new Date()) {
        return {
          code: "Expired E-Code",
          message:
            "Your account's E-Code has expired, please provide a new E-Code to renew your account."
        };
      }

      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const updateEcodeForUser = ecode => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    let currentUser = firebase.auth().currentUser;

    try {
      let ecodeQuerySnap = await db
        .collection("ecodes")
        .where("code", "==", ecode)
        .where("status", "==", "Available")
        .get();

      if (ecodeQuerySnap.docs.length !== 0) {
        console.log("Found ecode doc");
        let ecodeData = ecodeQuerySnap.docs[0].data();
        ecodeData.docId = ecodeQuerySnap.docs[0].id;

        const now = new Date();
        let ecodeEndDate = new Date(now);
        ecodeEndDate.setDate(ecodeEndDate.getDate() + ecodeData.durationInDays);

        let batch = db.batch();
        batch.set(
          db.collection("users").doc(currentUser.uid),
          {
            ecodeEndDate: ecodeEndDate,
            updatedAt: now
          },
          { merge: true }
        );

        batch.set(
          db.collection("ecodes").doc(ecodeData.docId),
          {
            activationDate:new Date(),
            status: "Used",
            user: { email: currentUser.email, id: currentUser.uid },
            updatedAt: now
          },
          { merge: true }
        );
        await batch.commit();

        return null;
      } else {
        console.log("Ecode is invalid");
        return { code: "Error", message: "E-Code is invalid" };
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const signUp = (creds, selectedLanguage) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();

    try {
      let ecodeQuerySnap = await db
        .collection("ecodes")
        .where("code", "==", creds.ecode)
        .where("status", "==", "Available")
        .get();

      if (ecodeQuerySnap.docs.length !== 0) {
        console.log("Found ecode doc");
        let ecodeData = ecodeQuerySnap.docs[0].data();
        ecodeData.docId = ecodeQuerySnap.docs[0].id;

        await firebase
          .auth()
          .createUserWithEmailAndPassword(creds.email, creds.password);

        let currentUser = firebase.auth().currentUser;

        const now = new Date();
        let ecodeEndDate = new Date(now);
        ecodeEndDate.setDate(ecodeEndDate.getDate() + ecodeData.durationInDays);

        let batch = db.batch();
        batch.set(db.collection("users").doc(currentUser.uid), {
          email: creds.email,
          name: creds.name,
          ecodeEndDate: ecodeEndDate,
          gender: creds.gender,
          birthday: creds.birthday.toDate(),
          createdAt: now,
          updatedAt: now,
          nativeLanguage: selectedLanguage,
          profileImagePath: ""
        });

        batch.set(
          db.collection("ecodes").doc(ecodeData.docId),
          {
            activationDate:new Date(),
            status: "Used",
            user: { email: currentUser.email, id: currentUser.uid },
            updatedAt: now
          },
          { merge: true }
        );
        await batch.commit();

        return null;
      } else {
        console.log("Ecode is invalid");
        return { code: "Error", message: "E-Code is invalid" };
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const freeTrialChoice = choiceString => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    let currentUser = firebase.auth().currentUser;
    try {
      await db
        .collection("users")
        .doc(currentUser.uid)
        .set(
          {
            choice: choiceString
          },
          { merge: true }
        );
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const resetEmailPassword = emailAddress => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    try {
      await firebase.auth().sendPasswordResetEmail(emailAddress);
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const initDBLoad = uid => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      //load translation info
      await dispatch(loadTranslationData());

      //load course info
      // await dispatch(loadAllCourse());

      //load user info
      await dispatch(loadUserInfo(uid));
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadUserInfo = uid => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    const storageRef = getFirebase()
      .storage()
      .ref();
    try {
      let userInfo = {};
      let currentUser = getFirebase().auth().currentUser;
      // console.log(uid, currentUser);
      let docSnap = await db
        .collection("users")
        .doc(currentUser.uid)
        .get();
      let profileImageUrl = "";
      if (docSnap.data() && docSnap.data().profileImagePath.length > 0) {
        profileImageUrl = await storageRef
          .child(docSnap.data().profileImagePath)
          .getDownloadURL();
      }
      userInfo = { docId: docSnap.id, ...docSnap.data(), profileImageUrl };
      // console.log(userInfo);
      if (
        userInfo.ecodeEndDate &&
        userInfo.ecodeEndDate.toDate() < new Date()
      ) {
        await getFirebase()
          .auth()
          .signOut();
        return {
          code: "Expired E-Code",
          message:
            "Your account's E-Code has expired, please provide a new E-Code to renew your account."
        };
      }

      let progressInfo = {};

      let progressDocSnap = await db
        .doc(`users/${currentUser.uid}/progress/progress`)
        .get();
      if (progressDocSnap.exists) {
        progressInfo = {
          ...progressDocSnap.data(),
          docId: progressDocSnap.id
        };
      }

      await dispatch({
        type: LOAD_USER_INFO,
        payload: { userInfo, progressInfo }
      });
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const updateUserInfo = (values, birthday, imageObj) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    const storageRef = getFirebase()
      .storage()
      .ref();
    const firebase = getFirebase();
    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_USER_DATA));
      let newProfileImagePath = "";
      let newProfileImageUrl = "";
      if (getState().auth.userInfo.profileImageUrl.length === 0) {
        //new upload
        let newProfileImagePathDir = `users/${
          getState().firebase.auth.uid
        }/images`;
        await firebase.uploadFile(
          newProfileImagePathDir,
          imageObj.imageLocalList[0]
        );
        newProfileImagePath = `${newProfileImagePathDir}/${imageObj.imageLocalList[0].name}`;

        newProfileImageUrl = await storageRef
          .child(newProfileImagePath)
          .getDownloadURL();
        await db.doc(`users/${getState().firebase.auth.uid}`).update({
          profileImagePath: newProfileImagePath,
          gender: values.gender,
          name: values.name,
          birthday: birthday,
          updatedAt: new Date()
        });
      } else {
        //update existing

        //remove image
        if (imageObj.imageDBToRemoveList.length > 0) {
          await firebase.deleteFile(getState().auth.userInfo.profileImagePath);
        }

        if (imageObj.imageLocalList.length > 0) {
          let newProfileImagePathDir = `users/${
            getState().firebase.auth.uid
          }/images`;
          await firebase.uploadFile(
            newProfileImagePathDir,
            imageObj.imageLocalList[0]
          );
          newProfileImagePath = `${newProfileImagePathDir}/${imageObj.imageLocalList[0].name}`;
        }

        if (newProfileImagePath.length > 0) {
          newProfileImageUrl = await storageRef
            .child(newProfileImagePath)
            .getDownloadURL();
        }
        await db.doc(`users/${getState().firebase.auth.uid}`).update({
          profileImagePath:
            newProfileImagePath.length > 0
              ? newProfileImagePath
              : getState().auth.userInfo.profileImagePath,
          gender: values.gender,
          name: values.name,
          birthday: birthday,
          updatedAt: new Date()
        });
      }

      dispatch({
        type: UPDATE_USER_DATA,
        payload: {
          values,
          birthday,
          profileImagePath:
            newProfileImagePath.length > 0
              ? newProfileImagePath
              : getState().auth.userInfo.profileImagePath,
          profileImageUrl:
            newProfileImageUrl.length > 0
              ? newProfileImageUrl
              : getState().auth.userInfo.profileImageUrl
        }
      });

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
      throw err;
    }
  };
};

export const reset = (oldPassword, newPassword) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: RESET });
  };
};

export const resetPassword = (oldPassword, newPassword) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: RESET_PASSWORD_START });
    const user = getFirebase().auth().currentUser;
    const firebase = getFirebase();

    let response = await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, oldPassword)
      .catch(function(error) {
        // An error happened.
        console.log(getFirebase().auth().currentUser);
        console.log(error);
        dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: {
            errMsg: "Incorrect old password"
          }
        });
        return false;
      });

    // User re-authenticated.
    if (response !== false) {
      console.log(true);

      user
        .updatePassword(newPassword)
        .then(function() {
          // Update successful.
          dispatch({ type: RESET_PASSWORD_SUCCESS });
        })
        .catch(function(error) {
          // An error happened.
          dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: {
              errMsg:
                "Error occured while reseting password. Please try again later."
            }
          });
        });
    }
  };
};

export const logout = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase.auth().signOut();
      dispatch({ type: RESET_USER_INFO });
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateExamProgress = (currentScore, examId, answersIndex) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_EXAM_PROGRESS));
      let examData = {
        [examId]: { score: currentScore, answersIndex: answersIndex }
      };
      await db
        .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
        .set({ exams: examData }, { merge: true });

      dispatch({
        type: UPDATE_EXAM_PROGRESS,
        payload: { examId, answersIndex, currentScore }
      });
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};

export const updateUserLanguageInfo = (language, uid) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // console.log(language)
      dispatch(operationStarted(operationTypeEnum.UPDATE_USER_LANGUAGE));
      await db
        .doc(`users/${getState().firebase.auth.uid}`)
        .update({ nativeLanguage: language, updatedAt: new Date() });

      dispatch({
        type: UPDATE_USER_LANGUAGE,
        payload: {
          language
        }
      });

      dispatch({
        type: UPDATE_LANGUAGE,
        payload: {
          language
        }
      });
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
      throw err;
    }
  };
};

export const updateLanguage = language => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      // console.log(language)
      dispatch(operationStarted(operationTypeEnum.UPDATE_USER_LANGUAGE));

      dispatch({
        type: UPDATE_LANGUAGE,
        payload: {
          language
        }
      });
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
      throw err;
    }
  };
};

export const updateLessonProgress = (lessonIdList, courseId, lessonId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_LESSON_PROGRESS));
      console.log("here", lessonId);

      await db
        .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
        .set(
          { lessons: { [courseId]: { [lessonId]: lessonIdList } } },
          { merge: true }
        );

      dispatch({
        type: UPDATE_LESSON_PROGRESS,
        payload: { courseId, lessonId, lessonIdList }
      });

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};

export const updateProbingQuestionProgress = (lessonIdList, questionId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_LESSON_PROGRESS));
      // console.log("here", lessonIdList);

      await db
        .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
        .set(
          { lessons: { probing_questions: { [questionId]: lessonIdList } } },
          { merge: true }
        );

      dispatch({
        type: UPDATE_PROBING_QUESTIONS_PROGRESS,
        payload: { questionId, lessonIdList }
      });

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};
