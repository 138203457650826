import React, { Fragment } from "react";
import { Col, Button, Row } from "antd";
import "./FunnelPageTopBar.css";
import {
  SAVE,
  CUTOMISETHEFUNNELPAGE,
  BACK,
  INTHISSTEP
} from "../BusinessPortal/TranslationConstant";

const FunnelPageTopBar = ({ onBack, onSave, windowWidth, auth }) => {
  return (
    <Fragment>
      <Row
        align="center"
        style={{ background: "white", textAlign: "center", padding: "16px" }}
      >
        <Col
          xs={{ offset: 0, span: 5 }}
          sm={{ offset: 0, span: 5 }}
          md={{ offset: 2, span: 2 }}
          lg={{ offset: 2, span: 2 }}
          xl={{ offset: 2, span: 2 }}
        >
          <Button
            className="funnel-page-nav-button-wrapper"
            block
            style={{
              fontFamily: "Poppins-Medium",
              borderRadius: "4px",
              color: "white",
              height: "auto"
            }}
            onClick={onBack}
            type="primary"
          >
            {
              BACK[
                auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
              ]
            }
          </Button>
        </Col>
        <Col
          xs={{ offset: 3, span: 8 }}
          sm={{ offset: 3, span: 8 }}
          md={{ offset: 4, span: 8 }}
          lg={{ offset: 4, span: 8 }}
          xl={{ offset: 4, span: 8 }}
        >
          <div
            className="funnel-page-topbar-title-wrapper"
            style={{
              fontFamily: "Poppins-SemiBold",
              color: "#34374D"
            }}
          >
            {
              CUTOMISETHEFUNNELPAGE[
                auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
              ]
            }
          </div>
        </Col>
        <Col
          xs={{ offset: 3, span: 5 }}
          sm={{ offset: 3, span: 5 }}
          md={{ offset: 4, span: 2 }}
          lg={{ offset: 4, span: 2 }}
          xl={{ offset: 4, span: 2 }}
        >
          <Button
            className="funnel-page-nav-button-wrapper"
            block
            style={{
              fontFamily: "Poppins-Medium",
              borderRadius: "4px",
              color: "white",
              height: "auto"
            }}
            onClick={onSave}
            type="primary"
            //   disabled={!isValidParams}
          >
            {
              SAVE[
                auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
              ]
            }
          </Button>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2} />
      </Row>

      <Row
        style={{
          background: "#F5F5F5",
          padding: "16px 0px"
        }}
      >
        <Col span={24}>
          <Row>
            <Col offset={4} span={16}>
              <Row
                align="middle"
                style={{
                  borderRadius: "16px",
                  padding: "16px",
                  background: "white",
                  boxShadow: "2px 1px rgba(0,0,0,0.14)",
                  textAlign: windowWidth > 576 ? "left" : "center"
                }}
              >
                <Col flex="auto">
                  <img
                    className="funnel-page-instruction-image-wrapper"
                    src="/assets/computer-image.svg"
                    alt=""
                  />
                </Col>
                <Col flex={6}>
                  <div
                    className="funnel-page-instruction-title-wrapper"
                    style={{
                      fontFamily: "Poppins-SemiBold"
                    }}
                  >
                    {
                      CUTOMISETHEFUNNELPAGE[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                  </div>
                  <div
                    className="funnel-page-instruction-desc-wrapper"
                    style={{
                      fontFamily: "Poppins-Regular"
                    }}
                  >
                    {
                      INTHISSTEP[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={4} />
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FunnelPageTopBar;
