import {
  CONTACT_US_START,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE
} from "./ContactUsConstants";
import { createReducer } from "../../app/common/utils/reducerUtils";

const initialState = {
  loading: false,
  contactUsSuccess: false,
  contactUsFailure: false,
  errMsg: ""
};

const contactUsStart = state => {
  return {
    ...initialState,
    loading: true
  };
};

const contactUsSuccess = state => {
  return {
    ...state,
    contactUsSuccess: true,
    loading: false
  };
};

const contactUsFailure = (state, payload) => {
  return {
    ...state,
    contactUsFailure: true,
    errMsg: payload.errMsg,
    loading: false
  };
};

export default createReducer(initialState, {
  [CONTACT_US_START]: contactUsStart,
  [CONTACT_US_SUCCESS]: contactUsSuccess,
  [CONTACT_US_FAILURE]: contactUsFailure
});
