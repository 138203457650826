import React, { Component, Fragment } from "react";
import { Row, Col, Typography, Form, Input, Button } from "antd";
import TopBar from "./TopBar";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { logout } from "../auth/authActions";
const { Text } = Typography;

class AdminLoginPage extends Component {
  state = {
    errorMessage: null
  };

  onHandleAdminLoginFormSubmit = async values => {
    const { firebase } = this.props;
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);

      //TODO:fix Can't perform a React state update on an unmounted component issue
      this.setState(
        {
          errorMessage: null
        },
        () => this.props.history.push("/console")
      );
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: err.message
      });
    }
  };

  async componentDidMount() {
    const { auth } = this.props;
    const isAuthenticated = auth.uid;
    const isAnonymous = auth.isAnonymous;
    if (isAuthenticated && !isAnonymous) await this.props.logout();
  }

  render() {
    const { errorMessage } = this.state;

    return (
      <Fragment>
        <Row>
          <Col span={24} style={{ borderBottom: "1px solid #DFDFDF" }}>
            <TopBar />
          </Col>
          <Col
            span={24}
            style={{ color: "red", textAlign: "center", marginTop: "24px" }}
          >
            {errorMessage && errorMessage}
          </Col>
          <Col
            span={6}
            offset={9}
            style={{
              border: "1px solid #DFDFDF",
              padding: "24px",
              marginTop: "24px"
            }}
          >
            <Text
              style={{
                fontFamily: "montserrat-bold",
                fontSize: "24px"
              }}
            >
              Admin Login
            </Text>
            <br />
            <br />
            <Form
              layout="vertical"
              onFinish={this.onHandleAdminLoginFormSubmit}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="email"
                    rules={[{ required: true, message: "Email is required" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="password"
                    rules={[
                      { required: true, message: "Passowrd is required" }
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col offset={20} span={4}>
                  <Button htmlType="submit">LOGIN</Button>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col span={9} />
        </Row>
      </Fragment>
    );
  }
}
const mapDispatchToProps = {
  logout
};

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(AdminLoginPage);
