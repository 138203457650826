import { Button, Col, Modal, Row } from "antd";
import React, { Fragment } from "react";

const GenerateECodesModal = ({
  isVisible,
  onCancel,
  onGenerate,
  onDurationChange,
  onEcodeNumberChange,
  newEcodesInfoObj
}) => {
  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        closeIcon={
          <img
            src="/assets/close-black-icon.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        }
        footer={null}
        width={500}
      >
        <div
          style={{
            fontFamily: "OpenSans-Bold",
            fontSize: "1.3rem",
            paddingLeft: "1rem"
          }}
        >
          Generate E-Codes
        </div>

        <Row
          style={{
            fontFamily: "OpenSans-Regular",
            fontSize: "1rem",
            padding: "1rem"
          }}
        >
          <Col span={24} style={{ marginTop: "0.5rem" }}>
            <div
              style={{
                border: "1px solid rgba(112,112,112,0.4)",
                padding: "0.5rem",
                borderRadius: "1rem"
              }}
            >
              <div style={{ marginLeft: "0.6rem" }}>
                <span
                  style={{
                    fontSize: "0.8rem",
                    color: "#757575"
                  }}
                >
                  Number of E-Codes required
                </span>

                <input
                  placeholder="Enter number"
                  type="number"
                  style={{
                    width: "100%",
                    fontSize: "0.9rem",
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent"
                  }}
                  onChange={e => onEcodeNumberChange(e, "totalECodes")}
                />
              </div>
            </div>
          </Col>

          <Col span={24} style={{ marginTop: "0.5rem" }}>
            <div
              style={{
                border: "1px solid rgba(112,112,112,0.4)",
                padding: "0.5rem",
                borderRadius: "1rem"
              }}
            >
              <div style={{ marginLeft: "0.6rem" }}>
                <span
                  style={{
                    fontSize: "0.8rem",
                    color: "#757575"
                  }}
                >
                  Duration in days
                </span>

                <input
                  placeholder="Enter duration"
                  type="number"
                  style={{
                    width: "100%",
                    fontSize: "0.9rem",
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent"
                  }}
                  onChange={e => onDurationChange(e, "duration")}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row
          justify="end"
          style={{
            backgroundColor: "#F8F8F9",
            borderBottomRightRadius: "24px",
            borderBottomLeftRadius: "24px",
            padding: "1rem"
          }}
        >
          <Col>
            <div
              style={{
                padding: "0.4rem 2rem",
                fontFamily: "OpenSans-Bold",
                fontSize: "1rem",
                cursor: "pointer"
              }}
              onClick={onCancel}
            >
              CANCEL
            </div>
          </Col>
          <Col>
            <Button
              style={{
                borderRadius: "2rem",
                padding: "0.4rem 2rem",
                fontFamily: "OpenSans-Bold",
                fontSize: "1rem",
                height: "auto",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                backgroundColor: "#E1E1E1",
                marginLeft: "2rem"
              }}
              onClick={onGenerate}
              disabled={
                newEcodesInfoObj.duration === "" ||
                newEcodesInfoObj.totalECodes === ""
              }
            >
              Generate E-Codes
            </Button>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default GenerateECodesModal;
