import { Button, Col, Row, Spin, Menu, Layout } from "antd";
import React, { Component } from "react";
import { Fragment } from "react";
import "./Lesson.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadTargetedCourseLessonsScenes,
  resetTargetedCourseLessonsScenes
} from "./courseActions";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import { getTransText, _saveLessonProgress } from "../../SharedUtils";
// import { operationTypeEnum } from "../../OperationTypeConstant";
import _ from "lodash";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { withFirebase } from "react-redux-firebase";
import {
  CONTINUE,
  CHECKANSWER,
  LESSON,
  BACK,
  PREVIOUS
} from "../TranslationConstant";
import { updateLessonProgress } from "../../auth/authActions";
import {
  AppstoreOutlined,
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";

const { SubMenu } = Menu;
const { Sider } = Layout;
let audioElement = new Audio();

class Lesson extends Component {
  constructor(props) {
    super(props);
    this._saveLessonProgress = _saveLessonProgress.bind(this);
  }

  state = {
    showContent: 0,
    showPoint: 0,
    isLoading: true,
    scene: 0,
    checked: false,
    answerMapping: [],
    contentArray: [],
    isPlayingAudio: false,
    isAudioDownloadedLocally: false,
    clickDisabled: false,
    loading: false,
    collapsed: false,
    seenScene: [],
    defaultOpenMenu: false
  };

  async componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;
    let lessonId = match.params.lessonId;

    this.props.loadTargetedCourseLessonsScenes(courseId, lessonId);

    // console.log(auth.progressInfo.lessons[courseId][lessonId])
    this.setState({
      courseId,
      lessonId
    });
  }

  componentWillUnmount() {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.props.resetTargetedCourseLessonsScenes();
  }

  async componentDidUpdate(prevProps) {
    const { course, auth, operation } = this.props;
    const { courseId, lessonId } = this.state;
    if (
      !_.isEqual(
        prevProps.course.lessonScenesInfoObj,
        course.lessonScenesInfoObj
      ) ||
      (course.lessonScenesInfoObj !== null && this.state.isLoading) ||
      auth.userInfo.nativeLanguage !== prevProps.auth.userInfo.nativeLanguage
    ) {
      this.setState({
        seenScene:
          auth.progressInfo.lessons &&
          auth.progressInfo.lessons[courseId] &&
          auth.progressInfo.lessons[courseId][lessonId]
            ? auth.progressInfo.lessons[courseId][lessonId]
            : [course.lessonScenesInfoObj.lessonInfoObj[this.state.scene].docId]
      });
      // let { first, second, third } = lessonPageInit(
      //   course,
      //   auth,
      //   courseId,
      //   lessonId
      // );
      // console.log(first, second, third);
      // this.setState({
      //   targetedLessonList: first,
      //   showContent: second,
      //   isAllLessonsCompleted: third
      // });
      this.loadLessonScenes();
    }

    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      // if (
      //   operation.operationType === operationTypeEnum.NO_OPS ||
      //   (prevProps.operation.operationType ===
      //     operationTypeEnum.UPDATE_LESSON_PROGRESS &&
      //     operation.operationType === operationTypeEnum.NO_OPS)
      // ) {
      //   this.props.history.push(`/business-portal`);
      // }
    }
  }

  loadLessonScenes = () => {
    const { course, auth, translations } = this.props;
    const { scene, showContent } = this.state;

    var contentArray = [];
    if (
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .contentType !== 2
    ) {
      contentArray = getTransText(
        auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .content,
        translations.translationObj
      ).text.split("<br>");
    }
    // console.log(contentArray)
    if (
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .answers
    ) {
      const shuffeledAnswer = _.shuffle(
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .answers
      );
      const answerOrder = [];
      for (var i = 0; i < contentArray.length; i++) {
        if (contentArray[i].includes("&a")) {
          answerOrder.push(
            course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
              showContent
            ].answers[i]
          );
        } else {
          answerOrder.push("");
        }
      }

      this.setState({
        isLoading: false,
        shuffeledAnswer,
        contentArray,
        answerOrder
      });
    } else {
      this.setState({
        isLoading: false,
        contentArray
      });
    }
    // console.log(contentArray.length, showContent, this.state.showPoint, scene);
  };

  check = () => {
    this.setState({
      checked: !this.state.checked
    });
  };

  handleDrop = (e, index) => {
    const { shuffeledAnswer, answerMapping } = this.state;
    const mappingExist = this.state.answerMapping.find(
      mapping => mapping.answerBoxIndex === index
    );

    if (!mappingExist) {
      const newAnswerMapping = answerMapping;
      const newShuffledAnswers = shuffeledAnswer;

      newAnswerMapping.push({
        answerBoxIndex: index,
        answer: e.dragData.answer
      });

      //Removing answer from available answers-row with idx from dragData
      newShuffledAnswers.splice(e.dragData.idx, 1);

      this.setState({
        answerMapping: newAnswerMapping,
        shuffeledAnswer: newShuffledAnswers
      });
    }
  };

  generateArrayToPrint = content => {
    const splitted = content.split("&a");
    const answerBoxCount = (content.match(/&a/g) || []).length;

    let newArray = _.cloneDeep(splitted);
    let words = 0;
    for (let i = 0; i < answerBoxCount; i++) {
      if (splitted[i] === "") {
        newArray.splice(i, 1, "&a");
      } else {
        words++;
        newArray.splice(i + words, 0, "&a");
      }
    }
    return newArray;
  };

  onBackClick = () => {
    const { courseId, lessonId, seenScene } = this.state;
    this._saveLessonProgress(courseId, lessonId, seenScene, `/business-portal`);
    // this.props.history.push(`/business-portal`);
  };

  continue = () => {
    const { course, auth, translations } = this.props;
    const {
      scene,
      showContent,
      showPoint,
      contentArray,
      courseId,
      lessonId
    } = this.state;
    // console.log(showContent, showPoint, scene);
    if (
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .contentType === 1 &&
      showPoint < contentArray.length - 1
    ) {
      // console.log("A");
      this.setState({
        showPoint: this.state.showPoint + 1
      });
    } else if (
      this.state.showContent <
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents.length - 1
    ) {
      // console.log("B");

      var newContentArray = [];
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
          showContent + 1
        ].contentType !== 2
      ) {
        newContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent + 1
              ].content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent + 1
              ].content,
              translations.translationObj
            ).text
        ).split("<br>");
      }
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
          showContent + 1
        ].answers
      ) {
        // console.log("Ba");
        const shuffeledAnswer = _.shuffle(
          course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
            showContent + 1
          ].answers
        );

        const answerOrder = [];
        var answerIndex = -1;
        for (var i = 0; i < newContentArray.length; i++) {
          if (newContentArray[i].includes("&a")) {
            answerIndex += 1;
            answerOrder.push(
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent + 1
              ].answers[answerIndex]
            );
          } else {
            answerOrder.push("");
          }
        }
        audioElement.pause();
        audioElement.currentTime = 0;
        this.setState({
          showContent: this.state.showContent + 1,
          shuffeledAnswer,
          contentArray: newContentArray,
          checked: false,
          answerOrder,
          answerMapping: [],
          isPlayingAudio: false
        });
      } else {
        // console.log("Bb");
        audioElement.pause();
        audioElement.currentTime = 0;
        this.setState({
          showContent: this.state.showContent + 1,
          contentArray: newContentArray,
          showPoint: 0,
          checked: false,
          isPlayingAudio: false
        });
      }
    } else if (scene < course.lessonScenesInfoObj.lessonInfoObj.length - 1) {
      // console.log("C");
      var newSceneContentArray = [];
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
          .contentType === 1
      ) {
        newSceneContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
                .content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
                .content,
              translations.translationObj
            ).text
        ).split("<br>");
      } else if (
        course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
          .contentType === 3
      ) {
        newSceneContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
                .content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
                .content,
              translations.translationObj
            ).text
        ).split("<br>");

        // console.log(newContentArray)
      }
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0].answers
      ) {
        // console.log("Ca");
        const shuffeledAnswer = _.shuffle(
          course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
            .answers
        );
        // console.log(newContentArray);
        const answerOrder = [];
        answerIndex = -1;
        for (i = 0; i < newSceneContentArray.length; i++) {
          if (newSceneContentArray[i].includes("&a")) {
            answerIndex += 1;
            answerOrder.push(
              course.lessonScenesInfoObj.lessonInfoObj[scene + 1].contents[0]
                .answers[answerIndex]
            );
          } else {
            answerOrder.push("");
          }
        }
        audioElement.pause();
        audioElement.currentTime = 0;
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[this.state.scene + 1].docId
          ) !== -1
        ) {
          this.setState({
            scene: this.state.scene + 1,
            showContent: 0,
            shuffeledAnswer,
            showPoint: 0,
            contentArray: newSceneContentArray,
            answerOrder,
            answerMapping: [],
            checked: false,
            isPlayingAudio: false
          });
        } else {
          let seenScene = this.state.seenScene;
          seenScene.push(
            course.lessonScenesInfoObj.lessonInfoObj[this.state.scene + 1].docId
          );
          this.setState({
            scene: this.state.scene + 1,
            showContent: 0,
            shuffeledAnswer,
            showPoint: 0,
            contentArray: newSceneContentArray,
            answerOrder,
            answerMapping: [],
            checked: false,
            isPlayingAudio: false,
            seenScene
          });
        }
      } else {
        audioElement.pause();
        audioElement.currentTime = 0;
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[this.state.scene + 1].docId
          ) !== -1
        ) {
          this.setState({
            scene: this.state.scene + 1,
            showContent: 0,
            showPoint: 0,
            contentArray: newSceneContentArray,
            checked: false,
            isPlayingAudio: false
          });
        } else {
          let seenScene = this.state.seenScene;
          seenScene.push(
            course.lessonScenesInfoObj.lessonInfoObj[this.state.scene + 1].docId
          );
          this.setState({
            scene: this.state.scene + 1,
            showContent: 0,
            showPoint: 0,
            contentArray: newSceneContentArray,
            checked: false,
            isPlayingAudio: false,
            seenScene
          });
        }
        // console.log("Cb");
      }
    } else
      this._saveLessonProgress(
        courseId,
        lessonId,
        this.state.seenScene,
        `/business-portal/${courseId}/${lessonId}/lesson-completed`
      );
    // this.props.history.push(
    //   `/business-portal/${courseId}/${lessonId}/lesson-completed`
    // );
  };

  previous = () => {
    const { course, auth, translations } = this.props;
    var { scene, showContent, showPoint, contentArray } = this.state;
    console.log(
      showContent,
      showPoint,
      scene,
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .contentType,
      contentArray.length,
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents.length - 1
    );

    if (
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .contentType === 1 &&
      // showPoint < contentArray.length - 1 &&
      showPoint !== 0
    ) {
      console.log("A", showPoint);
      this.setState({
        showPoint: this.state.showPoint - 1
      });
    } else if (scene === 0 && showContent > 0) {
      showContent = showContent - 1;
      let newContentArray = [];
      let newShowPoint = 0;
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .contentType !== 2
      ) {
        newContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].content,
              translations.translationObj
            ).text
        ).split("<br>");

        newShowPoint = newContentArray.length - 1;
      }

      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .answers
      ) {
        const shuffeledAnswer = _.shuffle(
          course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
            .answers
        );

        const answerOrder = [];
        let answerIndex = -1;
        for (let i = 0; i < newContentArray.length; i++) {
          if (newContentArray[i].includes("&a")) {
            answerIndex += 1;
            answerOrder.push(
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].answers[answerIndex]
            );
          } else {
            answerOrder.push("");
          }
        }
        audioElement.pause();
        audioElement.currentTime = 0;
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[scene].docId
          ) !== -1
        ) {
          this.setState({
            scene,
            showContent,
            shuffeledAnswer,
            contentArray: newContentArray,
            checked: false,
            answerOrder,
            answerMapping: [],
            isPlayingAudio: false
          });
        } else {
          let seenScene = this.state.seenScene;
          seenScene.push(course.lessonScenesInfoObj.lessonInfoObj[scene].docId);
          this.setState({
            scene,
            showContent,
            shuffeledAnswer,
            contentArray: newContentArray,
            checked: false,
            answerOrder,
            answerMapping: [],
            isPlayingAudio: false,
            seenScene
          });
        }
      } else {
        console.log("E", showPoint);
        audioElement.pause();
        audioElement.currentTime = 0;
        // console.log("Db");
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[scene].docId
          ) !== -1
        ) {
          console.log("Ea1", showPoint);
          this.setState({
            scene,
            showContent,
            contentArray: newContentArray,
            // showPoint: 0,
            checked: false,
            isPlayingAudio: false,
            showPoint: newShowPoint
          });
        } else {
          console.log("Eb1", showPoint);
          let seenScene = this.state.seenScene;
          seenScene.push(course.lessonScenesInfoObj.lessonInfoObj[scene].docId);
          this.setState({
            scene,
            showContent,
            contentArray: newContentArray,
            showPoint: 0,
            checked: false,
            isPlayingAudio: false,
            seenScene
          });
        }
      }
    } else if (showContent === 0) {
      let newShowPoint = showPoint;
      let newContentArray = [];
      scene -= 1;
      showContent =
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents.length - 1;
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .contentType !== 2
      ) {
        newContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].content,
              translations.translationObj
            ).text
        ).split("<br>");

        newShowPoint = newContentArray.length - 1;
      }

      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .answers
      ) {
        const shuffeledAnswer = _.shuffle(
          course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
            .answers
        );

        const answerOrder = [];
        var answerIndex = -1;
        for (var i = 0; i < newContentArray.length; i++) {
          if (newContentArray[i].includes("&a")) {
            answerIndex += 1;
            answerOrder.push(
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].answers[answerIndex]
            );
          } else {
            answerOrder.push("");
          }
        }
        audioElement.pause();
        audioElement.currentTime = 0;
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[scene].docId
          ) !== -1
        ) {
          this.setState({
            scene,
            showContent,
            shuffeledAnswer,
            contentArray: newContentArray,
            checked: false,
            answerOrder,
            answerMapping: [],
            isPlayingAudio: false
          });
        } else {
          let seenScene = this.state.seenScene;
          seenScene.push(course.lessonScenesInfoObj.lessonInfoObj[scene].docId);
          this.setState({
            scene,
            showContent,
            shuffeledAnswer,
            contentArray: newContentArray,
            checked: false,
            answerOrder,
            answerMapping: [],
            isPlayingAudio: false,
            seenScene
          });
        }
      } else {
        console.log("E", showPoint);
        audioElement.pause();
        audioElement.currentTime = 0;
        // console.log("Db");
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[scene].docId
          ) !== -1
        ) {
          console.log("Ea1", showPoint);
          this.setState({
            scene,
            showContent,
            contentArray: newContentArray,
            // showPoint: 0,
            checked: false,
            isPlayingAudio: false,
            showPoint: newShowPoint
          });
        } else {
          console.log("Eb1", showPoint);
          let seenScene = this.state.seenScene;
          seenScene.push(course.lessonScenesInfoObj.lessonInfoObj[scene].docId);
          this.setState({
            scene,
            showContent,
            contentArray: newContentArray,
            showPoint: 0,
            checked: false,
            isPlayingAudio: false,
            seenScene
          });
        }
      }
    } else if (
      this.state.showContent <=
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents.length - 1
    ) {
      console.log("B", showContent, scene, showPoint);

      let newContentArray = [];

      showContent -= 1;
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .contentType !== 2
      ) {
        newContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].content,
              translations.translationObj
            ).text
        ).split("<br>");
      }

      // co
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .answers
      ) {
        console.log("Ba");
        const shuffeledAnswer = _.shuffle(
          course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
            .answers
        );

        const answerOrder = [];
        let answerIndex = -1;
        for (let i = 0; i < newContentArray.length; i++) {
          if (newContentArray[i].includes("&a")) {
            answerIndex += 1;
            answerOrder.push(
              course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                showContent
              ].answers[answerIndex]
            );
          } else {
            answerOrder.push("");
          }
        }
        audioElement.pause();
        audioElement.currentTime = 0;
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[scene].docId
          ) !== -1
        ) {
          this.setState({
            scene,
            showContent: showContent,
            shuffeledAnswer,
            contentArray: newContentArray,
            checked: false,
            answerOrder,
            answerMapping: [],
            isPlayingAudio: false
          });
        } else {
          let seenScene = this.state.seenScene;
          seenScene.push(course.lessonScenesInfoObj.lessonInfoObj[scene].docId);
          this.setState({
            scene,
            showContent: showContent,
            shuffeledAnswer,
            contentArray: newContentArray,
            checked: false,
            answerOrder,
            answerMapping: [],
            isPlayingAudio: false
          });
        }
      } else {
        console.log("Bb");
        audioElement.pause();
        audioElement.currentTime = 0;
        if (
          this.state.seenScene.indexOf(
            course.lessonScenesInfoObj.lessonInfoObj[this.state.scene - 1].docId
          ) !== -1
        ) {
          this.setState({
            scene,
            showContent,
            contentArray: newContentArray,
            showPoint: 0,
            checked: false,
            isPlayingAudio: false
          });
        } else {
          let seenScene = this.state.seenScene;
          seenScene.push(
            course.lessonScenesInfoObj.lessonInfoObj[this.state.scene - 1].docId
          );
          this.setState({
            scene,
            showContent,
            contentArray: newContentArray,
            showPoint: 0,
            checked: false,
            isPlayingAudio: false,
            seenScene
          });
        }
      }
    } else if (scene < course.lessonScenesInfoObj.lessonInfoObj.length - 1) {
      console.log("C");
      var newSceneContentArray = [];
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
          .contentType === 1
      ) {
        newSceneContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
                .content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
                .content,
              translations.translationObj
            ).text
        ).split("<br>");
      } else if (
        course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
          .contentType === 3
      ) {
        newSceneContentArray = (auth.userInfo.nativeLanguage
          ? getTransText(
              auth.userInfo.nativeLanguage,
              course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
                .content,
              translations.translationObj
            ).text
          : getTransText(
              "en",
              course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
                .content,
              translations.translationObj
            ).text
        ).split("<br>");

        // console.log(newContentArray)
      }
      if (
        course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0].answers
      ) {
        console.log("Ca");
        const shuffeledAnswer = _.shuffle(
          course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
            .answers
        );
        // console.log(newContentArray);
        const answerOrder = [];
        answerIndex = -1;
        for (i = 0; i < newSceneContentArray.length; i++) {
          if (newSceneContentArray[i].includes("&a")) {
            answerIndex += 1;
            answerOrder.push(
              course.lessonScenesInfoObj.lessonInfoObj[scene - 1].contents[0]
                .answers[answerIndex]
            );
          } else {
            answerOrder.push("");
          }
        }
        audioElement.pause();
        audioElement.currentTime = 0;

        this.setState({
          scene: this.state.scene - 1,
          showContent: 0,
          shuffeledAnswer,
          showPoint: 0,
          contentArray: newSceneContentArray,
          answerOrder,
          answerMapping: [],
          checked: false,
          isPlayingAudio: false
        });
      } else {
        audioElement.pause();
        audioElement.currentTime = 0;

        this.setState({
          scene: this.state.scene - 1,
          showContent: 0,
          showPoint: 0,
          contentArray: newSceneContentArray,
          checked: false,
          isPlayingAudio: false
        });

        console.log("Cb");
      }
    }
  };

  loadScene = (scene, docId) => {
    // console.log("here", scene);
    const { course, auth, translations } = this.props;

    let showContent = 0;
    var contentArray = [];
    if (
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .contentType !== 2
    ) {
      contentArray = getTransText(
        auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .content,
        translations.translationObj
      ).text.split("<br>");
    }
    // console.log(contentArray)
    if (
      course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
        .answers
    ) {
      const shuffeledAnswer = _.shuffle(
        course.lessonScenesInfoObj.lessonInfoObj[scene].contents[showContent]
          .answers
      );
      const answerOrder = [];
      for (var i = 0; i < contentArray.length; i++) {
        if (contentArray[i].includes("&a")) {
          answerOrder.push(
            course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
              showContent
            ].answers[i]
          );
        } else {
          answerOrder.push("");
        }
      }
      // console.log(contentArray, scene, showContent);
      if (this.state.seenScene.indexOf(docId) !== -1) {
        this.setState({
          shuffeledAnswer,
          contentArray,
          answerOrder,
          scene,
          showContent
        });
      } else {
        let seenScene = this.state.seenScene;
        seenScene.push(docId);
        this.setState({
          shuffeledAnswer,
          contentArray,
          answerOrder,
          scene,
          seenScene,
          showContent
        });
      }
    } else {
      // console.log(contentArray, scene, showContent);
      if (this.state.seenScene.indexOf(docId) !== -1) {
        this.setState({
          contentArray,
          scene,
          showContent,
          showPoint: 0
        });
      } else {
        let seenScene = this.state.seenScene;
        seenScene.push(docId);
        this.setState({
          contentArray,
          scene,
          seenScene,
          showContent,
          showPoint: 0
        });
      }
    }
    // console.log(contentArray);
  };

  onHandleSoundIconClick = () => {
    const { course, auth } = this.props;
    const { scene, showContent } = this.state;

    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      // if (isAudioDownloadedLocally) {
      //   this.setState(
      //     {
      //       loading: true,
      //       clickDisabled: true
      //     },
      //     () => {
      //       this.setState(
      //         {
      //           isPlayingAudio: true,
      //           loading: false,
      //           clickDisabled: false
      //         },
      //         () => {
      //           audioElement.play();
      //         }
      //       );
      //     }
      //   );
      // } else {
      this.setState(
        {
          clickDisabled: true,
          loading: true
        },
        async () => {
          //get voice over url
          if (
            course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
              showContent
            ].voiceoverPath[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ].length > 0
          ) {
            let vUrl = await storageRef
              .child(
                course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                  showContent
                ].voiceoverPath[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              )
              .getDownloadURL();

            let downloadPromise = new Promise((resolve, reject) => {
              let xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.onload = function(event) {
                if (xhr.status === 200) {
                  resolve(xhr.response);
                } else {
                  reject(xhr.status);
                }
              };
              xhr.open("GET", vUrl);
              xhr.send();
            });

            let downloadedBlob = await downloadPromise;

            audioElement.addEventListener("ended", this.onAudioEnded);
            this.setState(
              {
                isAudioDownloadedLocally: true,
                isPlayingAudio: true,
                loading: false,
                clickDisabled: false
              },
              () => {
                audioElement.src = URL.createObjectURL(downloadedBlob);
                audioElement.play();
              }
            );
          }
        }
      );
      // }
    } else {
      //resume playing

      audioElement.play();
      this.setState({
        isPlayingAudio: true
      });
    }
  };

  onAudioEnded = () => {
    this.setState({
      isPlayingAudio: false
    });
  };

  onHandlePauseIconClick = () => {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.setState({
      isPlayingAudio: false
    });
  };

  lessonClick = async (courseId, lessonId) => {
    if (
      !window.location.href.includes(`/business-portal/${courseId}/${lessonId}`)
    ) {
      this.props.history.push(`/business-portal/${courseId}/${lessonId}`);
      await this._saveLessonProgress(
        courseId,
        this.state.lessonId,
        this.state.seenScene
      );
      this.setState({
        showContent: 0,
        showPoint: 0,
        isLoading: true,
        scene: 0,
        checked: false,
        answerMapping: [],
        contentArray: [],
        isPlayingAudio: false,
        isAudioDownloadedLocally: false,
        clickDisabled: false,
        loading: false,
        courseId: courseId,
        lessonId: lessonId,
        defaultOpenMenu: true
      });
      audioElement.pause();
      audioElement.currentTime = 0;

      await this.props.loadTargetedCourseLessonsScenes(courseId, lessonId);
      this.loadLessonScenes();
    }
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const {
      isLoading,
      scene,
      showContent,
      shuffeledAnswer,
      contentArray,
      checked,
      loading,
      isPlayingAudio,
      clickDisabled,
      seenScene,
      defaultOpenMenu
    } = this.state;
    const { course, translations, auth } = this.props;

    let answerBoxIndexCounter = 0;

    if (isLoading) return <LoadingComponent auth={auth} />;

    return (
      <Fragment>
        <Layout style={{ minHeight: "100vh", backgroundColor: "#5E51A1" }}>
          <Row>
            <Col xs={0} xl={24}>
              <Sider
                width={280}
                className="left-sidebar"
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.toggleCollapsed}
              >
                {!this.state.collapsed && (
                  <Button
                    icon={<LeftOutlined />}
                    className="lesson-back-button"
                    onClick={this.onBackClick}
                    style={{ border: "unset", padding: "40px 0px 0px 40px" }}
                  >
                    {
                      BACK[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                  </Button>
                )}

                <br />
                <br />
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultOpenKeys={defaultOpenMenu ? [this.state.courseId] : []}
                  style={{
                    backgroundColor: "#5E51A1",
                    font: "14px Poppins-Bold",
                    color: "#c6c7c7"
                  }}
                  defaultSelectedKeys={[
                    `${this.state.courseId}${this.state.lessonId}`
                  ]}
                  // selectedKeys={[location.pathname]}
                >
                  <SubMenu
                    icon={<AppstoreOutlined />}
                    key={course.lessonScenesInfoObj.courseInfoObj.docId}
                    title={
                      getTransText(
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en",
                        course.lessonScenesInfoObj.courseInfoObj.name,
                        translations.translationObj
                      ).text
                    }
                  >
                    {course.lessonScenesInfoObj.lessonInfoList.map(lesson => (
                      <Menu.Item
                        className="customclass"
                        key={`${course.lessonScenesInfoObj.courseInfoObj.docId}${lesson.docId}`}
                        style={{ color: "white" }}
                        onClick={() =>
                          this.lessonClick(
                            course.lessonScenesInfoObj.courseInfoObj.docId,
                            lesson.docId
                          )
                        }
                      >
                        {
                          LESSON[
                            auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                          ]
                        }{" "}
                        {
                          getTransText(
                            auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en",
                            lesson.name,
                            translations.translationObj
                          ).text
                        }
                      </Menu.Item>
                    ))}
                  </SubMenu>
                </Menu>
              </Sider>
            </Col>
          </Row>
          <Layout className="site-layout">
            <Row className="lesson-row">
              <Col xs={24} xl={16} className="lesson-container">
                <div className="lesson-title-row">
                  <div className="lesson-lesson-title">
                    {
                      getTransText(
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en",
                        course.lessonScenesInfoObj.courseInfoObj.name,
                        translations.translationObj
                      ).text
                    }
                  </div>
                  {/* {console.log(
                    course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                      showContent
                    ]
                  )} */}
                  {course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                    showContent
                  ].voiceoverPath &&
                  course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                    showContent
                  ].voiceoverPath[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ] ? (
                    loading ? (
                      <Spin />
                    ) : isPlayingAudio ? (
                      <img
                        onClick={this.onHandlePauseIconClick}
                        src="/assets/businessPortal/pause-button.png"
                        alt="Pause Button"
                        className="lesson-audio-button"
                      />
                    ) : (
                      <img
                        onClick={
                          clickDisabled ? null : this.onHandleSoundIconClick
                        }
                        src="/assets/businessPortal/play-button.png"
                        alt="Play Button"
                        className="lesson-audio-button"
                      />
                    )
                  ) : null}
                </div>
                <div
                  className="lesson-scene-title"
                  dangerouslySetInnerHTML={{
                    __html: getTransText(
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en",
                      course.lessonScenesInfoObj.lessonInfoObj[scene].title,
                      translations.translationObj
                    ).text
                  }}
                ></div>
                {course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                  showContent
                ].contentType === 3 ? (
                  <>
                    {contentArray.map((content, index) => {
                      return (
                        <div key={index} className="lesson-description">
                          {this.generateArrayToPrint(content).map(
                            (string, idx) => {
                              if (string === "&a") {
                                let currentBoxIndex = answerBoxIndexCounter;
                                answerBoxIndexCounter++;

                                //Check isAnswerBoxOccupied with correct index
                                var isAnswerBoxOccupied = this.state.answerMapping.find(
                                  mapping =>
                                    mapping.answerBoxIndex === currentBoxIndex
                                );

                                const answers =
                                  course.lessonScenesInfoObj.lessonInfoObj[
                                    scene
                                  ].contents[showContent].answers;

                                return isAnswerBoxOccupied ? (
                                  checked ? (
                                    <Fragment key={idx}>
                                      {isAnswerBoxOccupied.answer ===
                                        answers[currentBoxIndex] ||
                                      getTransText(
                                        auth.userInfo.nativeLanguage
                                          ? auth.userInfo.nativeLanguage
                                          : "en",
                                        isAnswerBoxOccupied.answer,
                                        translations.translationObj
                                      ).text ===
                                        getTransText(
                                          auth.userInfo.nativeLanguage
                                            ? auth.userInfo.nativeLanguage
                                            : "en",
                                          answers[currentBoxIndex],
                                          translations.translationObj
                                        ).text ? (
                                        <div className="lesson-answer correct">
                                          {
                                            getTransText(
                                              auth.userInfo.nativeLanguage
                                                ? auth.userInfo.nativeLanguage
                                                : "en",
                                              isAnswerBoxOccupied.answer,
                                              translations.translationObj
                                            ).text
                                          }
                                        </div>
                                      ) : (
                                        <div className="lesson-answer wrong">
                                          {
                                            getTransText(
                                              auth.userInfo.nativeLanguage
                                                ? auth.userInfo.nativeLanguage
                                                : "en",
                                              isAnswerBoxOccupied.answer,
                                              translations.translationObj
                                            ).text
                                          }
                                        </div>
                                      )}
                                    </Fragment>
                                  ) : (
                                    <div className="lesson-answer" key={idx}>
                                      {
                                        getTransText(
                                          auth.userInfo.nativeLanguage
                                            ? auth.userInfo.nativeLanguage
                                            : "en",
                                          isAnswerBoxOccupied.answer,
                                          translations.translationObj
                                        ).text
                                      }
                                    </div>
                                  )
                                ) : (
                                  <DropTarget
                                    key={idx}
                                    targetKey="answer"
                                    onHit={e => {
                                      this.handleDrop(e, currentBoxIndex);
                                    }}
                                  >
                                    <div className="answer-box" />
                                  </DropTarget>
                                );
                              } else {
                                return (
                                  <Fragment key={idx}>
                                    {index !== 0 && idx === 0 ? <br /> : ""}
                                    {string}
                                  </Fragment>
                                );
                              }
                            }
                          )}
                        </div>
                      );
                    })}
                    <div className="lesson-answer-row">
                      {shuffeledAnswer.map((answer, idx) => {
                        return (
                          <DragDropContainer
                            key={idx}
                            targetKey="answer"
                            dragData={{ answer, idx }}
                          >
                            <div className="lesson-answer">
                              {
                                getTransText(
                                  auth.userInfo.nativeLanguage
                                    ? auth.userInfo.nativeLanguage
                                    : "en",
                                  answer,
                                  translations.translationObj
                                ).text
                              }
                            </div>
                          </DragDropContainer>
                        );
                      })}
                    </div>
                  </>
                ) : course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                    showContent
                  ].contentType === 1 ? (
                  contentArray.map((content, index) => (
                    <div key={index}>
                      {index <= this.state.showPoint ? (
                        <Fragment key={index}>
                          {index !== 0 && <br />}
                          <div
                            className="lesson-description"
                            dangerouslySetInnerHTML={{
                              __html: auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    content,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    content,
                                    translations.translationObj
                                  ).text
                            }}
                          ></div>
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                ) : (
                  <span
                    className="lesson-description"
                    dangerouslySetInnerHTML={{
                      __html: auth.userInfo.nativeLanguage
                        ? getTransText(
                            auth.userInfo.nativeLanguage,
                            course.lessonScenesInfoObj.lessonInfoObj[scene]
                              .contents[showContent].content,
                            translations.translationObj
                          ).text
                        : getTransText(
                            "en",
                            course.lessonScenesInfoObj.lessonInfoObj[scene]
                              .contents[showContent].content,
                            translations.translationObj
                          ).text
                    }}
                  />
                )}
              </Col>
              <Col xs={24} xl={8} className="lesson-right-sidebar">
                {
                  LESSON[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }{" "}
                {course.lessonScenesInfoObj.lessonInfoList.map(
                  lesson =>
                    lesson.docId === this.state.lessonId &&
                    getTransText(
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en",
                      lesson.name,
                      translations.translationObj
                    ).text
                )}
                <br />
                <Row
                  style={{ padding: "1rem" }}
                  justify="center"
                  align="middle"
                >
                  <Col
                    xs={2}
                    sm={2}
                    md={3}
                    lg={4}
                    xl={2}
                    style={{ textAlign: "end" }}
                  >
                    {/* {console.log(clickDisabled, scene === 0, showContent === 0)} */}
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        borderWidth: "0px"
                      }}
                      disabled={
                        clickDisabled ||
                        (scene === 0 && showContent === 0 && this.state.showPoint === 0)
                      }
                      icon={<LeftOutlined style={{ height: "1rem" }} />}
                      onClick={this.previous}
                    ></Button>
                  </Col>
                  <Col xs={1} />
                  <Col xs={18} sm={18} md={16} lg={14} xl={18}>
                    {/* {console.log(seenScene)} */}
                    {course.lessonScenesInfoObj.lessonInfoObj.map(
                      (lessonScene, idx) => (
                        <Button
                          key={idx}
                          style={{
                            height: "1rem",
                            width: "1px",
                            backgroundColor:
                              scene === idx
                                ? "yellow"
                                : seenScene.includes(lessonScene.docId)
                                ? "#5E51A1"
                                : "lightgray"
                          }}
                          disabled={clickDisabled}
                          onClick={() => this.loadScene(idx, lessonScene.docId)}
                        >
                          &nbsp;
                        </Button>
                      )
                    )}
                  </Col>
                  <Col xs={1} />
                  <Col
                    xs={2}
                    sm={2}
                    md={3}
                    lg={4}
                    xl={2}
                    style={{ textAlign: "start" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        borderWidth: "0px"
                      }}
                      disabled={
                        clickDisabled ||
                        scene ===
                          course.lessonScenesInfoObj.lessonInfoObj.length - 1
                      }
                      icon={<RightOutlined style={{ height: "1rem" }} />}
                      onClick={this.continue}
                    ></Button>
                  </Col>
                </Row>
                <br />
                {course.lessonScenesInfoObj.lessonInfoObj[scene].contents[
                  showContent
                ].contentType === 3 && !checked ? (
                  <Button
                    className="lesson-continue-button"
                    onClick={this.check}
                    disabled={
                      this.state.answerMapping.length !==
                      (auth.userInfo.nativeLanguage
                        ? getTransText(
                            auth.userInfo.nativeLanguage,
                            course.lessonScenesInfoObj.lessonInfoObj[scene]
                              .contents[showContent].content,
                            translations.translationObj
                          ).text
                        : getTransText(
                            "en",
                            course.lessonScenesInfoObj.lessonInfoObj[scene]
                              .contents[showContent].content,
                            translations.translationObj
                          ).text
                      ).match(/&a/g).length
                    }
                  >
                    {
                      CHECKANSWER[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                  </Button>
                ) : (
                  <>
                    {showContent ===
                      (contentArray > 0 ? contentArray.length - 1 : 0) &&
                    seenScene.length !==
                      course.lessonScenesInfoObj.lessonInfoObj.length &&
                    scene ===
                      course.lessonScenesInfoObj.lessonInfoObj.length - 1 ? (
                      <Button
                        disabled={clickDisabled}
                        className="lesson-continue-button"
                        onClick={this.previous}
                      >
                        {
                          PREVIOUS[
                            auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                          ]
                        }
                      </Button>
                    ) : (
                      <Button
                        disabled={clickDisabled}
                        className="lesson-continue-button"
                        onClick={this.continue}
                      >
                        {
                          CONTINUE[
                            auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                          ]
                        }
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Layout>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations,
  operation: state.operation
});

const mapDispatchToProps = {
  loadTargetedCourseLessonsScenes,
  resetTargetedCourseLessonsScenes,
  updateLessonProgress
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Lesson);
