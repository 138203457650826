import { Col, Divider, Row, Spin } from "antd";
import React, { Component } from "react";
import { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadAllCourse,
  resetAllCourse,
} from "../BusinessPortal/lessons/courseActions";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { getTransText } from "../SharedUtils";
import moment from "moment";

import { withFirebase } from "react-redux-firebase";

import "./HomePage.less";
import _ from "lodash";
import {
  LEARNINGPATH,
  COURSES,
  COURSETABTITLE,
  GOODMORNING,
  GOODEVENING,
  GOODAFTERNOON,
  WELCOMETOBUSINESSPORTAL,
  POWERTOBELIEVE,
} from "./TranslationConstant";
let audioElement = new Audio();
class HomePage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.loadAllCourse();
  }

  componentWillUnmount() {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.props.resetAllCourse();
  }

  loadCourse = async () => {
    const { course, firebase, auth } = this.props;
    const storageRef = firebase.storage().ref();

    require("moment/min/locales.min");
    var format = "dddd, MMM D YYYY";
    let language = "en";
    var curHr = new Date().getHours();
    let imageUrlPromises = [];
    course.courseInfoObj &&
      course.courseInfoObj.forEach((item) => {
        if (item.imagePath && item.imagePath.length > 0) {
          imageUrlPromises.push(
            storageRef.child(item.imagePath).getDownloadURL()
          );
        }
      });

    let imageUrl = await Promise.all(imageUrlPromises);

    switch (auth.userInfo.nativeLanguage) {
      case "ms":
        language = "ms-my";
        break;
      case "cs":
        language = "zh-cn";
        break;
      case "ct":
        language = "zh-tw";
        break;
      case "in":
        language = "id";
        break;
      case "th":
        language = "th";
        break;
      case "en":
      default:
        language = "en";
    }
    let today = moment().locale(language).format(format);

    let currentTime;

    curHr < 12
      ? (currentTime =
          GOODMORNING[
            auth.userInfo
              ? auth.userInfo.nativeLanguage
                ? auth.userInfo.nativeLanguage
                : "en"
              : "en"
          ])
      : curHr < 15
      ? (currentTime =
          GOODAFTERNOON[
            auth.userInfo
              ? auth.userInfo.nativeLanguage
                ? auth.userInfo.nativeLanguage
                : "en"
              : "en"
          ])
      : (currentTime =
          GOODEVENING[
            auth.userInfo
              ? auth.userInfo.nativeLanguage
                ? auth.userInfo.nativeLanguage
                : "en"
              : "en"
          ]);
    this.onHandlePauseIconClick();
    course.courseInfoObj &&
      this.setState({
        isLoading: false,
        today,
        currentTime,
        imageUrl,
      });
  };

  async componentDidUpdate(prevProps) {
    const { course, auth } = this.props;
    if (
      !_.isEqual(prevProps.course.courseInfoObj, course.courseInfoObj) ||
      (course.courseInfoObj !== null && this.state.isLoading) ||
      auth.userInfo.nativeLanguage !== prevProps.auth.userInfo.nativeLanguage
    ) {
      // console.log("here");
      this.loadCourse();
    }
  }

  onCourseClick = (courseId) => {
    this.props.history.push(`/business-portal/${courseId}/choose-lesson`);
  };

  onHandleSoundIconClick = () => {
    const { course, auth } = this.props;
    // const { scene, showContent } = this.state;

    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      this.setState(
        {
          clickDisabled: true,
          loading: true,
        },
        async () => {
          //get voice over url
          if (
            course.powerToBelieve.contents[0].voiceoverPath[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ].length > 0
          ) {
            let vUrl = await storageRef
              .child(
                course.powerToBelieve.contents[0].voiceoverPath[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              )
              .getDownloadURL();

            let downloadPromise = new Promise((resolve, reject) => {
              let xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.onload = function (event) {
                if (xhr.status === 200) {
                  resolve(xhr.response);
                } else {
                  reject(xhr.status);
                }
              };
              xhr.open("GET", vUrl);
              xhr.send();
            });

            let downloadedBlob = await downloadPromise;

            audioElement.addEventListener("ended", this.onAudioEnded);
            this.setState(
              {
                isAudioDownloadedLocally: true,
                isPlayingAudio: true,
                loading: false,
                clickDisabled: false,
              },
              () => {
                audioElement.src = URL.createObjectURL(downloadedBlob);
                audioElement.play();
              }
            );
          }
        }
      );
    } else {
      //resume playing

      audioElement.play();
      this.setState({
        isPlayingAudio: true,
      });
    }
  };

  onAudioEnded = () => {
    this.setState({
      isPlayingAudio: false,
    });
  };

  onHandlePauseIconClick = () => {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.setState({
      isPlayingAudio: false,
    });
  };

  // popularCourses = [
  //   { lessonId: 1, imgPath: "/assets/businessPortal/marketing.png" },
  //   { lessonId: 2, imgPath: "/assets/businessPortal/business-speaking.png" },
  // ];

  // recommendedCourses = [
  //   { lessonId: 3, imgPath: "/assets/businessPortal/business-speaking.png" },
  //   { lessonId: 4, imgPath: "/assets/businessPortal/network-marketing.png" },
  //   {
  //     lessonId: 0,
  //     imgPath: "/assets/businessPortal/professional-selling-skill.png",
  //   },
  //   { lessonId: 0, imgPath: "/assets/businessPortal/yearly-income.png" },
  //   {
  //     lessonId: 0,
  //     imgPath: "/assets/businessPortal/pro-selling-skill-quizzes.png",
  //   },
  // ];

  render() {
    const { course, auth, translations } = this.props;
    const {
      isLoading,
      imageUrl,
      today,
      currentTime,
      isPlayingAudio,
      clickDisabled,
      loading,
    } = this.state;
    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row style={{ backgroundColor: "#F8F8F8" }}>
          <Col xs={2} xl={4} />
          <Col xs={20} xl={16}>
            <Row className="home-rows">
              <Col xs={24}>
                <div className="home-title">
                  {
                    WELCOMETOBUSINESSPORTAL[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                </div>
                <div className="home-date">{today}</div>
                <div className="home-title2">
                  {currentTime}
                  {auth && auth.userInfo && auth.userInfo.name ? (
                    <> , {auth.userInfo.name} </>
                  ) : null}
                </div>
                <Row align="middle">
                  <Col xs={20}>
                    <div className="home-quote-title">
                      {course.powerToBelieve &&
                        `"${
                          getTransText(
                            auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en",
                            course.powerToBelieve.title,
                            translations.translationObj
                          ).text
                        }"`}
                    </div>
                  </Col>
                  <Col xs={4} style={{ textAlign: "end" }}>
                    {course.powerToBelieve.contents[0].voiceoverPath &&
                    course.powerToBelieve.contents[0].voiceoverPath[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ] ? (
                      loading ? (
                        <Spin />
                      ) : isPlayingAudio ? (
                        <img
                          onClick={this.onHandlePauseIconClick}
                          src="/assets/businessPortal/pause-button.png"
                          alt="Pause Button"
                          className="lesson-audio-button"
                        />
                      ) : (
                        <img
                          onClick={
                            clickDisabled ? null : this.onHandleSoundIconClick
                          }
                          src="/assets/businessPortal/play-button.png"
                          alt="Play Button"
                          className="lesson-audio-button"
                        />
                      )
                    ) : null}
                  </Col>
                </Row>

                <div
                  className="home-quote-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      course.powerToBelieve &&
                      getTransText(
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en",
                        course.powerToBelieve.contents[0].content,
                        translations.translationObj
                      ).text,
                  }}
                ></div>
                <Divider orientation="right" className="home-title2">
                  {
                    POWERTOBELIEVE[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                </Divider>
              </Col>
            </Row>
          </Col>
          <Col xs={2} xl={4} />
        </Row>
        <Row className="home-rows">
          <Col xs={2} xl={4} />
          <Col xs={20} xl={16}>
            <div className="home-row2-title">
              {
                COURSETABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>

            <Row gutter={[30, 30]} className="home-category-row">
              {course.courseInfoObj.map(
                (courseInfo, idx) =>
                  courseInfo.audio && (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      key={idx}
                      onClick={() => this.onCourseClick(courseInfo.docId)}
                    >
                      <Row className="home-card">
                        <Col xs={24} md={12}>
                          <div className="home-card-half">
                            <div>
                              <div className="home-card-title">
                                {
                                  LEARNINGPATH[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </div>
                              <div className="home-card-title2">
                                {auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      courseInfo.name,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      courseInfo.name,
                                      translations.translationObj
                                    ).text}
                              </div>
                            </div>
                            <div>
                              <div className="home-card-title">
                                {courseInfo.lessons.length}{" "}
                                {
                                  COURSES[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <img
                            src={imageUrl[idx]}
                            alt=""
                            className="home-card-img"
                          />
                        </Col>
                      </Row>
                    </Col>
                  )
              )}
            </Row>

            <hr className="ebook-divider" />

            <Row gutter={[30, 30]} className="home-category-row">
              {course.courseInfoObj.map(
                (courseInfo, idx) =>
                  !courseInfo.audio && (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      key={idx}
                      onClick={() => this.onCourseClick(courseInfo.docId)}
                    >
                      <Row className="home-card">
                        <Col xs={24} md={12}>
                          <div className="home-card-half">
                            <div>
                              <div className="home-card-title">
                                {
                                  LEARNINGPATH[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </div>
                              <div className="home-card-title2">
                                {auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      courseInfo.name,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      courseInfo.name,
                                      translations.translationObj
                                    ).text}
                              </div>
                            </div>
                            <div>
                              <div className="home-card-title">
                                {courseInfo.lessons.length}{" "}
                                {
                                  COURSES[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <img
                            src={imageUrl[idx]}
                            alt=""
                            className="home-card-img"
                          />
                        </Col>
                      </Row>
                    </Col>
                  )
              )}
            </Row>
          </Col>

          <Col xs={2} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations,
  // general: state.general
});

const mapDispatchToProps = {
  loadAllCourse,
  resetAllCourse,
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HomePage);
