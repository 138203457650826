import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  notification
} from "antd";
import React, { Component, Fragment } from "react";
import GenerateECodesModal from "./modals/GenerateECodesModal";
import Moment from "moment";
import { loadAllECodes, generateEcodes } from "./ecode/ecodeActions";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from "@ant-design/icons";
import FileSaver from "file-saver";
import { withFirebase } from "react-redux-firebase";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

class GenerateECodesPage extends Component {
  state = {
    isGenerateECodesModalVisible: false,
    isLoading: true,
    newEcodesInfoObj: { duration: "", totalECodes: "" },
    reset: false
  };

  componentDidMount = () => {
    this.props.loadAllECodes();
  };

  componentDidUpdate(prevProps) {
    const { ecodesRecords } = this.props;
    if (
      !_.isEqual(
        prevProps.ecodesRecords.ecodeGenerationInfoObj,
        ecodesRecords.ecodeGenerationInfoObj
      ) ||
      (ecodesRecords.ecodeGenerationInfoObj !== null && this.state.isLoading)
    ) {
      let loadedECodeData = [];
      let totalCodes = 0;
      console.log(ecodesRecords.ecodeGenerationInfoObj);
      if (ecodesRecords.ecodeGenerationInfoObj) {
        ecodesRecords.ecodeGenerationInfoObj.forEach((ecodesRecord, idx) => {
          console.log(ecodesRecord);
          totalCodes += parseInt(ecodesRecord.totalECodes);
          loadedECodeData.push({
            key: idx,
            userName: ecodesRecord.createdBy,
            projectName: ecodesRecord.projectName,
            createdAt: Moment(
              new Date(ecodesRecord.createdAt.seconds * 1000)
            ).format("MMMM Do YYYY, h:mm a"),
            csvFilePath: ecodesRecord.csvFilePath,
            totalECodes: ecodesRecord.totalECodes
          });
        });
        // console.log(loadedECodeData);
        this.setState({
          isLoading: false,
          loadedECodeData,
          totalCodes
        });
      }
    }

    if (
      !ecodesRecords.loading &&
      ecodesRecords.generateEcodesSuccess &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully generated E-Codes",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        )
      });
      this.props.loadAllECodes();
      this.setState({
        isGenerateECodesModalVisible: false,
        reset: false,
        isLoading: true
      });
    } else if (
      !ecodesRecords.loading &&
      ecodesRecords.generateEcodesFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: ecodesRecords.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        )
      });
      this.setState({
        reset: false
      });
    }
  }

  onHandleGenerateECodePencilIconClick = record => {
    const { ecodesRecords } = this.props;
    // console.log(ecodesRecords.ecodeGenerationInfoObj[record.key]);
    const eCodeBundleId =
      ecodesRecords.ecodeGenerationInfoObj[record.key].docId;
    this.props.history.push(`/console/generate-e-codes/${eCodeBundleId}`);
  };

  onHandleGenerateECodesButtonClick = () => {
    this.setState({
      isGenerateECodesModalVisible: true
    });
  };

  onHandleGenerateECodesCancelButtonClick = () => {
    this.setState({
      isGenerateECodesModalVisible: false,
      newEcodesInfoObj: { duration: "", totalECodes: "" }
    });
  };

  onHandleGenerateECodesGenerateButtonClick = async () => {
    const { newEcodesInfoObj } = this.state;
    notification.open({
      key,
      message: "Generating E-Codes",
      duration: 0,
      icon: <LoadingOutlined />
    });
    this.props.generateEcodes(newEcodesInfoObj);
    this.setState({
      reset: true
    });
  };

  onHandleGenerateECodeDownloadIconClick = async record => {
    const { ecodesRecords } = this.props;
    console.log(ecodesRecords.ecodeGenerationInfoObj[record.key]);
    const ecodeList =
      ecodesRecords.ecodeGenerationInfoObj[record.key].ecodeList;
    let json = [];
    ecodeList.forEach(ecode => {
      let dataSet = {
        code: ecode.code,
        duration: ecode.durationInDays,
        status: ecode.status
      };
      json.push(dataSet);
    });
    let JsonFields = ["code", "duration", "status"];

    let csv = JsonFields.join(",") + "\n";

    json.forEach(element => {
      let code = element.code;
      let duration = element.duration;
      let status = element.status;

      csv += code + "," + duration + "," + status + "\n";
    });
    // console.log(csv);

    const csvData = new Blob([csv], {
      type: "text/csv;charset=utf-8;"
    });

    FileSaver.saveAs(csvData, `ecodes.csv`);
  };

  onDurationChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState(prevState => ({
      newEcodesInfoObj: {
        ...prevState.newEcodesInfoObj,
        [inputField]: inputVal
      }
    }));
  };

  onEcodeNumberChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState(prevState => ({
      newEcodesInfoObj: {
        ...prevState.newEcodesInfoObj,
        [inputField]: inputVal
      }
    }));
  };

  onHandleProjectSelectionMenuClick = ({ key }) => {
    const { general } = this.props;
    general.projectList.forEach(project => {
      if (project.projectId === key) {
        this.setState(prevState => ({
          newEcodesInfoObj: {
            ...prevState.newEcodesInfoObj,
            project: project
          }
        }));
      }
    });
  };

  render() {
    const {
      isGenerateECodesModalVisible,
      isLoading,
      loadedECodeData,
      newEcodesInfoObj,
      totalCodes
    } = this.state;
    const { general } = this.props;
    if (isLoading) return <LoadingComponent />;
    const eCodeColumns = [
      {
        title: "User",
        dataIndex: "userName",
        key: "userName"
      },
      {
        title: "Number of E-Codes",
        dataIndex: "totalECodes",
        key: "totalECodes",
        align: "center"
      },
      {
        title: "Generated Date",
        dataIndex: "createdAt",
        key: "createdAt"
      },
      {
        title: "",
        dataIndex: "downloadAction",
        key: "downloadAction",
        render: (_, record) => (
          <div style={{ textAlign: "end", marginRight: "2rem" }}>
            <Space size={32}>
              <img
                src="/assets/pencil-icon.svg"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.onHandleGenerateECodePencilIconClick(record)
                }
              />
              <img
                src="/assets/download-icon.svg"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.onHandleGenerateECodeDownloadIconClick(record)
                }
              />
            </Space>
          </div>
        )
      }
    ];
    return (
      <Fragment>
        <GenerateECodesModal
          isVisible={isGenerateECodesModalVisible}
          onCancel={this.onHandleGenerateECodesCancelButtonClick}
          onGenerate={this.onHandleGenerateECodesGenerateButtonClick}
          projectList={general.projectList}
          onDurationChange={this.onDurationChange}
          onEcodeNumberChange={this.onEcodeNumberChange}
          onHandleProjectSelectionMenuClick={
            this.onHandleProjectSelectionMenuClick
          }
          newEcodesInfoObj={newEcodesInfoObj}
        />
        <Row style={{ padding: "1rem" }} align="bottom" justify="space-between">
          <Col span={24} style={{ fontSize: "0.9rem" }}>
            You are here :&nbsp;&nbsp;
            <Breadcrumb separator=">" style={{ display: "inline" }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Generate E-Codes</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col
            style={{
              fontFamily: "OpenSans-Bold",
              fontSize: "1.8rem"
            }}
          >
            Generate E-Codes
          </Col>

          <Col style={{ marginRight: "1rem" }}>
            <Button
              type="primary"
              style={{
                borderRadius: "2rem",
                padding: "0.4rem 4rem",
                fontFamily: "OpenSans-Regular",
                fontSize: "1rem",
                height: "auto",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                color: "white"
              }}
              onClick={this.onHandleGenerateECodesButtonClick}
            >
              Generate E-Codes
            </Button>
          </Col>
        </Row>
        <br />
        <Input
          placeholder="Search"
          prefix={
            <img
              src="/assets/search-icon.svg"
              alt=""
              style={{
                width: "1rem",
                height: "1rem",
                marginRight: "0.5rem"
              }}
            />
          }
          size="large"
          style={{ borderRadius: "24px", width: "20rem", margin: "0rem 1rem" }}
        />

        <Table
          dataSource={loadedECodeData}
          columns={eCodeColumns}
          pagination={{
            showSizeChanger: false,
            pageSize: 10, //TODO
            position: ["bottomCenter"],
            // onChange: TODO,
            current: 1 //TODO
          }}
          footer={() => <span>Total E-codes: {totalCodes} </span>}
          style={{ marginTop: "2rem", padding: "0rem 1rem" }}
        />
      </Fragment>
    );
  }
}

const key = "generateEcodesNotification";

const mapStateToProps = state => ({
  ecodesRecords: state.ecodesRecords,
  general: state.general
});
const mapDispatchToProps = {
  loadAllECodes,
  generateEcodes
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(GenerateECodesPage);
