import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";
import "./Ebooks.less";
import { loadAllEBooks, resetEBook } from "../ebook/ebookActions";
import firebase from "firebase/app";
import "firebase/storage";
import { withFirebase } from "react-redux-firebase";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import { DOWNLOADNOW, FREEONLINEBOOK } from "./TranslationConstant";

class Ebooks extends Component {
  state = {
    isLoading: true,
    loadedEbooks: this.props.ebook
  };

  componentDidMount() {
    this.props.loadAllEBooks();
  }

  async componentDidUpdate(prevProps) {
    const { ebook, firebase, auth } = this.props;
    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(prevProps.ebook.eBookList, ebook.eBookList) &&
      ebook.eBookList
    ) {
      let bookImagePromises = [];

      // console.log(ebook.eBookList);
      let eBookList = [];
      if (
        auth.userInfo.nativeLanguage &&
        auth.userInfo.nativeLanguage === "ms"
      ) {
        eBookList = ebook.eBookList.filter(item => item.languageCode === "ms");
      } else {
        eBookList = ebook.eBookList.filter(item => item.languageCode === "en");
      }
      eBookList.forEach(item => {
        bookImagePromises.push(
          storageRef.child(item.imageFilePath).getDownloadURL()
        );
      });
      let loadedEbooksImages = await Promise.all(bookImagePromises);
      this.setState({
        eBookList,
        isLoading: false,
        loadedEbooksImages
      });
    } else if (
      auth.userInfo.nativeLanguage !== prevProps.auth.userInfo.nativeLanguage
    ) {
      let eBookList = [];
      let bookImagePromises = [];
      if (
        auth.userInfo.nativeLanguage &&
        auth.userInfo.nativeLanguage === "ms"
      ) {
        eBookList = ebook.eBookList.filter(item => item.languageCode === "ms");
      } else {
        eBookList = ebook.eBookList.filter(item => item.languageCode === "en");
      }
      eBookList.forEach(item => {
        bookImagePromises.push(
          storageRef.child(item.imageFilePath).getDownloadURL()
        );
      });
      let loadedEbooksImages = await Promise.all(bookImagePromises);
      this.setState({
        eBookList,
        loadedEbooksImages
      });
    }
  }

  componentWillUnmount() {
    this.props.resetEBook();
  }

  onHandleDownloadEBookButtonClick = async ebook => {
    console.log(ebook);
    const storageRef = firebase.storage().ref();
    var bookRef = storageRef.child(ebook.pdfFilePath);

    let pdfDownloadPromise = new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function(event) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(xhr.status);
        }
      };
      bookRef.getDownloadURL().then(url => {
        xhr.open("GET", url);
        xhr.send();
      });
    });

    let downloadedBlob = await pdfDownloadPromise;
    var url = URL.createObjectURL(downloadedBlob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "ebook_" + ebook.bookName;

    a.click();
  };

  render() {
    const { auth } = this.props;
    const { isLoading, eBookList } = this.state;
    if (isLoading) return <LoadingComponent auth={auth}/>;
    return (
      <Fragment>
        <Row className="ebook-row">
          <Col xs={2} md={2} xl={3} />
          <Col xs={20} md={20} xl={18}>
            <div className="ebook-title">
              {
                FREEONLINEBOOK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            {eBookList.map((ebook, index) => (
              <div key={index}>
                {index === 0 ? "" : <hr className="ebook-divider" />}
                <Row>
                  <Col xs={24} sm={8} lg={4} className="ebook-img-col">
                    <img
                      className="ebook-card-img"
                      src={
                        this.state.loadedEbooksImages &&
                        this.state.loadedEbooksImages[index]
                      }
                      alt={`Ebook-${index + 1}`}
                    />
                  </Col>
                  <Col xs={24} sm={0} md={0} lg={0} xl={16}>
                    <div className="ebook-card-title">{ebook.bookName}</div>
                    <div className="ebook-card-description">
                      {ebook.description}
                    </div>
                  </Col>

                  <Col xs={0} sm={16} lg={15} xl={0}>
                    <div className="ebook-card-title">{ebook.bookName}</div>
                    <div className="ebook-card-description">
                      {ebook.description}
                    </div>

                    <br />
                    <Button
                      className="ebook-card-button"
                      style={{ float: "right" }}
                      onClick={e =>
                        this.onHandleDownloadEBookButtonClick(ebook)
                      }
                    >
                      {
                        DOWNLOADNOW[
                          auth.userInfo.nativeLanguage
                            ? auth.userInfo.nativeLanguage
                            : "en"
                        ]
                      }
                    </Button>
                  </Col>

                  <Col
                    xs={24}
                    sm={0}
                    md={0}
                    lg={0}
                    xl={4}
                    className="ebook-button-col"
                  >
                    <Button
                      className="ebook-card-button"
                      onClick={e =>
                        this.onHandleDownloadEBookButtonClick(ebook)
                      }
                    >
                      {
                        DOWNLOADNOW[
                          auth.userInfo.nativeLanguage
                            ? auth.userInfo.nativeLanguage
                            : "en"
                        ]
                      }
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
          <Col xs={2} md={2} xl={3} />
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  loadAllEBooks,
  resetEBook
};

const mapStateToProps = state => ({
  ebook: state.ebook,
  auth: state.auth
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Ebooks);
