import React, { Fragment } from "react";
import { Modal, Steps, Row, Col, Button } from "antd";
import "./SelectEBookLanguageModal.css";
import { eBookLanguageCodeEnum } from "../SharedConstant";
import {
  NEXT,
  SELECTLANGUAGE,
  SELECTEBOOK,
  CUSTOMIZEFUNNEL
} from "../BusinessPortal/TranslationConstant";
const { Step } = Steps;

const SelectEbookLanguageModal = ({
  isVisible,
  onCancel,
  onNext,
  onLanguageImageClick,
  selectedLanguageCode,
  windowWidth,
  auth
}) => {
  return (
    <Fragment>
      <Modal
        title="&nbsp;"
        visible={isVisible}
        onCancel={onCancel}
        width={windowWidth > 900 ? 900 : windowWidth - 30}
        footer={null}
        closeIcon={
          <img
            src="/assets/close-white-icon.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        }
      >
        <Row align={windowWidth > 576 ? "top" : "middle"}>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
          >
            <Steps
              // current={2}
              className="select-ebook-model-steps-wrapper"
              direction={windowWidth > 576 ? "horizontal" : "vertical"}
              style={{
                fontFamily: "Poppins-SemiBold"
              }}
            >
              {/* {console.log(auth)} */}
              <Step
                title={
                  SELECTLANGUAGE[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }

                // icon={<img src="/assets/step-1-colored.svg" alt="" style={{width:"32px",height:"32px"}}/>}
              />
              <Step
                title={
                  SELECTEBOOK[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              />
              <Step
                title={
                  CUSTOMIZEFUNNEL[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              />
            </Steps>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
          >
            <Row justify="center">
              <Col style={{ padding: "16px" }}>
                <img
                  className={
                    "modal-select-language-img" +
                    (selectedLanguageCode ===
                    eBookLanguageCodeEnum.BAHASA_MALAYSIA
                      ? " selected"
                      : " not-selected")
                  }
                  src="/assets/bahasa-malaysia-type-label.svg"
                  alt=""
                  onClick={() =>
                    onLanguageImageClick(eBookLanguageCodeEnum.BAHASA_MALAYSIA)
                  }
                />
              </Col>
              <Col style={{ padding: "16px" }}>
                <img
                  className={
                    "modal-select-language-img" +
                    (selectedLanguageCode === eBookLanguageCodeEnum.ENGLISH
                      ? " selected"
                      : " not-selected")
                  }
                  src="/assets/english-type-label.svg"
                  alt=""
                  onClick={() =>
                    onLanguageImageClick(eBookLanguageCodeEnum.ENGLISH)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="end">
          <Col style={{ marginRight: "16px" }}>
            <Button
              className="select-ebook-model-button-wrapper"
              style={{
                fontFamily: "Poppins-Medium",
                borderRadius: "24px",
                height: "auto"
              }}
              disabled={!selectedLanguageCode}
              onClick={onNext}
              type="primary"
            >
              {
                NEXT[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default SelectEbookLanguageModal;
