import React, { Component, Fragment } from "react";
import { Col, Row, Form, Input, Button, Radio, Spin, notification } from "antd";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { updateUserInfo, resetPassword, reset } from "../../auth/authActions";
import { operationTypeEnum } from "../../OperationTypeConstant";
import _ from "lodash";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import DropzoneComponent from "../../app/common/upload/DropzoneComponent";
import ChangePasswordModal from "../../modals/ChangePasswordModal";
import "./EditProfilePage.less";
import {
  CloseCircleOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from "@ant-design/icons";
import {
  EDITPROFILE,
  SELECTYOURBIRTHDAY,
  BIRTHDAY,
  MALE,
  FEMALE,
  PREFERNOTSAY,
  NAME,
  PASSWORDREQUIRED
} from "../TranslationConstant";

class EditProfilePage extends Component {
  state = {
    loading: false,
    email: this.props.dbAuth.userInfo.email,
    name: this.props.dbAuth.userInfo.name,
    age: this.props.dbAuth.userInfo.age,
    gender: this.props.dbAuth.userInfo.gender,
    birthday: new Date(1000 * this.props.dbAuth.userInfo.birthday.seconds)
      .toISOString()
      .substr(0, 10),
    profileImageObj: {
      imageDisplayList: [],
      imageLocalList: [],
      imageDBList: [],
      imageDBToRemoveList: []
    },
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    reset: false
  };
  form = React.createRef();

  componentDidMount() {
    //same
    const { dbAuth } = this.props;
    this.setState(prevState => ({
      profileImageObj: {
        ...prevState.profileImageObj,
        imageDisplayList:
          dbAuth.userInfo.profileImageUrl.length > 0
            ? [dbAuth.userInfo.profileImageUrl]
            : [],
        imageDBList:
          dbAuth.userInfo.profileImageUrl.length > 0
            ? [dbAuth.userInfo.profileImageUrl]
            : [],
        imageLocalList: [],
        imageDBToRemoveList: []
      }
    }));
  }

  onChange = e => {
    console.log("radio checked", e.target.value);
    this.setState({
      gender: e.target.value
    });
  };

  componentDidUpdate(prevProps) {
    const { operation, dbAuth } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      // console.log();
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_USER_DATA &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        // this.props.navigation.goBack(null);
        this.props.history.goBack();
      }
    }

    if (!dbAuth.loading && dbAuth.resetPasswordSuccess && this.state.reset) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully changed password",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        )
      });
      this.setState({
        isChangePasswordModalVisible: false,
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
        reset: false
      });
    } else if (
      !dbAuth.loading &&
      dbAuth.resetPasswordFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: dbAuth.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        )
      });
      this.setState({
        reset: false
      });
    }
  }

  onHandleAcceptedFilesFromDropzone = acceptedFiles => {
    //filter just one image/file
    let file = acceptedFiles[0];

    let randomNumber =
      new Date().toISOString() + "_" + Math.round(Math.random() * 1e10);
    let fileObj = new File(
      [file],
      `${randomNumber}.${file.type.split("/")[1]}`,
      { type: file.type }
    );

    let uploadedFile = Object.assign(fileObj, {
      preview: URL.createObjectURL(fileObj)
    });

    this.setState(prevState => {
      let newImageLocalListTmp = [
        ...prevState.profileImageObj.imageLocalList,
        uploadedFile
      ];

      let newImageDBListTmp = [...prevState.profileImageObj.imageDBList];

      newImageDBListTmp = newImageDBListTmp.filter(
        (url, idx) =>
          !prevState.profileImageObj.imageDBToRemoveList.includes(idx)
      );

      return {
        profileImageObj: {
          ...prevState.profileImageObj,
          imageLocalList: [...newImageLocalListTmp],
          imageDisplayList: [...newImageDBListTmp, ...newImageLocalListTmp]
        }
      };
    });
  };

  onHandleChangePasswordCancelButtonClick = () => {
    this.setState({
      isChangePasswordModalVisible: false
    });
  };

  onHandleChangePasswordSaveButtonClick = async () => {
    const { oldPassword, newPassword } = this.state;
    await this.props.reset();
    notification.open({
      key,
      message: "Updating Password",
      duration: 0,
      icon: <LoadingOutlined />
    });
    this.props.resetPassword(oldPassword, newPassword);
    this.setState({
      reset: true
    });
  };

  onInputChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState(prevState => ({
      profileInfoObj: {
        ...prevState.profileInfoObj,
        [inputField]: inputVal
      }
    }));
  };

  onOldPasswordChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState({
      [inputField]: inputVal
    });
  };

  onNewPasswordChange = (e, inputField) => {
    let regex = "^(?=.*[a-z])(?=.*[0-9])(?=.{8,})";
    let inputVal = e.target.value;
    if (inputVal.match(regex)) {
      this.setState({
        [inputField]: inputVal,
        newPassErr: ""
      });
    } else {
      this.setState({
        [inputField]: inputVal,
        newPassErr:
          PASSWORDREQUIRED[
            this.props.dbAuth.userInfo
              ? this.props.dbAuth.userInfo.nativeLanguage
                ? this.props.dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ]
      });
    }
  };

  onNewPasswordRepeatChange = (e, inputField) => {
    let inputVal = e.target.value;

    this.setState({
      [inputField]: inputVal
    });
  };

  onHandlePhotoPreviewRemoveButtonClick = imageToRemoveIdx => {
    const { profileImageObj } = this.state;
    let imageDBToRemoveList = profileImageObj.imageDBToRemoveList;
    let imageDBList = profileImageObj.imageDBList;
    let imageLocalList = profileImageObj.imageLocalList;

    imageDBList = imageDBList.filter(
      (url, idx) => !imageDBToRemoveList.includes(idx)
    );

    let imageLocalListTmp = [...imageLocalList];
    let imageDBListTmp = [...imageDBList];
    let imageDBToRemoveListTmp = [...imageDBToRemoveList];

    if (imageToRemoveIdx > imageDBList.length - 1) {
      imageLocalListTmp.splice(imageToRemoveIdx - imageDBList.length, 1);
    } else {
      imageDBListTmp.splice(imageToRemoveIdx, 1);
      imageDBToRemoveListTmp.push(imageToRemoveIdx);
    }

    this.setState(prevState => ({
      profileImageObj: {
        ...prevState.profileImageObj,
        imageDisplayList: [...imageDBListTmp, ...imageLocalListTmp],
        imageLocalList: [...imageLocalListTmp],
        imageDBToRemoveList: [...imageDBToRemoveListTmp]
      }
    }));
  };

  validateInfoBeforeUpdatingProfileInfo = async imageObj => {
    console.log();
    let statusObj = {};
    statusObj.isValid = true;
    statusObj.errorMessage = "";

    try {
      if (
        !(
          imageObj.imageDBList.length > 0 &&
          imageObj.imageDisplayList.length > 0 &&
          imageObj.imageDBToRemoveList.length === 0 &&
          imageObj.imageLocalList.length === 0
        )
      ) {
        if (imageObj.imageDBToRemoveList.length === 0) {
          if (imageObj.imageLocalList.length === 0) {
            statusObj.isValid = false;
            statusObj.errorMessage = "Please include a profile image.";
            return statusObj;
          }
        } else {
          if (imageObj.imageDBToRemoveList.length > 0) {
            if (imageObj.imageLocalList.length === 0) {
              statusObj.isValid = false;
              statusObj.errorMessage =
                "Please include a new profile image to change.";
              return statusObj;
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    return statusObj;
  };

  handleSubmit = async () => {
    const { profileImageObj } = this.state;
    let statusObj = await this.validateInfoBeforeUpdatingProfileInfo(
      profileImageObj
    );
    // console.log(statusObj)
    if (statusObj.isValid) {
      const { firebase } = this.props;

      this.form.current.validateFields().then(values => {
        let birthday = firebase.firestore.Timestamp.fromDate(
          new Date(values.birthday)
        );
        // console.log(firebase.firestore.Timestamp.fromDate(birthday));
        this.props.updateUserInfo(values, birthday, profileImageObj);
      });
    } else {
      notification.open({
        message: "Failed to save profile information",
        description: statusObj.errorMessage,
        duration: 2
      });
    }
  };

  onHandleChangePasswordButtonClick = () => {
    this.setState({
      isChangePasswordModalVisible: true,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: ""
    });
  };

  render() {
    const { operation, dbAuth } = this.props;
    const {
      email,
      name,
      gender,
      birthday,
      loading,
      profileImageObj,
      isChangePasswordModalVisible,
      oldPassword,
      newPassword,
      newPasswordRepeat,
      newPassErr
    } = this.state;
    if (loading) return <LoadingComponent auth={dbAuth} />;
    return (
      <Spin tip="Saving..." spinning={operation.isProcessing} size="large">
        <Fragment>
          <ChangePasswordModal
            isVisible={isChangePasswordModalVisible}
            onCancel={this.onHandleChangePasswordCancelButtonClick}
            onSave={this.onHandleChangePasswordSaveButtonClick}
            onOldPasswordChange={this.onOldPasswordChange}
            onNewPasswordChange={this.onNewPasswordChange}
            onNewPasswordRepeatChange={this.onNewPasswordRepeatChange}
            oldPassword={oldPassword}
            newPassword={newPassword}
            newPasswordRepeat={newPasswordRepeat}
            newPassErr={newPassErr}
          />

          <Row>
            <Col xs={1} md={2} lg={3} xl={5} xxl={7} />
            <Col xs={22} md={20} lg={18} xl={14} xxl={10}>
              <Row style={{ margin: "20px" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <div className="profile-title">
                    {
                      EDITPROFILE[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>

                  <Col
                    offset={6}
                    span={12}
                    style={{ textAlign: "center", cursor: "pointer" }}
                  >
                    {profileImageObj.imageDisplayList.length > 0 ? (
                      <Row justify="center">
                        {profileImageObj.imageDisplayList.map((item, idx) => (
                          <Col key={`${item.name}_${idx}`}>
                            <Row>
                              <Col span={21} style={{ maxHeight: "300px" }}>
                                <img
                                  src={
                                    idx >
                                    profileImageObj.imageDBList.length -
                                      1 -
                                      profileImageObj.imageDBToRemoveList.length
                                      ? item.preview
                                      : item
                                  }
                                  alt=""
                                  style={{ width: "8rem", height: "8rem" }}
                                />
                              </Col>
                              <Col span={3}>
                                <Button
                                  icon={<CloseCircleOutlined />}
                                  style={{ border: "none" }}
                                  shape="circle"
                                  onClick={() =>
                                    this.onHandlePhotoPreviewRemoveButtonClick(
                                      idx
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <DropzoneComponent
                        onHandleAcceptedFilesFromDropzone={
                          this.onHandleAcceptedFilesFromDropzone
                        }
                        uploadImageIcon={
                          <div>
                            UPLOAD AVATAR
                            <div>
                              <img
                                src="/assets/avatar-icon.svg"
                                alt=""
                                style={{ width: "8rem", height: "8rem" }}
                              />
                            </div>
                          </div>
                        }
                      />
                    )}

                    {/* UPLOAD AVATAR
            <div>
              <img
                src="/assets/avatar-icon.svg"
                alt=""
                style={{ width: "8rem", height: "8rem" }}
              />
            </div> */}
                  </Col>
                  <Col span={6} />

                  <Form
                    ref={this.form}
                    autoComplete="off"
                    // onFinish={() => console.log("SUBMIT TEST")}
                    onFinish={this.handleSubmit}
                  >
                    <Row style={{ textAlign: "start" }}>
                      <Col offset={8} span={8}>
                        <br />
                      </Col>
                      <Col span={8} />

                      <Col span={24}>
                        <Form.Item
                          initialValue={email}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please provide your email!"
                            }
                          ]}
                        >
                          <Input
                            type="text"
                            size="large"
                            placeholder="Email"
                            disabled={true}
                            // prefix={
                            //   <img
                            //     src="/assets/profile-black-icon.svg"
                            //     alt=""
                            //     style={{ padding: "6px" }}
                            //   />
                            // }
                          />
                        </Form.Item>

                        <Form.Item
                          initialValue={name}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please provide your name!"
                            }
                          ]}
                        >
                          <Input
                            type="text"
                            // style={{ textTransform: "uppercase" }}
                            size="large"
                            placeholder={
                              NAME[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                            // className="profile-body"
                            // prefix={
                            //   <img
                            //     src="/assets/profile-black-icon.svg"
                            //     alt=""
                            //     style={{ padding: "6px" }}
                            //   />
                            // }
                          />
                        </Form.Item>
                        {/* <Form.Item
                      initialValue={age}
                      name="age"
                      rules={[
                        {
                          required: true,
                          message: "Please provide your age!"
                        } 
                      ]}
                    >
                      <Input
                        // onChange={this.onAgeChange}
                        // defaultValue = {age}
                        type="number"
                        size="large"
                        placeholder="Age"
                        prefix={
                          <img
                            src="/assets/add-user-black-icon.svg"
                            alt=""
                            style={{ padding: "8px" }}
                          />
                        }
                      />
                    </Form.Item> */}

                        <Form.Item
                          initialValue={birthday}
                          name="birthday"
                          rules={[
                            {
                              required: true,
                              message:
                                SELECTYOURBIRTHDAY[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                            }
                          ]}
                        >
                          <Input
                            type="date"
                            size="large"
                            placeholder={
                              BIRTHDAY[
                                dbAuth.userInfo
                                  ? dbAuth.userInfo.nativeLanguage
                                    ? dbAuth.userInfo.nativeLanguage
                                    : "en"
                                  : "en"
                              ]
                            }
                            // prefix={
                            //   <img
                            //     src="/assets/add-user-black-icon.svg"
                            //     alt=""
                            //     style={{ padding: "8px" }}
                            //   />
                            // }
                          />
                        </Form.Item>

                        <Form.Item
                          initialValue={gender}
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please choose your gender!"
                            }
                          ]}
                        >
                          <Radio.Group
                            onChange={this.onChange}
                            value={gender}
                            className="profile-body"
                          >
                            <Radio value="Male">
                              {
                                MALE[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </Radio>
                            <Radio value="Female">
                              {
                                FEMALE[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </Radio>
                            <Radio value="Prefer not to say">
                              {
                                PREFERNOTSAY[
                                  dbAuth.userInfo
                                    ? dbAuth.userInfo.nativeLanguage
                                      ? dbAuth.userInfo.nativeLanguage
                                      : "en"
                                    : "en"
                                ]
                              }
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ offset: 4, span: 16 }}
                        md={{ offset: 4, span: 8 }}
                      >
                        <Button
                          type="primary"
                          className="profile-button"
                          block
                          onClick={this.onHandleChangePasswordButtonClick}
                        >
                          Change Password
                        </Button>
                      </Col>
                      <Col
                        xs={{ offset: 4, span: 16 }}
                        md={{ offset: 1, span: 8 }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="profile-button"
                            block
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Col xs={1} md={2} lg={3} xl={5} xxl={7} />
            </Col>
          </Row>
        </Fragment>
      </Spin>
    );
  }
}

const key = "resetPasswordNotification";

const mapStateToProps = state => ({
  dbAuth: state.auth,
  feedback: state.feedback,
  operation: state.operation
});
const mapDispatchToProps = {
  updateUserInfo,
  reset,
  resetPassword
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(EditProfilePage);
