import React, { Fragment } from "react";
import { Row, Col } from "antd";
import { BOOKTAGLINE2, BOOKTAGLINE1, YOUNAMEORCOMPANY } from "./BusinessPortal/TranslationConstant";

const widthScaleDownRatio = 60; //the larger the value the smaller the font

const EbookCoverPreview = ({
  eBookContainerWidth,
  eBookContainerHeight,
  eBookCoverObj,
  backgroundImage,
  profileImageObj,
  isPublic,
  auth
}) => {
  return (
    <Fragment>
      <div
        className={
          "template0001-ebook-cover-container" +
          (isPublic ? " public" : " non-public") +
          (eBookCoverObj.selected ? " selected" : " not-selected")
        }
        style={{
          position: "relative",
          //  paddingTop: "133.33%",
          paddingTop: "141.43%"
        }}
      >
        <img
          src={backgroundImage}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        >
          <Row style={{ textAlign: "center" }}>
            <Col span={24} style={{ textAlign: "end" }}>
              <img
                src="/assets/error-icon.svg"
                alt=""
                style={{
                  visibility: eBookCoverObj.error ? "visible" : "hidden",
                  padding: "8px"
                }}
              />
            </Col>
          </Row>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            textAlign: "center",
            padding: "0px 8px",
            marginTop: eBookContainerHeight / 40 + "px"
          }}
        >
          <div
            style={{
              fontSize:
                eBookCoverObj.bookNameObj.fontSize *
                  (eBookContainerWidth / widthScaleDownRatio) +
                "%",
              fontFamily: eBookCoverObj.bookNameObj.fontFamily,
              color:
                eBookCoverObj.bookNameObj.color.length > 0
                  ? eBookCoverObj.bookNameObj.color
                  : "#FFFFFF",
              whiteSpace: "normal",
              overflowWrap: "break-word"
            }}
          >
            {eBookCoverObj.bookNameObj.text}
          </div>
          <div
            style={{
              fontSize:
                eBookCoverObj.authorObj.fontSize *
                  (eBookContainerWidth / widthScaleDownRatio) +
                "%",
              fontFamily: eBookCoverObj.authorObj.fontFamily,
              color:
                eBookCoverObj.authorObj.color.length > 0
                  ? eBookCoverObj.authorObj.color
                  : "#FFFFFF",
              whiteSpace: "normal",
              overflowWrap: "break-word",
              marginTop:"8px"
            }}
          >
            by{" "}
            {eBookCoverObj.authorObj.text.length > 0
              ? eBookCoverObj.authorObj.text
              :  YOUNAMEORCOMPANY[
                auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
              ]}
          </div>
        </div>

        {/* <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"
          }}
        >
          <img
            src={
              profileImageObj.imageDisplayList.length > 0
                ? profileImageObj.imageLocalList.length > 0
                  ? profileImageObj.imageLocalList[0].preview
                  : profileImageObj.imageDBList[0]
                : "/assets/profile-icon.png"
            }
            style={{
              objectFit: "cover",
              width: eBookContainerWidth * (9 / 24),
              height: eBookContainerWidth * (9 / 24)
            }}
            alt=""
          />
        </div> */}

        <div
          style={{
            position: "absolute",
            top: "75%",
            left: 0,
            width: "100%",
            textAlign: "center",
            padding: "0px 8px"
          }}
        >
          <div
            // className="template0001-ebook-tagline-wrapper"
            style={{
              fontSize:
                eBookCoverObj.tagline1Obj.fontSize *
                  (eBookContainerWidth / widthScaleDownRatio) +
                "%",
              fontFamily: eBookCoverObj.tagline1Obj.fontFamily,
              color:
                eBookCoverObj.tagline1Obj.color.length > 0
                  ? eBookCoverObj.tagline1Obj.color
                  : "#FFFFFF",
              whiteSpace: "normal",
              overflowWrap: "break-word"
            }}
          >
            {eBookCoverObj.tagline1Obj.text.length > 0
              ? eBookCoverObj.tagline1Obj.text
              :  BOOKTAGLINE1[
                auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
              ]}
          </div>
          <div
            style={{
              fontSize:
                eBookCoverObj.tagline2Obj.fontSize *
                  (eBookContainerWidth / widthScaleDownRatio) +
                "%",
              fontFamily: eBookCoverObj.tagline2Obj.fontFamily,
              color:
                eBookCoverObj.tagline2Obj.color.length > 0
                  ? eBookCoverObj.tagline2Obj.color
                  : "#FFFFFF",
              whiteSpace: "normal",
              overflowWrap: "break-word"
            }}
          >
            {eBookCoverObj.tagline2Obj.text.length > 0
              ? eBookCoverObj.tagline2Obj.text
              :  BOOKTAGLINE2[
                auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
              ]}
          </div>
        </div>

        {/* <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        >
          <Row style={{ textAlign: "center" }}>
            <Col span={24} style={{ textAlign: "end" }}>
              <img
                src="/assets/error-icon.svg"
                alt=""
                style={{
                  visibility: eBookCoverObj.error ? "visible" : "hidden",
                  padding: "8px"
                }}
              />
            </Col>
            <Col
              span={24}
              style={{
                padding: "0px 8px",
                marginTop: eBookContainerHeight / 20 + "px"
              }}
            >
              <div
                style={{
                  fontSize:
                    eBookCoverObj.bookNameObj.fontSize *
                      (eBookContainerWidth / widthScaleDownRatio) +
                    "%",
                  fontFamily: eBookCoverObj.bookNameObj.fontFamily,
                  color:
                    eBookCoverObj.bookNameObj.color.length > 0
                      ? eBookCoverObj.bookNameObj.color
                      : "#FFFFFF",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                {eBookCoverObj.bookNameObj.text}
              </div>
            </Col>

            <Col span={24} style={{ padding: "0px 8px" }}>
              <div
                style={{
                  fontSize:
                    eBookCoverObj.authorObj.fontSize *
                      (eBookContainerWidth / widthScaleDownRatio) +
                    "%",
                  fontFamily: eBookCoverObj.authorObj.fontFamily,
                  color:
                    eBookCoverObj.authorObj.color.length > 0
                      ? eBookCoverObj.authorObj.color
                      : "#FFFFFF",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                by{" "}
                {eBookCoverObj.authorObj.text.length > 0
                  ? eBookCoverObj.authorObj.text
                  : "Your Name or Company Name"}
              </div>
            </Col>

            <Col
              span={24}
              style={{ marginTop: eBookContainerHeight / 15 + "px" }}
            >
              <Row>
                <Col offset={7} span={10}>
                  <img
                    src={
                      profileImageObj.imageDisplayList.length > 0
                        ? profileImageObj.imageLocalList.length > 0
                          ? profileImageObj.imageLocalList[0].preview
                          : profileImageObj.imageDBList[0]
                        : "/assets/profile-icon.png"
                    }
                    style={{
                      objectFit: "cover",
                      width: eBookContainerWidth * (9 / 24),
                      height: eBookContainerWidth * (9 / 24)
                    }}
                    alt=""
                  />
                </Col>
                <Col span={7} />
              </Row>
            </Col>

            <Col
              span={24}
              style={{
                marginTop: eBookContainerHeight / 11 + "px",
                // marginBottom: "24px",
                padding: "0px 8px"
              }}
            >
              <div
                // className="template0001-ebook-tagline-wrapper"
                style={{
                  fontSize:
                    eBookCoverObj.tagline1Obj.fontSize *
                      (eBookContainerWidth / widthScaleDownRatio) +
                    "%",
                  fontFamily: eBookCoverObj.tagline1Obj.fontFamily,
                  color:
                    eBookCoverObj.tagline1Obj.color.length > 0
                      ? eBookCoverObj.tagline1Obj.color
                      : "#FFFFFF",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                {eBookCoverObj.tagline1Obj.text.length > 0
                  ? eBookCoverObj.tagline1Obj.text
                  : "Book Tagline 1"}
              </div>
              <div
                style={{
                  fontSize:
                    eBookCoverObj.tagline2Obj.fontSize *
                      (eBookContainerWidth / widthScaleDownRatio) +
                    "%",
                  fontFamily: eBookCoverObj.tagline2Obj.fontFamily,
                  color:
                    eBookCoverObj.tagline2Obj.color.length > 0
                      ? eBookCoverObj.tagline2Obj.color
                      : "#FFFFFF",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                {eBookCoverObj.tagline2Obj.text.length > 0
                  ? eBookCoverObj.tagline2Obj.text
                  : "Book Tagline 2"}
              </div>
            </Col>
          </Row>
        </div> */}
      </div>
    </Fragment>
  );
};

export default EbookCoverPreview;
