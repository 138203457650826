import { LOAD_GENERALS } from "./generalConstant";
import { createReducer } from "../app/common/utils/reducerUtils";

const initialState = {
  generalObj: null
};

const loadGenerals = (state, payload) => {
  return {
    ...state,
    generalObj: payload.general
  };
};

export default createReducer(initialState, {
  [LOAD_GENERALS]: loadGenerals
});
