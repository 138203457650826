import {
  Button,
  Col,
  Menu,
  Row,
  notification,
  Popover,
  Typography,
  Divider,
  Select,
} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { sendMessage } from "./ContactUs/ContactUsAction";
import ContactUsModal from "./ContactUs/ContactUs";
import { updateUserLanguageInfo } from "../auth/authActions";
import { resetTranslation } from "../BusinessPortal/translation/translationActions";
import "./Header.less";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { logout } from "../auth/authActions";
import { withFirebase } from "react-redux-firebase";
import _ from "lodash";
import {
  SEARCH,
  CONTACTUS,
  COURSETAB,
  EXAMTAB,
  SALESFUNNELTAB,
  EBOOKTAB,
  PROBINGQUESTIONTAB,
  EMAILTEMPLATESTAB,
  BUSINESSSPEAKINGTAB,
  RESOURCESTAB,
  EDITPROFILE,
  SIGNOUT,
  DIRECTORY,
  SELECTLANGUAGE,
} from "./TranslationConstant";

const { Text } = Typography;
class Header extends Component {
  state = {
    visible: false,
    filter: "",
    filteredData: [],
    links: [],
    openKeys: [],
    languagePopoverVisible: false,
    diroctoryCollapsed: false,
    languageCollapsed: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
      hovered: false,
    });
  };

  hideModal = (values) => {
    this.setState({
      reset: true,
      visible: false,
    });
  };

  sendMessage = (values) => {
    // form.current.validateFields().then(values => {
    notification.open({
      key,
      message: "Sending Message",
      duration: 0,
      icon: <LoadingOutlined />,
    });
    this.props.sendMessage(values);
    this.setState({
      reset: true,
      visible: false,
    });
    // });
  };
  // languagePopoverClick = () => {
  //   this.setState({
  //     languagePopoverVisible: false
  //   });
  // };

  languagePopoverChange = (visible) => {
    this.setState({
      languagePopoverVisible: visible,
    });
  };

  handleHoverChange = (visible) => {
    this.setState({
      hovered: visible,
    });
  };
  handleHoverClick = () => {
    this.setState({
      hovered: false,
    });
  };

  handleEditProfileClick = () => {
    this.props.history.push("/business-portal/edit-profile");
  };
  handleSignOutBtnClick = async () => {
    await this.props.logout();
    await this.props.resetTranslation();
    this.setState({
      hovered: false,
      isSignedIn: false,
    });
  };

  handleSubMenuClick = () => {
    this.setState({
      openKeys: _.isEmpty(this.state.openKeys) ? ["menu"] : [],
    });
  };

  closeMenu = () => {
    this.setState({
      openKeys: [],
    });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  handleSearch = (value) => {
    const { links } = this.state;
    const lowercasedFilter = value.toLowerCase();
    // console.log(links);
    const filteredData = links.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    if (value) {
      this.setState({ filteredData });
    } else {
      this.setState({ filteredData: [] });
    }
  };

  onLanguageMenuClick = async (e) => {
    const { dbAuth } = this.props;
    if (dbAuth.userInfo) {
      this.props.updateUserLanguageInfo(
        e.key,
        this.props.dbAuth.nativeLanguage
      );

      let links = [
        {
          url: "/business-portal",
          title: COURSETAB[e.key],
        },
        {
          url: "/business-portal/exams",
          title: EXAMTAB[e.key],
        },
        // {
        //   url: "/business-portal/sales-funnel",
        //   title: SALESFUNNELTAB[e.key],
        // },
        {
          url: "/business-portal/ebooks",
          title: EBOOKTAB[e.key],
        },
        {
          url: "/business-portal/probing-questions",
          title: PROBINGQUESTIONTAB[e.key],
        },
        {
          url: "/business-portal/email-templates",
          title: EMAILTEMPLATESTAB[e.key],
        },
        {
          url: "/business-portal/business-speaking",
          title: BUSINESSSPEAKINGTAB[e.key],
        },
        {
          url: "/business-portal/resources",
          title: RESOURCESTAB[e.key],
        },
      ];

      this.setState({
        links,
        languagePopoverVisible: false,
        diroctoryCollapsed: false,
        // languagePopoverVisible: !this.state.languagePopoverVisible
      });
    }
  };

  componentDidMount() {
    const { dbAuth } = this.props;
    let links = [
      {
        url: "/business-portal",
        title:
          COURSETAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
      {
        url: "/business-portal/exams",
        title:
          EXAMTAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
      // {
      //   url: "/business-portal/sales-funnel",
      //   title:
      //     SALESFUNNELTAB[
      //       dbAuth.userInfo
      //         ? dbAuth.userInfo.nativeLanguage
      //           ? dbAuth.userInfo.nativeLanguage
      //           : "en"
      //         : "en"
      //     ],
      // },
      {
        url: "/business-portal/ebooks",
        title:
          EBOOKTAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
      {
        url: "/business-portal/probing-questions",
        title:
          PROBINGQUESTIONTAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
      {
        url: "/business-portal/email-templates",
        title:
          EMAILTEMPLATESTAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
      {
        url: "/business-portal/business-speaking",
        title:
          BUSINESSSPEAKINGTAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
      {
        url: "/business-portal/resources",
        title:
          RESOURCESTAB[
            dbAuth.userInfo
              ? dbAuth.userInfo.nativeLanguage
                ? dbAuth.userInfo.nativeLanguage
                : "en"
              : "en"
          ],
      },
    ];
    this.setState({ links });
  }

  componentDidUpdate() {
    const { contactUs } = this.props;
    if (!contactUs.loading && contactUs.contactUsSuccess && this.state.reset) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully sent a message",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        ),
      });
      this.setState({
        reset: false,
      });
    } else if (
      !contactUs.loading &&
      contactUs.contactUsFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: contactUs.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        ),
      });
      // this.form.current.resetFields();
      this.setState({
        reset: false,
      });
    }
  }

  handleMenuClick = (e) => {
    // message.info('Please choose a Language.');
    // this.setState({ directory: [] });
    this.props.history.push(this.state.links[e.key - 1].url);
    // console.log("Please choose a Language.", e, this.state.links);
  };

  handleLanguageClick = (e) => {
    console.log("Language Picked!");
  };
  
  render() {
    const { links, filteredData } = this.state;
    const { dbAuth } = this.props;
    // console.log(filteredData);

    // const menu = (
    //   <Menu onClick={this.handleLanguageClick}>
    //     <Menu.Item key="1" className="header-language-text">
    //       English
    //     </Menu.Item>
    //     <hr className="divider" />
    //     <Menu.Item key="2" className="header-language-text">
    //       Bahasa Melayu
    //     </Menu.Item>
    //     {/* <Menu.Item key="3"  className="header-language-text">
    //       Bahasa Indonesia
    //     </Menu.Item> */}
    //   </Menu>
    // );

    return (
      <Fragment>
        <Row className="header-row1" align="middle">
          <Col xs={8} sm={10} md={6} lg={8} xl={12}>
            {/* Change md={8} for no language dropdown */}
            {/* Change md={5} for language dropdown */}
            <div className="header-title">BizMastery</div>
          </Col>
          <Col
            xs={16}
            sm={14}
            md={18}
            lg={16}
            xl={12}
            style={{ textAlign: "end" }}
          >
            {dbAuth.userInfo && (
              <Popover
                visible={this.state.languagePopoverVisible}
                onVisibleChange={this.languagePopoverChange}
                // onClick={this.languagePopoverClick}
                overlayClassName="home-page-popover-container-wrapper"
                content={
                  <Menu
                    defaultSelectedKeys={[
                      dbAuth.userInfo
                        ? dbAuth.userInfo.nativeLanguage
                          ? dbAuth.userInfo.nativeLanguage
                          : "en"
                        : "en",
                    ]}
                    style={{
                      fontFamily: "Calibri-Regular",
                      fontSize: "16px",
                      borderRight: "none",
                    }}
                    onClick={(e) => this.onLanguageMenuClick(e)}
                  >
                    <Menu.Item key={"en"}>
                      <img
                        src="/assets/businessPortal/uk-icon.svg"
                        alt=""
                        style={{ width: "50px", height: "25px" }}
                      />
                      English
                    </Menu.Item>
                    <Menu.Item key={"ms"}>
                      <img
                        src="/assets/businessPortal/malaysia-icon.svg"
                        alt=""
                        style={{ width: "50px", height: "25px" }}
                      />
                      Bahasa Malaysia
                    </Menu.Item>
                    <Menu.Item key={"cs"}>
                      <img
                        src="/assets/businessPortal/china-icon.svg"
                        alt=""
                        style={{ width: "50px", height: "25px" }}
                      />
                      简体中文
                    </Menu.Item>
                    <Menu.Item key={"ct"}>
                      <img
                        src="/assets/businessPortal/china-icon.svg"
                        alt=""
                        style={{ width: "50px", height: "25px" }}
                      />
                      繁體中文
                    </Menu.Item>
                    <Menu.Item key={"in"}>
                      <img
                        src="/assets/businessPortal/indonesia-icon.svg"
                        alt=""
                        style={{ width: "50px", height: "25px" }}
                      />
                      Bahasa Indonesia
                    </Menu.Item>
                    {/* <Menu.Item key={"th"}>
                      <img
                        src="/assets/businessPortal/thailand-icon.svg"
                        alt=""
                        style={{ width: "50px", height: "25px" }}
                      />
                      ไทย
                    </Menu.Item> */}
                  </Menu>
                }
                trigger="click"
              >
                <span
                  className="header-button"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {dbAuth.userInfo
                    ? dbAuth.userInfo.nativeLanguage
                      ? dbAuth.userInfo.nativeLanguage === "en"
                        ? "English"
                        : dbAuth.userInfo.nativeLanguage === "ms"
                        ? "Malay"
                        : dbAuth.userInfo.nativeLanguage === "cs"
                        ? "简体中文"
                        : dbAuth.userInfo.nativeLanguage === "ct"
                        ? "繁體中文"
                        : dbAuth.userInfo.nativeLanguage === "in"
                        ? "Indonesia"
                        : "ไทย"
                      : "English"
                    : "English"}
                  &nbsp;&nbsp;
                  <img
                    src="/assets/businessPortal/down-ui-icon.svg"
                    alt=""
                    width="20px"
                  />
                </span>
              </Popover>
            )}

            <Select
              showSearch
              value={this.state.value}
              style={{ textAlign: "start" }}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.handleSearch}
              onChange={this.handleChange}
              notFoundContent={null}
              className="header-search-input"
              placeholder={
                <React.Fragment>
                  <img
                    src="/assets/businessPortal/search-icon.png"
                    alt="Search Icon"
                  />
                  &nbsp;{" "}
                  {
                    SEARCH[
                      dbAuth.userInfo
                        ? dbAuth.userInfo.nativeLanguage
                          ? dbAuth.userInfo.nativeLanguage
                          : "en"
                        : "en"
                    ]
                  }
                </React.Fragment>
              }
              bordered={false}
            >
              {filteredData.map((link, idx) => (
                <Select.Option key={idx} value={link.title}>
                  <Link to={link.url}>
                    <div>{link.title}</div>
                  </Link>
                </Select.Option>
              ))}
            </Select>

            <Button className="header-button" onClick={this.showModal}>
              {
                CONTACTUS[
                  dbAuth.userInfo
                    ? dbAuth.userInfo.nativeLanguage
                      ? dbAuth.userInfo.nativeLanguage
                      : "en"
                    : "en"
                ]
              }
            </Button>

            <ContactUsModal
              visible={this.state.visible}
              hideModal={this.hideModal}
              sendMessage={this.sendMessage}
              auth={dbAuth}
            />
            <Popover
              // cursor="pointer"

              visible={this.state.hovered}
              onVisibleChange={this.handleHoverChange}
              placement="bottom"
              trigger="click"
              content={
                <>
                  <div
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      width: "82px",
                      cursor: "pointer",
                    }}
                  >
                    <Text
                      className="header-popover-text"
                      onClick={this.handleEditProfileClick}
                    >
                      {
                        EDITPROFILE[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                      <br />
                    </Text>
                  </div>
                  <Divider />
                  <div
                    style={{
                      paddingBottom: "10px",
                      textAlign: "center",
                      width: "82px",
                      cursor: "pointer",
                    }}
                  >
                    <Link to="/">
                      <Text
                        className="header-popover-text"
                        onClick={this.handleSignOutBtnClick}
                      >
                        {
                          SIGNOUT[
                            dbAuth.userInfo
                              ? dbAuth.userInfo.nativeLanguage
                                ? dbAuth.userInfo.nativeLanguage
                                : "en"
                              : "en"
                          ]
                        }
                        <br />
                      </Text>
                    </Link>
                  </div>
                </>
              }
            >
              {/* <img
                style={{ cursor: "pointer" }}
                src="/assets/businessPortal/profile_default.png"
                alt="Sample Profile"
                className="header-profile-pic"
              /> */}

              {dbAuth.userInfo && (
                <img
                  src={
                    dbAuth.userInfo.profileImagePath.length > 0
                      ? dbAuth.userInfo.profileImageUrl
                      : "/assets/businessPortal/profile_default.png"
                  }
                  style={{ cursor: "pointer" }}
                  alt="Sample Profile"
                  className="header-profile-pic"
                />
              )}
            </Popover>
          </Col>
        </Row>
        <Row className="header-row2">
          <Col xs={0} md={1} xl={3} />
          <Col xs={0} md={22} xl={18}>
            <Row justify="space-between">
              {links.map((link, idx) => (
                <Col flex={1} key={idx}>
                  <Link to={link.url}>
                    <div className="header-row2-text">{link.title}</div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={0} md={1} xl={3} />
          <Col xs={24} md={0}>
            {/* {console.log(directory)} */}
            <Menu
              mode="vertical"
              className="header-menu"
              onClick={(e) => this.handleMenuClick(e)}
            >
              <SubMenu
                key="menu"
                title={
                  DIRECTORY[
                    dbAuth.userInfo
                      ? dbAuth.userInfo.nativeLanguage
                        ? dbAuth.userInfo.nativeLanguage
                        : "en"
                      : "en"
                  ]
                }
              >
                {links.map((link, index) => (
                  <Menu.Item key={index + 1} className="header-menu-text">
                    {link.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            </Menu>
            <Menu
              mode="vertical"
              className="header-menu"
              onClick={(e) => this.onLanguageMenuClick(e)}
            >
              <SubMenu
                key="menu"
                title={
                  SELECTLANGUAGE[
                    dbAuth.userInfo
                      ? dbAuth.userInfo.nativeLanguage
                        ? dbAuth.userInfo.nativeLanguage
                        : "en"
                      : "en"
                  ]
                }
              >
                <Menu.Item key={"en"}>
                  <img
                    src="/assets/businessPortal/uk-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                  English
                </Menu.Item>
                <Menu.Item key={"ms"}>
                  <img
                    src="/assets/businessPortal/malaysia-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                  Bahasa Malaysia
                </Menu.Item>
                <Menu.Item key={"cs"}>
                  <img
                    src="/assets/businessPortal/china-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                  简体中文
                </Menu.Item>
                <Menu.Item key={"ct"}>
                  <img
                    src="/assets/businessPortal/china-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                  繁體中文
                </Menu.Item>
                <Menu.Item key={"in"}>
                  <img
                    src="/assets/businessPortal/indonesia-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                  Bahasa Indonesia
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const key = "contactUsNotification";
const mapDispatchToProps = {
  logout,
  sendMessage,
  updateUserLanguageInfo,
  resetTranslation
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  contactUs: state.contactUs,
  dbAuth: state.auth,
  // general: state.general,
  translations: state.translations,
});
export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
