import React, { Component, Fragment } from "react";
import { Row, Col, Typography, Modal, Divider } from "antd";

const { Text } = Typography;

class EBOOKModal extends Component {
  // form = React.createRef();
  render() {
    const { visible, hideModal } = this.props;
    return (
      <Fragment>
        <Modal visible={visible} footer={false} onCancel={hideModal}>
          <Row key="">
            <Col span={2} />
            <Col span={20}>
              <Row>
                <Col offset={1} span={22} style={{ textAlign: "center" }}>
                  <Text className="home-title">
                    Download E-Book & Power Point
                  </Text>
                </Col>
                <Divider />
                <Col span={24}>
                  <div>1. Formula “A.I.D.A” ( Sales Presentation)</div>

                  <div>2. Sales Click Funnels ( Follow Chart )</div>

                  <div>3. Sales Click Funnels 2 ( Follow Chart )</div>

                  <div>4. Sosial Marketing Game Plan</div>

                  <div>5. Foundation Why stages Matter (My Signature Talk)</div>

                  <div>6. Copywriting Mastery</div>

                  <div>7. Facebook Friendly Marketing</div>

                  <div>8. Facebook Advertising</div>

                  <div>9. Facebook Advertising Checklist</div>

                  <div>10. The 5 Step to Fecebook Live</div>

                  <div>11. ManyChat (Messenger Auto Responder)</div>
                </Col>
              </Row>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

export default EBOOKModal;
