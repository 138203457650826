
import { operationFailed, operationCompleted, operationStarted } from "../operation/operationActions";
import { LOAD_FUNNEL_PAGE, CHECK_SALES_FUNNEL_AVAILABILITY, SAVE_FUNNEL_PAGE, RESET_SALES_FUNNEL_DATA } from "./salesFunnelConstant";
import { operationTypeEnum } from "../OperationTypeConstant";
import { firebaseFunc } from "../app/config/firebase";
import { loadFunnelPageBookBackground } from "../image/imageActions";
import { RESET_BOOK_BACKGROUND } from "../image/imageConstant";

export const saveSalesFunnel = (values, uid, profileImageObj, isUpdate, bookId) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const db = getFirebase().firestore();

        try {
            dispatch(operationStarted(operationTypeEnum.SAVE_FUNNEL_PAGE))


            //clean up old storage content first, if any...
            let salesfunnelSnap = await db.doc(`sales_funnels/${uid}`).get();
            if (salesfunnelSnap.exists) {
                let backgroundImagePath = salesfunnelSnap.data().backgroundImagePath;
                await firebase.deleteFile(backgroundImagePath);

                // let profileImagePath = salesfunnelSnap.data().profileImagePath;
                // //consider clean up IFF there's new image to upload
                // if (profileImageObj.imageLocalList.length > 0) {
                //     if (profileImagePath.length > 0) { //optional
                //         await firebase.deleteFile(profileImagePath);
                //     }
                // }
            }

            let updatedAt = new Date();
            let infoToUpdateObj = {}

            //download background & reupload again
            let backgroundImageUrl = values.backgroundImageUrls[values.selectedBackgroundIdx]

            let imageDownloadPromise = new Promise((resolve, reject) => {

                let xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function (event) {
                    //this.status === xhr.status
                    if (xhr.status === 200) {
                        resolve(xhr.response)
                    } else {
                        reject(xhr.status)
                    }

                };
                xhr.open('GET', backgroundImageUrl);
                xhr.send();
            });

            let downloadedBlob = await imageDownloadPromise;
            let matchedBackgroundImageInArr = getState().image.funnelPageImageObj.backgroundImagePathList[values.selectedBackgroundIdx].split("/");
            let newBackgroundImageName = matchedBackgroundImageInArr[matchedBackgroundImageInArr.length - 1]
            let newBackgroundImagePathDir = `sales_funnels/${uid}/backgroundImagePath`;
            let newBackgroundImagePath = `${newBackgroundImagePathDir}/${newBackgroundImageName}`;
            await getFirebase().storage().ref(newBackgroundImagePath).put(downloadedBlob);
            infoToUpdateObj.backgroundImagePath = newBackgroundImagePath;



            // if (profileImageObj.imageLocalList.length > 0) {
            //     let newFunnelPageProfileImagePathDir = `sales_funnels/${uid}/profileImagePath`;
            //     await firebase.uploadFile(newFunnelPageProfileImagePathDir, profileImageObj.imageLocalList[0]);
            //     //update document with latest uploaded file path
            //     let profileImagePath = `${newFunnelPageProfileImagePathDir}/${profileImageObj.imageLocalList[0].name}`;
            //     infoToUpdateObj.profileImagePath = profileImagePath;

            // } else {
            //     infoToUpdateObj.profileImagePath = isUpdate ? getState().salesFunnel.funnelPageObj.profileImagePath : ""
            // }

            infoToUpdateObj.bookId = bookId;
            infoToUpdateObj.updatedAt = updatedAt

            infoToUpdateObj.eBookCoverInfo = {
                bookNameInfo: {
                    text: values.eBookCoverObj.bookNameObj.text,
                    color: values.eBookCoverObj.bookNameObj.color,
                    fontSize: values.eBookCoverObj.bookNameObj.fontSize,
                    fontFamily: values.eBookCoverObj.bookNameObj.fontFamily
                },
                authorInfo: {
                    text: values.eBookCoverObj.authorObj.text,
                    color: values.eBookCoverObj.authorObj.color,
                    fontSize: values.eBookCoverObj.authorObj.fontSize,
                    fontFamily: values.eBookCoverObj.authorObj.fontFamily
                },
                tagline1Info: {
                    text: values.eBookCoverObj.tagline1Obj.text,
                    color: values.eBookCoverObj.tagline1Obj.color,
                    fontSize: values.eBookCoverObj.tagline1Obj.fontSize,
                    fontFamily: values.eBookCoverObj.tagline1Obj.fontFamily
                },
                tagline2Info: {
                    text: values.eBookCoverObj.tagline2Obj.text,
                    color: values.eBookCoverObj.tagline2Obj.color,
                    fontSize: values.eBookCoverObj.tagline2Obj.fontSize,
                    fontFamily: values.eBookCoverObj.tagline2Obj.fontFamily
                }
            }

            infoToUpdateObj.buttonInfo = {
                text: values.buttonObj.text,
                buttonUrl: values.buttonObj.buttonUrl,
                color: values.buttonObj.color,
                fontSize: values.buttonObj.fontSize,
                fontFamily: values.buttonObj.fontFamily
            }

            infoToUpdateObj.headerInfo = {
                text: values.headerObj.text,
                color: values.headerObj.color,
                fontSize: values.headerObj.fontSize,
                fontFamily: values.headerObj.fontFamily
            }

            infoToUpdateObj.outline1Info = {
                text: values.outline1Obj.text,
                color: values.outline1Obj.color,
                fontSize: values.outline1Obj.fontSize,
                fontFamily: values.outline1Obj.fontFamily
            }

            infoToUpdateObj.outline2Info = {
                text: values.outline2Obj.text,
                color: values.outline2Obj.color,
                fontSize: values.outline2Obj.fontSize,
                fontFamily: values.outline2Obj.fontFamily
            }

            infoToUpdateObj.outline3Info = {
                text: values.outline3Obj.text,
                color: values.outline3Obj.color,
                fontSize: values.outline3Obj.fontSize,
                fontFamily: values.outline3Obj.fontFamily
            }

            infoToUpdateObj.outline4Info = {
                text: values.outline4Obj.text,
                color: values.outline4Obj.color,
                fontSize: values.outline4Obj.fontSize,
                fontFamily: values.outline4Obj.fontFamily
            }

            infoToUpdateObj.outline5Info = {
                text: values.outline5Obj.text,
                color: values.outline5Obj.color,
                fontSize: values.outline5Obj.fontSize,
                fontFamily: values.outline5Obj.fontFamily,
                isVisible: values.outline5Obj.isVisible
            }

            infoToUpdateObj.bottomDesc1Info = {
                text: values.bottomDesc1Obj.text,
                color: values.bottomDesc1Obj.color,
                fontSize: values.bottomDesc1Obj.fontSize,
                fontFamily: values.bottomDesc1Obj.fontFamily
            }

            infoToUpdateObj.bottomDesc2Info = {
                text: values.bottomDesc2Obj.text,
                color: values.bottomDesc2Obj.color,
                fontSize: values.bottomDesc2Obj.fontSize,
                fontFamily: values.bottomDesc2Obj.fontFamily
            }

            infoToUpdateObj.bottomDesc3Info = {
                text: values.bottomDesc3Obj.text,
                color: values.bottomDesc3Obj.color,
                fontSize: values.bottomDesc3Obj.fontSize,
                fontFamily: values.bottomDesc3Obj.fontFamily
            }

            if (isUpdate) {
                await db.doc(`sales_funnels/${uid}`).set({ ...infoToUpdateObj }, { merge: true })
            } else {
                await db.doc(`sales_funnels/${uid}`).set({ ...infoToUpdateObj })
            }

            dispatch({ type: SAVE_FUNNEL_PAGE, payload: { infoToUpdateObj } })


            let generateEBookForSalesFunnel = firebaseFunc.httpsCallable("generateEBookForSalesFunnel");
            let result = await generateEBookForSalesFunnel({ bookId, uid })

            if (result.data.msg !== "OK") {
                throw (new Error("Some issues occured during eBook generation. Please check with admin."))
            }

            dispatch(operationCompleted())

        } catch (err) {
            console.log(err)
            dispatch(operationFailed())
        }
    }
}


// export const saveSalesFunnel_OLD = (values, uid, profileImageObj, isUpdate, bookId) => {
//     return async (dispatch, getState, { getFirebase }) => {
//         const firebase = getFirebase();
//         const db = getFirebase().firestore();

//         try {
//             dispatch(operationStarted(operationTypeEnum.SAVE_FUNNEL_PAGE))
//             let updatedAt = new Date();
//             let infoToUpdateObj = {}

//             //download background & reupload again
//             let backgroundImageUrl = values.backgroundImageUrls[values.selectedBackgroundIdx]

//             let imageDownloadPromise = new Promise((resolve, reject) => {

//                 let xhr = new XMLHttpRequest();
//                 xhr.responseType = 'blob';
//                 xhr.onload = function (event) {
//                     //this.status === xhr.status
//                     if (xhr.status === 200) {
//                         resolve(xhr.response)
//                     } else {
//                         reject(xhr.status)
//                     }

//                 };
//                 xhr.open('GET', backgroundImageUrl);
//                 xhr.send();
//             });

//             let downloadedBlob = await imageDownloadPromise;
//             let matchedBackgroundImageInArr = getState().image.funnelPageImageObj.backgroundImagePathList[values.selectedBackgroundIdx].split("/");
//             let newBackgroundImageName = matchedBackgroundImageInArr[matchedBackgroundImageInArr.length - 1]
//             let newBackgroundImagePathDir = `sales_funnels/${uid}/backgroundImagePath`;
//             let newBackgroundImagePath = `${newBackgroundImagePathDir}/${newBackgroundImageName}`;
//             await getFirebase().storage().ref(newBackgroundImagePath).put(downloadedBlob);
//             infoToUpdateObj.backgroundImagePath = newBackgroundImagePath;


//             if (profileImageObj.imageLocalList.length > 0) {
//                 let newFunnelPageProfileImagePathDir = `sales_funnels/${uid}/profileImagePath`;
//                 await firebase.uploadFile(newFunnelPageProfileImagePathDir, profileImageObj.imageLocalList[0]);
//                 //update document with latest uploaded file path
//                 let profileImagePath = `${newFunnelPageProfileImagePathDir}/${profileImageObj.imageLocalList[0].name}`;
//                 infoToUpdateObj.profileImagePath = profileImagePath;

//                 if (getState().salesFunnel.funnelPageObj &&
//                     getState().salesFunnel.funnelPageObj.profileImagePath.length > 0 &&
//                     profileImagePath !== getState().salesFunnel.funnelPageObj.profileImagePath) {

//                     await firebase.deleteFile(getState().salesFunnel.funnelPageObj.profileImagePath);
//                 }
//             } else {
//                 infoToUpdateObj.profileImagePath = isUpdate ? getState().salesFunnel.funnelPageObj.profileImagePath : ""
//             }

//             infoToUpdateObj.bookId = bookId;
//             infoToUpdateObj.updatedAt = updatedAt
//             infoToUpdateObj.bookName = values.bookName
//             infoToUpdateObj.author = values.author
//             infoToUpdateObj.tagline1 = values.tagline1
//             infoToUpdateObj.tagline2 = values.tagline2
//             infoToUpdateObj.eBookDesc = values.eBookDesc
//             infoToUpdateObj.header = values.header
//             infoToUpdateObj.outline1 = values.outline1
//             infoToUpdateObj.outline2 = values.outline2
//             infoToUpdateObj.outline3 = values.outline3
//             infoToUpdateObj.outline4 = values.outline4
//             infoToUpdateObj.outline5 = values.outline5
//             infoToUpdateObj.buttonName = values.buttonName
//             infoToUpdateObj.buttonUrl = values.buttonUrl
//             infoToUpdateObj.bottomDesc1 = values.bottomDesc1
//             infoToUpdateObj.bottomDesc2 = values.bottomDesc2
//             infoToUpdateObj.bottomDesc3 = values.bottomDesc3

//             if (isUpdate) {
//                 await db.doc(`sales_funnels/${uid}`).set({ ...infoToUpdateObj }, { merge: true })
//             } else {
//                 await db.doc(`sales_funnels/${uid}`).set({ ...infoToUpdateObj })
//             }

//             dispatch({ type: SAVE_FUNNEL_PAGE, payload: { infoToUpdateObj } })


//             let generateEBookForSalesFunnel = firebaseFunc.httpsCallable("generateEBookForSalesFunnel");
//             let result = await generateEBookForSalesFunnel({ bookId, uid })

//             if (result.data.msg !== "OK") {
//                 throw (new Error("Some issues occured during eBook generation. Please check with admin."))
//             }

//             dispatch(operationCompleted())

//         } catch (err) {
//             console.log(err)
//             dispatch(operationFailed())
//         }
//     }
// }





export const loadFunnelPageData = (uid) => {
    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();

        try {
            dispatch(operationStarted(operationTypeEnum.LOAD_FUNNEL_PAGE))

            let funnelPageObj = {}
            let docSnap = await db.doc(`sales_funnels/${uid}`).get();
            if (docSnap.exists) {
                funnelPageObj = { ...docSnap.data(), docId: docSnap.id }
            }

            dispatch({ type: LOAD_FUNNEL_PAGE, payload: { funnelPageObj } })

            //load template
            await dispatch(loadFunnelPageBookBackground())


            dispatch(operationCompleted())

        } catch (err) {
            console.log(err)

            dispatch(operationFailed())
        }
    }
}

export const loadFunnelPageDataOnlyBackground = () => {
    return async (dispatch, getState, { getFirebase }) => {

        try {
            dispatch(operationStarted(operationTypeEnum.LOAD_FUNNEL_PAGE))

            //load template
            await dispatch(loadFunnelPageBookBackground())


            dispatch(operationCompleted())

        } catch (err) {
            console.log(err)

            dispatch(operationFailed())
        }
    }
}

export const resetSalesFunnelData = () => {
    return async (dispatch) => {
        try {

            dispatch({ type: RESET_SALES_FUNNEL_DATA })
            dispatch({ type: RESET_BOOK_BACKGROUND })

        } catch (err) {
            console.log(err)
        }
    }
}



export const checkIfUserOwnFunnelPage = (uid) => {
    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        const storageRef = getFirebase().storage().ref();
        try {

            let isOwningSalesFunnel = false
            let docSnap = await db.doc(`sales_funnels/${uid}`).get();

            //if exist,s capture pdf file path
            let pdfFileUrl = "";
            if (docSnap.exists) {
                isOwningSalesFunnel = true
                if (docSnap.data().eBookFilePath && docSnap.data().eBookFilePath.length > 0) {
                    pdfFileUrl = await storageRef.child(docSnap.data().eBookFilePath).getDownloadURL();
                }
            }
            dispatch({ type: CHECK_SALES_FUNNEL_AVAILABILITY, payload: { isOwningSalesFunnel, pdfFileUrl } })

        } catch (err) {
            console.log(err)
            throw (err)


        }
    }
}

