import React, { Fragment, Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NavigationComponent from "./NavigationComponent";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import TopBar from "./TopBar";
import ECodeDetailsPage from "./ECodeDetailsPage";
import GenerateECodesPage from "./GenerateECodesPage";

class ConsoleDashboard extends Component {
  filterMenuKey = key => {
    if (this.props.location.pathname.search(key) >= 0) {
      return this.props.location.pathname;
    } else {
      return key;
    }
  };

  // componentDidUpdate = (prevProps) => {
  //   const auth = this.props.auth;
  //   if (prevProps.auth.isEmpty) {
  //     console.log("Fetch because just logged in");
  //     if(auth.isAnonymous){
  //       this.props.history.push("/");
  //     }
  //   }
  // };

  render() {
    const { auth } = this.props;
    const isAuthenticated = auth.uid && !auth.isAnonymous;
    if (!isAuthenticated) return <Redirect to="/" />; //TODO:remove this when passing to client for verification

    return (
      <Fragment>
        <Row>
          <Col span={24} style={{ borderBottom: "1px solid #DFDFDF" }}>
            <TopBar />
          </Col>
          <Col
            flex="2"
            style={{ borderRight: "1px solid #DFDFDF", minHeight: "1000px" }}
          >
            <NavigationComponent filterMenuKey={this.filterMenuKey} />
          </Col>
          <Col flex="9">
            <Switch>
              <Redirect exact from="/console" to="/console/generate-e-codes" />
              <Route
                path="/console/generate-e-codes/:eCodeBundleId"
                component={ECodeDetailsPage}
              />
              <Route
                path="/console/generate-e-codes"
                component={GenerateECodesPage}
              />
            </Switch>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default connect(mapStateToProps)(ConsoleDashboard);
