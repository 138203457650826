import Template0001 from "./templates/Template0001";

export const eBookLanguageCodeEnum = {
    BAHASA_MALAYSIA: "ms",
    ENGLISH: "en"
};

export const supportedTemplateMap = new Map([
    ["T0001", Template0001],
])

export const salesFunnelPageModeEnum = {
    NEW: "NEW",
    UPDATE: "UPDATE",
    PUBLIC: "PUBLIC"
};

//font related
export const supportedFontVariantsMap = new Map([
    ["Lato", ["Regular", "Italic", "Bold"]],
    ["Roboto", ["Regular", "Italic", "Bold"]],
    ["OpenSans", ["Regular", "Italic", "Bold"]],
    ["Oswald", ["Regular", "Bold"]],
    ["SourceSansPro", ["Regular", "Italic", "Bold"]],
    ["Montserrat", ["Regular", "Italic", "Bold"]],
    ["Raleway", ["Regular", "Italic", "Bold"]],
    ["PTSans", ["Regular", "Italic", "Bold"]],
    ["Lora", ["Regular", "Italic", "Bold"]],
    ["NotoSans", ["Regular","Italic", "Bold"]],
])
//template text

//tagline
export const bookTaglineTemplateList = [
    { value: "Sample tagline", label: "Sample tagline" }
]

//title
export const titleTemplateList = [
    { value: "Sample Title", label: "Sample Title" }
];

//outline
export const outlineTemplateList = [
    { value: "Sample outline", label: "Sample outline" }
];

//bottom desc
export const bottomDescTemplateList = [
    { value: "Sample description", label: "Sample description" }
];

//constraint
export const minFontSize = 5
export const maxFontSize = 50
