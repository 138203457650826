import { Row, Col, Input, Collapse } from "antd";
import React, { Component, Fragment } from "react";
import "./EmailTemplates.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getTransText } from "../SharedUtils";
import { loadAllEmailTemplates } from "../BusinessPortal/lessons/courseActions";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import _ from "lodash";
import {
  SEARCH,
  CHOOSEEMAIL,
  EMAILTEMPLATESTABTITLE,
  EMAILTEMPLATESTABDESC
} from "./TranslationConstant";
const { Panel } = Collapse;

class EmailTemplates extends Component {
  state = {
    filter: "",
    isLoading: true
  };

  componentDidMount() {
    this.props.loadAllEmailTemplates();
  }

  async componentDidUpdate(prevProps) {
    const { course, auth, translations } = this.props;
    if (
      !_.isEqual(
        prevProps.course.emailTemplatesInfoObj,
        course.emailTemplatesInfoObj
      ) ||
      (course.emailTemplatesInfoObj !== null && this.state.isLoading)
    ) {
      let emailTemplatesInfoObj = [];
      course.emailTemplatesInfoObj.forEach(emailTemplates => {
        emailTemplatesInfoObj.push({
          ...emailTemplates,
          name: getTransText(
            auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
            emailTemplates.name,
            translations.translationObj
          ).text
        });
      });
      this.setState({
        isLoading: false,
        emailTemplatesInfoObj
      });
    }
    if (
      prevProps.auth.userInfo.nativeLanguage !== auth.userInfo.nativeLanguage
    ) {
      let emailTemplatesInfoObj = [];
      course.emailTemplatesInfoObj.forEach(emailTemplates => {
        emailTemplatesInfoObj.push({
          ...emailTemplates,
          name: getTransText(
            auth.userInfo.nativeLanguage,
            emailTemplates.name,
            translations.translationObj
          ).text
        });
      });
      this.setState({
        emailTemplatesInfoObj
      });
    }
  }

  handleChange = event => {
    this.setState({ filter: event.target.value });
  };

  render() {
    const { auth, translations } = this.props;

    const { isLoading, filter, emailTemplatesInfoObj } = this.state;
    if (isLoading) return <LoadingComponent auth={auth} />;

    const lowercasedFilter = filter.toLowerCase();
    const filteredData = emailTemplatesInfoObj.filter(item => {
      return Object.keys(item).some(
        key =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    const xlCol1 = filteredData.filter((v, i) => i % 3 === 0);
    const xlCol2 = filteredData.filter((v, i) => i % 3 === 1);
    const xlCol3 = filteredData.filter((v, i) => i % 3 === 2);
    const xsCol1 = filteredData.filter((v, i) => i % 2 === 0);
    const xsCol2 = filteredData.filter((v, i) => i % 2 === 1);

    return (
      <Fragment>
        <Row>
          <Col span={24}>
            <img
              src="/assets/businessPortal/email-banner.jpeg"
              alt="Email Templates Banner"
              className="email-banner"
            />
          </Col>
        </Row>
        <Row className="email-row1" justify="center">
          <Col span={22}>
            <div className="email-title">
              {
                EMAILTEMPLATESTABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <div className="email-description">
              {
                EMAILTEMPLATESTABDESC[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <Input
              onChange={this.handleChange}
              value={filter}
              className="email-search-input"
              placeholder={
                SEARCH[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
              prefix={
                <img
                  src="/assets/businessPortal/search-icon.png"
                  alt="Search Icon"
                />
              }
              bordered={false}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={1} />
          <Col xs={22}>
            <Row>
              <Col xs={24} style={{ textAlign: "center", margin: "30px 0px" }}>
                <div className="email-title">
                  {
                    CHOOSEEMAIL[
                      auth.userInfo.nativeLanguage
                        ? auth.userInfo.nativeLanguage
                        : "en"
                    ]
                  }
                </div>
              </Col>
            </Row>
            <Row justify="space-around" align="top" gutter={[40, 40]}>
              <Col xs={24} md={0}>
                {filteredData.map((category, index) => (
                  <Fragment key={index}>
                    {index === 0 ? "" : <br />}
                    <div className="accordion-category">
                      <div className="accordion-title">
                        {auth.userInfo.nativeLanguage
                          ? getTransText(
                              auth.userInfo.nativeLanguage,
                              category.name,
                              translations.translationObj
                            ).text
                          : getTransText(
                              "en",
                              category.name,
                              translations.translationObj
                            ).text}
                      </div>
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="accordian"
                      >
                        {/* {console.log(category.templates)} */}
                        {category.templates.map((value, index) => (
                          <Panel
                            header={
                              auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    value.name,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    value.name,
                                    translations.translationObj
                                  ).text
                            }
                            key={index + 1}
                            className="email-template-description"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      value.description,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      value.description,
                                      translations.translationObj
                                    ).text
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Fragment>
                ))}
              </Col>
              <Col xs={0} md={12} xl={0}>
                {xsCol1.map((category, index) => (
                  <Fragment key={index}>
                    {index === 0 ? "" : <br />}
                    <div className="accordion-category">
                      <div className="accordion-title">
                        {auth.userInfo.nativeLanguage
                          ? getTransText(
                              auth.userInfo.nativeLanguage,
                              category.name,
                              translations.translationObj
                            ).text
                          : getTransText(
                              "en",
                              category.name,
                              translations.translationObj
                            ).text}
                      </div>
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="accordian"
                      >
                        {category.templates.map((value, index) => (
                          <Panel
                            header={
                              auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    value.name,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    value.name,
                                    translations.translationObj
                                  ).text
                            }
                            key={index + 1}
                            className="email-template-description"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      value.description,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      value.description,
                                      translations.translationObj
                                    ).text
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Fragment>
                ))}
              </Col>
              <Col xs={0} md={12} xl={0}>
                {xsCol2.map((category, index) => (
                  <Fragment key={index}>
                    {index === 0 ? "" : <br />}
                    <div className="accordion-category">
                      <div className="accordion-title">
                        {auth.userInfo.nativeLanguage
                          ? getTransText(
                              auth.userInfo.nativeLanguage,
                              category.name,
                              translations.translationObj
                            ).text
                          : getTransText(
                              "en",
                              category.name,
                              translations.translationObj
                            ).text}
                      </div>
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="accordian"
                      >
                        {category.templates.map((value, index) => (
                          <Panel
                            header={
                              auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    value.name,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    value.name,
                                    translations.translationObj
                                  ).text
                            }
                            key={index + 1}
                            className="email-template-description"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      value.description,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      value.description,
                                      translations.translationObj
                                    ).text
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Fragment>
                ))}
              </Col>
              <Col xs={0} xl={8}>
                {xlCol1.map((category, index) => (
                  <Fragment key={index}>
                    {index === 0 ? "" : <br />}
                    <div className="accordion-category">
                      <div className="accordion-title">
                        {auth.userInfo.nativeLanguage
                          ? getTransText(
                              auth.userInfo.nativeLanguage,
                              category.name,
                              translations.translationObj
                            ).text
                          : getTransText(
                              "en",
                              category.name,
                              translations.translationObj
                            ).text}
                      </div>
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="accordian"
                      >
                        {category.templates.map((value, index) => (
                          <Panel
                            header={
                              auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    value.name,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    value.name,
                                    translations.translationObj
                                  ).text
                            }
                            key={index + 1}
                            className="email-template-description"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      value.description,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      value.description,
                                      translations.translationObj
                                    ).text
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Fragment>
                ))}
              </Col>
              <Col xs={0} xl={8}>
                {xlCol2.map((category, index) => (
                  <Fragment key={index}>
                    {index === 0 ? "" : <br />}
                    <div className="accordion-category">
                      <div className="accordion-title">
                        {auth.userInfo.nativeLanguage
                          ? getTransText(
                              auth.userInfo.nativeLanguage,
                              category.name,
                              translations.translationObj
                            ).text
                          : getTransText(
                              "en",
                              category.name,
                              translations.translationObj
                            ).text}
                      </div>
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="accordian"
                      >
                        {category.templates.map((value, index) => (
                          <Panel
                            header={
                              auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    value.name,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    value.name,
                                    translations.translationObj
                                  ).text
                            }
                            key={index + 1}
                            className="email-template-description"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      value.description,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      value.description,
                                      translations.translationObj
                                    ).text
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Fragment>
                ))}
              </Col>
              <Col xs={0} xl={8}>
                {xlCol3.map((category, index) => (
                  <Fragment key={index}>
                    {index === 0 ? "" : <br />}
                    <div className="accordion-category">
                      <div className="accordion-title">
                        {auth.userInfo.nativeLanguage
                          ? getTransText(
                              auth.userInfo.nativeLanguage,
                              category.name,
                              translations.translationObj
                            ).text
                          : getTransText(
                              "en",
                              category.name,
                              translations.translationObj
                            ).text}
                      </div>
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="accordian"
                      >
                        {category.templates.map((value, index) => (
                          <Panel
                            header={
                              auth.userInfo.nativeLanguage
                                ? getTransText(
                                    auth.userInfo.nativeLanguage,
                                    value.name,
                                    translations.translationObj
                                  ).text
                                : getTransText(
                                    "en",
                                    value.name,
                                    translations.translationObj
                                  ).text
                            }
                            key={index + 1}
                            className="email-template-description"
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: auth.userInfo.nativeLanguage
                                  ? getTransText(
                                      auth.userInfo.nativeLanguage,
                                      value.description,
                                      translations.translationObj
                                    ).text
                                  : getTransText(
                                      "en",
                                      value.description,
                                      translations.translationObj
                                    ).text
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Fragment>
                ))}
              </Col>
            </Row>
          </Col>
          <Col xs={1} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations,
  // general: state.general
});

const mapDispatchToProps = {
  loadAllEmailTemplates
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EmailTemplates);
