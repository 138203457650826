import {
  RESET_EBOOK,
  LOAD_ALL_EBOOKS
  //   LOAD_SELECTED_EBOOK,
  //   LOAD_EBOOK,
} from "./ebookConstant";

export const loadAllEBooks = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let eBookList = [];

      // if (languageCode === "ms") {
      //   let eBookEnQuerySnap = await db.collection(`eBooks_ms`).get();
      //   eBookEnQuerySnap.forEach(docSnap => {
      //     eBookList.push({ ...docSnap.data(), docId: docSnap.id });
      //   });
      // } else {
      //   let eBookEnQuerySnap = await db.collection(`eBooks_en`).get();
      //   eBookEnQuerySnap.forEach(docSnap => {
      //     eBookList.push({ ...docSnap.data(), docId: docSnap.id });
      //   });
      // }
      let eBookEnQuerySnap = await db.collection("ebooks").get();
      eBookEnQuerySnap.forEach(docSnap => {
        eBookList.push({ ...docSnap.data(), docId: docSnap.id });
      });
      // console.log(eBookList);
      dispatch({ type: LOAD_ALL_EBOOKS, payload: { eBookList } });
    } catch (err) {
      console.log(err);
    }
  };
};


export const resetEBook = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_EBOOK });
    } catch (err) {
      console.log(err);
    }
  };
};

// export const loadEBook = (languageCode) => {
//   return async (dispatch, getState, { getFirebase }) => {
//     const db = getFirebase().firestore();
//     try {
//       let eBookList = [];
//       let eBookQuerySnap = await db.collection("ebooks").get();
//       eBookQuerySnap.forEach((docSnap) => {
//         eBookList.push({ ...docSnap.data(), docId: docSnap.id });
//       });

//       dispatch({ type: LOAD_EBOOK, payload: { eBookList } });
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

// export const loadSelectedEBook = (languageCode, bookId) => {
//   return async (dispatch, getState, { getFirebase }) => {
//     const db = getFirebase().firestore();
//     try {
//       let selectedEBook = {};
//       let docSnap = await db.doc(`ebooks_${bookId}`).get();
//       if (docSnap.exists) {
//         selectedEBook = { ...docSnap.data(), docId: docSnap.id };
//       }

//       dispatch({ type: LOAD_SELECTED_EBOOK, payload: { selectedEBook } });
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };
