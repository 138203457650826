import React, { Fragment } from "react";
import { Button, Col, Modal, Row, Input } from "antd";

const ChangePasswordModal = ({
  isVisible,
  onCancel,
  onSave,
  onOldPasswordChange,
  onNewPasswordChange,
  onNewPasswordRepeatChange,
  oldPassword,
  newPassword,
  newPasswordRepeat,
  newPassErr
}) => {
  return (
    <Fragment>
      <Modal
        // bodyStyle={{
        //   backgroundColor: "white"
        // }}
        visible={isVisible}
        onCancel={onCancel}
        closeIcon={
          <img
            src="/assets/close-black-icon.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        }
        footer={null}
        width={500}
      >
        <div
          style={{
            fontFamily: "OpenSans-Bold",
            fontSize: "1.3rem",
            paddingLeft: "1rem"
          }}
        >
          CHANGE PASSWORD
        </div>

        <Row
          style={{
            fontFamily: "OpenSans-Regular",
            fontSize: "1rem",
            padding: "1rem"
          }}
        >
          <Col span={24}>
            <div
              style={{
                border: "1px solid rgba(112,112,112,0.4)",
                padding: "0.5rem",
                borderRadius: "1rem",
                marginTop: "1rem",
                backgroundColor: "white"
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",
                  marginLeft: "0.6rem",
                  color: "#757575"
                }}
              >
                Old Password
              </span>

              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <br />
                <img
                  src="/assets/lock-icon.svg"
                  alt=""
                  style={{ padding: "0.5rem" }}
                />
                <Input.Password
                  placeholder="Old Password"
                  type="password"
                  onChange={e => onOldPasswordChange(e, "oldPassword")}
                  value={oldPassword}
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    border: "none",
                    outline: "none"
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                border: "1px solid rgba(112,112,112,0.4)",
                padding: "0.5rem",
                borderRadius: "1rem",
                marginTop: "1rem",
                backgroundColor: "white"
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",
                  marginLeft: "0.6rem",
                  color: "#757575"
                }}
              >
                New Password
              </span>

              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <br />
                <img
                  src="/assets/lock-icon.svg"
                  alt=""
                  style={{ padding: "0.5rem" }}
                />
                <Input.Password
                  placeholder="New Password"
                  type="password"
                  onChange={e => onNewPasswordChange(e, "newPassword")}
                  value={newPassword}
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    border: "none",
                    outline: "none"
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            {newPassErr !== "" && (
              <div style={{ color: "red" }}>{newPassErr}</div>
            )}
          </Col>
          <Col span={24}>
            <div
              style={{
                border:
                  newPassword !== newPasswordRepeat && newPasswordRepeat !== ""
                    ? "1px solid red"
                    : "1px solid rgba(112,112,112,0.4)",
                padding: "0.5rem",
                borderRadius: "1rem",
                marginTop: "1rem",
                backgroundColor: "white"
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",
                  marginLeft: "0.6rem",
                  color: "#757575"
                }}
              >
                Repeat New Password
              </span>

              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <br />
                <img
                  src="/assets/lock-icon.svg"
                  alt=""
                  style={{ padding: "0.5rem" }}
                />
                <Input.Password
                  placeholder="Repeat New Password"
                  type="password"
                  onChange={e =>
                    onNewPasswordRepeatChange(e, "newPasswordRepeat")
                  }
                  value={newPasswordRepeat}
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    border: "none",
                    outline: "none"
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            {newPassword !== newPasswordRepeat && newPasswordRepeat !== "" && (
              <div style={{ color: "red" }}>Password doesn't match!</div>
            )}
          </Col>
        </Row>
        <Row
          justify="end"
          style={{
            backgroundColor: "#F8F8F9",
            borderBottomRightRadius: "24px",
            borderBottomLeftRadius: "24px",
            padding: "1rem"
          }}
        >
          <Col>
            <div
              style={{
                padding: "0.4rem 2rem",
                fontFamily: "OpenSans-Bold",
                fontSize: "1rem",
                cursor: "pointer"
              }}
              onClick={onCancel}
            >
              CANCEL
            </div>
          </Col>
          <Col>
            <Button
              style={{
                borderRadius: "2rem",
                padding: "0.4rem 2rem",
                fontFamily: "OpenSans-Bold",
                fontSize: "1rem",
                height: "auto",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                backgroundColor: "#E1E1E1",
                marginLeft: "2rem"
              }}
              onClick={onSave}
              disabled={
                newPassword !== newPasswordRepeat ||
                oldPassword === "" ||
                newPassword === "" ||
                newPasswordRepeat === "" ||
                newPassErr !== ""
              }
            >
              SAVE
            </Button>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default ChangePasswordModal;
