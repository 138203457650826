import React, { Fragment } from "react";
import { Modal, Form, Row, Col, Input, Button } from "antd";
import {
  RESET,
  CANCEL,
  ECODE,
  PLEASEENTERECODE,
  PROVIDENEWECODETORENEWACCOUNT
} from "../BusinessPortal/TranslationConstant";

const NewEcodeModal = React.forwardRef(
  ({ isVisible, onCancel, windowHeight, onSubmit, isUpdating, selectedLanguage }, ref) => (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        // width={windowHeight > 700 ? 700 : windowHeight - 30}
        width="500px"
        footer={null}
        forceRender
      >
        <Form
          ref={ref}
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Row
            style={{
              textAlign: "center",
              fontFamily: "Poppins-Regular",
              fontSize: "16px"
            }}
          >
            <Col
              xs={{ offset: 0, span: 24 }}
              sm={{ offset: 4, span: 16 }}
              style={{ marginTop: "16px" }}
            >
              {PROVIDENEWECODETORENEWACCOUNT[selectedLanguage]}
            </Col>
            <Col xs={0} sm={6} md={6} lg={6} xl={6} />

            <Col
              xs={{ offset: 0, span: 24 }}
              sm={{ offset: 4, span: 16 }}
              style={{ marginTop: "16px" }}
            >
              <Form.Item
                name="ecode"
                rules={[
                  {
                    required: true,
                    message: PLEASEENTERECODE[selectedLanguage]
                  }
                ]}
              >
                <Input
                  placeholder={ECODE[selectedLanguage]}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={0} sm={4} />
          </Row>

          <Row justify="end">
            <Col style={{ marginRight: "16px" }}>
              <Button onClick={onCancel}>
                {CANCEL[selectedLanguage]}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary" loading={isUpdating}>
                {RESET[selectedLanguage]}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  )
);

export default NewEcodeModal;
