import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from './app/store/configureStore';
import { Provider, useSelector } from 'react-redux';
import { firebase } from './app/config/firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import DbAuthLoad from './DbAuthLoad';
import DomainVal from './DomainVal';

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return null
  return children
}
const store = configureStore();


const rrfConfig = {
  // userProfiles: 'users',
  // useFirestoreForProfile: true
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

const rootEl = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <AuthIsLoaded>
          <DbAuthLoad>
            <DomainVal>
              <App />
            </DomainVal>
          </DbAuthLoad>
        </AuthIsLoaded>
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>
  ,
  rootEl
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
