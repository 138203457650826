import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";
import "./Exams.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { loadAllExam } from "./examActions";
import { getTransText } from "../../SharedUtils";
import { VIEWCERT, REPEAT, STARTNOW, LEVEL, EXAMTABTITLE } from "../TranslationConstant";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import _ from "lodash";

class Exams extends Component {
  state = {
    isLoading: true,
    reviewQuestions: false,
  };

  componentDidMount() {
    this.props.loadAllExam();
  }

  async componentDidUpdate(prevProps) {
    const { exams } = this.props;
    if (
      !_.isEqual(prevProps.exams.examInfoObj, exams.examInfoObj) ||
      (exams.examInfoObj !== null && this.state.isLoading)
    ) {
      // console.log(exams.examInfoObj);
      this.setState({
        isLoading: false,
      });
    }
  }

  onRepeatClick = (exam) => {
    // console.log(exam);
    this.props.history.push(`/business-portal/exams/${exam.docId}`);
  };

  onViewCertClick = (exam) => {
    this.props.history.push(`/business-portal/exams/${exam.docId}/certificate`);
  };

  onStartClick = (exam) => {
    // console.log(exam);
    this.props.history.push(`/business-portal/exams/${exam.docId}`);
  };

  render() {
    const { exams, auth, translations } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return <LoadingComponent auth={auth}/>;
    return (
      <Fragment>
        <Row>
          <Col xs={24}>
            <img
              src="/assets/businessPortal/exam-banner.jpeg"
              alt="Exam Banner"
              className="exam-banner"
            />
          </Col>
        </Row>
        <Row className="exam-row2">
          <Col xs={3} />
          <Col xs={18}>
            <div className="exam-description">
              {
                EXAMTABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <br />
            <Row justify="start" align="top" gutter={[60, 30]}>
              {exams.examInfoObj.map((exam, idx) => (
                <Col key={idx} xs={24} sm={24} md={24} lg={24} xl={12}>
                  <div className="exam-card">
                    <Row align="middle">
                      <Col xs={0} sm={24} md={12} lg={12} xl={14}>
                        <div className="exam-card-half">
                          {auth &&
                          auth.progressInfo &&
                          auth.progressInfo.exams &&
                          auth.progressInfo.exams[exam.docId] &&
                          auth.progressInfo.exams[exam.docId].score > 50 ? (
                            <>
                              <div className="exam-level-text-passed">
                                {
                                  LEVEL[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }{" "}
                                {
                                  getTransText(
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en",
                                    exam.name,
                                    translations.translationObj
                                  ).text
                                }
                              </div>
                              <img
                                src={`/assets/businessPortal/exam-passed-${auth.userInfo.nativeLanguage
                                  ? auth.userInfo.nativeLanguage
                                  : "en"}.svg`}
                                alt="Passed Exam"
                                className="exam-img"
                              />
                            </>
                          ) : (
                            <>
                              <div className="exam-level-text">
                                {
                                  LEVEL[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }{" "}
                                {
                                  getTransText(
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en",
                                    exam.name,
                                    translations.translationObj
                                  ).text
                                }
                              </div>
                              <img
                                src={`/assets/businessPortal/exam-untouched-${auth.userInfo.nativeLanguage
                                  ? auth.userInfo.nativeLanguage
                                  : "en"}.svg`}
                                alt="Untouched Exam"
                                className="exam-img"
                              />
                            </>
                          )}
                        </div>
                      </Col>
                      <Col
                        xs={24}
                        sm={0}
                        md={0}
                        lg={0}
                        xl={0}
                        style={{ textAlign: "center" }}
                      >
                        <div className="exam-card-half">
                          {auth &&
                          auth.progressInfo &&
                          auth.progressInfo.exams &&
                          auth.progressInfo.exams[exam.docId] &&
                          auth.progressInfo.exams[exam.docId].score > 50 ? (
                            <>
                              <div className="exam-level-text-passed">
                                {
                                  LEVEL[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }{" "}
                                {
                                  getTransText(
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en",
                                    exam.name,
                                    translations.translationObj
                                  ).text
                                }
                              </div>
                              <img
                                src="/assets/businessPortal/exam-passed.png"
                                alt="Passed Exam"
                                className="exam-img"
                                style={{ width: "90%" }}
                              />
                            </>
                          ) : (
                            <>
                              <div className="exam-level-text">
                                {
                                  LEVEL[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }{" "}
                                {
                                  getTransText(
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en",
                                    exam.name,
                                    translations.translationObj
                                  ).text
                                }
                              </div>
                              <img
                                src="/assets/businessPortal/exam-untouched.png"
                                alt="Untouched Exam"
                                className="exam-img"
                                style={{ width: "90%" }}
                                // width="100%"
                              />
                            </>
                          )}
                        </div>
                      </Col>
                      <Col xs={0} sm={24} md={12} lg={12} xl={10}>
                        <div className="exam-card-half">
                          {auth &&
                          auth.progressInfo &&
                          auth.progressInfo.exams &&
                          auth.progressInfo.exams[exam.docId] &&
                          auth.progressInfo.exams[exam.docId].score > 49 ? (
                            <>
                              <Button
                                className="exam-start-button"
                                onClick={() => this.onViewCertClick(exam)}
                              >
                                {" "}
                                {
                                  VIEWCERT[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </Button>
                              <br />
                              <br />
                              <Button
                                className="exam-start-button"
                                onClick={() => this.onRepeatClick(exam)}
                              >
                                {
                                  REPEAT[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </Button>
                            </>
                          ) : auth &&
                            auth.progressInfo &&
                            auth.progressInfo.exams &&
                            auth.progressInfo.exams[exam.docId] &&
                            auth.progressInfo.exams[exam.docId].score > -1 ? (
                            <>
                              <div className="exam-score-text">
                                {auth &&
                                  auth.progressInfo &&
                                  auth.progressInfo.exams &&
                                  auth.progressInfo.exams[exam.docId] &&
                                  auth.progressInfo.exams[exam.docId].score}
                                %
                              </div>
                              <br />
                              <Button
                                className="exam-start-button"
                                onClick={() => this.onRepeatClick(exam)}
                              >
                                {
                                  REPEAT[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </Button>
                            </>
                          ) : (
                            <Button
                              className="exam-start-button"
                              style={{ margin: "10px" }}
                              onClick={() => this.onStartClick(exam)}
                            >
                              {
                                STARTNOW[
                                  auth.userInfo.nativeLanguage
                                    ? auth.userInfo.nativeLanguage
                                    : "en"
                                ]
                              }
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                        <div className="exam-card-half">
                          {auth &&
                          auth.progressInfo &&
                          auth.progressInfo.exams &&
                          auth.progressInfo.exams[exam.docId] &&
                          auth.progressInfo.exams[exam.docId].score > 49 ? (
                            <>
                              <Button
                                className="exam-start-button-small"
                                style={{ marginTop: "10px" }}
                                onClick={() => this.onViewCertClick(exam)}
                              >
                                {
                                  VIEWCERT[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </Button>
                              <br />
                              <br />
                              <Button
                                className="exam-start-button-small"
                                onClick={() => this.onRepeatClick(exam)}
                              >
                                {
                                  REPEAT[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </Button>
                            </>
                          ) : auth &&
                            auth.progressInfo &&
                            auth.progressInfo.exams &&
                            auth.progressInfo.exams[exam.docId] &&
                            auth.progressInfo.exams[exam.docId].score > -1 ? (
                            <>
                              <div className="exam-score-text">
                                {auth &&
                                  auth.progressInfo &&
                                  auth.progressInfo.exams &&
                                  auth.progressInfo.exams[exam.docId] &&
                                  auth.progressInfo.exams[exam.docId].score}
                                %
                              </div>
                              <br />
                              <Button
                                className="exam-start-button-small"
                                onClick={() => this.onRepeatClick(exam)}
                              >
                                {
                                  REPEAT[
                                    auth.userInfo.nativeLanguage
                                      ? auth.userInfo.nativeLanguage
                                      : "en"
                                  ]
                                }
                              </Button>
                            </>
                          ) : (
                            <Button
                              className="exam-start-button-small"
                              style={{ marginTop: "10px" }}
                              onClick={() => this.onStartClick(exam)}
                            >
                              {
                                STARTNOW[
                                  auth.userInfo.nativeLanguage
                                    ? auth.userInfo.nativeLanguage
                                    : "en"
                                ]
                              }
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={3} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  exams: state.exams,
  auth: state.auth,
  translations: state.translations,
  // general: state.general
});

const mapDispatchToProps = {
  loadAllExam,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Exams);
