import React, { Component, Fragment } from "react";
import { Row, Col, Button, Collapse, Form, Input, message } from "antd";
// import Marquee from "react-marquee-slider";
import "@brainhubeu/react-carousel/lib/style.css";
import "../BusinessPortal/LandingPage.less";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import {
  initDBLoad,
  login,
  resetEmailPassword,
  updateEcodeForUser,
  updateUserLanguageInfo,
  updateLanguage,
} from "../auth/authActions";
import {
  PLEASEENTEREMAIL,
  EMAILADD,
  INVALIDEMAIL,
  PLEASEENTERPASSWORD,
  PASSWORD,
  PASSWORDREQUIRED,
} from "./../BusinessPortal/TranslationConstant";
import LoadingComponent from "../app/common/loading/LoadingComponent";

// import Bowser from "bowser"
const { Panel } = Collapse;

class Login extends Component {
  state = { isLoggingIn: false, isLoading: false };

  componentDidUpdate() {}

  showRegister = () => {
    this.props.history.push("/register");
  };

  onForgotPassowrdClick = () => {
    this.props.history.push("/login/forgotPassword");
  };

  onHandleLoginFormSubmit = (values) => {
    const { history, login, initDBLoad } = this.props;

    this.setState(
      {
        isLoggingIn: true,
      },
      async () => {
        let errorObj = await login(values);

        this.setState(
          {
            isLoggingIn: false,
          },
          async () => {
            if (errorObj) {
              message.error(errorObj.code + ":" + errorObj.message);
            } else {
              this.setState({ isLoading: true }, async () => {
                await initDBLoad(this.props.auth.uid); // use auth will get stale data since destructing happens before login call
                history.push("/business-portal");
              });
            }
          }
        );
      }
    );
  };

  render() {
    const { dbAuth } = this.props;
    const { isLoggingIn, isLoading } = this.state;
    if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        <Row>
          <Col className="login-background" xs={0} lg={10} flex="auto">
            <div
              style={{
                font: "2.3vw Poppins-Bold",
                color: "white",
                margin: "60px 60px",
                textAlign: "initial",
              }}
            >
              Get tomorrow's skills, today with mini MBA BizMastery.
            </div>
            <div
              style={{
                font: "1.5vw Poppins",
                color: "white",
                textAlign: "initial",
                margin: "60px 60px",
              }}
            >
              Unlock your full potential and future-proof your career with Mini
              MBA BizMastery.
            </div>
            <img
              className="login-image"
              src="/assets/New/Login/Login-Image.svg"
              alt="Landing Page Row 1"
              style={{ width: "80%" }}
            />
          </Col>
          <Col xs={0} lg={14}>
            <Col flex="auto" style={{ margin: "60px 180px" }}>
              <div
                style={{
                  font: "28px Poppins-Bold",
                  margin: "60px 0px",
                  color: "#5E51A1 ",
                }}
              >
                BizMastery
              </div>
              <div
                style={{
                  font: "34px Poppins-Bold",
                  margin: "60px 0px",
                  color: "black",
                }}
              >
                Log in now to get full access.
              </div>

              <Form ref={this.form} onFinish={this.onHandleLoginFormSubmit}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: INVALIDEMAIL[dbAuth.selectedLanguage],
                    },
                    {
                      required: true,
                      message: PLEASEENTEREMAIL[dbAuth.selectedLanguage],
                    },
                  ]}
                >
                  <Input
                    placeholder={EMAILADD[dbAuth.selectedLanguage]}
                    size="large"
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      borderColor: "#c2beda",
                      padding: "0px 30px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                      message: PASSWORDREQUIRED[dbAuth.selectedLanguage],
                      // type: "regexp"
                    },
                    {
                      required: true,
                      message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage],
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={PASSWORD[dbAuth.selectedLanguage]}
                    size="large"
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      borderColor: "#c2beda",
                      padding: "0px 30px",
                    }}
                  />
                </Form.Item>
                <Button
                  size="large"
                  htmlType="submit"
                  className="sign-up-button-blue"
                  loading={isLoggingIn}
                >
                  Login
                </Button>
              </Form>
              <br />
              <div style={{ textAlign: "center" }}>
                Don't have an account?{" "}
                <u
                  onClick={this.showRegister}
                  style={{ cursor: "pointer", color: "#5E51A1 " }}
                >
                  Register
                </u>{" "}
                <br />
                <br />
                <u
                  onClick={this.onForgotPassowrdClick}
                  style={{ cursor: "pointer", color: "#5E51A1 " }}
                >
                  Forgot password?
                </u>{" "}
              </div>
            </Col>
          </Col>
          <Col xs={24} lg={0}>
            <Col flex="auto" style={{ margin: "40px 40px" }}>
              <div
                style={{
                  font: "28px Poppins-Bold",
                  margin: "60px 0px",
                  color: "#5E51A1 ",
                }}
              >
                BizMastery
              </div>
              <div
                style={{
                  font: "34px Poppins-Bold",
                  margin: "60px 0px",
                  color: "black",
                }}
              >
                Log in now to get full access.
              </div>

              <Form ref={this.form} onFinish={this.onHandleLoginFormSubmit}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: INVALIDEMAIL[dbAuth.selectedLanguage],
                    },
                    {
                      required: true,
                      message: PLEASEENTEREMAIL[dbAuth.selectedLanguage],
                    },
                  ]}
                >
                  <Input
                    placeholder={EMAILADD[dbAuth.selectedLanguage]}
                    size="large"
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      borderColor: "#c2beda",
                      padding: "0px 30px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                      message: PASSWORDREQUIRED[dbAuth.selectedLanguage],
                      // type: "regexp"
                    },
                    {
                      required: true,
                      message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage],
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={PASSWORD[dbAuth.selectedLanguage]}
                    size="large"
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      borderColor: "#c2beda",
                      padding: "0px 30px",
                    }}
                  />
                </Form.Item>
                <Button
                  size="large"
                  htmlType="submit"
                  className="sign-up-button-blue"
                  loading={isLoggingIn}
                >
                  Login
                </Button>
              </Form>
              <br />
              <div style={{ textAlign: "center" }}>
                Don't have an account?{" "}
                <u
                  onClick={this.showRegister}
                  style={{ cursor: "pointer", color: "#5E51A1 " }}
                >
                  Register
                </u>{" "}
                <br />
                <br />
                <u
                  onClick={this.onForgotPassowrdClick}
                  style={{ cursor: "pointer", color: "#5E51A1 " }}
                >
                  Forgot password?
                </u>{" "}
              </div>
            </Col>
            <Col
              flex="auto"
              style={{
                backgroundColor: "#5E51A1 ",
                textAlign: "center",
                padding: "60px 60px 0px 60px",
              }}
            >
              <div
                style={{
                  font: "28px Poppins-Bold",
                  color: "white",
                  textAlign: "initial",
                }}
              >
                Get tomorrow's skills, today with mini MBA BizMastery.
              </div>
              <div
                style={{
                  font: "18px Poppins",
                  color: "white",
                  marginTop: "20px",
                  textAlign: "initial",
                }}
              >
                Unlock your full potential and future-proof your career with
                Mini MBA BizMastery.
              </div>
              <img
                src="/assets/New/Login/Login-Image.svg"
                alt="Landing Page Row 1"
                style={{ width: "80%" }}
              />
            </Col>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  contactUs: state.contactUs,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  initDBLoad,
  login,
  resetEmailPassword,
  updateEcodeForUser,
  updateUserLanguageInfo,
  updateLanguage,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
