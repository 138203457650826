import { Row, Col, Input, Button } from "antd";
import React, { Component, Fragment } from "react";
import "./ProbingQuestions.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getTransText } from "../SharedUtils";
import { loadProbingQuestions } from "../BusinessPortal/lessons/courseActions";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import {
  ALL,
  DISCOVERMORE,
  SEEMORE,
  SEARCH,
  PROBINGQUESTIONTABTITLE,
  PROBINGQUESTIONTABDESC
} from "./TranslationConstant";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";

class ProbingQuestions extends Component {
  // questions = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  state = {
    filter: "",
    isLoading: true,
    selectedIndex: 0,
    pageLimit: 12,
    currentLength: 12
  };

  componentDidMount() {
    this.props.loadProbingQuestions();
  }

  async componentDidUpdate(prevProps) {
    const { course, firebase, auth, translations } = this.props;
    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(
        prevProps.course.probingQuestionsInfoObj,
        course.probingQuestionsInfoObj
      ) ||
      (course.probingQuestionsInfoObj !== null && this.state.isLoading)
    ) {
      let imageUrlPromises = [];
      course.probingQuestionsInfoObj.forEach(item => {
        if (item.imagePath && item.imagePath.length > 0) {
          imageUrlPromises.push(
            storageRef.child(item.imagePath).getDownloadURL()
          );
        }
      });
      let imageUrl = await Promise.all(imageUrlPromises);

      var newProbingQuestionsInfoObj = [];
      const topics = [
        ALL[auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"]
      ];
      for (var i = 0; i < course.probingQuestionsInfoObj.length; i++) {
        // console.log(course.probingQuestionsInfoObj[i].name)
        topics.push(
          getTransText(
            auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
            course.probingQuestionsInfoObj[i].name,
            translations.translationObj
          ).text
        );
        newProbingQuestionsInfoObj.push({
          ...course.probingQuestionsInfoObj[i],
          name: getTransText(
            auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
            course.probingQuestionsInfoObj[i].name,
            translations.translationObj
          ).text,
          imageUrl: imageUrl[i]
        });
      }

      this.setState({
        isLoading: false,
        newProbingQuestionsInfoObj,
        imageUrl,
        topics
      });
    }
    if (
      prevProps.auth.userInfo.nativeLanguage !== auth.userInfo.nativeLanguage
    ) {
      let newProbingQuestionsInfoObj = this.state.newProbingQuestionsInfoObj;
      // console.log(newProbingQuestionsInfoObj);
      const topics = [
        ALL[auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"]
      ];
      for (var id = 0; id < course.probingQuestionsInfoObj.length; id++) {
        topics.push(
          getTransText(
            auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
            course.probingQuestionsInfoObj[id].name,
            translations.translationObj
          ).text
        );

        newProbingQuestionsInfoObj[id].name = getTransText(
          auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en",
          course.probingQuestionsInfoObj[id].name,
          translations.translationObj
        ).text;
      }

      this.setState({
        filter: "",
        selectedIndex: 0,
        topics,
        newProbingQuestionsInfoObj
      });
    }
  }

  handleChange = event => {
    this.setState({ filter: event.target.value });
  };

  onTopicButtonClick = (topic, index) => {
    if (index === 0) {
      this.setState({ selectedIndex: index, filter: "" });
    } else {
      this.setState({ selectedIndex: index, filter: topic });
    }
  };

  onDiscoverMoreClick = course => {
    this.props.history.push(
      `/business-portal/probing-questions/${course.docId}`
    );
  };

  onSeeMoreClick = () => {
    this.setState(prevState => ({
      currentLength: prevState.currentLength + prevState.pageLimit
    }));
  };

  render() {
    const { auth } = this.props;
    const {
      isLoading,
      filter,
      newProbingQuestionsInfoObj,
      topics,
      selectedIndex,
      currentLength
    } = this.state;
    if (isLoading) return <LoadingComponent auth={auth}/>;

    const lowercasedFilter = filter.toLowerCase();
    const filteredData = newProbingQuestionsInfoObj.filter(item => {
      return Object.keys(item).some(
        key =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    // console.log(newProbingQuestionsInfoObj, filteredData);
    return (
      <Fragment>
        <Row className="probing-questions-row">
          <Col xs={2} lg={3} xl={5} />
          <Col xs={20} lg={18} xl={14} style={{ textAlign: "center" }}>
            <div className="probing-questions-title">
              {
                PROBINGQUESTIONTABTITLE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <div className="probing-questions-description">
              {
                PROBINGQUESTIONTABDESC[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <Input
              onChange={this.handleChange}
              // value={filter}
              className="probing-questions-search-input"
              placeholder={
                SEARCH[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
              prefix={
                <img
                  src="/assets/businessPortal/search-icon2.png"
                  alt="Search Icon 2"
                />
              }
              bordered={false}
            />
          </Col>
          <Col xs={2} lg={3} xl={5} />
        </Row>
        <Row className="probing-questions-row2">
          <Col xs={2} lg={3} />
          <Col xs={20} lg={18}>
            <Row>
              <Col>
                {/* {console.log(topics.slice(0, pageLimit))} */}
                {topics.map((topic, index) =>
                  selectedIndex === index ? (
                    <Button
                      key={index}
                      className="filter-button"
                      style={{ backgroundColor: "#368973" }}
                    >
                      {topic}
                    </Button>
                  ) : (
                    <Button
                      key={index}
                      className="filter-button"
                      onClick={() => this.onTopicButtonClick(topic, index)}
                    >
                      {topic}
                    </Button>
                  )
                )}
              </Col>
            </Row>
            <hr className="probing-questions-divider" />
            <Row gutter={[30, 50]}>
              {filteredData.slice(0, currentLength).map((course, index) => (
                <Col xs={24} md={12} lg={8} xl={6} key={index}>
                  <div className="probing-questions-card">
                    <img
                      src={course.imageUrl}
                      alt={index}
                      className="probing-questions-card-img"
                    />
                    <div
                      className="card-overlay"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.onDiscoverMoreClick(course)}
                    >
                      <Button
                        className="card-overlay-button"
                        onClick={() => this.onDiscoverMoreClick(course)}
                      >
                        {
                          DISCOVERMORE[
                            auth.userInfo.nativeLanguage
                              ? auth.userInfo.nativeLanguage
                              : "en"
                          ]
                        }
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {filteredData.length > currentLength ? (
              <Button onClick={this.onSeeMoreClick} className="more-button">
                {
                  SEEMORE[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            ) : null}
          </Col>
          <Col xs={2} lg={3} />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth,
  translations: state.translations
  // general: state.general
});

const mapDispatchToProps = {
  loadProbingQuestions
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProbingQuestions);
