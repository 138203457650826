import {
  LOAD_EBOOK,
  RESET_EBOOK,
  LOAD_SELECTED_EBOOK,
  LOAD_ALL_EBOOKS
} from "./bookConstant";

export const loadAllEBooks = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let eBookList = [];
      // let eBookEnQuerySnap = await db
      //   .collection(`eBooks_${languageCode}`)
      //   .get();
      // eBookEnQuerySnap.forEach(docSnap => {
      //   eBookList.push({ ...docSnap.data(), docId: docSnap.id });
      // });

      let eBookMsQuerySnap = await db.collection("eBooks_ms").get();
      eBookMsQuerySnap.forEach(docSnap => {
        eBookList.push({ ...docSnap.data(), docId: docSnap.id });
      });

      dispatch({ type: LOAD_ALL_EBOOKS, payload: { eBookList } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadEBook = languageCode => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let eBookList = [];
      let eBookQuerySnap = await db.collection(`eBooks_${languageCode}`).get();
      eBookQuerySnap.forEach(docSnap => {
        eBookList.push({ ...docSnap.data(), docId: docSnap.id });
      });

      dispatch({ type: LOAD_EBOOK, payload: { eBookList } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadSelectedEBook = (languageCode, bookId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let selectedEBook = {};
      let docSnap = await db.doc(`eBooks_${languageCode}/${bookId}`).get();
      if (docSnap.exists) {
        selectedEBook = { ...docSnap.data(), docId: docSnap.id };
      }

      dispatch({ type: LOAD_SELECTED_EBOOK, payload: { selectedEBook } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetEBook = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_EBOOK });
    } catch (err) {
      console.log(err);
    }
  };
};
