import React, { Fragment } from "react";
import { Button, Col, Row } from "antd";
import "./FreeYoutube.css";
import { Link } from "react-router-dom";
import {
  FREEYOUTUBE,
  YOUTUBEDESC,
  CLICKHERE
} from "../BusinessPortal/TranslationConstant";

const FreeYoutube = auth => {
  return (
    <Fragment>
      <Row>
        <Col
          span={24}
          style={{
            borderRadius: "16px",
            padding: "40px",
            background: "white",
            boxShadow: "2px 1px rgba(0,0,0,0.14)",
            textAlign: "center"
          }}
        >
          <img src="/assets/youtube-logo.svg" alt="" />
          <br />
          <br />
          <div className="user-main-page-free-youtube-title-wrapper">
            {/* {console.log(auth)} */}
            {
              FREEYOUTUBE[
                auth
                ? auth.auth
                  ? auth.auth.userInfo
                    ? auth.auth.userInfo.nativeLanguage
                      ? auth.auth.userInfo.nativeLanguage
                      : "en"
                    : "en"
                  : "en"
                : "en"
              ]
            }
          </div>

          <br />
          <span className="user-main-page-free-youtube-desc-wrapper">
            {
              YOUTUBEDESC[
                auth
                ? auth.auth
                  ? auth.auth.userInfo
                    ? auth.auth.userInfo.nativeLanguage
                      ? auth.auth.userInfo.nativeLanguage
                      : "en"
                    : "en"
                  : "en"
                : "en"
              ]
            }
          </span>
          {/* <span
            className="user-main-page-free-youtube-desc-wrapper"
            style={{
              borderBottom: "1px solid black"
            }}
          >
            
          </span> */}
          <br />
          <br />
          <Button
            type="primary"
            style={{
              fontFamily: "Poppins-Medium",
              borderRadius: "4px",
              color: "white",
              height: "48px",
              width: "150px"
            }}
          >
            {
              CLICKHERE[
                auth
                ? auth.auth
                  ? auth.auth.userInfo
                    ? auth.auth.userInfo.nativeLanguage
                      ? auth.auth.userInfo.nativeLanguage
                      : "en"
                    : "en"
                  : "en"
                : "en"
              ]
            }
            <Link
              to={{ pathname: "https://www.youtube.com" }}
              target="_blank"
            ></Link>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FreeYoutube;
