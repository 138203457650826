import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin } from "antd";
import React, { Component } from "react";
import { Fragment } from "react";
import "./ExamQuestions.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { loadAllExam } from "./examActions";
import { updateExamProgress } from "../../auth/authActions";
import { getTransText } from "../../SharedUtils";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import { operationTypeEnum } from "../../OperationTypeConstant";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";
import { _saveExamProgress } from "../../SharedUtils";
import {
  BACK,
  CONTINUE,
  LEVEL,
  SAVINGEXAMPROGRESS,
  QUESTION
} from "../TranslationConstant";

class ExamQuestions extends Component {
  constructor(props) {
    super(props);
    this._saveExamProgress = _saveExamProgress.bind(this);
  }

  state = {
    showContent: 0,
    isLoading: true,
    answerValue: -1,
    totalCorrect: 0,
    answersIndex: [],
    saving: false,
    seenQuestions: []
  };

  componentDidMount() {
    const { match } = this.props;
    let levelId = match.params.levelId;

    this.props.loadAllExam();

    this.setState({
      levelId
    });
  }

  async componentDidUpdate(prevProps) {
    const { exams, auth } = this.props;
    const { levelId } = this.state;

    if (
      !_.isEqual(prevProps.exams.examInfoObj, exams.examInfoObj) ||
      (exams.examInfoObj !== null && this.state.isLoading)
    ) {
      const questionList = [];

      // console.log(exams.examInfoObj);
      for (const level of exams.examInfoObj) {
        if (level.docId === levelId) {
          for (const question of level.questions) {
            questionList.push(question);
          }
        }
      }

      var previousScore = 0;

      if (
        auth &&
        auth.progressInfo &&
        auth.progressInfo.exams &&
        auth.progressInfo.exams[levelId]
      ) {
        previousScore = auth.progressInfo.exams[levelId].score;
      }

      // console.log(questionList);
      this.setState({
        isLoading: false,
        questionList,
        previousScore
      });
    }

    const { operation } = this.props;
    const { currentScore } = this.state;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_EXAM_PROGRESS &&
        operation.operationType === operationTypeEnum.NO_OPS
      ) {
        if (currentScore > 49) {
          this.props.history.push(
            `/business-portal/exams/${levelId}/certificate`
          );
        } else {
          this.props.history.push(`/business-portal/exams/${levelId}/fail`);
        }
      }
    }
  }

  onBackClick = () => {
    window.history.back();
  };

  continue = () => {
    const {
      showContent,
      questionList,
      answersIndex,
      answerValue,
      seenQuestions
    } = this.state;
    seenQuestions.push(showContent);
    answersIndex.push(answerValue);
    var totalCorrect = this.state.totalCorrect;

    if (questionList[showContent].answer === answerValue) {
      totalCorrect += 1;
    }

    if (showContent === questionList.length - 1) {
      const score = ((totalCorrect / questionList.length) * 100).toFixed(0);

      this.setState(
        {
          totalCorrect: totalCorrect,
          answersIndex: answersIndex,
          currentScore: score,
          saving: true
        },
        () => {
          this._saveExamProgress();
        }
      );
    } else {
      this.setState({
        totalCorrect: totalCorrect,
        showContent: showContent + 1,
        answerValue: -1,
        answersIndex: answersIndex,
        seenQuestions
      });
    }
  };

  onChange = e => {
    // console.log("radio checked", e.target.value);
    this.setState({
      answerValue: parseInt(e.target.value)
    });
  };

  render() {
    const {
      isLoading,
      showContent,
      levelId,
      questionList,
      answerValue,
      saving,
      seenQuestions
    } = this.state;
    const { exams, operation, auth, translations } = this.props;
    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Spin
          tip={
            SAVINGEXAMPROGRESS[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
          spinning={operation.isProcessing && saving}
          size="large"
        >
          <Row className="lesson-paragraph-row">
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={0}
              xl={4}
              className="lesson-left-sidebar"
              style={{ padding: "40px 0px 0px 40px" }}
            >
              <Button
                icon={<LeftOutlined />}
                className="lesson-back-button"
                onClick={this.onBackClick}
              >
                {
                  BACK[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
              <br />
              <br />
              {exams.examInfoObj.map((lesson, idx) => {
                if (lesson.docId === levelId) {
                  return (
                    <div key={idx} className="lesson-left-sidebar-title">
                      {
                        LEVEL[
                          auth.userInfo.nativeLanguage
                            ? auth.userInfo.nativeLanguage
                            : "en"
                        ]
                      }{" "}
                      {auth.userInfo.nativeLanguage
                        ? getTransText(
                            auth.userInfo.nativeLanguage,
                            lesson.name,
                            translations.translationObj
                          ).text
                        : getTransText(
                            "en",
                            lesson.name,
                            translations.translationObj
                          ).text}
                    </div>
                  );
                } else return null;
              })}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={0}
              style={{ paddingTop: "40px", paddingLeft: "40px" }}
            >
              <Button
                icon={<LeftOutlined />}
                className="lesson-back-button-small"
                onClick={this.onBackClick}
                style={{ border: "0px" }}
              >
                {
                  BACK[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            </Col>

            <Col lg={24} xl={12} className="lesson-paragraph-container">
              {exams.examInfoObj.map((lesson, idx) => {
                if (lesson.docId === levelId) {
                  return (
                    <div key={idx} className="lesson-paragraph-title">
                      {
                        LEVEL[
                          auth.userInfo.nativeLanguage
                            ? auth.userInfo.nativeLanguage
                            : "en"
                        ]
                      }{" "}
                      {
                        getTransText(
                          auth.userInfo.nativeLanguage
                            ? auth.userInfo.nativeLanguage
                            : "en",
                          lesson.name,
                          translations.translationObj
                        ).text
                      }
                    </div>
                  );
                } else return null;
              })}
              <br />

              <div
                className="lesson-paragraph-description"
                dangerouslySetInnerHTML={{
                  __html: getTransText(
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en",
                    questionList[showContent].question,
                    translations.translationObj
                  ).text
                }}
                style={{ paddingBottom: "10px" }}
              ></div>
              {/* {console.log(questionList[showContent].answer)} */}
              {questionList[showContent].options.map((option, idx) => {
                // console.log(answerValue === idx, answerValue, idx);
                return (
                  <label key={idx} className="container">
                    {
                      getTransText(
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en",
                        option,
                        translations.translationObj
                      ).text
                    }
                    <input
                      type="radio"
                      name="radio"
                      value={idx}
                      onChange={e => this.onChange(e)}
                      checked={answerValue === idx}
                    />
                    <span className="checkmark"></span>
                  </label>
                );
              })}
            </Col>
            <Col
              xs={24}
              xl={8}
              className="lesson-right-sidebar"
              style={{ padding: "2rem 2rem" }}
            >
              <div>
                {
                  QUESTION[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }{" "}
                {showContent + 1}
              </div>

              <br />
              {questionList.map((lessonScene, idx) => (
                <Button
                  key={idx}
                  style={{
                    height: "1rem",
                    width: "1px",
                    backgroundColor:
                      showContent === idx
                        ? "yellow"
                        : seenQuestions.includes(idx)
                        ? "black"
                        : "lightgray"
                  }}
                  disabled
                >
                  &nbsp;
                </Button>
              ))}

              <br />
              <br />
              <Button
                disabled={answerValue === -1}
                className="lesson-continue-button"
                onClick={this.continue}
              >
                {
                  CONTINUE[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </Button>
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  exams: state.exams,
  auth: state.auth,
  translations: state.translations,
  operation: state.operation
});

const mapDispatchToProps = {
  loadAllExam,
  updateExamProgress
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ExamQuestions);
