import {
  CONTACT_US_START,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE
} from "./ContactUsConstants";

export const sendMessage = values => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: CONTACT_US_START });
    const db = getFirebase().firestore();

    db.collection("messages")
      .doc()
      .set({
        createdAt: new Date(),
        message: values.message,
        email: values.email,
        name: values.name
      })
      .then(() => {
        dispatch({ type: CONTACT_US_SUCCESS });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: CONTACT_US_FAILURE,
          payload: {
            errMsg:
              "Error occured while sending message. Please try again later."
          }
        });
      });
  };
};

