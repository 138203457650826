import { Modal } from "antd";
import React, { Component } from "react";
import Icon from "@ant-design/icons";
import SignUp from "./SignUp";
import Login from "./Login";
import FreeTrial from "./FreeTrial";
import "./LandingPage.less";

const cancel_icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.388"
    height="27.388"
    viewBox="0 0 27.388 27.388"
  >
    <path
      d="M161.43-4.5,159.5-2.57l-4.913-4.913L149.67-2.57,147.736-4.5l4.913-4.913-4.913-4.913,1.934-1.934,4.913,4.913,4.913-4.913,1.934,1.934-4.913,4.913Zm-6.847-18.607A13.69,13.69,0,0,0,140.889-9.417,13.69,13.69,0,0,0,154.583,4.277,13.69,13.69,0,0,0,168.277-9.417a13.69,13.69,0,0,0-13.694-13.694Z"
      transform="translate(-140.889 23.111)"
      fill="#b1b1b1"
      fillRule="evenodd"
    />
  </svg>
);

export default class LandingPageModal extends Component {
  state = {
    showLogin: false,
    isLoggedIn: false
  };

  handleSubmit = () => {
    this.setState({
      showLogin: !this.state.showLogin
    });
  };

  onCancel = () => {
    if (this.state.showLogin && this.state.isLoggedIn) {
      this.props.goToBusinessPortal();
    } else {
      this.setState({
        showLogin: false,
        isLoggedIn: false
      });
      this.props.onLoginModalCancel();
    }
  };

  showLogin = () => {
    this.setState({
      showLogin: true
    });
  };

  showSignUp = () => {
    this.setState({
      showLogin: false
    });
  };

  logIn = () => {
    this.setState({
      isLoggedIn: true
    });
  };

  // TODO : Replace with real system
  redirectToFreeTrial = () => {
    this.setState({
      showLogin: true,
      isLoggedIn: true
    });
  };

  render() {
    const { visible } = this.props;
    return (
      <Modal
        destroyOnClose={true}
        centered
        maskClosable={true}
        closable={true}
        visible={visible}
        footer={null}
        width="100%"
        onCancel={this.onCancel}
        closeIcon={<Icon component={cancel_icon} />}
        className="landing-page-modal-body"
      >
        {this.state.showLogin ? (
          this.state.isLoggedIn ? (
            <FreeTrial goToBusinessPortal={this.props.goToBusinessPortal} />
          ) : (
            <SignUp
              showLogin={this.showSignUp}
              redirectToFreeTrial={this.redirectToFreeTrial}
            />
          )
        ) : (
          <Login showSignUp={this.showLogin} logIn={this.logIn} />
        )}
      </Modal>
    );
  }
}
