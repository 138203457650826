import React, { Fragment } from "react";
import { Drawer, Input, Select, AutoComplete, Checkbox } from "antd";
import { filterOptionFunc } from "./antUtils";
import {
  outlineTemplateList,
  minFontSize,
  supportedFontVariantsMap,
  maxFontSize
} from "./SharedConstant";
import InputColor from "react-input-color";
import {
  OUTLINE,
  OUTLINECANNOTBEEMPTY,
  FONTTYPE,
  FONTVARIANT,
  FONTSIZE,
  COLOR,
  HIDE
} from "./BusinessPortal/TranslationConstant";
const rgbHex = require("rgb-hex");

const Outline5TextDrawer = ({
  onClose,
  isVisible,
  windowWidth,
  onTextChange,
  onColorChange,
  onFontSizeChange,
  onFontFamilyChange,
  onCheckboxChange,
  inputFieldObj,
  auth
}) => {
  return (
    <Fragment>
      <Drawer
        onClose={onClose}
        visible={isVisible}
        width={windowWidth > 600 ? 600 : windowWidth}
      >
        <div
          style={{
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            OUTLINE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }{" "}
          5{" "}
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error && inputFieldObj.text.length === 0
              ? `(${
                  OUTLINECANNOTBEEMPTY[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                })`
              : null}
          </span>
        </div>

        <AutoComplete
          style={{ width: "100%" }}
          options={outlineTemplateList}
          filterOption={filterOptionFunc}
          onChange={value => onTextChange(value, "outline5Obj")}
          value={inputFieldObj.text}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTTYPE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.fontFamily.split("-")[0]}
          onChange={value => onFontFamilyChange(value, "outline5Obj", 0)}
        >
          {[...supportedFontVariantsMap.keys()].map((item, idx) => (
            <Select.Option key={`${item}_${idx}`} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTVARIANT[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>

        <Select
          style={{ width: "100%" }}
          value={inputFieldObj.fontFamily.split("-")[1]}
          onChange={value => onFontFamilyChange(value, "outline5Obj", 1)}
        >
          {supportedFontVariantsMap
            .get(inputFieldObj.fontFamily.split("-")[0])
            .map((item, idx) => (
              <Select.Option key={`${item}_${idx}`} value={item}>
                {item}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            FONTSIZE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
          <span
            style={{
              color: "red",
              fontFamily: "Poppins-SemiBold",
              fontSize: "12px"
            }}
          >
            {inputFieldObj.error
              ? inputFieldObj.fontSize <= minFontSize
                ? "(Font size is too small)"
                : inputFieldObj.fontSize >= maxFontSize
                ? "(Font size is too big)"
                : null
              : null}
          </span>
        </div>
        <Input
          type="number"
          style={{ fontSize: "18px", fontFamily: "Poppins-Regular" }}
          onChange={e => onFontSizeChange(e, "outline5Obj")}
          value={inputFieldObj.fontSize}
        />
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Regular",
            color: "#34374d",
            margin: "16px 0px 8px"
          }}
        >
          {
            COLOR[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </div>
        <InputColor
          initialValue={
            inputFieldObj.color.length > 0
              ? "#" + rgbHex(inputFieldObj.color)
              : "#FFFFFF"
          }
          onChange={value => onColorChange(value, "outline5Obj")}
          style={{ width: "100%", height: "35px" }}
        />
        <Checkbox
          onChange={e => onCheckboxChange(e, "outline5Obj")}
          checked={!inputFieldObj.isVisible}
        >
          {
            HIDE[
              auth.userInfo
                ? auth.userInfo.nativeLanguage
                  ? auth.userInfo.nativeLanguage
                  : "en"
                : "en"
            ]
          }
        </Checkbox>
      </Drawer>
    </Fragment>
  );
};

export default Outline5TextDrawer;
