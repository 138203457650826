import { Button, Col, Form, Input, Row, message, Popover } from "antd";
import React, { Component, Fragment } from "react";
import "./Login.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import {
  initDBLoad,
  login,
  resetEmailPassword,
  updateEcodeForUser,
  updateUserLanguageInfo,
  updateLanguage
} from "../auth/authActions";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
import { withRouter } from "react-router";
import NewEcodeModal from "../modals/NewEcodeModal";
import {
  LOGINTOGETFULLACCESS,
  INVALIDEMAIL,
  PLEASEENTEREMAIL,
  EMAILADD,
  PLEASEENTERPASSWORD,
  PASSWORD,
  LOGINCAP,
  FORGOTPASSWORD,
  NOTREGISTERED,
  LOGINSMALL
} from "./TranslationConstant";

class Login extends Component {
  resetPasswordFormRef = React.createRef();
  newEcodeFormRef = React.createRef();
  state = {
    isResetting: false,
    isLoggingIn: false,
    isUpdating: false,
    isForgotPasswordModalVisible: false,
    isNewEcodeModalVisible: false
  };

  onHandleLoginFormSubmit = async values => {
    this.setState({ isLoggingIn: true }, async () => {
      const {
        history,
        login,
        initDBLoad,
        dbAuth,
        updateUserLanguageInfo
      } = this.props;

      let errorObj = await login(values);

      if (errorObj) {
        if (errorObj.code !== "Expired E-Code") {
          message.error(errorObj.code + ":" + errorObj.message);
        }
        this.setState({
          isNewEcodeModalVisible: errorObj.code === "Expired E-Code",
          isLoggingIn: false
        });
      } else {
        await updateUserLanguageInfo(dbAuth.selectedLanguage);
        await initDBLoad(this.props.auth.uid); // use auth will get stale data since destructing happens before login call
        history.push("/business-portal");
      }
    });
  };

  onHandleForgotPasswordLabelClick = () => {
    this.resetPasswordFormRef.current.setFieldsValue({
      emailToReset: ""
    });
    this.setState({
      isForgotPasswordModalVisible: true
    });
  };

  onHandleForgotPasswordCancelButtonClick = () => {
    this.setState({
      isForgotPasswordModalVisible: false
    });
  };

  onHandleForgotPasswordResetButtonClick = values => {
    this.setState(
      {
        isResetting: true
      },
      async () => {
        let errorObj = await this.props.resetEmailPassword(values.emailToReset);
        if (errorObj) {
          message.error(errorObj.code + " : " + errorObj.message);
        } else {
          message.success(
            "Instruction to reset password has been sent to your mailbox.Please check."
          );
        }

        this.setState({
          isResetting: false,
          isForgotPasswordModalVisible: errorObj ? true : false
        });
      }
    );
  };

  onHandleNewEcodeModalCancelButtonClick = () => {
    this.setState({
      isNewEcodeModalVisible: false
    });
  };

  onHandleNewEcodeModalSubmitButtonClick = values => {
    const { initDBLoad, updateEcodeForUser, auth, history } = this.props;
    this.setState(
      {
        isUpdating: true
      },
      async () => {
        let errorObj = await updateEcodeForUser(values.ecode);
        if (errorObj) {
          message.error(errorObj.code + " : " + errorObj.message);
        } else {
          await initDBLoad(auth.uid); // use auth will get stale data since destructing happens before login call
          history.push("/business-portal");
        }

        this.setState({
          isUpdating: false,
          isNewEcodeModalVisible: errorObj ? true : false
        });
      }
    );
  };

  onLanguageMenuClick = async e => {
    this.props.updateLanguage(e);
    // this.setState({ languagePopoverVisible: !this.state.languagePopoverVisible });
  };

  render() {
    const {
      isResetting,
      isLoggingIn,
      isUpdating,
      isForgotPasswordModalVisible,
      isNewEcodeModalVisible
    } = this.state;
    const { showSignUp, dbAuth } = this.props;
    return (
      <Fragment>
        <ForgotPasswordModal
          ref={this.resetPasswordFormRef}
          isVisible={isForgotPasswordModalVisible}
          onCancel={this.onHandleForgotPasswordCancelButtonClick}
          onReset={this.onHandleForgotPasswordResetButtonClick}
          isResetting={isResetting}
          selectedLanguage={dbAuth.selectedLanguage}
        />
        <NewEcodeModal
          ref={this.newEcodeFormRef}
          isVisible={isNewEcodeModalVisible}
          onCancel={this.onHandleNewEcodeModalCancelButtonClick}
          onSubmit={this.onHandleNewEcodeModalSubmitButtonClick}
          isUpdating={isUpdating}
          selectedLanguage={dbAuth.selectedLanguage}
        />
        <Row align="middle" className="login-body">
          <Col xs={1} md={2} lg={1} />
          <Col xs={22} md={20} lg={22}>
            <Row align="middle">
              <Col xs={24} lg={16} xl={16}>
                <img
                  src="/assets/businessPortal/skyline.gif"
                  alt="Skyline"
                  style={{
                    width: "100%",
                    padding: "10px"
                  }}
                />
              </Col>
              <Col
                xs={24}
                lg={{ span: 7, offset: 1 }}
                xl={{ span: 7, offset: 1 }}
                style={{ textAlign: "center" }}
              >
                <Col xs={24}>
                  <Popover
                    overlayClassName="home-page-popover-container-wrapper"
                    content={<div>English</div>}
                    onClick={e => this.onLanguageMenuClick("en")}
                  >
                    <img
                      src="/assets/businessPortal/uk-icon.svg"
                      alt=""
                      style={{ width: "50px", height: "25px" }}
                    />
                  </Popover>
                  <Popover
                    overlayClassName="home-page-popover-container-wrapper"
                    content={<div>Bahasa Malaysia</div>}
                    onClick={e => this.onLanguageMenuClick("ms")}
                  >
                    <img
                      src="/assets/businessPortal/malaysia-icon.svg"
                      alt=""
                      style={{ width: "50px", height: "25px" }}
                    />
                  </Popover>

                  <Popover
                    overlayClassName="home-page-popover-container-wrapper"
                    content={<div>简体中文</div>}
                    onClick={e => this.onLanguageMenuClick("cs")}
                  >
                    <img
                      src="/assets/businessPortal/china-icon.svg"
                      alt=""
                      style={{ width: "50px", height: "25px" }}
                    />
                  </Popover>
                  <Popover
                    overlayClassName="home-page-popover-container-wrapper"
                    content={<div>繁體中文</div>}
                    onClick={e => this.onLanguageMenuClick("ct")}
                  >
                    <img
                      src="/assets/businessPortal/china-icon.svg"
                      alt=""
                      style={{ width: "50px", height: "25px" }}
                    />
                  </Popover>
                  <Popover
                    overlayClassName="home-page-popover-container-wrapper"
                    content={<div>Bahasa Indonesia</div>}
                    onClick={e => this.onLanguageMenuClick("in")}
                  >
                    <img
                      src="/assets/businessPortal/indonesia-icon.svg"
                      alt=""
                      style={{ width: "50px", height: "25px" }}
                    />
                  </Popover>
                </Col>
                <br />
                <div className="login-header">
                  {LOGINSMALL[dbAuth.selectedLanguage]}
                </div>
                <br />
                <div className="login-description">
                  {LOGINTOGETFULLACCESS[dbAuth.selectedLanguage]}
                </div>
                <br />
                <Form
                  layout="vertical"
                  onFinish={this.onHandleLoginFormSubmit}
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: INVALIDEMAIL[dbAuth.selectedLanguage]
                      },
                      {
                        required: true,
                        message: PLEASEENTEREMAIL[dbAuth.selectedLanguage]
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={EMAILADD[dbAuth.selectedLanguage]}
                      className="login-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage]
                      }
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={PASSWORD[dbAuth.selectedLanguage]}
                      className="login-input"
                    />
                  </Form.Item>

                  <Row>
                    <Col xs={4} sm={4} md={8} lg={3} xl={5} />
                    <Col xs={16} sm={16} md={8} lg={18} xl={14}>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="login-button"
                        block
                        loading={isLoggingIn}
                      >
                        {LOGINCAP[dbAuth.selectedLanguage]}
                      </Button>
                    </Col>
                    <Col xs={4} sm={4} md={8} lg={3} xl={5} />
                  </Row>
                </Form>
                <br />
                <div
                  className="login-description"
                  onClick={this.onHandleForgotPasswordLabelClick}
                  style={{ cursor: "pointer" }}
                >
                  {FORGOTPASSWORD[dbAuth.selectedLanguage]}
                </div>
                <br />
                <div className="login-description">
                  <u onClick={showSignUp} style={{ cursor: "pointer" }}>
                    {NOTREGISTERED[dbAuth.selectedLanguage]}
                  </u>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={1} md={2} lg={1} />
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  initDBLoad,
  login,
  resetEmailPassword,
  updateEcodeForUser,
  updateUserLanguageInfo,
  updateLanguage
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  dbAuth: state.auth
});

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
