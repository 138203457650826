import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Popover
} from "antd";
import "./SignUp.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { initDBLoad, login, signUp, updateLanguage } from "../auth/authActions";
import {
  GETSTARTEDNOW,
  ITSFREETOJOINANDGAIN,
  PLEASEENTERFULLNAME,
  NAME,
  PLEASEENTEREMAIL,
  EMAILADD,
  MALE,
  FEMALE,
  PREFERNOTSAY,
  INVALIDEMAIL,
  ECODE,
  PLEASESELECTGENDER,
  PLEASEENTERPASSWORD,
  PLEASEENTERECODE,
  PLEASESELECTBIRTHDAY,
  PRIVACYPOLICY,
  SIGNUP,
  SIGNIN,
  HERE,
  ALREADYHAVEACCOUNT,
  GENDER,
  DATE,
  PLEASEACCEPTPRIVACYPOLICY,
  PASSWORD,
  PASSWORDREQUIRED
} from "./TranslationConstant";

class SignUp extends Component {
  state = {
    reset: false,
    signingUp: false,
    isLoading: true
  };

  onPasswordChange = (e, inputField) => {
    let regex = "^(?=.*[a-z])(?=.*[0-9])(?=.{8,})";
    let inputVal = e.target.value;
    if (inputVal.match(regex)) {
      this.setState({
        [inputField]: inputVal,
        newPassErr: ""
      });
    } else {
      this.setState({
        [inputField]: inputVal,
        newPassErr:
          "Password must contain at least 1 number, 1 alphabet and 8 character long"
      });
    }
  };

  onHandleSignUpFormFinish = async e => {
    const {
      signUp,
      redirectToFreeTrial,
      initDBLoad,
      auth,
      dbAuth
    } = this.props;
    this.setState(
      {
        signingUp: true
      },
      async () => {
        let result = await signUp(e, dbAuth.selectedLanguage);

        //error clause
        if (result) {
          message.error(result.code + " : " + result.message);
          this.setState({
            signingUp: false
          });
        } else {
          await initDBLoad(auth.uid);
          redirectToFreeTrial(); //TODO: Replace with proper system/check
        }
      }
    );
  };

  onLanguageMenuClick = async e => {
    this.props.updateLanguage(e);
    // this.setState({ languagePopoverVisible: !this.state.languagePopoverVisible });
  };

  render() {
    const { showLogin, dbAuth } = this.props;

    return (
      <Row align="middle" className="sign-up-body">
        <Col xs={1} md={2} lg={1} />
        <Col xs={22} md={20} lg={22}>
          <Row align="middle">
            <Col xs={24} lg={16}>
              <img
                src="/assets/businessPortal/skyline.gif"
                alt="Skyline"
                style={{ width: "100%", padding: "10px" }}
              />
            </Col>

            <Col
              xs={24}
              lg={{ span: 7, offset: 1 }}
              style={{ textAlign: "center" }}
            >
              <Col xs={24}>
                <Popover
                  overlayClassName="home-page-popover-container-wrapper"
                  content={<div>English</div>}
                  onClick={e => this.onLanguageMenuClick("en")}
                >
                  <img
                    src="/assets/businessPortal/uk-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                </Popover>
                <Popover
                  overlayClassName="home-page-popover-container-wrapper"
                  content={<div>Bahasa Malaysia</div>}
                  onClick={e => this.onLanguageMenuClick("ms")}
                >
                  <img
                    src="/assets/businessPortal/malaysia-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                </Popover>

                <Popover
                  overlayClassName="home-page-popover-container-wrapper"
                  content={<div>简体中文</div>}
                  onClick={e => this.onLanguageMenuClick("cs")}
                >
                  <img
                    src="/assets/businessPortal/china-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                </Popover>
                <Popover
                  overlayClassName="home-page-popover-container-wrapper"
                  content={<div>繁體中文</div>}
                  onClick={e => this.onLanguageMenuClick("ct")}
                >
                  <img
                    src="/assets/businessPortal/china-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                </Popover>
                <Popover
                  overlayClassName="home-page-popover-container-wrapper"
                  content={<div>Bahasa Indonesia</div>}
                  onClick={e => this.onLanguageMenuClick("in")}
                >
                  <img
                    src="/assets/businessPortal/indonesia-icon.svg"
                    alt=""
                    style={{ width: "50px", height: "25px" }}
                  />
                </Popover>
              </Col>
              <br />
              <div className="sign-up-header">
                {GETSTARTEDNOW[dbAuth.selectedLanguage]}
              </div>
              <br />
              <div className="sign-up-description">
                {ITSFREETOJOINANDGAIN[dbAuth.selectedLanguage]}
              </div>
              <br />
              <Form
                ref={this.form}
                onFinish={this.onHandleSignUpFormFinish}
                initialValues={{
                  name: this.state.name,
                  email: this.state.email,
                  mobileNumber: this.state.mobileNumber,
                  address: this.state.address,
                  birthday: this.state.birthday
                }}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: PLEASEENTERFULLNAME[dbAuth.selectedLanguage]
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={NAME[dbAuth.selectedLanguage]}
                    className="sign-up-input"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: INVALIDEMAIL[dbAuth.selectedLanguage]
                    },
                    {
                      required: true,
                      message: PLEASEENTEREMAIL[dbAuth.selectedLanguage]
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={EMAILADD[dbAuth.selectedLanguage]}
                    className="sign-up-input"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  onChange={e => this.onPasswordChange(e, "password")}
                  value={this.state.newPassword}
                  rules={[
                    {
                      pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
                      message: PASSWORDREQUIRED[dbAuth.selectedLanguage]
                      // type: "regexp"
                    },
                    {
                      required: true,
                      message: PLEASEENTERPASSWORD[dbAuth.selectedLanguage]
                    }
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder={PASSWORD[dbAuth.selectedLanguage]}
                    className="sign-up-input"
                  />
                </Form.Item>
                <Form.Item
                  name="ecode"
                  rules={[
                    {
                      required: true,
                      message: PLEASEENTERECODE[dbAuth.selectedLanguage]
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={ECODE[dbAuth.selectedLanguage]}
                    className="sign-up-input"
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: PLEASESELECTGENDER[dbAuth.selectedLanguage]
                    }
                  ]}
                >
                  <Select
                    placeholder={GENDER[dbAuth.selectedLanguage]}
                    className="sign-up-gender-select"
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    <Select.Option
                      className="sign-up-gender-select"
                      value="Male"
                    >
                      {MALE[dbAuth.selectedLanguage]}
                    </Select.Option>
                    <Select.Option
                      className="sign-up-gender-select"
                      value="Female"
                    >
                      {FEMALE[dbAuth.selectedLanguage]}
                    </Select.Option>
                    <Select.Option
                      className="sign-up-gender-select"
                      value="Prefer not to say"
                    >
                      {PREFERNOTSAY[dbAuth.selectedLanguage]}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="birthday"
                  rules={[
                    {
                      required: true,
                      message: PLEASESELECTBIRTHDAY[dbAuth.selectedLanguage]
                    }
                  ]}
                >
                  <DatePicker
                    // disabledDate={this.disabledDate}

                    placeholder={DATE[dbAuth.selectedLanguage]}
                    format="DD/MM/YYYY"
                    inputReadOnly
                    suffixIcon={null}
                    getPopupContainer={trigger => trigger.parentNode}
                    className="sign-up-datepicker"
                  />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              PLEASEACCEPTPRIVACYPOLICY[dbAuth.selectedLanguage]
                            )
                    }
                  ]}
                >
                  <Checkbox className="sign-up-checkbox">
                    {PRIVACYPOLICY[dbAuth.selectedLanguage]}
                  </Checkbox>
                </Form.Item>

                <Row>
                  <Col xs={4} sm={4} md={8} lg={3} xl={5} />
                  <Col xs={16} sm={16} md={8} lg={18} xl={14}>
                    <Button
                      size="large"
                      htmlType="submit"
                      className="sign-up-button"
                      loading={this.state.signingUp}
                    >
                      {SIGNUP[dbAuth.selectedLanguage]}
                    </Button>
                  </Col>
                  <Col xs={4} sm={4} md={8} lg={3} xl={5} />
                </Row>
              </Form>
              <br />
              <div className="sign-up-description">
                {ALREADYHAVEACCOUNT[dbAuth.selectedLanguage]}{" "}
                <u onClick={showLogin} style={{ cursor: "pointer" }}>
                  {SIGNIN[dbAuth.selectedLanguage]}
                </u>{" "}
                {HERE[dbAuth.selectedLanguage]}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={1} md={2} lg={1} />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  dbAuth: state.auth
});

const mapDispatchToProps = {
  signUp,
  initDBLoad,
  login,
  updateLanguage
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
