import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";
import "./LessonCompleted.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadTargetedCourseLessons,
  resetTargetedCourseLesson
} from "./courseActions";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import _ from "lodash";
import {
  LESSONCOMPLETE,
  GREATSTART,
  CONTINUE,
  REDO
} from "../TranslationConstant";

class LessonCompleted extends Component {
  state = {
    isLoading: true
  };

  redoLesson = () => {
    this.props.history.goBack();
  };

  continue = () => {
    if (window.location.pathname.includes("probing-questions")) {
      this.props.history.push("/business-portal/probing-questions");
    } else {
      const { course } = this.props;
      const { lessonId, courseId } = this.state;
      // if (course.lessonInfoList)
      course.lessonInfoList.forEach((lesson, idx) => {
        if (lesson.docId === lessonId) {
          if (idx !== course.lessonInfoList.length - 1) {
            this.props.history.push(
              `/business-portal/${courseId}/${
                course.lessonInfoList[idx + 1].docId
              }`
            );
          } else {
            this.props.history.push("/business-portal");
          }
        }
      });
    }
  };

  componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;
    let lessonId = match.params.lessonId;

    this.props.loadTargetedCourseLessons(courseId);

    this.setState({
      courseId,
      lessonId
    });
  }

  async componentDidUpdate(prevProps) {
    const { course, auth } = this.props;
    if (
      !_.isEqual(prevProps.course.lessonInfoList, course.lessonInfoList) ||
      (course.lessonInfoList !== null && this.state.isLoading) ||
      auth.userInfo.nativeLanguage !== prevProps.auth.userInfo.nativeLanguage
    ) {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this.props.resetTargetedCourseLesson();
  }

  render() {
    const { auth } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return <LoadingComponent auth={auth} />;
    return (
      <Fragment>
        <Row className="lesson-completed-row">
          <Col xs={24}>
            <div className="lesson-completed-title">
              {
                LESSONCOMPLETE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <Col xs={0} sm={24} md={24} lg={24} xl={24}>
              <img
                src="/assets/businessPortal/lesson-completed.png"
                alt="Lesson Completed"
                className="lesson-completed-img"
                width="330px"
              />
            </Col>
            <Col xs={24} sm={0} md={0} lg={0} xl={0}>
              <img
                src="/assets/businessPortal/lesson-completed.png"
                alt="Lesson Completed"
                className="lesson-completed-img"
                width="80%"
                style={{ paddingLeft: "60px", paddingRight: "60px" }}
              />
            </Col>

            <br />
            <br />
            <div className="lesson-completed-description">
              {
                GREATSTART[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <Button
              className="lesson-completed-continue-button"
              onClick={this.continue}
            >
              {
                CONTINUE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />
            <div
              className="lesson-completed-redo-text"
              onClick={this.redoLesson}
            >
              <u>
                {
                  REDO[
                    auth.userInfo.nativeLanguage
                      ? auth.userInfo.nativeLanguage
                      : "en"
                  ]
                }
              </u>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapDispatchToProps = {
  loadTargetedCourseLessons,
  resetTargetedCourseLesson
};

const mapStateToProps = state => ({
  auth: state.auth,
  course: state.course
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LessonCompleted);
