import { RESET_SALES_FUNNEL_DATA, LOAD_FUNNEL_PAGE, SAVE_FUNNEL_PAGE, CHECK_SALES_FUNNEL_AVAILABILITY } from "./salesFunnelConstant"
import { createReducer } from "../app/common/utils/reducerUtils"

const initialState = {
    isOwningSalesFunnel: null,
    pdfFileUrl: null,
    funnelPageObj: null
}



const checkSalesFunnelAvailabilty = (state, payload) => {
    return {
        ...state,
        isOwningSalesFunnel: payload.isOwningSalesFunnel,
        pdfFileUrl: payload.pdfFileUrl
    }
}

const resetSalesFunnelData = (state) => {
    return {
        ...state,
        isOwningSalesFunnel: null,
        funnelPageObj: null,
        pdfFileUrl: null
    }
}

const loadFunnelPage = (state, payload) => {
    return {
        ...state,
        funnelPageObj: payload.funnelPageObj
    }
}

const saveFunnelPage = (state, payload) => {
    return {
        ...state,
        funnelPageObj: { ...state.funnelPageObj, ...payload.infoToUpdateObj }
    }
}

export default createReducer(initialState, {
    [CHECK_SALES_FUNNEL_AVAILABILITY]: checkSalesFunnelAvailabilty,
    [RESET_SALES_FUNNEL_DATA]: resetSalesFunnelData,
    [LOAD_FUNNEL_PAGE]: loadFunnelPage,
    [SAVE_FUNNEL_PAGE]: saveFunnelPage
})