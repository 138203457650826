export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const RESET_USER_INFO = "RESET_USER_INFO";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_LESSON_PROGRESS = "UPDATE_LESSON_PROGRESS";
export const UPDATE_EXAM_PROGRESS = "UPDATE_EXAM_PROGRESS";
export const UPDATE_USER_LANGUAGE = " UPDATE_USER_LANGUAGE";
export const UPDATE_LANGUAGE = " UPDATE_LANGUAGE";
export const UPDATE_PROBING_QUESTIONS_PROGRESS = " UPDATE_PROBING_QUESTIONS_PROGRESS";
export const RESET = "RESET";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
