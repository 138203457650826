import {
  LOAD_ALL_COURSE,
  RESET_ALL_COURSE,
  LOAD_COURSE_LESSONS,
  RESET_COURSE_LESSONS,
  RESET_COURSE_LESSON_SCENE,
  LOAD_COURSE_LESSON_SCENE,
  LOAD_ALL_EMAIL_TEMPLATES,
  LOAD_BUSINESS_SPEAKING,
  LOAD_PROBING_QUESTIONS
} from "./courseConstant";

export const loadAllCourse = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let powerToBelieve = {};
      let courseInfoList = [];
      let courseDocSnap = await db
        .collection("courses_new")
        .orderBy("order")
        .get();
      let total = 0;
      courseDocSnap.forEach(async courseDocSnap => {
        total += 1;
        let lessonQuerySnap = await db
          .collection(`courses_new/${courseDocSnap.id}/lessons`)
          .orderBy("order")
          .get();

        let lessonInfoList = [];
        lessonQuerySnap.forEach(docSnap => {
          lessonInfoList.push({ ...docSnap.data(), docId: docSnap.id });
        });

        courseInfoList.push({
          ...courseDocSnap.data(),
          docId: courseDocSnap.id,
          lessons: lessonInfoList
        });

        if (courseInfoList.length === total) {
          // console.log(courseInfoList);
          courseInfoList.forEach(async course => {
            if (course.docId === "power_to_believe") {
              let random = Math.floor(Math.random() * course.lessons.length);
              let lessonSceneQuerySnap = await db
                .collection(
                  `courses_new/${course.docId}/lessons/${course.lessons[random].docId}/scenes`
                )
                .get();
              let scenerandom = randomExcluded(
                lessonSceneQuerySnap.docs.length,
                0
              );

              // console.log(random, scenerandom);
              // console.log(courseInfoList);
              lessonSceneQuerySnap.forEach(docSnap => {
                if (docSnap.id === `${scenerandom}`) {
                  powerToBelieve = docSnap.data();
                  // console.log(powerToBelieve);
                  dispatch({
                    type: LOAD_ALL_COURSE,
                    payload: { courseInfoList, powerToBelieve }
                  });
                }
              });
            }
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

function randomExcluded(length, excluded) {
  var n = Math.floor(Math.random() * length);
  if (n >= excluded) n++;
  return n;
}

export const resetAllCourse = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_ALL_COURSE });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedCourseLessons = courseId => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      //load all topics also
      let topicQuerySnap = await db
        .collection(`courses_new/${courseId}/lessons`)
        .orderBy("order")
        .get();

      let lessonInfoList = [];

      let total = 0;
      topicQuerySnap.forEach(async lessonDocSnap => {
        total += 1;
        let sceneQuerySnap = await db
          .collection(`courses_new/${courseId}/lessons/${lessonDocSnap.id}/scenes`)
          .orderBy("order")
          .get();

        let sceneInfoList = [];
        sceneQuerySnap.forEach(docSnap => {
          sceneInfoList.push({ ...docSnap.data(), docId: docSnap.id });
        });

        lessonInfoList.push({
          ...lessonDocSnap.data(),
          docId: lessonDocSnap.id,
          scenes: sceneInfoList
        });
        if (lessonInfoList.length === total) {
          // console.log(lessonInfoList)
          dispatch({ type: LOAD_COURSE_LESSONS, payload: { lessonInfoList } });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedCourseLesson = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_COURSE_LESSONS });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedCourseLessonsScenes = (courseId, lessonId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let courseDocSnap = await db.doc(`courses_new/${courseId}`).get();

      if (courseDocSnap.exists) {
        let courseLessonsSnap = await db
          .collection(`courses_new/${courseId}/lessons`)
          .orderBy("order")
          .get();

        let lessonInfoList = [];
        courseLessonsSnap.forEach(docSnap => {
          lessonInfoList.push({
            ...docSnap.data(),
            docId: docSnap.id
          });
        });

        // console.log(lessonInfoList);
        let lessonDocSnap = await db
          .collection(`courses_new/${courseId}/lessons/${lessonId}/scenes`)
          .orderBy("order")
          .get();

        let lessonScenesInfoObj = {
          lessonInfoObj: []
        };

        let sceneInfoList = [];
        lessonDocSnap.forEach(docSnap => {
          sceneInfoList.push({
            ...docSnap.data(),
            docId: docSnap.id
          });
        });
        lessonScenesInfoObj.courseInfoObj = {
          ...courseDocSnap.data(),
          docId: courseDocSnap.id
        };
        lessonScenesInfoObj.lessonInfoList = lessonInfoList;
        lessonScenesInfoObj.lessonInfoObj = sceneInfoList;

        dispatch({
          type: LOAD_COURSE_LESSON_SCENE,
          payload: { lessonScenesInfoObj }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedCourseLessonsScenes = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_COURSE_LESSON_SCENE });
    } catch (err) {
      console.log(err);
    }
  };
};

//email templetes related
export const loadAllEmailTemplates = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // let courseInfoObj = [];
      let emailTemplatesInfoObj = [];
      let emailTemplatesDocSnap = await db
        .collection("email_templates_new")
        .orderBy("order")
        .get();
      let total = 0;
      emailTemplatesDocSnap.forEach(async emailTemplatesDocSnap => {
        total += 1;
        let lessonQuerySnap = await db
          .collection(
            `email_templates_new/${emailTemplatesDocSnap.id}/templates`
          )
          // .orderBy("order")
          .get();

        let templateInfoList = [];
        lessonQuerySnap.docs
          .sort((a, b) => {
            return a.id - b.id;
          })
          .forEach(docSnap => {
            templateInfoList.push({ ...docSnap.data(), docId: docSnap.id });
          });
        emailTemplatesInfoObj.push({
          ...emailTemplatesDocSnap.data(),
          docId: emailTemplatesDocSnap.id,
          templates: templateInfoList
        });
        if (emailTemplatesInfoObj.length === total) {
          // console.log(emailTemplatesInfoObj);

          dispatch({
            type: LOAD_ALL_EMAIL_TEMPLATES,
            payload: { emailTemplatesInfoObj }
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

//business speaking related
export const loadBusinessSpeaking = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // let courseInfoObj = [];
      let businessSpeakingInfo = [];
      let businessSpeakingSnap = await db
        .collection("business_speaking_new")
        .get();

      businessSpeakingSnap.forEach(async businessSpeakingDocSnap => {
        businessSpeakingInfo.push({
          ...businessSpeakingDocSnap.data(),
          docId: businessSpeakingDocSnap.id
        });
      });

      // console.log(emailTemplatesInfoObj);

      dispatch({
        type: LOAD_BUSINESS_SPEAKING,
        payload: { businessSpeakingInfo }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadProbingQuestions = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let probingQuestionsInfoObj = [];
      let probingQuestionDocSnap = await db
        .collection("probing_questions_new")
        .orderBy("order")
        .get();
      let total = 0;

      probingQuestionDocSnap.forEach(async probingQuestionDocSnap => {
        total += 1;
        let lessonQuerySnap = await db
          .collection(
            `probing_questions_new/${probingQuestionDocSnap.id}/questions`
          )
          .get();

        let questionInfoList = [];
        lessonQuerySnap.forEach(docSnap => {
          questionInfoList.push({
            ...docSnap.data(),
            docId: docSnap.id,
            courseName: probingQuestionDocSnap.data().name
          });
        });
        probingQuestionsInfoObj.push({
          ...probingQuestionDocSnap.data(),
          docId: probingQuestionDocSnap.id,
          questions: questionInfoList
        });
        if (probingQuestionsInfoObj.length === total) {
          // console.log(probingQuestionsInfoObj)
          dispatch({
            type: LOAD_PROBING_QUESTIONS,
            payload: { probingQuestionsInfoObj }
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};
