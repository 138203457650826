import { LOAD_ALL_EXAM } from "./examConstant";

export const loadAllExam = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let examInfoObj = [];
      let examSnap = await db
        .collection("exams_new")
        .orderBy("order")
        .get();

      examSnap.forEach(async examDocSnap => {
        examInfoObj.push({
          ...examDocSnap.data(),
          docId: examDocSnap.id
        });
      });

      // console.log(examInfoObj);

      dispatch({
        type: LOAD_ALL_EXAM,
        payload: { examInfoObj }
      });
    } catch (err) {
      console.log(err);
    }
  };
};
