import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDwcyD9v1ZzkDZtaIkdKkKUWvxu69QKRcI",
//   authDomain: "dev-rav-business.firebaseapp.com",
//   databaseURL: "https://dev-rav-business.firebaseio.com",
//   projectId: "dev-rav-business",
//   storageBucket: "dev-rav-business.appspot.com",
//   messagingSenderId: "836248037130",
//   appId: "1:836248037130:web:9cfe70424a505c56a9bcf3"
// };
// firebase.initializeApp(firebaseConfig);

const prdFirebaseConfig = {
  apiKey: "AIzaSyCSsA0MlyGvrRBKSXfSU_Y0A8IMFGizPts",
  authDomain: "prd-rav-business.firebaseapp.com",
  databaseURL: "https://prd-rav-business.firebaseio.com",
  projectId: "prd-rav-business",
  storageBucket: "prd-rav-business.appspot.com",
  messagingSenderId: "289849281875",
  appId: "1:289849281875:web:efbd56f0879a512af8e5a6",
  measurementId: "G-CNZW3CEDVQ"
};
firebase.initializeApp(prdFirebaseConfig);

firebase.firestore();
const firebaseFunc = firebase.app().functions("asia-east2");

export { firebase, firebaseFunc };
