import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "../../auth/authReducer";
import bookReducer from "../../book/bookReducer";
import operationReducer from "../../operation/operationReducer";
import salesFunnelReducer from "../../salesFunnel/salesFunnelReducer";
import domainReducer from "../../domain/domainReducer";
import imageReducer from "../../image/imageReducer";
import courseReducer from "../../BusinessPortal/lessons/courseReducer";
import ebookReducer from "../../ebook/ebookReducer";
import resourcesReducer from "../../resources/resourcesReducer";
import examReducer from "../../BusinessPortal/exam/examReducer";
import translationReducer from "../../BusinessPortal/translation/translationReducer";
import ContactUsReducer from "../../BusinessPortal/ContactUs/ContactUsReducer";
import generalReducer from "../../general/generalReducer";
import ecodeReducer from "../../Admin/ecode/ecodeReducer";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  translations: translationReducer,
  book: bookReducer,
  operation: operationReducer,
  salesFunnel: salesFunnelReducer,
  domain: domainReducer,
  image: imageReducer,
  course: courseReducer,
  ebook: ebookReducer,
  resource: resourcesReducer,
  exams: examReducer,
  contactUs: ContactUsReducer,
  general: generalReducer,
  ecodesRecords: ecodeReducer
});

export default rootReducer;
