import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router";
import { connect } from "react-redux";
import UserMainPage from "./UserMainPage";
import FunnelPageCustomise from "./FunnelPageCustomise";

class PortalDashboard extends Component {
  render() {
    // const { auth } = this.props;
    // const isAuthenticated = auth.uid && !auth.isAnonymous;
    // if (!isAuthenticated) return <Redirect to="/sales-funnel" />;

    return (
      <Fragment>
        <Switch>
          <Route
            path="/business-portal/sales-funnel/new-funnel-page/:languageCode/:bookId"
            component={FunnelPageCustomise}
          />
          <Route
            path="/business-portal/sales-funnel/funnel-page/:funnelPageId"
            component={FunnelPageCustomise}
          />
          <Route
            path="/business-portal/sales-funnel"
            component={UserMainPage}
          />
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});
export default connect(mapStateToProps)(PortalDashboard);
