import { LOAD_RESOURCE, RESET_RESOURCE } from "./resourcesConstant";

export const loadResource = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // console.log("Loading Resource List");
      let resourceList = [];

      const resourceRef = await db.collection("resources").get();

      resourceRef.forEach((docSnap) => {
        resourceList.push({ ...docSnap.data(), docId: docSnap.id });
      });
      // console.log(resourceList);

      dispatch({ type: LOAD_RESOURCE, payload: { resourceList } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetResource = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_RESOURCE });
    } catch (err) {
      console.log(err);
    }
  };
};
