import React, { Fragment } from "react";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";

const NavigationComponent = ({ location, filterMenuKey }) => {
  return (
    <Fragment>
      <Menu
        theme="light"
        style={{
          fontFamily: "montserrat-regular",
          fontSize: "16px",
          verticalAlign: "middle"
        }}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item
          className="test"
          key={filterMenuKey("/console/generate-e-codes")}
          icon={
            <img
              src="/assets/booking-icon.svg"
              alt=""
              style={{ marginRight: "16px", width: "20px", height: "20px" }}
            />
          }
          style={{
            borderBottom: "1px solid #DFDFDF"
          }}
        >
          <Link to="/console/generate-e-codes">Generate E-Codes</Link>
        </Menu.Item>
      </Menu>
    </Fragment>
  );
};

export default withRouter(NavigationComponent);
