import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./ExamCert.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import { withFirebase } from "react-redux-firebase";
import { getTransText } from "../../SharedUtils";
import { loadAllExam } from "./examActions";
import _ from "lodash";
import {
  CONGRATULATION,
  HEREYOURCERT,
  REVIEWQUEST,
  PRINT,
  DONE,
  BACK,
  LEVEL
} from "../TranslationConstant";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ExamCert extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    let levelId = match.params.levelId;
    this.props.loadAllExam();
    window.scrollTo(0, 0);
    this.setState({
      levelId,
    });
  }

  async componentDidUpdate(prevProps) {
    const { levelId } = this.state;
    const { exams, auth, firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      !_.isEqual(prevProps.exams.examInfoObj, exams.examInfoObj) ||
      (exams.examInfoObj !== null && this.state.isLoading)
    ) {
      // let exam = {};
      // for (const level of exams.examInfoObj) {
      //   if (level.docId === levelId) {
      //     exam = level;
      //   }
      // }
      if (
        auth &&
        auth.progressInfo &&
        auth.progressInfo.exams &&
        auth.progressInfo.exams[levelId] &&
        auth.progressInfo.exams[levelId].certificatePath !== undefined
      ) {
        let url = await storageRef
          .child(auth.progressInfo.exams[levelId].certificatePath)
          .getDownloadURL();
        console.log(url);

        this.setState({
          isLoading: false,
          pdfUrl: url,
        });
      } else {
        var generateExamCert = firebase
          .app()
          .functions("asia-east2")
          .httpsCallable("generateExamCert");
        generateExamCert({
          userId: auth.userInfo.docId,
          levelName: levelId,
          name: auth.userInfo.name,
        })
          .then(async (result) => {
            console.log(result.data);
            let url = await storageRef
              .child(result.data.fileName)
              .getDownloadURL();
            this.setState({
              isLoading: false,
              pdfUrl: url,
            });
          })
          .catch((error) => {
            // Getting the Error details.
            console.log(error);
            // ...
          });
      }
    }
  }
  reviewQuestions = () => {
    const { levelId } = this.state;
    this.props.history.push(`/business-portal/${levelId}/review-questions`);
  };

  print = async (pdfUrl) => {
    window.open(pdfUrl).print();
  };

  back = () => {
    this.props.history.push(`/business-portal/exams`);
  };

  render() {
    const { isLoading, levelId, pdfUrl } = this.state;
    const { exams, auth, translations } = this.props;
    if (isLoading) return <LoadingComponent auth={auth}/>;
    return (
      <Fragment>
        <Row className="exam-row">
          <Col xs={0} sm={0} md={0} lg={0} xl={4} className="exam-left-sidebar">
            <Button
              icon={<LeftOutlined />}
              className="exam-back-button"
              onClick={this.back}
            >
              {
                BACK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />
            {exams.examInfoObj.map((lesson, idx) => {
              if (lesson.docId === levelId) {
                return (
                  <div key={idx} className="lesson-left-sidebar-title">
                    {
                      LEVEL[
                        auth.userInfo.nativeLanguage
                          ? auth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }{" "}
                    {auth.userInfo.nativeLanguage
                      ? getTransText(
                          auth.userInfo.nativeLanguage,
                          lesson.name,
                          translations.translationObj
                        ).text
                      : getTransText(
                          "en",
                          lesson.name,
                          translations.translationObj
                        ).text}
                  </div>
                );
              } else return null;
            })}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={0}
            style={{ paddingTop: "40px", paddingLeft: "40px" }}
          >
            <Button
              icon={<LeftOutlined />}
              className="exam-back-button-small"
              onClick={this.back}
            >
              {
                BACK[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
          </Col>

          <Col xs={0} sm={0} md={0} lg={0} xl={16} className="exam-container">
            <div className="exam-title">
              {
                CONGRATULATION[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <div className="exam-sub-title">
              {
                HEREYOURCERT[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <Document file={pdfUrl} style={{ width: "50px" }}>
              <Page pageNumber={1} />
            </Document>
          </Col>
          <Col xs={0} sm={24} md={24} lg={24} xl={0} className="exam-container">
            <div className="exam-title">
              {
                CONGRATULATION[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <div className="exam-sub-title">
              {
                HEREYOURCERT[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />

            {/* {console.log(pdfUrl)} */}
            <Document file={pdfUrl}>
              <Page pageNumber={1} />
            </Document>
          </Col>
          <Col xs={24} sm={0} md={0} lg={0} xl={0} className="exam-container">
            <div className="exam-title">
              {
                CONGRATULATION[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <div className="exam-sub-title">
              {
                HEREYOURCERT[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </div>
            <br />
            <Document file={pdfUrl} >
              <Page pageNumber={1} />
            </Document>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={4}
            className="exam-right-sidebar"
            style={{ textAlign: "center", paddingTop: "40px" }}
          >
            <Button
              className="exam-continue-button"
              onClick={this.reviewQuestions}
            >
              {
                REVIEWQUEST[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />
            <Button
              className="exam-continue-button"
              onClick={() => this.print(pdfUrl)}
            >
              {
                PRINT[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
            <br />
            <br />

            <Button className="exam-continue-button" onClick={this.back}>
              {
                DONE[
                  auth.userInfo.nativeLanguage
                    ? auth.userInfo.nativeLanguage
                    : "en"
                ]
              }
            </Button>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={0}
            style={{
              left: "0",
              bottom: "0",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Button
              className="exam-continue-button"
              onClick={this.reviewQuestions}
            >
              REVIEW QUESTIONS
            </Button>
            <br />
            <br />
            <Button
              className="exam-continue-button"
              onClick={() => this.print(pdfUrl)}
            >
              PRINT
            </Button>
            <br />
            <br />

            <Button className="exam-continue-button" onClick={this.back}>
              DONE
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  exams: state.exams,
  auth: state.auth,
  translations: state.translations
});

const mapDispatchToProps = {
  loadAllExam,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ExamCert);
